/* eslint-disable camelcase */
import { Complexes } from '../Complexes/types'
import { Entity } from '../Entity/types'
import { ConstructionSites } from '../ConstructionSites/types'
import { Eic } from '../Eic/types'

export enum ModalType {
  CREATE = 'create',
  UPDATE = 'update',
}

export type PortfolioState = {
  portfolioList: PortfolioItem[]
  displaySnackbar: boolean
  isLoadingPortfolio: boolean
  lignes: Ligne[]
  robustestComplexes: Complexes[]
}

export type PortfolioItem = {
  id: string
  libelle: string
  entite: Entity
  // eslint-disable-next-line camelcase
  batiments_voyageurs: Complexes[]
  lignes: Ligne[]
  travaux: ConstructionSites[]
  generic?: boolean
}

export type Ligne = {
  id: string,
  bv_debut: {
    id: string,
    libelle: string,
    code_ci?: string,
    abrev?: string,
    eic?: Eic,
    gaia_id?: string,
  },
  bv_fin: {
    id: string,
    libelle: string,
    code_ci?: string,
    abrev?: string,
    eic?: Eic,
    gaia_id?: string,
  },
  bv_via?: {
    id: string,
    libelle: string,
    code_ci?: string,
    abrev?: string,
    eic?: Eic,
    gaia_id?: string,
  },
  eic?: {
    id?: string,
    libelle?: string,
    dzp?: string,
    animateurs?: [
      {
        cerbere_id: string
      }
    ],
    visiteurs?: [
      {
        cerbere_id: string
      }
    ]
  },
  libelle: string,
}
