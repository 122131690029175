import { CloseOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { ReactElement } from 'react'

interface ModalCloseButtonProps {
  handleClose: () => void
  className?: string
}

const ModalCloseButton = ({ handleClose, className }: ModalCloseButtonProps): ReactElement => (
  <IconButton
    size="small"
    onClick={handleClose}
    className={className}
  >
    <CloseOutlined />
  </IconButton>
)

ModalCloseButton.defaultProps = {
  className: '',
}

export default ModalCloseButton
