import { get, patch, post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { COLUMN_NAMES_COPIL, COLUMN_NAMES_ROADMAP } from 'pages/ActionPlan/utils'
import { ThunkApi } from 'reducers/types'
import { UserSimple } from 'reducers/Users/types'
import { getUsersSimple } from 'reducers/Users/utils'
import { resetForm } from './actionPlan.reducer'
import {
  ActionRaw,
  ComposantAction,
  ComposantWithAction,
  ComposantWithActionRaw,
  GetActionsParams,
  GetClonableObjectsParams,
  GetParams,
  LoadPdfActionPlanParams,
  ObjectClonable,
  PatchActionByIdParams,
  PatchStateActionParams,
  PatchStateActionPilotParams,
  PostComposantsTodoCurJalonParams,
  PostCreateActionParams,
} from './types'

const getObjFerroviaires = createAsyncThunk(
  'get/actionPlan/ObjFerroviaires',
  async (saId: string | null, thunkApi) => {
    try {
      const response = await get(`/robustest/sa/${saId}/plan-action/`)
      return response
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getComposantsByObjFer = createAsyncThunk<ComposantAction[], GetParams, ThunkApi>(
  'get/actionPlan/ComposantsByObjFer',
  async (search: GetParams, thunkApi) => {
    try {
      const response = await get(
        `/robustest/sa/${search.saId}/plan-action/${search.type}/${search.objectId}/composants/`,
      )
      return response
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getComposantsByObjFerCurJalon = createAsyncThunk<ComposantAction[], GetParams, ThunkApi>(
  'get/actionPlan/ComposantsByObjFerCurJalon',
  async (search: GetParams, thunkApi) => {
    try {
      const response = await get(
        `/robustest/sa/${search.saId}/plan-action/${search.type}/${search.objectId}/composants/cur-jalon/`,
      )
      return response
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postComposantsTodoCurJalon = createAsyncThunk<ComposantAction[], PostComposantsTodoCurJalonParams, ThunkApi>(
  'post/actionPlan/ComposantsByObjFerCurJalon',
  async (params: PostComposantsTodoCurJalonParams, thunkApi) => {
    try {
      const response = await post(
        `/robustest/sa/${params.saId}/plan-action/${params.type}/${params.objectId}/composants/`,
        params.composants,
      )
      return response
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getActionColumn = createAsyncThunk<{data: ComposantWithAction[], columnName?: string}, GetParams, ThunkApi>(
  'get/actionPlan/ActionColToClear',
  async (params: GetParams, thunkApi) => {
    try {
      const response = await get(
        `/robustest/sa/${params.saId}/plan-action/${params.type}/${params.objectId}/composants/${params.status}/`,
      )
      const userIdsSet: (string | null | undefined)[] = []
      response.forEach((composant: ComposantWithActionRaw) => {
        composant.actions.forEach((action: ActionRaw) => {
          if (action) {
            userIdsSet.push(action.copilote?.cerbere_id)
            userIdsSet.push(action.pilote?.cerbere_id)
          }
        })
      })
      const usersResponse: UserSimple[] = await getUsersSimple(userIdsSet)

      const responseFormat = response.map((composant: ComposantWithActionRaw) => ({
        ...composant,
        actions: composant.actions.map((action: ActionRaw) => {
          const pilote = usersResponse.find((user: UserSimple) => user.id === action.pilote?.cerbere_id)
          const copilote = usersResponse.find((user: UserSimple) => user.id === action.copilote?.cerbere_id)
          return {
            ...action,
            pilote: pilote || action.pilote,
            copilote: copilote || action.copilote,
          }
        }),
      }))
      return {
        data: responseFormat.filter((composant: ComposantWithAction) => composant.actions.length > 0),
        columnName: params.status,
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getActionsByComposant = createAsyncThunk(
  'get/actionPlan/ActionsByComposant',
  async (args: GetActionsParams, thunkApi) => {
    try {
      const response: ActionRaw[] = await get(
        `/robustest/sa/${args.saId}/plan-action/${args.type}/${args.objectId}/composants/${args.componentId}/actions/`,
      )
      const usersList: (string | undefined)[] = []
      response.forEach((action: ActionRaw) => {
        usersList.push(action.pilote?.cerbere_id)
        usersList.push(action.copilote?.cerbere_id)
      })
      const usersResponse: UserSimple[] = await getUsersSimple(usersList)
      return response.map((rawAction: ActionRaw) => ({
        ...rawAction,
        pilote: usersResponse.find((user: UserSimple) => user.id === rawAction.pilote?.cerbere_id),
        copilote: usersResponse.find((user: UserSimple) => user.id === rawAction.copilote?.cerbere_id),
      }))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postCreateAction = createAsyncThunk(
  'post/actionPlan/CreateAction',
  async (params: PostCreateActionParams, thunkApi) => {
    try {
      const response = await post(
        // eslint-disable-next-line max-len
        `/robustest/sa/${params.saId}/plan-action/${params.type}/${params.objectId}/composants/${params.composantsId}/actions/`,
        {
          ...params.action,
          pilote: params.action.pilote?.id ? { cerbere_id: params.action.pilote.id } : null,
          copilote: params.action.copilote?.id ? { cerbere_id: params.action.copilote.id } : null,
          entite: params.action.entite.map(entite => entite.id),
        },
      )
      thunkApi.dispatch(getActionsByComposant({
        saId: params.saId,
        type: params.type,
        objectId: params.objectId,
        componentId: params.composantsId,
      }))
      COLUMN_NAMES_ROADMAP.forEach(status => {
        thunkApi.dispatch(getActionColumn({
          saId: params.saId,
          type: params.type,
          objectId: params.objectId,
          status,
        }))
      })
      thunkApi.dispatch(getComposantsByObjFer({
        saId: params.saId,
        objectId: params.objectId,
        type: params.type,
      }))
      thunkApi.dispatch(resetForm())
      return response
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const patchActionById = createAsyncThunk(
  'patch/actionPlan/ActionById',
  async (params: PatchActionByIdParams, thunkApi) => {
    try {
      const response = await patch(
        // eslint-disable-next-line max-len
        `/robustest/sa/${params.saId}/plan-action/${params.type}/${params.objectId}/composants/${params.composantsId}/actions/${params.action.id}/`,
        {
          ...params.action,
          pilote: params.action.pilote?.id ? { cerbere_id: params.action.pilote.id } : null,
          copilote: params.action.copilote?.id ? { cerbere_id: params.action.copilote.id } : null,
          entite: params.action.entite.map(entite => entite.id),
        },
      )
      COLUMN_NAMES_ROADMAP.forEach(status => {
        thunkApi.dispatch(getActionColumn({
          saId: params.saId,
          type: params.type,
          objectId: params.objectId,
          status,
        }))
      })
      thunkApi.dispatch(getActionsByComposant({
        saId: params.saId,
        type: params.type,
        objectId: params.objectId,
        componentId: params.composantsId,
      }))
      thunkApi.dispatch(resetForm())
      return {
        ...params.action,
        ...response,
        pilote: params.action.pilote,
        copilote: params.action.copilote,
      }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const patchStateAction = createAsyncThunk(
  'patch/actionPlan/StateAction',
  async (params: PatchStateActionParams, thunkApi) => {
    try {
      const response = await patch(
        // eslint-disable-next-line max-len
        `/robustest/sa/${params.saId}/plan-action/${params.type}/${params.objectId}/composants/${params.composantsId}/actions/${params.actionId}/animateur/`,
        params.data,
      )
      COLUMN_NAMES_COPIL.forEach(status => {
        thunkApi.dispatch(getActionColumn({
          saId: params.saId,
          type: params.type,
          objectId: params.objectId,
          status,
        }))
      })
      COLUMN_NAMES_ROADMAP.forEach(status => {
        thunkApi.dispatch(getActionColumn({
          saId: params.saId,
          type: params.type,
          objectId: params.objectId,
          status,
        }))
      })
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const patchStateActionPilot = createAsyncThunk(
  'patch/actionPlan/StateActionPilot',
  async (params: PatchStateActionPilotParams, thunkApi) => {
    try {
      const response = await patch(
        // eslint-disable-next-line max-len
        `/robustest/sa/${params.saId}/plan-action/${params.type}/${params.objectId}/composants/${params.composantsId}/actions/${params.actionId}/pilote/`,
        params.data,
      )
      COLUMN_NAMES_ROADMAP.forEach(status => {
        thunkApi.dispatch(getActionColumn({
          saId: params.saId,
          type: params.type,
          objectId: params.objectId,
          status,
        }))
      })
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getClonableObjects = createAsyncThunk(
  'get/ClonableObjects',
  async (params: GetClonableObjectsParams, thunkApi) => {
    try {
      const response: ObjectClonable[] = await get(`/robustest/sa/${params.saId}/plan-action/objets/`, {
        composant: params.componentId,
        jalon: params.jalonNumber,
      })
      return response.filter(obj => obj.id !== params.objectId)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postCloneAction = createAsyncThunk(
  'post/actionPlan/CloneAction',
  async (params: PostCreateActionParams, thunkApi) => {
    try {
      const response = await post(
        // eslint-disable-next-line max-len
        `/robustest/sa/${params.saId}/plan-action/${params.type}/${params.objectId}/composants/${params.composantsId}/actions/`,
        {
          ...params.action,
          pilote: params.action.pilote?.id ? { cerbere_id: params.action.pilote.id } : null,
          copilote: params.action.copilote?.id ? { cerbere_id: params.action.copilote.id } : null,
          entite: params.action.entite.map(entite => entite.id),
        },
      )
      thunkApi.dispatch(resetForm())
      return response
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const loadPdfActionPlan = createAsyncThunk(
  'get/actionPlan/Pdf',
  async (params: LoadPdfActionPlanParams, thunkApi) => {
    try {
      const response = await get(
        `/robustest/sa/${params.sa}/plan-action/pdf/${params.mode}/`, {}, undefined, { responseType: 'blob' },
      )

      return response
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export {
  getObjFerroviaires,
  getComposantsByObjFer,
  getComposantsByObjFerCurJalon,
  postComposantsTodoCurJalon,
  postCreateAction,
  getActionColumn,
  getActionsByComposant,
  patchActionById,
  patchStateAction,
  patchStateActionPilot,
  getClonableObjects,
  postCloneAction,
  loadPdfActionPlan,
}
