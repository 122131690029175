import { ReactElement } from 'react'
import { NavLink } from 'react-router-dom'
import { Typography } from '@mui/material'
import { ArrowBackIos } from '@mui/icons-material'
import slugify from 'slugify'
import { UserMeRoles, UserRole } from 'reducers/Users/types'
import { ObjectFerTab } from 'reducers/SA/types'
import PopOver from 'components/PopOver/PopOver'
import CustomSwitch from 'components/CustomSwitch/CustomSwitch'
import terms from 'common/terms'

type ContributionsHeaderProps = {
  activeObjFer: ObjectFerTab | null
  activeJalon: number | null
  activeSa: UserMeRoles | null
  switchAllContribution: boolean
  handleSwitchAllContribution: () => void
}

const ContributionsHeader = ({
  activeSa, activeJalon, activeObjFer, switchAllContribution, handleSwitchAllContribution,
}: ContributionsHeaderProps): ReactElement => (
  <div className="contributions-header d-flex w-100">
    <NavLink
      to={`/tableau-suivi/${slugify(activeSa?.libelle || '' as string)}`}
      className="back-button"
    >
      <ArrowBackIos fontSize="large" />
    </NavLink>
    <div className="contributions-header-text d-flex flex-column">
      <div className="header-title d-flex align-items-center">
        <Typography variant="h5">
          {`${activeSa?.libelle} - J${activeJalon}`}
        </Typography>
        {activeObjFer && (
          <>
            <Typography variant="h5" className="pl-2 pr-2"> | </Typography>
            { activeObjFer?.type === 'L' ? (
              <PopOver popContent={activeObjFer.libelle}>
                <Typography variant="h5">{activeObjFer?.abrev}</Typography>
              </PopOver>
            ) : (
              <Typography variant="h5">{activeObjFer?.libelle}</Typography>
            )}
          </>
        )}
      </div>
    </div>
    <div className="switch-all">
      {
        activeSa?.roles.includes(UserRole.animatorAxe) && (
          <CustomSwitch
            value={switchAllContribution}
            handleChange={handleSwitchAllContribution}
            label={terms.Suivi.labelToggleSwitchAllContribution}
          />
        )
      }
    </div>
  </div>
)

export default ContributionsHeader
