import { Action, ActionRaw } from 'reducers/ActionPlan/types'
import { Entity } from 'reducers/Admin/Entity/types'
import { Territories, UserSimple } from 'reducers/Users/types'

/* eslint-disable camelcase */
export type MonitoringState = {
  zpMonitorings: ZpMonitoring[]
  possibleEic: PossibleEic[]
  possibleYears: string[]
  isLoadingData: boolean
  isLoadingEf: boolean
  possiblePortfolio: PortfolioSimple[]
  supervisionEfList: SupervisionEfWithUser[]
}

export type ZpMonitoring = {
  id: string
  type: string
  commentaire: string
  libelle: string
  libelle_eic: string
  abrev: string
  date_copil: string
  cotation: string
  gravite: string
  score: number
  max: number
  status_jalon: string
  composants: ComposantMonitoring[]
}

export type ComposantMonitoring = {
  id: number
  libelle: string
  cotation: string
  proposition: string | null
  analyse: string | null
  date: string | null
  animator: string | null | UserSimple
}

export type PossibleEic = {
  id: string
  libelle: string
}

export type ParamsMonitoringDzp = {
  annee: string
  eic: string[]
  numero_jalon: number
}

// DZP Module
export type CotationsPerComponent = {
  id: number,
  libelle: string,
  cotations: CotationCell[]
}

export type CotationCell = {
  cotation: string | null,
  proposition: string | null,
  analyse: string | null,
  date: string | null
  animator: null | UserSimple
}

export type DzpFooterTable = {
  globalCotation: {
    commentaire: string | null,
    cotation: string | null
  }[]
  composite: {
      score: number | null,
      max: number | null,
    }[]
  gravity: (string | null)[]
}
// End DZP Module

// Contributions Module
export enum SelectType {
  ENTITY= 'entity',
  PORTFOLIO= 'portfolio',
  YEAR= 'year',
  COMPONENT= 'component',
}

export enum OrderingByEnum {
  COMPONENT = 'composant',
  TERRITORY = 'objet ferroviaire',
}

export type PortfolioSimple = {
  id: string
  libelle: string
}

export type ContributionWithUser = ContributionRes & {
  auteur: UserSimple
}

export type ContributionRes = {
  id: string
  auteur: string
  createur: string
  numero_jalon: number
  analyse: string
  proposition: string
  cotation: string
  timestamp: string
  file: string | null
  role: string
  is_global: boolean
  visibilite: Entity[]
  axes: Territories[]
  from_axe: boolean
}

export type SupervisionEf = {
  type: string,
  libelle: string,
  libelle_eic: string,
  composant_libelle: string,
  composant_id: number,
  abrev: string,
  cotation: string,
  numerator: number,
  denominator: number,
  contributions: ContributionRes[]
  actions: ActionRaw[]
}

export type SupervisionEfWithUser = {
  type: string,
  libelle: string,
  libelle_eic: string,
  composant_libelle: string,
  composant_id: number,
  abrev: string,
  cotation: string,
  numerator: number,
  denominator: number,
  contributions: ContributionWithUser[]
  actions: Action[]
}

export type ParmasGetSupervisionEf = {
  portefeuille: string
  entite: string
  annee: string
  composants: number[]
  numero_jalon: number
}
// End Contributions Module
