import terms from 'common/terms'
import { ReactElement } from 'react'
import { Contribution } from 'reducers/Contributions/types'

interface AnimatorToggleProps {
  isGlobal: boolean | null
  setIsGlobal: (arg: boolean | null) => void
  selectedContribution: Contribution | null
}

const AnimatorToggle = ({
  isGlobal, setIsGlobal, selectedContribution,
}: AnimatorToggleProps): ReactElement => (
  <div className="btn-group btn-group-toggle mb-2">
    <label
      className={`btn btn-outline-grey 
                ${isGlobal ? 'btn-active ' : ' '}
                ${selectedContribution !== null ? 'btn-disabled ' : ' '}
                `}
      htmlFor="button-global"
    >
      <input
        name="types"
        readOnly
        type="checkbox"
        id="button-global"
        disabled={selectedContribution !== null}
        checked={isGlobal === true}
        onClick={() => setIsGlobal(true)}
      />
      {terms.Contributions.editor.globalContrib}
    </label>
    <label
      className={`btn btn-outline-grey
                ${!isGlobal ? 'btn-active ' : ' '}
                ${selectedContribution !== null ? 'btn-disabled ' : ' '}
                `}
      htmlFor="button-specific"
    >
      <input
        name="types"
        readOnly
        type="checkbox"
        id="button-specific"
        disabled={selectedContribution !== null}
        checked={isGlobal === false}
        onClick={() => setIsGlobal(false)}
      />
      {terms.Contributions.editor.specificContrib}
    </label>
  </div>
)

export default AnimatorToggle
