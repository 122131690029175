import { GRAVITY } from 'common/cotation'
import terms from 'common/terms'
import { ReactElement } from 'react'

interface Props {
  gravityInput: number;
  disabled: boolean;
  setGravity: (gravity: number) => void;
}

const SelectGravity = ({ setGravity, gravityInput, disabled }: Props): ReactElement => (
  <div className="cotations">
    <div className="libelle-contribution">
      {terms.Suivi.GlobalContribution.labelGravity}
    </div>
    <div className="cotation-container">
      {
        GRAVITY.map(gravityItem => (
          <button
            type="button"
            key={gravityItem.value}
            onClick={() => setGravity(gravityInput === gravityItem.value ? 0 : gravityItem.value)}
            className={gravityInput === gravityItem.value ? 'selected gravity' : ''}
            disabled={disabled}
          >
            <span>
              {gravityItem.label}
            </span>
          </button>
        ))
      }
    </div>
  </div>
)

export default SelectGravity
