import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { UserMeRoles } from 'reducers/Users/types'
import { RootState } from 'Store'
import slugify from 'slugify'
import { setSavedSa } from 'reducers/app'
import { useEffect } from 'react'

const useChangeSaURL = (
  setValue: (value: UserMeRoles) => void, valuesList: UserMeRoles[]): (value: UserMeRoles | null
) => void => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { idSA } = useSelector((state: RootState) => state.app)

  const changeUrlSAHistory = (newValue: UserMeRoles | null) => {
    if (valuesList.length !== 0) {
      const selectedSa = valuesList.find((sa: UserMeRoles) => sa.id === idSA)
      if (selectedSa) {
        setValue(selectedSa)
      } else {
        setValue(valuesList[0])
        dispatch(setSavedSa(
          { idSA: valuesList[0].id, labelSA: valuesList[0].libelle, eicLibelle: valuesList[0].eic.libelle },
        ))
        history.push(`${slugify(valuesList[0].libelle)}`)
      }
    }
    if (newValue !== null) {
      dispatch(setSavedSa({ idSA: newValue.id, labelSA: newValue.libelle, eicLibelle: newValue.eic.libelle }))
      setValue(valuesList.filter((sa: UserMeRoles) => sa.id === newValue.id)[0])
      history.push(`${slugify(newValue.libelle)}`)
    }
  }

  useEffect(() => {
    const newSa = valuesList.find((sa: UserMeRoles) => sa.id === idSA)
    changeUrlSAHistory(newSa || null)
  }, [valuesList])

  return changeUrlSAHistory
}

export default useChangeSaURL
