import terms from 'common/terms'
import { SettingPdfList, SettingPdfToggled } from 'reducers/Suivi/types'

type IsFormValid = (
  objectList: SettingPdfList,
  objectToggle: SettingPdfToggled,
  termsChecked: boolean,
  jalon: number,
  ) => boolean
const isFormValid: IsFormValid = (objectList, objectToggle, termsChecked, jalon) => {
  const { display, ...userContributor } = objectToggle
  if (jalon === 0) {
    return (objectList.entites.length > 0)
      && termsChecked
      && Object.values(userContributor).some(v => v === true)
  }
  return (Object.values(objectList).some(v => v.length > 0)
    && termsChecked
    && (typeof display === 'boolean')
    && Object.values(userContributor).some(v => v === true))
}

type ObjectName = (name: string) => keyof SettingPdfList
const objectName: ObjectName = name => {
  if (name === terms.Suivi.pdfTerms.bv.travaux.title) {
    return 'travaux'as keyof SettingPdfList
  }
  return name.toLowerCase() as keyof SettingPdfList
}

type DownloadPdf = (data: ArrayBuffer, libelle: string) => void
const downloadPdf: DownloadPdf = (data, libelle) => {
  const filename = new Date().toLocaleDateString('fr-FR')
  const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${libelle}-${filename}.pdf`)
  document.body.appendChild(link)
  link.click()
}

export { isFormValid, objectName, downloadPdf }
