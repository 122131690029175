import {
  ChangeEvent, ReactElement,
} from 'react'
import {
  TextField, TextareaAutosize, FormControlLabel,
  Checkbox,
  Select,
  Box,
  MenuItem,
  Chip,
  SelectChangeEvent,
  InputLabel,
  FormControl,
  OutlinedInput,
} from '@mui/material'
import terms from 'common/terms'
import { PrimaryBtn, SecondaryBtn } from 'themes/theme'
import CustomDatePicker from 'components/DatePicker/CustomDatePicker'
import UserSearch from 'components/UserSearch/UserSearch'
import { CreateAction } from 'reducers/ActionPlan/types'
import { Entity } from 'reducers/Admin/Entity/types'
import { User } from 'reducers/Users/types'
import {
  selectUserCoPilote, selectUserPilote,
} from 'reducers/ActionPlan/actionPlan.reducer'
import './ModalForm.scss'

interface ModalFormProps {
  title: string,
  action: CreateAction
  entities: Entity[]
  selectedPilote: User | null
  selectedCoPilote: User | null
  handleChangeName?: (e: ChangeEvent<HTMLInputElement>) => void
  handleChangeEntity?: (event: SelectChangeEvent<string[]>) => void
  handleChangeDateOuverture?: (date: string) => void
  handleChangeDateBouclage?: (date: string) => void
  handleChangeDescription?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  handleChangeBesoinValidation?: () => void
  closeButton: () => void
  disableButton?: boolean
  disabledCheckBox?: boolean
  handleValidate?: () => void
  validateLabel?: string
  disabledForm: boolean
}

const ModalForm = ({
  title, action, entities, selectedPilote, selectedCoPilote,
  handleChangeName, handleChangeEntity, handleChangeDescription, handleChangeBesoinValidation,
  handleChangeDateBouclage, handleChangeDateOuverture,
  closeButton, disableButton, handleValidate, disabledForm, validateLabel, disabledCheckBox,
}: ModalFormProps): ReactElement => (
  <div className="modal-form">
    <div className="modal-form-title">
      {title}
    </div>
    <div className="modal-form-form">
      <TextField
        size="small"
        disabled={disabledForm}
        className="modal-form-form-name"
        id="outlined-basic"
        label={terms.actionPlan.modalAddAction.form.champName}
        variant="outlined"
        value={action.nom}
        onChange={handleChangeName}
      />
      <div className="modal-form-form-entity">
        <FormControl sx={{ width: '100%' }}>
          <InputLabel id="select-entities-label">{terms.actionPlan.modalAddAction.form.champEntite}</InputLabel>
          <Select
            labelId="select-entities-label"
            disabled={disabledForm}
            className="w-100"
            multiple
            value={action.entite.flatMap(entity => entity?.id || [])}
            onChange={handleChangeEntity}
            input={<OutlinedInput label={terms.actionPlan.modalAddAction.form.champEntite} />}
            renderValue={selected => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map(value => (
                  <Chip
                    variant="outlined"
                    key={entities.find(e => e.id === value)?.id}
                    label={entities.find(e => e.id === value)?.libelle}
                  />
                ))}
              </Box>
            )}
          >
            { entities.map(e => (
              <MenuItem key={e.libelle} value={e.id}>
                {e.libelle}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="modal-form-form-dates">
        <div className="modal-form-form-dates-open">
          <span className="modal-form-form-dates-span">
            {terms.actionPlan.modalAddAction.form.openDate}
          </span>
          <div className="modal-form-form-dates-date">
            <CustomDatePicker
              copilDate={action.date_ouverture}
              disabled={disabledForm}
              setDate={handleChangeDateOuverture}
            />
          </div>
        </div>
        <div className="modal-form-form-dates-close">
          <span className="modal-form-form-dates-span">
            {terms.actionPlan.modalAddAction.form.closeDate}
          </span>
          <div className="modal-form-form-dates-date">
            <CustomDatePicker
              copilDate={action.date_bouclage}
              disabled={disabledForm}
              setDate={handleChangeDateBouclage}
            />
          </div>
        </div>
      </div>
      <TextareaAutosize
        disabled={disabledForm}
        className="modal-form-form-description"
        placeholder={terms.actionPlan.modalAddAction.form.champDescription}
        minRows={6}
        value={action.description}
        onChange={handleChangeDescription}
      />
      <div className="modal-form-form-acteurs">
        <UserSearch
          disabled={disabledForm}
          placeholder={terms.actionPlan.modalAddAction.form.champPA}
          selectedUser={selectedPilote}
          setSelectedUser={selectUserPilote}
          size="small"
          className="modal-form-form-acteurs-pilote"
        />
        <UserSearch
          disabled={disabledForm}
          placeholder={terms.actionPlan.modalAddAction.form.champCopilote}
          selectedUser={selectedCoPilote}
          setSelectedUser={selectUserCoPilote}
          size="small"
          className="modal-form-form-acteurs-pilote"
        />
      </div>
      <div className="modal-form-form-dates">
        {action.date_realisation && (
          <div className="modal-form-form-dates-open">
            <span className="modal-form-form-dates-span">
              {terms.actionPlan.modalAddAction.form.finishDate}
            </span>
            <div className="modal-form-form-dates-date">
              <CustomDatePicker disabled copilDate={action.date_realisation} />
            </div>
          </div>
        )}
        {action.date_cloture && (
          <div className="modal-form-form-dates-close">
            <span className="modal-form-form-dates-span">
              {terms.actionPlan.modalAddAction.form.closureDate}
            </span>
            <div className="modal-form-form-dates-date">
              <CustomDatePicker disabled copilDate={action.date_cloture} />
            </div>
          </div>
        )}
      </div>
      { !disabledForm && handleChangeBesoinValidation && (
      <FormControlLabel
        className="modal-form-form-checkbox"
        disabled={disabledCheckBox}
        control={(
          <Checkbox
            checked={action.besoin_validation}
            onChange={handleChangeBesoinValidation}
          />
            )}
        label={terms.actionPlan.modalAddAction.form.terms}
      />
      )}
    </div>
    <div className="modal-form-buttons">
      <div>
        <SecondaryBtn
          onClick={closeButton}
        >
          {terms.Common.cancel}
        </SecondaryBtn>
      </div>
      { handleValidate && (
      <div>
        <PrimaryBtn
          disabled={disableButton}
          onClick={handleValidate}
        >
          {validateLabel}
        </PrimaryBtn>
      </div>
      )}
    </div>
  </div>
)

ModalForm.defaultProps = {
  handleChangeName: undefined,
  handleChangeDescription: undefined,
  handleChangeEntity: undefined,
  handleChangeDateBouclage: undefined,
  handleChangeDateOuverture: undefined,
  handleChangeBesoinValidation: undefined,
  disableButton: false,
  disabledCheckBox: false,
  handleValidate: undefined,
  validateLabel: terms.Common.confirm,
}

export default ModalForm
