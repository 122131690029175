import { ReactElement } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { format, getMonth, getYear } from 'date-fns'
import {
  Button, InputAdornment, OutlinedInput,
} from '@mui/material'
import fr from 'date-fns/locale/fr'
import './stylesheets/datepicker.scss'
import { CalendarMonthTwoTone } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { months, years } from './const'

registerLocale('fr', fr)

interface CustomDateProps {
  copilDate: string,
  setOpen?: (v: boolean) => void,
  disabled: boolean,
  setDate?: (v: string) => void,
}
export default function CustomDatePicker({
  copilDate,
  setOpen,
  disabled,
  setDate,
}: CustomDateProps): ReactElement {
  const dispatch = useDispatch()

  return (
    <DatePicker
      portalId="root-portal"
      disabled={disabled}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button size="small" className="arrow-btn" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {'<'}
          </Button>
          <select
            className="select-datepicker"
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
          >
            {months.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <select
            className="select-datepicker"
            value={getYear(date).toString()}
            onChange={({ target: { value } }) => changeYear(+value)}
          >
            {years.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <Button size="small" className="arrow-btn" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {'>'}
          </Button>
        </div>
      )}
      selected={new Date(copilDate)}
      dateFormat="dd/MM/yyyy"
      onChange={date => {
        if (date !== null && setDate !== undefined) {
          dispatch(setDate(format(new Date(date), 'yyyy-MM-dd')))
          if (setOpen) {
            setOpen(true)
          }
        }
      }}
      locale="fr"
      customInput={(
        <OutlinedInput
          className="picker-input"
          endAdornment={(
            <InputAdornment position="end">
              <CalendarMonthTwoTone />
            </InputAdornment>
            )}
        />
)}
    />
  )
}

CustomDatePicker.defaultProps = {
  setOpen: undefined,
  setDate: undefined,
}
