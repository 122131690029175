import { Autocomplete, FormLabel, TextField } from '@mui/material'
import terms from 'common/terms'
import UserSearch from 'components/UserSearch/UserSearch'
import {
  ChangeEvent, ReactElement, SyntheticEvent, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ContributorFunction } from 'reducers/Admin/ContributorFunction/types'
import { ParamComplexe } from 'reducers/Parametrage/Complexes/types'
import { ParamConstructionSite } from 'reducers/Parametrage/ConstructionSites/types'
import {
  setSaComplexes, setSaLignes, setSaTravaux, selectUser,
} from 'reducers/Parametrage/Contributeurs/contributeurs.reducer'
import { ParamContributeur } from 'reducers/Parametrage/Contributeurs/types'
import { ParamLigne } from 'reducers/Parametrage/ParamLignes/types'
import { User } from 'reducers/Users/types'
import { RootState } from 'Store'
import { PrimaryBtn } from 'themes/theme'
import { getLabelLigne } from 'reducers/Parametrage/utils'
import PerimeterColumn from './PerimeterColumn'
import './contributorsAdd.scss'

interface ContributorsAddProps {
  contributor: ParamContributeur,
  user: User | null,
  userFunction: ContributorFunction | null,
  functionOptions: ContributorFunction[],
  onFunctionChange: (event: SyntheticEvent, value: ContributorFunction | null) => void,
  handleCheckComplexe: (element: ParamComplexe) => (event?: ChangeEvent, checked?: boolean) => void,
  handleCheckLigne: (element: ParamLigne) => (event?: ChangeEvent, checked?: boolean) => void,
  handleCheckTravaux: (element: ParamConstructionSite) => (event?: ChangeEvent, checked?: boolean) => void,
  handleValidate: () => void,
  validateLabel?: string,
  readOnlyUser?: boolean,
}

const ContributorsAdd = ({
  contributor, user, userFunction, functionOptions, onFunctionChange, handleCheckComplexe,
  handleCheckLigne, handleCheckTravaux, handleValidate, readOnlyUser, validateLabel,
}: ContributorsAddProps): ReactElement => {
  const dispatch = useDispatch()
  const { isLoading } = useSelector((state: RootState) => state.users)
  const { listComplexesSa } = useSelector((state: RootState) => state.paramComplexes)
  const { listLignesSa } = useSelector((state: RootState) => state.paramLignes)
  const { listConstructionSitesSa } = useSelector((state: RootState) => state.paramConstructionSites)
  const [complexesSA, setComplexesSA] = useState<ParamComplexe[]>([])
  const [lignesSa, setLignesSa] = useState<ParamLigne[]>([])
  const [travauxSa, setTravauxSa] = useState<ParamConstructionSite[]>([])
  const [functionSearch, setFunctionSearch] = useState('')

  useEffect(() => {
    if (listComplexesSa) {
      setComplexesSA(listComplexesSa.filter(
        (item: ParamComplexe) => item.active,
      ))
    }
    if (listLignesSa) {
      setLignesSa(listLignesSa.filter((item: ParamLigne) => item.active))
    }
    if (listConstructionSitesSa) {
      setTravauxSa(listConstructionSitesSa.filter(
        (item: ParamConstructionSite) => item.active,
      ))
    }
  }, [listComplexesSa, listLignesSa, listConstructionSitesSa])

  useEffect(() => {
    if (listComplexesSa) {
      dispatch(setSaComplexes(
        listComplexesSa.filter(item => item.active),
      ))
    }
  }, [listComplexesSa])

  useEffect(() => {
    if (listLignesSa) {
      dispatch(setSaLignes(listLignesSa.filter(item => item.active)))
    }
  }, [listLignesSa])

  useEffect(() => {
    if (listConstructionSitesSa) {
      dispatch(setSaTravaux(
        listConstructionSitesSa.filter(item => item.active),
      ))
    }
  }, [listConstructionSitesSa])

  return (
    <div className="contributors-add">
      <UserSearch
        placeholder={terms.Settings.contributor.userSearchPlaceholder}
        selectedUser={user}
        setSelectedUser={selectUser}
        readOnlyUser={readOnlyUser}
        size="small"
        className="contributors-add-user"
        label={terms.Settings.contributor.user}
      />
      <div className="contributors-add-function my-4">
        <FormLabel required>
          {terms.Settings.contributor.function}
        </FormLabel>
        <Autocomplete
          disablePortal
          loading={isLoading}
          options={functionOptions.filter(func => func.active && func.entite.active)}
          getOptionLabel={option => option.libelle}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          noOptionsText={functionSearch ? terms.Common.noResults : terms.Settings.contributor.noFunctionSearch}
          value={userFunction}
          renderInput={params => (
            <TextField
              {...params}
              placeholder={terms.Settings.contributor.functionSearchPlaceholder}
              fullWidth
              size="small"
            />
          )}
          onInputChange={(_, newInputValue) => setFunctionSearch(newInputValue)}
          onChange={onFunctionChange}
        />
      </div>
      <div className="contributor-perimeter">
        <FormLabel required>
          {terms.Settings.contributor.perimeter}
        </FormLabel>
        <div className="perimeter-columns-container">
          {listComplexesSa && contributor?.batiments_voyageurs && (
            <PerimeterColumn
              columnLabel={terms.Settings.contributor.listComplexe}
              allLabel={terms.Settings.contributor.allComplexes}
              options={complexesSA}
              selectedOptions={contributor.batiments_voyageurs.filter(
                item => item.active,
              )}
              handleCheck={handleCheckComplexe}
              getId={elem => elem?.batiment_voyageurs?.id}
              getLabel={elem => elem?.batiment_voyageurs?.libelle}
            />
          )}
          {listLignesSa && contributor?.lignes && (
            <PerimeterColumn
              columnLabel={terms.Settings.contributor.listLigne}
              allLabel={terms.Settings.contributor.allLignes}
              options={lignesSa}
              selectedOptions={contributor.lignes.filter(
                item => item.active,
              )}
              handleCheck={handleCheckLigne}
              getId={elem => elem?.id}
              getLabel={elem => {
                if (elem?.ligne?.bv_debut?.libelle && elem.ligne.bv_fin?.libelle) {
                  return getLabelLigne(
                    elem.ligne.bv_debut.libelle, elem.ligne.bv_fin.libelle, elem.ligne.bv_via?.libelle,
                  )
                }
                return ''
              }}
            />
          )}
          {listConstructionSitesSa && contributor?.travaux && (
            <PerimeterColumn
              columnLabel={terms.Settings.contributor.listTravaux}
              allLabel={terms.Settings.contributor.allTravaux}
              options={travauxSa}
              selectedOptions={contributor.travaux.filter(
                item => item.active,
              )}
              handleCheck={handleCheckTravaux}
              getId={elem => elem?.id}
              getLabel={elem => elem?.travaux?.libelle}
            />
          )}
        </div>
        <div className="text-center mt-4">
          <PrimaryBtn
            disabled={!contributor?.utilisateur || !contributor?.fonction}
            onClick={handleValidate}
          >
            {validateLabel}
          </PrimaryBtn>
        </div>
      </div>
    </div>
  )
}

ContributorsAdd.defaultProps = {
  readOnlyUser: false,
  validateLabel: terms.Common.add,
}

export default ContributorsAdd
