import {
  Autocomplete,
  FormLabel,
  TextField,
} from '@mui/material'
import terms from 'common/terms'
import React, { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { searchComplexes } from 'reducers/Admin/Complexes/complexes.thunk'
import {
  closeModalConfirmation,
  closeModalErrComplexe,
  hideSnackbarAdd,
  hideSnackbarUpdate,
  hideSnackbarDelete,
  inputAddComplexeSa,
  onModalAppartComplex,
  onModalAssosComplex,
  setSaComplexe,
  changeSaComplexe,
} from 'reducers/Parametrage/Complexes/complexes.reducer'
import { initialcomplexes } from 'reducers/Admin/Complexes/complexes'
import {
  deleteSaComplexe, postSaComplexe, getDefaultJalonsComplexe, patchSAComplexe,
  getSaComplexes,
} from 'reducers/Parametrage/Complexes/complexes.thunk'
import { RootState } from 'Store'
import ModalInfo from 'components/Modal/ModalInfo/ModalInfo'
import ModalError from 'components/Modal/ModalError/ModalError'
import ModalConfirmation from 'components/Modal/ModalConfirmation/ModalConfirmation'
import ModalObjectComposants from 'components/Modal/ModalObjectComposants/ModalObjectComposants'
import CustomSnackbar from 'components/Snackbar/Snackbar'
import { PrimaryBtn } from 'themes/theme'
import './complexes.scss'
import Loader from 'components/Loader'
import DragComplex from './dragComplexe/DragComplexe'
import { JALONS_NUMBERS } from '../constants'
import AutocompleteOption from './AutocompleteOption'

import '../settings.scss'

const ParamComplexes = (): ReactElement => {
  const dispatch = useDispatch()
  const {
    modalConfirmation,
    selectedComplexe,
    addComplexeSa,
    errors,
    modalErrAssosComplexe,
    modalErrComplexe,
    displaySnackbarAdd,
    displaySnackbarUpdate,
    displaySnackbarDelete,
    saComplexe,
    openComposantsModal,
    defaultComposantsComplexes,
  } = useSelector((state: RootState) => state.paramComplexes)
  const listAllComplexes = useSelector((state: RootState) => state.complexes.complexes)
  const { userMeSettings } = useSelector((state: RootState) => state.users)
  const { idSA, labelSA } = useSelector((state: RootState) => state.app)
  const { isLoadingComplexe } = useSelector((state: RootState) => state.paramComplexes)

  const thisSA = userMeSettings.find(item => item.id === idSA)

  useEffect(() => {
    dispatch(searchComplexes(''))
    dispatch(getSaComplexes(idSA))
  }, [idSA])

  useEffect(() => {
    if (errors) {
      if (errors.data[0].includes('appartient')) {
        dispatch(onModalAppartComplex())
      } else {
        dispatch(onModalAssosComplex())
      }
    }
  }, [errors])

  useEffect(() => {
    JALONS_NUMBERS.forEach(n => dispatch(getDefaultJalonsComplexe(n)))
  }, [])

  const handleDelete = () => {
    dispatch(deleteSaComplexe(selectedComplexe?.id))
    dispatch(closeModalConfirmation())
  }

  const handlePatchValidate = () => dispatch(patchSAComplexe({
    idSA,
    idBV: saComplexe?.id,
    complexe: {
      sa_jalon: saComplexe?.sa_jalon,
    },
  }))

  const readOnlyParamJalon = !!saComplexe?.batiment_voyageurs?.eic
  && !labelSA?.includes(saComplexe?.batiment_voyageurs?.eic?.libelle || '')

  return (
    <>
      {
        isLoadingComplexe ? (
          <div className="setting-loader">
            <Loader message={terms.Common.loading} />
          </div>
        ) : (
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-around">
              <span className=" w-50 ml-3 mt-3 font-weight-bold">
                {terms.Settings.complexe.listNotActive}
              </span>
              <span className=" w-50 ml-3 mt-3 font-weight-bold">
                {terms.Settings.complexe.listActive}
              </span>
            </div>
            <div className="d-flex">
              <DragComplex />
            </div>
            <div className="searchContainer mt-3">
              <FormLabel required className="labelSearch">
                {terms.Settings.complexe.search}
              </FormLabel>
              <Autocomplete
                className="searchComplex"
                disablePortal
                id="searchComplex"
                options={listAllComplexes}
                value={addComplexeSa || null}
                onChange={(_e, newValue) => {
                  dispatch(inputAddComplexeSa(newValue))
                }}
                onOpen={() => {
                  if (addComplexeSa) {
                    dispatch(searchComplexes(addComplexeSa?.libelle))
                  } else {
                    dispatch(initialcomplexes())
                  }
                }}
                onInputChange={(_e, newValue) => dispatch(searchComplexes(newValue))}
                getOptionLabel={option => option.libelle}
                renderOption={(props, option) => (
                  <AutocompleteOption
                    props={props}
                    label={option.libelle}
                    option={option?.eic?.libelle}
                  />
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder="Complexe"
                    size="small"
                  />
                )}
              />
              <div className="text-center mt-4">
                <PrimaryBtn
                  disabled={addComplexeSa === null || addComplexeSa === undefined}
                  onClick={() => {
                    dispatch(postSaComplexe({
                      saId: idSA,
                      ComplexeId: addComplexeSa?.id,
                    }))
                  }}
                >
                  {terms.Common.add}
                </PrimaryBtn>
              </div>
            </div>
          </div>
        )
      }

      <ModalInfo
        onModal={modalErrComplexe}
        titleOption={terms.Settings.complexe.error.errorAddComplexe.title2}
        content={`${errors?.data[0]}.`}
        contentOption={terms.Settings.complexe.error.errorAddComplexe.contactName}
        contactMail={terms.Settings.complexe.error.errorAddComplexe.mail}
        contactPhone={terms.Settings.complexe.error.errorAddComplexe.tel}
        handleClose={() => dispatch(closeModalErrComplexe())}
      />
      <ModalError
        onModal={modalErrAssosComplexe}
        titleOption={terms.Settings.complexe.error.errorAddComplexe.title2}
        content={`${errors?.data[0]}.`}
        handleClose={() => dispatch(closeModalErrComplexe())}
      />
      <ModalConfirmation
        handleConfirmClick={handleDelete}
        onModal={modalConfirmation}
        content1={terms.Settings.complexe.modalConfirmation.content1}
        content2={terms.Settings.complexe.modalConfirmation.content2}
        variable1={selectedComplexe?.batiment_voyageurs?.libelle}
        variable2={thisSA?.eic.libelle}
        handleClose={() => dispatch(closeModalConfirmation())}
      />
      <ModalObjectComposants
        open={openComposantsModal}
        readOnly={readOnlyParamJalon}
        handleClose={() => dispatch(setSaComplexe())}
        object={saComplexe}
        defaultComponents={defaultComposantsComplexes}
        title={terms.Settings.complexe.composantModal.title}
        clearText={terms.Settings.resetComplexeJalon}
        handleChangeObject={object => dispatch(changeSaComplexe(object))}
        handleValidate={handlePatchValidate}
      />

      <CustomSnackbar
        message={terms.Settings.complexe.snackbarMessage.complexeAdded}
        displaySnackbar={displaySnackbarAdd}
        handleClose={() => dispatch(hideSnackbarAdd())}
      />
      <CustomSnackbar
        message={terms.Settings.complexe.snackbarMessage.complexeUpdated}
        displaySnackbar={displaySnackbarUpdate}
        handleClose={() => dispatch(hideSnackbarUpdate())}
      />
      <CustomSnackbar
        message={terms.Settings.complexe.snackbarMessage.complexeDeleted}
        displaySnackbar={displaySnackbarDelete}
        handleClose={() => dispatch(hideSnackbarDelete())}
      />
    </>
  )
}

export default ParamComplexes
