import { Box, Tab, Tabs } from '@mui/material'
import useChangeSaURL from 'common/hooks'
import terms from 'common/terms'
import Loader from 'components/Loader'
import CustomSnackbar from 'components/Snackbar/Snackbar'
import ContentTabObject from 'pages/Suivi/ContentTabObject/ContentTabObject'
import { downloadPdf } from 'pages/Suivi/ExportPdf/utils'
import { typeObject } from 'pages/Suivi/utils'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hideModalSnackbar, resetPdfData } from 'reducers/ActionPlan/actionPlan.reducer'
import {
  getComposantsByObjFer, getComposantsByObjFerCurJalon, getObjFerroviaires,
} from 'reducers/ActionPlan/actionPlan.thunk'
import { getSaEntities } from 'reducers/Contributions/contributions.thunk'
import { getSaPreferences } from 'reducers/Parametrage/Preferences/preferences.thunk'
import { ObjectFerTab } from 'reducers/SA/types'
import { UserMeRoles, UserRole } from 'reducers/Users/types'
import { RootState } from 'Store'
import { getUserMeActionAxeToggle, postUserMeActionAxeToggle } from 'reducers/Users/users.thunk'
import ActionContent from './ActionContent/ActionContent'
import CopilMode from './CopilMode/CopilMode'
import ActionPlanHeader from './ActionPlanHeader'

import './actionPlan.scss'

const ActionPlan = (): ReactElement => {
  const dispatch = useDispatch()
  const { userMePlanAction } = useSelector((state: RootState) => state.users)
  const [selectedValue, setSelectedValue] = useState<UserMeRoles | null>(null)
  const [displayToggle, setDisplayToggle] = useState<boolean>(true)
  const { idSA } = useSelector((state: RootState) => state.app)

  const changeUrlSAHistory = useChangeSaURL(setSelectedValue, userMePlanAction)
  const [tabValue, setTabValue] = useState<number>(0)
  const {
    objFerroviaires,
    modalAdd,
    pdfData,
    isLoading,
    isLoadingObj,
  } = useSelector((state: RootState) => state.actionPlan)
  const { isAxeActionToggle } = useSelector((state: RootState) => state.users)
  const [isDecider, setIsDecider] = useState<boolean>(false)
  const [isPilot, setIsPilot] = useState<boolean>(false)
  const [switchAllAction, setSwitchAllAction] = useState<boolean>(isAxeActionToggle)

  const currentSa = userMePlanAction?.find(sa => sa.id === idSA)

  const getComposant = (sa: string | null, objectFer: ObjectFerTab) => {
    if (!isPilot && !isDecider) {
      dispatch(getComposantsByObjFer({
        saId: sa,
        objectId: objectFer.id,
        type: typeObject(objectFer.type),
      }))
      dispatch(getComposantsByObjFerCurJalon({
        saId: sa,
        objectId: objectFer.id,
        type: typeObject(objectFer.type),
      }))
    }
  }

  // Handler functions ---------------------
  const handleChangeSa = (_: React.SyntheticEvent, newValue: UserMeRoles | null) => {
    changeUrlSAHistory(newValue)
    setTabValue(0)
  }

  const handleChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const handleClickTab = (element: ObjectFerTab) => () => {
    if (selectedValue?.id) {
      getComposant(selectedValue.id, element)
    }
  }

  const handleSwitchAllAction = () => {
    setSwitchAllAction(!switchAllAction)
    dispatch(postUserMeActionAxeToggle(!switchAllAction))
  }

  // Effects ---------------------
  useEffect(() => {
    if (idSA) {
      dispatch(getSaPreferences({ saId: idSA }))
    }
  }, [idSA])

  useEffect(() => {
    if (currentSa?.roles.includes(UserRole.animatorAxe)) {
      dispatch(getUserMeActionAxeToggle())
    }
  }, [currentSa])

  useEffect(() => {
    if (isAxeActionToggle !== switchAllAction) {
      setSwitchAllAction(isAxeActionToggle)
    }
  }, [isAxeActionToggle])

  useEffect(() => {
    if (selectedValue?.roles.includes(UserRole.admin) || selectedValue?.roles.includes(UserRole.animator)) {
      setDisplayToggle(true)
      setIsPilot(false)
      setIsDecider(false)
    } else if (selectedValue?.roles.includes(UserRole.decider)) {
      setDisplayToggle(false)
      setIsPilot(false)
      setIsDecider(true)
    } else if (selectedValue?.roles.includes(UserRole.pilot)) {
      setDisplayToggle(true)
      setIsPilot(true)
      setIsDecider(false)
    } else {
      setDisplayToggle(true)
      setIsPilot(false)
      setIsDecider(false)
    }
    if (selectedValue?.id) {
      dispatch(getObjFerroviaires(selectedValue.id))
      dispatch(getSaEntities({
        activeSA: selectedValue.id,
        origineSA: objFerroviaires?.[tabValue].sa_origine || null,
      }))
    }
  }, [selectedValue, tabValue])

  useEffect(() => {
    if (objFerroviaires?.length
      && selectedValue?.id
      && currentSa?.roles.some(item => [
        UserRole.admin,
        UserRole.animator,
        UserRole.animatorAxe,
      ].includes(item))) {
      getComposant(selectedValue.id, objFerroviaires[tabValue])
    }
  }, [objFerroviaires, selectedValue, isAxeActionToggle, tabValue])
  useEffect(() => {
    if (pdfData) {
      downloadPdf(pdfData, `${currentSa?.libelle}-${
        displayToggle ? terms.actionPlan.namePdf : terms.actionPlan.nameCopil
      }`)
      dispatch(resetPdfData())
    }
  }, [pdfData])

  return (
    <div className="action-plan">
      <ActionPlanHeader
        currentSa={currentSa}
        switchAllAction={switchAllAction}
        handleSwitchAllAction={handleSwitchAllAction}
        displayToggle={displayToggle}
        idSA={idSA}
        userMePlanAction={userMePlanAction}
        selectedValue={selectedValue}
        handleChangeSa={handleChangeSa}
        setDisplayToggle={setDisplayToggle}
        isDecider={isDecider}
        isPilot={isPilot}
      />
      <Box className="action-plan-box">
        {selectedValue && objFerroviaires && objFerroviaires.length > 0 && (
          <>
            <Tabs value={tabValue} onChange={handleChangeTab} className="action-tabs">
              {objFerroviaires.map(element => (
                <Tab
                  disabled={isLoading}
                  onClick={handleClickTab(element)}
                  key={element.id}
                  label={(
                    <ContentTabObject
                      isAxeAccredited={element.borrowed}
                      type={element.type}
                      abrev={element.abrev}
                      libelle={element.libelle}
                    />
                    )}
                  className="action-tab-button"
                />
              ))}
            </Tabs>
            {
              isLoadingObj ? (
                <Loader
                  message={terms.Common.loading}
                />
              ) : (
                <Box className="action-panel-container">
                  {displayToggle ? (
                    <ActionContent
                      tabValue={tabValue}
                      isPilot={isPilot}
                    />
                  ) : (
                    <CopilMode
                      tabValue={tabValue}
                    />
                  )}
                </Box>
              )
            }

            <CustomSnackbar
              message={modalAdd.snackMessage}
              displaySnackbar={modalAdd.displaySnackbar}
              handleClose={() => dispatch(hideModalSnackbar())}
              severity={modalAdd.snackSeverity}
            />
          </>
        )}
      </Box>

    </div>
  )
}

export default ActionPlan
