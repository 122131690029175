import terms from 'common/terms'
import { round } from 'lodash'
import { ReactElement, useEffect, useState } from 'react'

type Props = {
  zoom: number
}

const Zoom = ({ zoom }: Props): ReactElement => {
  const [displayZoom, setDisplayZoom] = useState(false)

  useEffect(() => {
    setDisplayZoom(true)

    const timeout = setTimeout(() => {
      setDisplayZoom(false)
    }, 3000)

    return () => {
      clearTimeout(timeout)
    }
  }, [zoom])

  return (
    <p className={`map-zoom${displayZoom ? ' displayed' : ''}`}>
      <b>{`${terms.Admin.portfolio.map.zoom} : `}</b>
      <b>{round(zoom / 4.5, 1)}</b>
    </p>
  )
}

export default Zoom
