import { cotationInfo } from 'common/cotation'
import { typeObject } from 'pages/Suivi/utils'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { openAddModal } from 'reducers/ActionPlan/actionPlan.reducer'
import { getContributions } from 'reducers/Contributions/contributions.thunk'
import { RootState } from 'Store'
import './actionItemToDo.scss'

interface ActionItemToDoProps {
  idComponent: number,
  libelle: string,
  jalon: number,
  date: number,
  cotation: string,
  display: boolean,
  tabValue: number,
  componentId: string,
}

const ActionItemToDo = ({
  idComponent, libelle, jalon, date, cotation, display, tabValue, componentId,
}: ActionItemToDoProps): ReactElement => {
  const dispatch = useDispatch()
  const { idSA } = useSelector((state: RootState) => state.app)
  const { objFerroviaires } = useSelector((state: RootState) => state.actionPlan)
  const currentObj = objFerroviaires?.[tabValue]

  const handleClickOpen = () => {
    dispatch(openAddModal({
      componentId: idComponent,
      libelle,
      jalon,
      objectType: typeObject(currentObj?.type),
      objectFerId: currentObj?.id,
      componentIdString: componentId,
    }))
    if (idSA && currentObj) {
      dispatch(getContributions({
        idSa: idSA,
        numJalon: jalon,
        typeObj: typeObject(currentObj.type),
        idObj: currentObj?.id,
        idComponent,
      }))
    }
  }
  return (
    <>
      {display && (
        <button
          type="button"
          className="action-item d-flex justify-content-between  align-items-end"
          onClick={handleClickOpen}
        >
          <div className="action-item-id d-flex align-items-center justify-content-center">
            <div className="action-item-id-item">
              <span>
                {idComponent}
              </span>
            </div>
            <div className="action-item-libelle">
              <p>{libelle}</p>
            </div>
          </div>
          <div className="d-flex">
            <div className="action-item-sa d-flex mr-3 ml-1">
              <div className="action-item-jalon mr-2">
                {`J${jalon}`}
              </div>
              <div className="action-item-date">
                {date}
              </div>
            </div>
            <div className="action-item-cotation">
              <div
                className="action-item-cotation-item"
                style={{ backgroundColor: cotationInfo(cotation)?.color }}
              >
                {cotation}
              </div>
            </div>
          </div>
        </button>
      )}
    </>
  )
}

export default ActionItemToDo
