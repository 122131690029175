import { createSlice } from '@reduxjs/toolkit'
import { getSaPreferences, postSaPreferences } from './preferences.thunk'
import { ParametragePreferencesState } from './types'

const initialState: ParametragePreferencesState = {
  mailOuvertureJalon: false,
  mailRappelClotureJalon: false,
  actionExpanded: false,
  displaySnackbar: false,
  isLoadingPreferences: false,
}

export const parametragePreferences = createSlice({
  name: 'parametragePreferences',
  initialState,
  reducers: {
    hideSnackbar: state => {
      state.displaySnackbar = false
    },
  },
  extraReducers: builder => {
    builder.addCase(getSaPreferences.pending, state => {
      state.isLoadingPreferences = true
    })
    builder.addCase(getSaPreferences.rejected, state => {
      state.isLoadingPreferences = false
    })
    builder.addCase(getSaPreferences.fulfilled, (state, action) => {
      state.mailOuvertureJalon = action.payload.mail_ouverture_jalon
      state.mailRappelClotureJalon = action.payload.mail_rappel_cloture_jalon
      state.actionExpanded = action.payload.full_action_display
      state.isLoadingPreferences = false
    })
    builder.addCase(postSaPreferences.fulfilled, (state, action) => {
      state.mailOuvertureJalon = action.payload.mail_ouverture_jalon
      state.mailRappelClotureJalon = action.payload.mail_rappel_cloture_jalon
      state.actionExpanded = action.payload.full_action_display
      state.displaySnackbar = true
    })
  },
})

export const {
  hideSnackbar,
} = parametragePreferences.actions

export default parametragePreferences.reducer
