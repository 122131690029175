import { Box } from '@mui/material'
import { ReactElement } from 'react'
import { TabPanelProps } from './types'

export default function TabPanel(props: TabPanelProps): ReactElement {
  const {
    children, value, index, ...other
  } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ width: '100%' }}
    >
      {(value === index || true) && (
      <Box sx={{
        p: 3,
        background: '#fff',
        boxShadow: '0 0 5px rgb(217 217 217 / 60%)',
        boder: 'none',
        height: '100%',
      }}
      >
        {children}
      </Box>
      )}
    </div>
  )
}
