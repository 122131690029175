import { LAYER_NAMES } from 'services/map'
import { LayerProperties } from '../types'

const RpLayerPaint: LayerProperties = {
  bvLayer: {
    id: LAYER_NAMES.bvLayer,
    type: 'symbol',
    layout: {
      'icon-image': 'station',
      'icon-size': 0.5,
      'icon-allow-overlap': true,
    },
    paint: {},
  },
  bvLayerHighlighted: {
    id: `${LAYER_NAMES.bvLayer}-highlighted`,
    type: 'symbol',
    layout: {
      'icon-image': 'stationEditing',
      'icon-size': 0.25,
      'icon-allow-overlap': true,
    },
    paint: {},
  },
  bvLigneLayer: {
    id: `${LAYER_NAMES.bvLayer}-ligne`,
    type: 'symbol',
    layout: {
      'icon-image': 'stationSelected',
      'icon-size': 0.25,
      'icon-allow-overlap': true,
    },
    paint: {},
  },
  bvLayerName: {
    id: `${LAYER_NAMES.bvLayer}-name`,
    type: 'symbol',
    layout: {
      'text-font': ['Open Sans Regular'],
      'text-size': 14,
      'text-anchor': 'left',
      'symbol-placement': 'point',
      'text-allow-overlap': false,
      'text-offset': [1.5, 0],
      'text-field': '{libelle}',
    },
    paint: {
      'text-color': '#0088ce',
      'text-halo-color': '#FFFFFF',
      'text-halo-width': 1,
    },
  },
}

export default RpLayerPaint
