import { ReactElement } from 'react'
import { Layer, Source, ViewportProps } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { FeatureCollection } from 'geojson'
import ConstructionSiteLayerPaint from './ConstructionSiteLayerPaint'

type Props = {
  selectedConstructionSite: string[]
  hoveredIds: string[]
  viewport: ViewportProps
}

export function ConstructionSiteLayer({ selectedConstructionSite, hoveredIds, viewport }: Props): ReactElement {
  const { constructionSitesFeatures } = useSelector((state: RootState) => state.map)
  const result = {
    type: 'FeatureCollection',
    features: constructionSitesFeatures && constructionSitesFeatures.features.filter(
      feature => !!feature?.properties?.geometry_geo?.coordinates.length,
    ),
  }
  const csNamesToShow = viewport.zoom && viewport.zoom > 9 ? undefined : ['in', ['get', 'id'], ['literal', hoveredIds]]
  return (
    <Source
      id="constructionSite-source"
      type="geojson"
      data={result as FeatureCollection}
    >
      <Layer
        {...ConstructionSiteLayerPaint.constructionSiteLayer}
      />
      <Layer
        {...ConstructionSiteLayerPaint.constructionSiteLayerHighlighted}
        filter={['in', 'id', ...selectedConstructionSite]}
      />
      <Layer
        {...ConstructionSiteLayerPaint.constructionSiteLayerName}
        filter={csNamesToShow}
      />
    </Source>
  )
}

export default ConstructionSiteLayer
