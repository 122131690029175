import { createSlice } from '@reduxjs/toolkit'
import { searchComplexes, patchComplexes } from './complexes.thunk'
import { ComplexesState } from './types'

const initialState: ComplexesState = {
  complexes: [],
  searchValue: '',
  isLoadingComplexes: false,
}

export const complexes = createSlice({
  name: 'complexes',
  initialState,
  reducers: {
    initialcomplexes: state => {
      state.complexes = []
    },
  },
  extraReducers: builder => {
    builder.addCase(searchComplexes.pending, state => { state.isLoadingComplexes = true })
    builder.addCase(searchComplexes.rejected, state => { state.isLoadingComplexes = false })
    builder.addCase(searchComplexes.fulfilled, (state, { payload }) => {
      state.isLoadingComplexes = false
      state.complexes = payload.complexes.slice(0, 20).map(payloadComplex => ({
        libelle: payloadComplex.libelle,
        id: payloadComplex.id,
        abrev: payloadComplex.abrev,
        code_ci: payloadComplex.code_ci,
        eic: payloadComplex.eic,
        gaia_id: payloadComplex.gaia_id,
        sa: payloadComplex.sa,
      }))
      state.searchValue = payload.search
    })
    builder.addCase(patchComplexes.fulfilled, (state, { payload }) => {
      const index = state.complexes.findIndex(({ id }) => id === payload.id)
      state.complexes[index] = { ...payload }
    })
  },
})

export const { initialcomplexes } = complexes.actions

export default complexes.reducer
