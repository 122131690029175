import terms from 'common/terms'
import WeatherIcon from 'pages/ActionPlan/ActionContent/ColumnAction/component/WeatherIcon'
import { weather } from 'pages/ActionPlan/utils'
import { ReactElement } from 'react'

interface Props {
  animatorWeather: number | null
  pilotWeather: number | null
  animatorComment: string
  pilotComment: string
  fullAction: boolean
}

const ProgressReport = ({
  animatorWeather,
  pilotWeather,
  animatorComment,
  pilotComment,
  fullAction,
}: Props): ReactElement => (
  <div className="progress-report">
    <div className="progress-item">
      <div className="title">
        <span>
          {terms.Monitoring.Contributions.Action.modal.animatorProgress}
        </span>
        {
          animatorWeather && (
            <span className="mr-2">
              <WeatherIcon weather={weather(animatorWeather)} />
            </span>
          )
        }
      </div>

      <div className="content">
        {animatorComment}
      </div>
    </div>
    {
      fullAction && (
        <div className="progress-item">
          <div className="title">
            <span>
              {terms.Monitoring.Contributions.Action.modal.pilotProgress}
            </span>
            {
              pilotWeather && (
                <span className="mr-2 icons">
                  <WeatherIcon weather={weather(pilotWeather)} />
                </span>
              )
            }
          </div>
          <div className="content">
            {pilotComment}
          </div>
        </div>
      )
    }

  </div>
)

export default ProgressReport
