import { SettingsTabListElement } from 'components/SettingsTab/SettingsTabList'
import { ContributorListItem } from 'pages/SettingsSA/contributors/contributorsList/ContributorsList'
import { Entity } from 'reducers/Admin/Entity/types'
import { UserSimple, User } from 'reducers/Users/types'
import { ParamContributeur } from './Contributeurs/types'

type Element = {
  firstName: string,
  lastName: string,
}

export function formatSettingsTabList<T extends Element>(settingsTabList: T[]): T[] {
  const formattedSettingsTabList = settingsTabList.map((element: T) => {
    const { firstName, lastName } = element
    return {
      ...element,
      lastName: lastName.toUpperCase(),
      firstName: firstName.charAt(0).toUpperCase() + firstName.slice(1),
    }
  })
  return formattedSettingsTabList.sort((a, b) => {
    if (`${a.lastName}${a.firstName}` < `${b.lastName}${b.firstName}`) {
      return -1
    }
    if (`${a.lastName}${a.firstName}` > `${b.lastName}${b.firstName}`) {
      return 1
    }
    return 0
  })
}

export const formatSettingsTabListElement = (
  id?: string | null, user?: UserSimple | User | null, entity?: Entity | null,
): SettingsTabListElement => ({
  id: id || '',
  cerbereId: user?.id || '',
  firstName: user?.firstName || '',
  lastName: user?.lastName || '',
  entityName: entity?.libelle || '',
  entityId: entity?.id || '',
})

type GetLabelLigne = (start: string, end: string, via?: string) => string
export const getLabelLigne: GetLabelLigne = (start, end, via) => {
  if (via) {
    return `${start} - ${end} via ${via} `
  }
  return `${start} - ${end}`
}
export const formatContributorListItem = (
  contributor?: ParamContributeur, user?: User | UserSimple | null,
): ContributorListItem => ({
  id: contributor?.id || '',
  cerbereId: user?.id || '',
  firstName: user?.firstName || '',
  lastName: user?.lastName || '',
  displayName: user?.displayName || '',
  fonction: contributor?.fonction,
  complexes: contributor?.batiments_voyageurs?.map(complex => complex.batiment_voyageurs?.libelle || ''),
  lignes: contributor?.lignes?.flatMap(line => {
    if (line.ligne?.bv_debut?.libelle && line.ligne?.bv_fin?.libelle) {
      return getLabelLigne(line.ligne.bv_debut?.libelle, line.ligne.bv_fin?.libelle, line.ligne?.bv_via?.libelle)
    }
    return []
  }),
  travaux: contributor?.travaux?.map(work => work.travaux?.libelle || ''),
})
