import terms from 'common/terms'
import CustomSwitch from 'components/CustomSwitch/CustomSwitch'
import { ReactElement } from 'react'
import { PrimaryBtn } from 'themes/theme'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import SaSelector from 'components/SaSelector/SaSelector'
import { UserMeRoles, UserRole } from 'reducers/Users/types'
import { useDispatch, useSelector } from 'react-redux'
import { loadPdfActionPlan } from 'reducers/ActionPlan/actionPlan.thunk'
import { RootState } from 'Store'
import { displayPdfButton } from './utils'
import DisplayToggle from './DisplayToggle/DisplayToggle'

interface Props {
  currentSa: UserMeRoles | undefined
  switchAllAction: boolean
  handleSwitchAllAction: () => void
  displayToggle: boolean
  idSA: string | null
  userMePlanAction: UserMeRoles[]
  selectedValue: UserMeRoles | null
  handleChangeSa: (_: React.SyntheticEvent, newValue: UserMeRoles | null) => void
  setDisplayToggle: (value: boolean) => void
  isDecider: boolean
  isPilot: boolean
}

const ActionPlanHeader = ({
  currentSa,
  switchAllAction,
  handleSwitchAllAction,
  displayToggle,
  idSA,
  userMePlanAction,
  selectedValue,
  handleChangeSa,
  setDisplayToggle,
  isDecider,
  isPilot,
}: Props): ReactElement => {
  const dispatch = useDispatch()
  const { isLoading, isLoadingColumn, isLoadingObj } = useSelector((state: RootState) => state.actionPlan)

  return (
    <div className="action-sa-select">
      {
        currentSa?.roles.includes(UserRole.animatorAxe) && (
          <CustomSwitch
            value={switchAllAction}
            handleChange={handleSwitchAllAction}
            label={terms.actionPlan.toggleDisplayAllActions}
          />
        )
      }
      {(displayPdfButton(displayToggle, currentSa?.roles)) && (
      <PrimaryBtn
        className="action-btn-export"
        onClick={() => dispatch(loadPdfActionPlan({
          sa: idSA,
          mode: displayToggle ? terms.actionPlan.pdf.roadMap : terms.actionPlan.pdf.copil,
        }))}
      >
        <FileUploadIcon />
        <span className="action-btn-export-text">
          {displayToggle ? terms.actionPlan.exportRoute : terms.actionPlan.exportCopil}
        </span>
      </PrimaryBtn>
      )}

      <div className="action-sa-select-item">
        <SaSelector
          options={userMePlanAction}
          value={selectedValue}
          handleChange={handleChangeSa}
          disabled={isLoading || isLoadingColumn || isLoadingObj}
        />
      </div>
      {!isDecider && !isPilot && (
        <DisplayToggle displayToggle={displayToggle} setDisplayToggle={setDisplayToggle} />
      )}
    </div>
  )
}

export default ActionPlanHeader
