import { Fade, Popper } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import terms from 'common/terms'
import { ReactElement, useEffect } from 'react'
import { CotationCell } from 'reducers/monitoring/types'
import { dateToDisplay } from 'pages/ActionPlan/utils'
import './CotationCell.scss'
import { backgroundColorContributor } from 'pages/Suivi/utils'

interface Props {
  open: boolean,
  cotation: CotationCell
  anchorEl: HTMLButtonElement | null
  handleClose: () => void
}

const CotationPopper = ({
  open, cotation, anchorEl, handleClose,
}: Props): ReactElement => {
  // Fermer la Popper lorsque l'utilisateur clique à l'extérieur de la Popper
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Si la Popper est ouverte et le clic n'est pas dans la Popper
      if (open && anchorEl && !anchorEl.contains(event.target as Node)) {
        handleClose()
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [open, anchorEl, handleClose])

  return (
    <Popper
      sx={{ zIndex: 1 }}
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <div className="popper-container">
            <div className="title">
              <span>
                {terms.Monitoring.Dzp.cotations.popper.title}
              </span>
              <button
                type="button"
                onClick={handleClose}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="content">
              <div className="author">
                <div className="animator">
                  <div
                    className="avatar"
                    style={{ background: backgroundColorContributor(cotation.animator?.id || '') }}
                  >
                    <span>
                      {cotation.animator
                        ? `
                        ${cotation.animator.lastName[0].toUpperCase()}${cotation.animator.firstName[0].toUpperCase()}`
                        : ''}
                    </span>
                  </div>
                  <div className="username">
                    <span>
                      {terms.Monitoring.Dzp.cotations.popper.animator}
                    </span>
                    <p>
                      {cotation.animator
                        ? `${cotation.animator.lastName.toUpperCase()} ${cotation.animator.firstName}`
                        : ''}
                    </p>
                  </div>
                </div>
                <div className="date">
                  <span>
                    {dateToDisplay(cotation.date || '').date}
                  </span>
                  <span>
                    {dateToDisplay(cotation.date || '').hours}
                  </span>
                </div>
              </div>
              <div className="item">
                <span>
                  {terms.Monitoring.Dzp.cotations.popper.analyse}
                </span>
                <p>
                  {cotation.analyse}
                </p>
              </div>
              <div className="item">
                <span>
                  {terms.Monitoring.Dzp.cotations.popper.proposition}
                </span>
                <p>
                  {cotation.proposition}
                </p>
              </div>
            </div>
          </div>
        </Fade>
      )}
    </Popper>
  )
}

export default CotationPopper
