import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getProductionZones, postProductionZone } from '../admin.thunk'
import { ProductionZone, ProductionZoneState } from './types'

const initialState: ProductionZoneState = {
  productionZones: [],
  displaySnackbar: false,
  isLoadingProductionZones: false,
}

export const productionZone = createSlice({
  name: 'productionZone',
  initialState,
  reducers: {
    addProductionZone: state => {
      if (state.productionZones.find(stateZone => stateZone.id === 'new')) {
        return
      }
      state.productionZones = [...state.productionZones, { libelle: '', abrev: '', id: 'new' }]
    },
    changeProductionZone: (state, action: PayloadAction<Partial<ProductionZone>>) => {
      const index = state.productionZones.findIndex(stateZone => stateZone.id === action.payload.id)
      state.productionZones[index] = { ...state.productionZones[index], ...action.payload }
    },
    deleteNewProductionZone: state => {
      const index = state.productionZones.findIndex(entitie => entitie.id === 'new')
      if (index !== -1) { state.productionZones.splice(index, 1) }
    },
    hideSnackbar: state => {
      state.displaySnackbar = false
    },
  },
  extraReducers: builder => {
    builder.addCase(getProductionZones.pending, state => { state.isLoadingProductionZones = true })
    builder.addCase(getProductionZones.rejected, state => { state.isLoadingProductionZones = false })
    builder.addCase(getProductionZones.fulfilled, (state, { payload }) => {
      state.isLoadingProductionZones = false
      state.productionZones = payload.map(payloadZone => ({
        libelle: payloadZone.libelle,
        abrev: payloadZone.abrev,
        id: payloadZone.id,
      }))
    })
    builder.addCase(postProductionZone.fulfilled, (state, { payload }) => {
      const index = state.productionZones.findIndex(({ id }) => id === payload.id)
      const indexToChange = index === -1 ? state.productionZones.findIndex(({ id }) => id === 'new') : index
      state.productionZones[indexToChange] = { ...payload }
      state.displaySnackbar = true
    })
  },
})

export const {
  addProductionZone, changeProductionZone, deleteNewProductionZone, hideSnackbar,
} = productionZone.actions

export default productionZone.reducer
