import {
  deleteRequest,
  get,
  patch,
  post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ComposantWithoutJalon } from 'reducers/Admin/Composant/types'
import { ThunkApi } from 'reducers/types'
import { getSaContributeurs } from '../Contributeurs/contributeurs.thunk'
import {
  ParamLigne, ParamPatchLigne, ParamPostLigne, RequestParamsGetLigne,
} from './types'

const getSaLignes = createAsyncThunk(
  'sa/Lignes/get',
  async (saId: string | null, thunkApi) => {
    try {
      const response: ParamLigne[] = await get(`/robustest/sa/${saId}/lignes/`)
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getSaLigneById = createAsyncThunk(
  'sa/ligne/getById',
  async (params: RequestParamsGetLigne, thunkApi) => {
    try {
      const response: ParamLigne = await
      get(`/robustest/sa/${params.saId}/lignes/${params.ligneId}`)
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postSaLigne = createAsyncThunk<ParamLigne, ParamPostLigne, ThunkApi>(
  'sa/ligne/post',
  async (newligne: ParamPostLigne, thunkApi) => {
    try {
      const response: ParamLigne = await post(`/robustest/sa/${newligne.saId}/lignes/`, newligne.ligne)
      thunkApi.dispatch(getSaContributeurs(newligne.saId))
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const patchSaLigne = createAsyncThunk<ParamLigne, ParamPatchLigne, ThunkApi>(
  'sa/ligne/patch',
  async (updateLigneSA: ParamPatchLigne, thunkApi) => {
    try {
      const response: ParamLigne = await patch(
        `/robustest/sa/${updateLigneSA.idSA}/lignes/${updateLigneSA.idLigne}`, updateLigneSA.ligne,
      )
      thunkApi.dispatch(getSaContributeurs(updateLigneSA.idSA))
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteSaLigne = createAsyncThunk(
  'sa/ligne/delete',
  async (complexeId: string | undefined, thunkApi) => {
    try {
      const response = await deleteRequest(
        `/robustest/sa/${localStorage.getItem('idSA')}/lignes/${complexeId}`,
      ).then(() => {
        thunkApi.dispatch(getSaContributeurs(localStorage.getItem('idSA')))
        thunkApi.dispatch(getSaLignes(localStorage.getItem('idSA')))
      })
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getDefaultJalonsLigne = createAsyncThunk(
  'sa/ligne/jalons',
  async (numeroJalon: number, thunkApi) => {
    try {
      const response: ComposantWithoutJalon[] = await
      get('/robustest/defauts/', { jalon: numeroJalon, type: 'ligne' })
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export {
  getSaLignes,
  postSaLigne,
  patchSaLigne,
  deleteSaLigne,
  getSaLigneById,
  getDefaultJalonsLigne,
}
