import { ReactElement, useState } from 'react'
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { postPonderationComposant } from 'reducers/Admin/Ponderation/ponderation.thunk'
import { CommonProps, PonderationItem } from 'reducers/Admin/Ponderation/types'
import terms from 'common/terms'
import PonderationInput from '../PonderationInput/PonderationInput'

const PonderationTable = ({ error, setError, checkInput }: CommonProps): ReactElement => {
  const dispatch = useDispatch()
  const { ponderationList } = useSelector((state: RootState) => state.ponderation)
  const [ponderations, setPonderations] = useState<PonderationItem[]>(ponderationList)

  const handleChange = (inputValue: string, itemId: number, type: string) => {
    const newPonderations = ponderations.map(ponderation => {
      if (ponderation.num_composant === itemId) {
        const newTypeObject = ponderation.typeObject.map(typeObject => {
          if (typeObject.type === type) {
            return {
              ...typeObject,
              poids: parseInt(inputValue, 10),
            }
          }
          return typeObject
        })
        return {
          ...ponderation,
          typeObject: newTypeObject,
        }
      }
      return ponderation
    })
    setPonderations(newPonderations as PonderationItem[])

    if (!checkInput(inputValue)) {
      setError([
        ...error,
        {
          id: itemId,
          type,
        },
      ])
      return
    }
    setError(error.filter(err => !(err.type === type && err.id === itemId)))
    dispatch(postPonderationComposant({
      num_composant: itemId,
      poids: parseInt(inputValue, 10),
      type,
    }))
  }

  return (
    <div className="ponderation">
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {
              terms.Admin.ponderation.columnsName.map(column => (
                <TableCell key={column} className="bold">
                  {column}
                </TableCell>
              ))
            }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              ponderations.map(ponderation => (
                <TableRow key={ponderation.num_composant}>
                  <TableCell className="bold">{ponderation.num_composant}</TableCell>
                  <TableCell>{ponderation.libelle}</TableCell>
                  {
                    ponderation.typeObject.map(type => (
                      <TableCell key={`${ponderation.num_composant}-${type.type}`}>
                        <div className="input-container">
                          <PonderationInput
                            inputValue={type.poids.toString()}
                            handleChange={e => handleChange(
                              e.target.value, ponderation.num_composant, type.type,
                            )}
                            messageError={terms.Admin.ponderation.message.error.composantText}
                            error={error.some(err => err.id === ponderation.num_composant && err.type === type.type)}
                          />
                        </div>

                      </TableCell>
                    ))
                  }
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default PonderationTable
