import { ReactElement, ReactNode, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import PropTypes from 'prop-types'
import terms from 'common/terms'
import { ObjectFerTab } from 'reducers/SA/types'
import { getComposant } from 'pages/ActionPlan/utils'

import './columnAction.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import PopOver from 'components/PopOver/PopOver'
import ComposantTodoEdit from './ComposantToDoEdit/ComposantTodoEdit'

interface ColumnActionProps {
  title: string,
  tabValue?: number,
  children: ReactNode,
}

const ColumnAction = ({ title, children, tabValue }: ColumnActionProps): ReactElement => {
  const [isExpanded, setIsExpanded] = useState(true)

  const toggleExpand = () => setIsExpanded(!isExpanded)
  const dispatch = useDispatch()
  const { idSA } = useSelector((state: RootState) => state.app)
  const { objFerroviaires } = useSelector((state: RootState) => state.actionPlan)
  const [onModalComposantEdit, setOpenModalComposantEdit] = useState(false)

  const isColTodo = (libelle: string) => libelle === terms.actionPlan.column.toDo.title

  const handleClickModalComposantEdit = (saId: string | null, objFer: ObjectFerTab) => {
    setOpenModalComposantEdit(!onModalComposantEdit)
    dispatch(getComposant(saId, objFer))
  }

  return (
    <>
      <div className={`action-panel ${isExpanded ? '' : 'collapsed'}`}>
        <div className={`action-panel-title ${isExpanded ? '' : 'collapsed'}`}>
          <div className="action-panel-title-libelle">
            {title}
          </div>
          {isColTodo(title) && isExpanded && (
            <PopOver
              popContent={terms.actionPlan.column.toDo.buttonModify}
            >
              <button
                type="button"
                className="action-panel-title-button"
                onClick={() => handleClickModalComposantEdit(
                  idSA, objFerroviaires ? objFerroviaires[tabValue || 0] : {} as ObjectFerTab,
                )}
              >
                <FontAwesomeIcon className="action-panel-title-icon" icon={faPenToSquare} />
              </button>
            </PopOver>
          )}
          <FontAwesomeIcon className="collapse-button" icon={isExpanded ? faMinus : faPlus} onClick={toggleExpand} />
        </div>
        {isExpanded && (
        <div className="action-panel-content">
          {children}
        </div>
        )}
      </div>
      {
        objFerroviaires?.length !== 0 && (
          <ComposantTodoEdit
            onModal={onModalComposantEdit}
            objectFerId={objFerroviaires?.[tabValue || 0].id || null}
            objectFerType={objFerroviaires?.[tabValue || 0].type || null}
            closeModal={() => handleClickModalComposantEdit(
              idSA, objFerroviaires ? objFerroviaires[tabValue || 0] : {} as ObjectFerTab,
            )}
          />
        )
      }
    </>
  )
}

ColumnAction.propTypes = {
  title: PropTypes.string.isRequired,
  tabValue: PropTypes.number,
  children: PropTypes.node.isRequired,
}

ColumnAction.defaultProps = {
  tabValue: 0,
}

export default ColumnAction
