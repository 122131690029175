import { RootState } from 'Store'
import terms from 'common/terms'
import Accordion from 'components/Accordion/Accordion'
import Map from 'components/MapGL/MapGL'
import CustomSnackbar from 'components/Snackbar/Snackbar'
import { ReactElement, useEffect } from 'react'
import { MapEvent } from 'react-map-gl'
import { useDispatch, useSelector } from 'react-redux'
import {
  addPortfolio, changePortfolio, deleteNewPortfolio, hideSnackbar,
} from 'reducers/Admin/Portfolio/portfolio.reducer'
import { postOrPatchPortfolio } from 'reducers/Admin/Portfolio/portfolio.thunk'
import { PortfolioItem } from 'reducers/Admin/Portfolio/type'
import { getConstructionSitesLayer, getTracks } from 'reducers/Map/map.thunk'
import { MAP_LAYER_SOURCE } from 'services/map'
import { PrimaryBtn } from 'themes/theme'
import './Portfolio.scss'
import PortfolioDetail from './PortfolioDetail'

const Portfolio = (): ReactElement => {
  const dispatch = useDispatch()
  const {
    portfolioList, isLoadingPortfolio, displaySnackbar,
  } = useSelector((state: RootState) => state.portfolio)
  const { allLignes } = useSelector((state: RootState) => state.map)
  const { robustestComplexes, lignes } = useSelector((state: RootState) => state.portfolio)
  const { constructionSites } = useSelector((state: RootState) => state.constructionSites)

  const handlePostOrPatch = (element: PortfolioItem) => {
    dispatch(postOrPatchPortfolio(element))
  }

  useEffect(() => {
    dispatch(getConstructionSitesLayer())
    allLignes?.forEach(ligne => {
      dispatch(getTracks({
        ligneId: ligne.id,
        libelle: ligne.libelle,
        ids: [
          ...(ligne.bv_debut.gaia_id ? [ligne.bv_debut.gaia_id] : []),
          ...(ligne.bv_via?.gaia_id ? [ligne.bv_via.gaia_id] : []),
          ...(ligne.bv_fin.gaia_id ? [ligne.bv_fin.gaia_id] : []),
        ],
      }))
    })
  }, [allLignes])

  function handleDispatch<T>(portfolioSelected: PortfolioItem, key: string, value: T[]) {
    dispatch(changePortfolio({ ...portfolioSelected, [key]: value }))
  }

  const onFeatureClick = (e: MapEvent, portfolio: PortfolioItem) => {
    if (e.features?.[0]?.sourceLayer === MAP_LAYER_SOURCE.pr) {
      if (portfolio.batiments_voyageurs.find(item => item.gaia_id === e.features?.[0].properties?.id)) {
        handleDispatch(portfolio, 'batiments_voyageurs', portfolio.batiments_voyageurs.filter(
          item => item.gaia_id !== e.features?.[0].properties?.id,
        ))
      } else {
        handleDispatch(portfolio, 'batiments_voyageurs', [
          ...portfolio.batiments_voyageurs,
          ...(robustestComplexes.filter(item => item.gaia_id === e.features?.[0].properties?.id)),
        ])
      }
    }
    if (e.features?.[0]?.source === MAP_LAYER_SOURCE.constructionSite) {
      if (portfolio.travaux.find(item => item.id === e.features?.[0].properties?.id)) {
        handleDispatch(portfolio, 'travaux', portfolio.travaux.filter(
          item => item.id !== e.features?.[0].properties?.id,
        ))
      } else {
        handleDispatch(portfolio, 'travaux', [
          ...portfolio.travaux,
          ...(constructionSites.filter(item => item.id === e.features?.[0].properties?.id)),
        ])
      }
    }
    if (e.features?.[0]?.source === MAP_LAYER_SOURCE.sectionLine) {
      if (portfolio.lignes.find(item => item.id === e.features?.[0].properties?.id)) {
        handleDispatch(portfolio, 'lignes', portfolio.lignes.filter(
          item => item.id !== e.features?.[0].properties?.id,
        ))
      } else {
        handleDispatch(portfolio, 'lignes', [
          ...portfolio.lignes,
          ...(lignes.filter(item => item.id === e.features?.[0].properties?.id)),
        ])
      }
    }
  }

  return (
    <>
      <Accordion
        name={terms.Admin.portfolio.name}
        elementList={portfolioList}
        onAddEntity={() => dispatch(addPortfolio())}
        onDeleteEntity={() => dispatch(deleteNewPortfolio())}
        isLoading={isLoadingPortfolio}
        newElementWording={terms.Admin.portfolio.new}
        displaySnackbar={displaySnackbar}
      >
        {portfolio => (
          <div className="portfolio">
            <div className="portfolio-container">
              <PortfolioDetail portfolio={portfolio} />
              <div className="map">
                <Map
                  onFeatureClick={(e: MapEvent) => onFeatureClick(e, portfolio)}
                  bvSelected={portfolio.batiments_voyageurs}
                  constructionSiteSelected={portfolio.travaux}
                  sectionLineSelected={portfolio.lignes}
                />
              </div>
            </div>
            <div className="row align-items-center mt-3 justify-content-end">
              <PrimaryBtn
                type="submit"
                className="btn btn-gradient-blue mr-2"
                disabled={
                  !portfolio.libelle
                  || !portfolio.entite.id
                  || (!portfolio.batiments_voyageurs.length && !portfolio.travaux.length && !portfolio.lignes.length)
                }
                onClick={() => handlePostOrPatch(portfolio)}
              >
                {terms.Admin.validate}
              </PrimaryBtn>
            </div>
          </div>
        )}
      </Accordion>
      <CustomSnackbar
        message={terms.Admin.portfolio.validationNotice}
        displaySnackbar={displaySnackbar}
        handleClose={() => dispatch(hideSnackbar())}
      />
    </>
  )
}

export default Portfolio
