import { Autocomplete, FormLabel, TextField } from '@mui/material'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { searchComplexes } from 'reducers/Admin/Complexes/complexes.thunk'
import {
  inputDebutComplexe,
  inputFinComplexe,
  inputViaComplexe,
} from 'reducers/Parametrage/ParamLignes/paramLignes.reducers'
import { initialcomplexes } from 'reducers/Admin/Complexes/complexes'
import { postSaLigne } from 'reducers/Parametrage/ParamLignes/paramLignes.thunk'
import { RootState } from 'Store'
import { PrimaryBtn } from 'themes/theme'
import '../paramLignes.scss'
import { ReactElement } from 'react'
import AutocompleteOption from 'pages/SettingsSA/complexes/AutocompleteOption'

const SearchLigne = (): ReactElement => {
  const dispatch = useDispatch()
  const {
    debutComplexe,
    finComplexe,
    viaComplexe,
  } = useSelector((state: RootState) => state.paramLignes)
  const listAllComplexes = useSelector((state: RootState) => state.complexes.complexes)
  const { idSA } = useSelector((state: RootState) => state.app)

  return (
    <div className="searchContainer mt-3">
      <div className="mb-3">
        <FormLabel required className="labelSearch">
          {terms.Settings.lignes.search.depart}
        </FormLabel>
        <Autocomplete
          className="searchComplex"
          disablePortal
          id="searchligneStart"
          options={listAllComplexes}
          value={debutComplexe || null}
          onChange={(_e, newValue) => {
            dispatch(inputDebutComplexe(newValue))
          }}
          onOpen={() => {
            if (debutComplexe) {
              dispatch(searchComplexes(debutComplexe?.libelle))
            } else {
              dispatch(initialcomplexes())
            }
          }}
          onInputChange={(_e, newValue) => dispatch(searchComplexes(newValue))}
          getOptionLabel={option => option.libelle}
          renderOption={(props, option) => (
            <AutocompleteOption
              props={props}
              label={option.libelle}
              option={option?.eic?.libelle}
            />
          )}
          renderInput={params => (
            <TextField
              {...params}
              placeholder="Complexe"
              size="small"
            />
          )}
        />
      </div>
      <div className="mb-3">
        <FormLabel required className="labelSearch">
          {terms.Settings.lignes.search.fin}
        </FormLabel>
        <Autocomplete
          className="searchComplex"
          disablePortal
          id="searchLigneEnd"
          options={listAllComplexes}
          value={finComplexe || null}
          onChange={(_e, newValue) => {
            dispatch(inputFinComplexe(newValue))
          }}
          onOpen={() => {
            if (finComplexe) {
              dispatch(searchComplexes(finComplexe?.libelle))
            } else {
              dispatch(initialcomplexes())
            }
          }}
          onInputChange={(_e, newValue) => dispatch(searchComplexes(newValue))}
          getOptionLabel={option => option.libelle}
          renderOption={(props, option) => (
            <AutocompleteOption
              props={props}
              label={option.libelle}
              option={option?.eic?.libelle}
            />
          )}
          renderInput={params => (
            <TextField
              {...params}
              placeholder="Complexe"
              size="small"
            />
          )}
        />
      </div>
      <div className="mb-3">
        <FormLabel className="labelSearch">
          {terms.Settings.lignes.search.via}
        </FormLabel>
        <Autocomplete
          className="searchComplex"
          disablePortal
          id="searchLigneVia"
          options={listAllComplexes}
          value={viaComplexe || null}
          onOpen={() => {
            if (viaComplexe) {
              dispatch(searchComplexes(viaComplexe?.libelle))
            } else {
              dispatch(initialcomplexes())
            }
          }}
          onChange={(_e, newValue) => {
            dispatch(inputViaComplexe(newValue))
          }}
          onInputChange={(_e, newValue) => dispatch(searchComplexes(newValue))}
          getOptionLabel={option => option.libelle}
          renderOption={(props, option) => (
            <AutocompleteOption
              props={props}
              label={option.libelle}
              option={option?.eic?.libelle}
            />
          )}
          renderInput={params => (
            <TextField
              {...params}
              placeholder="Complexe"
              size="small"
            />
          )}
        />
      </div>
      <div className="text-center mt-4">
        <PrimaryBtn
          disabled={
            debutComplexe === null || debutComplexe === undefined || finComplexe === null || finComplexe === undefined
          }
          onClick={() => {
            dispatch(postSaLigne({
              saId: idSA,
              ligne: {
                ligne: {
                  bv_debut: debutComplexe?.id,
                  bv_fin: finComplexe?.id,
                  bv_via: (viaComplexe?.id) ? viaComplexe.id : null,
                },
              },
            }))
          }}
        >
          {terms.Common.add}
        </PrimaryBtn>
      </div>
    </div>
  )
}

export default SearchLigne
