import {
  get, post, deleteRequest, patch,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ContributorListItem } from 'pages/SettingsSA/contributors/contributorsList/ContributorsList'
import { ContributorFunction } from 'reducers/Admin/ContributorFunction/types'
import { ThunkApi } from 'reducers/types'
import { getUserMeSuivi } from 'reducers/Users/users.thunk'
import { getUsersSimple } from 'reducers/Users/utils'
import { formatContributorListItem } from '../utils'
import { loadRole } from './contributeurs.reducer'
import {
  ParamContributeur, ParamDeleteContributeur, ParamPatchContributeur, ParamPostContributeur,
} from './types'

const getSaContributeurs = createAsyncThunk<ContributorListItem[], string | null, ThunkApi>(
  'sa/contributeurs/get',
  async (saId: string | null, thunkApi) => {
    try {
      const response: ParamContributeur[] = await get(`/robustest/sa/${saId}/contributeurs/`)
      const usersResponse = await getUsersSimple(response.map(contributor => contributor.utilisateur?.cerbere_id))
      return usersResponse.map(user => {
        const contributor = response.find(contrib => contrib.utilisateur?.cerbere_id === user.id)
        return formatContributorListItem(contributor, user)
      })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

type RequestParams = {
  saId: string | null
  contributorId: string
}

const getSaContributeursById = createAsyncThunk(
  'sa/contributeurs/getById',
  async (params: RequestParams, thunkApi) => {
    try {
      const response: ParamContributeur = await
      get(`/robustest/sa/${params.saId}/contributeurs/${params.contributorId}/`)
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

type functionParams = {
  entite?: string,
  active?: boolean,
}

const getSaContributeursFunctions = createAsyncThunk(
  'sa/contributeurs/functions',
  async (params: functionParams, thunkApi) => {
    try {
      const response: ContributorFunction[] = await get('/robustest/fonctions/', params)
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postSaContributeurs = createAsyncThunk<ContributorListItem, ParamPostContributeur, ThunkApi>(
  'sa/contributeurs/post',
  async (newContributeurAd: ParamPostContributeur, thunkApi) => {
    try {
      const response: ParamContributeur = await post(`/robustest/sa/${newContributeurAd.saId}/contributeurs/`, {
        utilisateur: newContributeurAd.user,
        fonction: newContributeurAd.functionId,
        batiments_voyageurs: newContributeurAd.complexesId,
        lignes: newContributeurAd.lignesId,
        travaux: newContributeurAd.travauxId,
      })
      if (response.utilisateur?.cerbere_id === newContributeurAd.userMeId) {
        thunkApi.dispatch(getUserMeSuivi())
        thunkApi.dispatch(loadRole())
      }
      return formatContributorListItem(response, newContributeurAd.selectedContributor)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const patchSaContributeurs = createAsyncThunk<ContributorListItem, ParamPatchContributeur, ThunkApi>(
  'sa/contributeur/patch',
  async (updateContributeurSA: ParamPatchContributeur, thunkApi) => {
    try {
      const response: ParamContributeur = await patch(
        `/robustest/sa/${updateContributeurSA.saId}/contributeurs/${updateContributeurSA.saContributorId}/`,
        updateContributeurSA.contributor,
      )
      return formatContributorListItem(response, updateContributeurSA.user)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteSaContributeurs = createAsyncThunk<ParamContributeur, ParamDeleteContributeur, ThunkApi>(
  'sa/contributeurs/delete',
  async (contributorToDelete: ParamDeleteContributeur, thunkApi) => {
    try {
      const response: ParamContributeur = await
      deleteRequest(`/robustest/sa/${contributorToDelete.saId}/contributeurs/${contributorToDelete.saContributorId}/`)
      if (contributorToDelete.needRefresh) {
        thunkApi.dispatch(getUserMeSuivi())
        thunkApi.dispatch(loadRole())
      }
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

// eslint-disable-next-line import/prefer-default-export
export {
  getSaContributeurs,
  getSaContributeursById,
  getSaContributeursFunctions,
  postSaContributeurs,
  patchSaContributeurs,
  deleteSaContributeurs,
}
