const formatDate = (timestamp: string): string | null => {
  const rawDate = timestamp.split(' ')[0] || null
  const year = rawDate?.split('-')[0] || null
  const month = rawDate?.split('-')[1] || null
  const day = rawDate?.split('-')[2] || null
  return (year && month && day) ? `${day}/${month}/${year}` : null
}

const formatHour = (timestamp: string): string | null => {
  const rawHour = timestamp.split(' ')[1] || null
  const hours = rawHour?.split(':')[0] || null
  const minutes = rawHour?.split(':')[1] || null
  return (hours && minutes) ? `${hours}:${minutes}` : null
}

export default {
  formatDate,
  formatHour,
}
