import { Box, Modal, Typography } from '@mui/material'
import { ReactElement } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import { PrimaryBtn } from 'themes/theme'
import terms from 'common/terms'
import './modalConfirmation.scss'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}

interface ModalConfirmProps {
  onModal: boolean,
  content1?: string,
  content2?: string,
  variable1?: string,
  variable2?: string,
  content3?: string,
  content4?: string,
  handleClose: () => void,
  handleConfirmClick: () => void
}

const ModalConfirmation = ({
  onModal,
  content1,
  content2,
  variable1,
  variable2,
  content3,
  content4,
  handleClose,
  handleConfirmClick,
}: ModalConfirmProps): ReactElement => (
  <Modal
    open={onModal}
    onClose={handleClose}
  >
    <Box className="modal-confirmation" sx={style}>
      <InfoIcon className="modal-confirmation_icon" />
      <Typography className="modal-confirmation_title mt-4" variant="h6" component="h1" color="success">
        {terms.Common.confirmation}
      </Typography>
      <Typography className="text-center mt-4" variant="h6" component="h2">
        {`${content1} ${variable1} ${content2} ${variable2} ?`}
      </Typography>
      {(content3 !== '') && (
        <Typography className="text-center mt-4" variant="h6" component="h2">
          {content3}
        </Typography>
      )}
      {(content4 !== '') && (
        <Typography className="text-center mt-4" variant="h6" component="h2">
          {content4}
        </Typography>
      )}
      <PrimaryBtn
        className="mt-5"
        onClick={handleConfirmClick}
      >
        {terms.Common.confirm}
      </PrimaryBtn>
      <button
        className="modal-confirmation_close"
        type="button"
        onClick={handleClose}
      >
        {terms.Admin.close}
      </button>
    </Box>
  </Modal>
)

ModalConfirmation.defaultProps = {
  content1: '',
  content2: '',
  variable1: '',
  variable2: '',
  content3: '',
  content4: '',
}

export default ModalConfirmation
