import { Complexes } from 'reducers/Admin/Complexes/types'
import { ConstructionSites } from 'reducers/Admin/ConstructionSites/types'
import { Ligne } from 'reducers/Admin/Portfolio/type'
import { AutocompleteValue } from 'reducers/types'

/**
 * @param complexeList
 * @param constructionSiteList
 * @param lignesList
 * @param robustestComplexes
 * @returns AutocompleteValue[]
 *
 * @description
 * This function takes four arrays of objects and returns an array of AutocompleteValue.
 * The AutocompleteValue is an object with two properties: id and libelle.
 */
const getObjList = (
  complexeList: Complexes[],
  constructionSiteList: ConstructionSites[],
  lignesList: Ligne[],
  robustestComplexes: Complexes[],
): AutocompleteValue[] => {
  const objList = []
  const complexesIds = robustestComplexes.map(complexe => complexe.gaia_id)
  if (complexeList.length) {
    objList.push(...complexeList.filter(c => complexesIds.includes(c.gaia_id)).map(complexe => ({
      id: complexe.id,
      libelle: `C - ${complexe.libelle} ${complexe.eic?.libelle ? `(${complexe.eic.libelle})` : ''}`,
    })))
  }
  if (constructionSiteList.length) {
    objList.push(...constructionSiteList.map(constructionSite => ({
      id: constructionSite.id,
      libelle: `
        T - ${constructionSite.libelle} ${constructionSite.eic?.libelle ? `(${constructionSite.eic.libelle})` : ''}
      `,
    })))
  }
  if (lignesList.length) {
    objList.push(...lignesList.map(ligne => ({
      id: ligne.id,
      libelle: `L - 
        ${ligne.bv_debut.libelle} -
        ${ligne.bv_fin.libelle}
        ${ligne?.bv_via ? `via ${ligne?.bv_via?.libelle}` : ''}
        ${ligne?.eic?.libelle ? `(${ligne?.eic?.libelle})` : ''}
        `,
    })))
  }

  return objList as AutocompleteValue[]
}

/**
 *
 * @param values
 * @param robustestComplexes
 * @param constructionSites
 * @param allLignes
 * @returns {
 *  batiments_voyageurs: Complexes[],
 *  travaux: ConstructionSites[],
 *  lignes: Ligne[],
 * }
 *
 * @description
 * This function takes an array of selected values and sort them into three different arrays:
 * 1. batiments_voyageurs: Complexes[]
 * 2. travaux: ConstructionSites[]
 * 3. lignes: Ligne[]
 */
const sortSelectedObj = (
  values: AutocompleteValue[],
  robustestComplexes: Complexes[],
  constructionSites: ConstructionSites[],
  allLignes: Ligne[],
): {
  // eslint-disable-next-line camelcase
  batiments_voyageurs: Complexes[],
  travaux: ConstructionSites[],
  lignes: Ligne[],

} => {
  if (values.length) {
    const selectedComplexes = robustestComplexes.filter(
      complexe => values.map(value => value.id).includes(complexe.id),
    )
    const selectedConstructionSites = constructionSites.filter(
      constructionSite => values.map(value => value.id).includes(constructionSite.id),
    )
    const selectedLignes = allLignes ? allLignes.filter(
      ligne => values.map(value => value.id).includes(ligne.id),
    ) : []
    return {
      batiments_voyageurs: selectedComplexes,
      travaux: selectedConstructionSites,
      lignes: selectedLignes,
    }
  }
  return {
    batiments_voyageurs: [],
    travaux: [],
    lignes: [],
  }
}

const portfolioService = {
  getObjList,
  sortSelectedObj,
}

export default portfolioService
