import terms from 'common/terms'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import ModalForm from './ModalForm'

interface FormAddActionProps {
  closeButton: () => void
}

const FormViewAction = ({
  closeButton,
}: FormAddActionProps): ReactElement => {
  const { selectedPilote, selectedCoPilote, patchAction } = useSelector((state: RootState) => state.actionPlan)
  const { entitiesVisibility } = useSelector((state: RootState) => state.contributions)

  return (
    <ModalForm
      disabledForm
      title={terms.actionPlan.modalAddAction.formViewActionTitle}
      action={patchAction}
      entities={entitiesVisibility}
      selectedPilote={selectedPilote}
      selectedCoPilote={selectedCoPilote}
      closeButton={closeButton}
    />
  )
}

export default FormViewAction
