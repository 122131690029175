import { ReactElement } from 'react'
import imgConstruct from 'assets/images/page-en-construction.png'

import './MapAlert.scss'

const MapAlert = (): ReactElement => (
  <div className="map-alert">
    <img src={imgConstruct} alt="En construction" />
  </div>
)

export default MapAlert
