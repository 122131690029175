import terms from 'common/terms'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setBesoinValidation,
  setDateBouclage,
  setDateOuverture,
  setDescription,
  setEntite,
  setName,
} from 'reducers/ActionPlan/actionPlan.reducer'
import { RootState } from 'Store'
import { postCreateAction } from 'reducers/ActionPlan/actionPlan.thunk'
import { SelectChangeEvent } from '@mui/material'
import ModalForm from './ModalForm'

interface FormAddActionProps {
  closeButton: () => void
  composantId: string | undefined
  objectFerType: string | undefined
  objectFerId: string | undefined
}

const FormAddAction = ({
  closeButton,
  composantId,
  objectFerType,
  objectFerId,
}: FormAddActionProps): ReactElement => {
  const dispatch = useDispatch()
  const { selectedPilote, selectedCoPilote, createAction } = useSelector((state: RootState) => state.actionPlan)
  const { idSA } = useSelector((state: RootState) => state.app)
  const { entitiesVisibility } = useSelector((state: RootState) => state.contributions)

  const postForm = () => {
    if (objectFerType && objectFerId && composantId) {
      dispatch(postCreateAction({
        saId: idSA,
        type: objectFerType,
        objectId: objectFerId,
        composantsId: composantId,
        action: createAction,
      }))
    }
  }

  const disableAddAction = (): boolean => (
    createAction.nom === '' || createAction.entite === null || createAction.date_ouverture === ''
    || createAction.date_bouclage === '' || createAction.description === ''
  )

  const handleChangeEntity = (evt: SelectChangeEvent<string[]>) => {
    const { target: { value } } = evt
    if (typeof value !== 'string') {
      dispatch(setEntite(entitiesVisibility.filter(e => value.includes(e.id as string))))
    }
  }

  return (
    <ModalForm
      disabledForm={false}
      title={terms.actionPlan.modalAddAction.formAddActionTitle}
      action={createAction}
      entities={entitiesVisibility}
      selectedPilote={selectedPilote}
      selectedCoPilote={selectedCoPilote}
      handleChangeName={e => dispatch(setName(e.target.value))}
      handleChangeEntity={handleChangeEntity}
      handleChangeDescription={e => dispatch(setDescription(e.target.value))}
      handleChangeDateBouclage={setDateBouclage}
      handleChangeDateOuverture={setDateOuverture}
      handleChangeBesoinValidation={() => dispatch(setBesoinValidation(!createAction.besoin_validation))}
      closeButton={closeButton}
      disableButton={disableAddAction()}
      handleValidate={postForm}
      validateLabel={terms.actionPlan.modalAddAction.formAddActionValidate}
    />
  )
}

export default FormAddAction
