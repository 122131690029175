import {
  Box,
  Modal,
  Typography,
} from '@mui/material'
import { ReactElement } from 'react'
import { PrimaryBtn } from 'themes/theme'
import { FaExclamationTriangle } from 'react-icons/fa'
import terms from 'common/terms'
import './modalError.scss'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}

interface ModalErrorProps {
  onModal: boolean,
  content: string | undefined | string[],
  handleClose: () => void,
  titleOption?: string | string[],
  contentOption?: string | undefined | string[],
  contentOption1?: string | undefined | string[],
}

const ModalError = ({
  onModal,
  handleClose,
  titleOption,
  content,
  contentOption1,
  contentOption,
}: ModalErrorProps): ReactElement => (
  <Modal
    open={onModal}
    onClose={handleClose}
  >
    <Box className="modal-error" sx={style}>
      <FaExclamationTriangle className="modal-error_warning" size="58px" />
      <Typography className="modal-error_title mt-4" variant="h6" component="h1" color="success">
        {terms.errors.error}
      </Typography>
      {(titleOption !== '') && (
        <Typography className="modal-error_title mt-4" variant="h6" component="h1" color="success">
          {titleOption}
        </Typography>
      )}
      <Typography className="modal-error_content mt-4 mb-4" variant="h6" component="h2">
        {content}
      </Typography>
      {(contentOption !== '') && (
        <Typography className="modal-error_content mt-4 mb-4" variant="h6" component="h2">
          {contentOption}
        </Typography>
      )}
      {(contentOption1 !== '') && (
        <Typography className="modal-error_content mt-4 mb-4" variant="h6" component="h2">
          {contentOption1}
        </Typography>
      )}
      <PrimaryBtn
        onClick={handleClose}
      >
        {terms.Admin.close}
      </PrimaryBtn>
    </Box>
  </Modal>
)

ModalError.defaultProps = {
  contentOption: '',
  contentOption1: '',
  titleOption: '',
}

export default ModalError
