import { useDispatch, useSelector } from 'react-redux'
import { SelectChangeEvent } from '@mui/material'
import terms from 'common/terms'
import { useHistory } from 'react-router-dom'
import slugify from 'slugify'
import FormCreateSA from 'components/FormCreateSA/FormCreateSA'
import ModalError from 'components/Modal/ModalError/ModalError'
import { ReactElement, useEffect } from 'react'
import { PrimaryBtn } from 'themes/theme'
import { changeValueCreateSA, changeValueDateNewSA, closeModal } from 'reducers/SA/sa.reducer'
import { postSA } from 'reducers/SA/sa.thunk'
import { RootState } from 'Store'
import './createSA.scss'

const CreateSA = (): ReactElement => {
  const dispatch = useDispatch()

  const {
    yearNewSa, territory, errors,
  } = useSelector((state: RootState) => state.sa)
  const { territories, axes } = useSelector((state: RootState) => state.users.userMe)
  const { labelSA } = useSelector((state: RootState) => state.app)
  const history = useHistory()

  useEffect(() => {
    if (territories.length !== 0) {
      dispatch(changeValueCreateSA({ id: territories[0]?.id, libelle: territories[0]?.libelle }))
    }
  }, [territories])

  const addSA = async () => {
    const createSa = await dispatch(postSA({
      eic: territory.id,
      annee: yearNewSa,
    })) as unknown as { type: string, payload: { id: string } }

    if (createSa.type.endsWith('fulfilled')) {
      history.push(`/tableau-suivi/${slugify(labelSA as string)}`)
    }
  }

  const handleChangeTerritory = (event: SelectChangeEvent) => {
    const elemSelected = axes.concat(territories).find(item => item.libelle === event.target.value)
    if (elemSelected) {
      dispatch(changeValueCreateSA({ id: elemSelected.id, libelle: event.target.value }))
    }
  }

  return (
    <>
      <div className="createSa">
        <h1 className="d-flex flex-column text-uppercase mb-3">
          <span>{terms.CreateSA.title1}</span>
          <span className="createSa-title--item">{terms.CreateSA.title2}</span>
        </h1>
        <p>{terms.CreateSA.content}</p>
        <hr className="w-50 my-5" />
        <p className="font-weight-bold">{terms.CreateSA.selectTitle}</p>
        <FormCreateSA
          handleChangeTerritory={handleChangeTerritory}
          handleChangeDate={(event: SelectChangeEvent) => {
            dispatch(changeValueDateNewSA(+event.target.value))
          }}
        />
        <div className="text-center mt-4">
          <PrimaryBtn
            onClick={() => addSA()}
          >
            {terms.CreateSA.button}
          </PrimaryBtn>
        </div>
      </div>
      <ModalError
        onModal={!!errors}
        content={errors?.data.non_field_errors}
        handleClose={() => dispatch(closeModal())}
      />
    </>

  )
}

export default CreateSA
