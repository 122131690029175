import ObjectFerCS from 'components/ObjectFer/ObjectFerCS'
import { ReactElement } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { ParamConstructionSite } from 'reducers/Parametrage/ConstructionSites/types'
import './listParametrage.scss'

interface listComp {
  title: string,
  listId: string,
  objectFer: ParamConstructionSite []
}

const ListParametrageCS = ({ listId, objectFer }: listComp): ReactElement => (
  <Droppable droppableId={listId}>
    {provider => (
      <ul
        className="configListContainer"
        {...provider.droppableProps}
        ref={provider.innerRef}
      >
        {objectFer.map((item, index) => (
          <ObjectFerCS
            key={item.id}
            obj={item}
            list={listId}
            libelle={item.travaux?.libelle}
            objectId={item.id}
            index={index}
          />
        ))}
      </ul>
    )}
  </Droppable>
)

export default ListParametrageCS
