import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ContributionRaw } from 'reducers/Contributions/types'
import { ParamAnimator } from 'reducers/Parametrage/Animators/types'
import { ParamContributeur } from 'reducers/Parametrage/Contributeurs/types'
import { ParamDecider } from 'reducers/Parametrage/Deciders/types'
import {
  ComposantObject, ComposantObjectWithContribution, ComposantObjectWithContributionRaw,
  getSaJalonNbObjectResponse, SaJalon,
} from 'reducers/SA/types'
import { ThunkApi } from 'reducers/types'
import {
  UserMeRoles, UserRole, UserSimple, UserSimpleWithEmail,
} from 'reducers/Users/types'
import { typeObject } from 'pages/Suivi/utils'
import { setSnackbar, SnackbarSeverity } from 'reducers/app'
import terms from 'common/terms'
import {
  ParamsPostSettingPdf,
  ParamsPostToggle,
  getPossibleUsersEmailResponse,
  Mail,
  ParamsPostMail,
  ParamsDeleteMail,
  ParamsPostSettingPdfJ0,
  ParamsGetSAJalonByNumber,
  ParamsPatchGlobalContrib,
  GlobalContribution,
  ParamGetComponantsObject,
} from './types'
import {
  fileUrl, formatAnimatorList, formatUserList, getUserIds,
} from './utils'

const getUsersContributeurs = createAsyncThunk(
  'get/contributeur/suivi',
  async (params: (string | undefined)[], thunkApi) => {
    try {
      const response: UserSimple[] = await post('/cerbere/users/simple/', params)
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getToggleDisplayAllComponent = createAsyncThunk(
  'get/suivi/toggle',
  async (_, thunkApi) => {
    try {
      const response = await get('/robustest/users/me/toggle/')
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postToggleDisplayAllComponent = createAsyncThunk<ParamsPostToggle, ParamsPostToggle, ThunkApi>(
  'post/suivi/toggle',
  async (params:ParamsPostToggle, thunkApi) => {
    try {
      const response = await post('/robustest/users/me/toggle/', params)
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const fetchDataToCreatePdf = createAsyncThunk(
  'post/suivi/setting/pdf',
  async (params: ParamsPostSettingPdf | ParamsPostSettingPdfJ0, thunkApi) => {
    try {
      const response = await post(
        `/robustest/sa/${params.idSA}/jalons/${params.numJalon}/pdf/${params.typeToDisplay}`,
        params.option,
        { responseType: 'blob' }, // Ajoutez cette option pour récupérer les données sous forme de Blob
      )
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getSaJalonNbObject = createAsyncThunk<ComposantObject[], ParamGetComponantsObject, ThunkApi>(
  'get/sa/jalon/object',
  async (param: ParamGetComponantsObject, thunkApi) => {
    try {
      const userIdsSet = new Set<string>()
      const response: getSaJalonNbObjectResponse[] = await get(
        `/robustest/sa/${param.idSa}/jalons/${param.nJalon}/${param.type}/${param.idObject}/`,
      )
      response.forEach(composant => composant?.contributeurs?.forEach(user => userIdsSet.add(user.cerbere_id)))
      const usersResponse: UserSimple[] = await post('/cerbere/users/simple/', [...userIdsSet])
      return response.map(composant => ({
        ...composant,
        contributeurs: composant.contributeurs?.map(contributor => ({
          fonction: contributor.fonction,
          contributeur: usersResponse.find(user => user.id === contributor.cerbere_id),
          entite_id: contributor.entite_id,
        })).filter(contributor => contributor.contributeur),
      }))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getSAJalonNbObjectArray = createAsyncThunk<ComposantObjectWithContribution[], ParamGetComponantsObject, ThunkApi>(
  'get/sa/jalon/object/array',
  async (param: ParamGetComponantsObject, thunkApi) => {
    try {
      const userIds = new Set<string | null>()
      let response
      if (param.nJalon === 0) {
        response = await get(
          `/robustest/sa/${param.idSa}/jalons/${param.nJalon}/tableau/`,
        )
      } else {
        response = await get(
          `/robustest/sa/${param.idSa}/jalons/${param.nJalon}/${param.type}/${param.idObject}/tableau/`,
        )
      }

      response.forEach((composant: ComposantObjectWithContributionRaw) => {
        if (composant.contributeurs && composant.contributeurs.length > 0) {
          composant.contributeurs.forEach(contributeur => {
            userIds.add(contributeur.cerbere_id)
          })
        }
        if (composant.contributions[0]) {
          composant.contributions.forEach((contribution: ContributionRaw) => {
            userIds.add(contribution.auteur)
            userIds.add(contribution.createur)
          })
        }
      })
      const usersResponse: UserSimple[] = await post('/cerbere/users/simple/', [...userIds])
      const responseWithUrl = await Promise.all(response.map(
        async (composant: ComposantObjectWithContributionRaw) => ({
          ...composant,
          contributeurs: composant?.contributeurs?.map(contributor => ({
            fonction: contributor.fonction,
            contributeur: usersResponse.find(user => user.id === contributor.cerbere_id),
            entityId: contributor.entite_id,
          })).filter(contributor => contributor.contributeur),
          contributions: await Promise.all(composant.contributions.map(async (contribution: ContributionRaw) => ({
            ...contribution,
            file: await fileUrl(contribution.file),
          }))),
        }),
      ))

      return responseWithUrl.map(
        (composant: ComposantObjectWithContributionRaw) => ({
          ...composant,
          contributions: composant.contributions.map((contribution: ContributionRaw) => ({
            ...contribution,
            auteur: usersResponse.find(user => user.id === contribution.auteur) || null,
            createur: usersResponse.find(user => user.id === contribution.createur) || null,
            visibilite: contribution.visibilite.map(entity => entity.id || ''),
          })),
        }),
      )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getSAJalonByNumber = createAsyncThunk<SaJalon, ParamsGetSAJalonByNumber, ThunkApi>(
  'get/sa/jalon/number',
  async (params: ParamsGetSAJalonByNumber, thunkApi) => {
    try {
      const response: SaJalon = await get(`/robustest/sa/${params.idSa}/jalons/${params.jalon}/`)
      if (params.jalon > 0 && response.obj_ferroviaires.length > 0) {
        const requestParams = {
          idSa: params.idSa,
          nJalon: params.jalon,
          idObject: response.obj_ferroviaires[params.activeTabObject || 0].id,
          type: typeObject(response.obj_ferroviaires[params.activeTabObject || 0].type),
        }
        thunkApi.dispatch(getSAJalonNbObjectArray(requestParams))
        thunkApi.dispatch(getSaJalonNbObject(requestParams))
      } else {
        thunkApi.dispatch(getSAJalonNbObjectArray({
          idSa: params.idSa,
          nJalon: 0,
          idObject: '',
          type: '',
        }))
      }
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getPossibleUsersEmailBySa = createAsyncThunk<getPossibleUsersEmailResponse, UserMeRoles, ThunkApi>(
  'get/suivi/possibleUsersEmail/',
  async (sa: UserMeRoles, thunkApi) => {
    try {
      const possibleAnimators = sa.eic.animateurs
      const possibleContributors: ParamContributeur[] = await get(`/robustest/sa/${sa.id}/contributeurs/`)
      const possibleDeciders: ParamDecider[] = await get(`/robustest/sa/${sa.id}/decideurs/`, { all: true })
      const possibleEntityAnimateurs: ParamAnimator[] = await get(`/robustest/sa/${sa.id}/animateur/`, { all: true })

      const usersIds = new Set<string>([
        ...possibleAnimators.map(user => user.cerbere_id),
        ...getUserIds(possibleContributors),
        ...getUserIds(possibleDeciders),
        ...getUserIds(possibleEntityAnimateurs),
      ])

      const usersResponse: UserSimpleWithEmail[] = await post('/cerbere/users/simple_with_email/', [...usersIds])

      return {
        animators: formatAnimatorList(possibleAnimators, usersResponse, sa.eic.libelle),
        contributors: formatUserList(possibleContributors, UserRole.contributor, usersResponse),
        deciders: formatUserList(possibleDeciders, UserRole.decider, usersResponse),
        entityAnimators: formatUserList(possibleEntityAnimateurs, UserRole.entityAnimator, usersResponse),
      }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getSaSavedMails = createAsyncThunk<Mail[], string, ThunkApi>(
  'get/sa/savedMails',
  async (idSa: string, thunkApi) => {
    try {
      const response = await get(`/robustest/sa/${idSa}/mail/`)
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postSaSavedMail = createAsyncThunk<Mail, ParamsPostMail, ThunkApi>(
  'post/sa/savedMails',
  async (params: ParamsPostMail, thunkApi) => {
    try {
      const response = await post(`/robustest/sa/${params.idSA}/mail/`, params.mail)
      thunkApi.dispatch(getSaSavedMails(params.idSA))
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteSaSavedMail = createAsyncThunk<Mail, ParamsDeleteMail, ThunkApi>(
  'delete/sa/savedMails',
  async (params: ParamsDeleteMail, thunkApi) => {
    try {
      const response = await deleteRequest(`/robustest/sa/${params.idSA}/mail/${params.idMail}/`)
      thunkApi.dispatch(getSaSavedMails(params.idSA))
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const patchSaJalonObjectFerroviaire = createAsyncThunk<GlobalContribution, ParamsPatchGlobalContrib, ThunkApi>(
  'patch/sa/jalon/objectFerroviaire',
  async (params: ParamsPatchGlobalContrib, thunkApi) => {
    try {
      const response = await patch(
        `/robustest/sa/${params.idSa}/jalons/${params.jalon}/${params.type}/${params.idObjFerroviaire}`,
        {
          cotation: params.contribution.cotation,
          perimetre: params.contribution.perimetre || null,
          commentaire: params.contribution.commentaire || null,
        },
      )
      thunkApi.dispatch(setSnackbar({
        message: terms.Suivi.GlobalContribution.SnackBar.success,
        severity: SnackbarSeverity.SUCCESS,
      }))
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export {
  getUsersContributeurs,
  getToggleDisplayAllComponent,
  postToggleDisplayAllComponent,
  getSAJalonByNumber,
  getSaJalonNbObject,
  getSAJalonNbObjectArray,
  fetchDataToCreatePdf,
  getPossibleUsersEmailBySa,
  getSaSavedMails,
  postSaSavedMail,
  deleteSaSavedMail,
  patchSaJalonObjectFerroviaire,
}
