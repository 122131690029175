import fullOsmTheme from 'assets/mapstyles/full.json'
import Geo from 'geojson'
import {
  MutableRefObject, ReactElement, Ref, forwardRef, useRef,
  useState,
} from 'react'
import ReactMapGL, {
  MapEvent,
  MapRef,
  Popup,
  ViewportProps,
} from 'react-map-gl'
import { Complexes } from 'reducers/Admin/Complexes/types'
import { Ligne } from 'reducers/Admin/Portfolio/type'
import {
  DEFAULT_VIEWPORT, LAYER_NAMES, MAP_LAYER_SOURCE, loadBvImages, transformRequest,
} from 'services/map'
import { ConstructionSites } from 'reducers/Admin/ConstructionSites/types'
import BvLayer from './Layers/BvLayer/BvLayer'
import RegionLayer from './Layers/RegionLayer/RegionLayer'
import MapNavigationControl from './MapNavigationControl'
import Zoom from './MapZoom'

import ConstructionSiteLayer from './Layers/ConstructionSiteLayer/ConstructionSiteLayer'
import SectionLineLayer from './Layers/SectionLineLayer/SectionLineLayer'
import './map.scss'

interface Props {
  onFeatureClick: (e: MapEvent) => void
  bvSelected: Complexes[]
  constructionSiteSelected: ConstructionSites[]
  sectionLineSelected: Ligne[]
}

export type MapHandle = {
  handlePrintMap: (callback: (fd: FormData) => void) => void
  handleGetRenderedBV: () => Geo.Feature[]
  handleToggleUnselectedBV: () => void
}

const MapGL = forwardRef<MapHandle, Props>(({
  onFeatureClick,
  bvSelected,
  constructionSiteSelected,
  sectionLineSelected,
}): ReactElement => {
  const mapRef: MutableRefObject<MapRef | undefined> | undefined = useRef()
  const [viewport, setViewport] = useState<ViewportProps>(DEFAULT_VIEWPORT)
  const [hoveredIds, setHoveredIds] = useState<string[]>([])
  const [hoveredLine, setHoveredLine] = useState<GeoJSON.Feature | null>(null)
  const [hoveredLineEvent, setHoveredLineEvent] = useState<MapEvent | null>(null)

  const onViewportChange = (newViewport: ViewportProps) => {
    setViewport({ ...newViewport, transitionDuration: 0 })
  }

  const bvligneIds = (elements: Ligne[]) => elements.map(item => {
    const ids = new Set<string>()
    if (item.bv_debut.gaia_id) ids.add(item.bv_debut.gaia_id)
    if (item.bv_via?.gaia_id) ids.add(item.bv_via.gaia_id)
    if (item.bv_fin.gaia_id) ids.add(item.bv_fin.gaia_id)
    return Array.from(ids)
  })

  const onHover = (e: MapEvent) => {
    const features = e.features || []
    if (features.length > 0) {
      const ids = features.map(f => f.properties?.id)
      setHoveredIds(ids)
      const lineFeature = features.find(f => f.source === MAP_LAYER_SOURCE.sectionLine)
      if (lineFeature) {
        setHoveredLine(lineFeature)
        setHoveredLineEvent(e)
      } else {
        setHoveredLine(null)
        setHoveredLineEvent(null)
      }
    } else {
      setHoveredIds([])
      setHoveredLine(null)
      setHoveredLineEvent(null)
    }
  }

  return (
    <div className="map-gl">
      <Zoom zoom={viewport.zoom || 0} />
      <MapNavigationControl viewport={viewport} setViewport={setViewport} />
      <ReactMapGL
        {...viewport}
        ref={mapRef as Ref<MapRef>}
        width="100%"
        height="100%"
        minZoom={4.5}
        transformRequest={transformRequest}
        mapStyle={fullOsmTheme}
        onLoad={() => { loadBvImages(mapRef) }}
        onViewportChange={onViewportChange}
        onClick={onFeatureClick}
        onHover={onHover}
        interactiveLayerIds={[
          LAYER_NAMES.bvLayer,
          `${LAYER_NAMES.bvLayer}-name`,
          LAYER_NAMES.constructionSiteLayer,
          `${LAYER_NAMES.constructionSiteLayer}-name`,
          LAYER_NAMES.sectionLineLayer,
        ]}
      >
        <RegionLayer />
        <SectionLineLayer selectedSection={sectionLineSelected.map(item => item.id) || []} />
        <BvLayer
          selectedBv={bvSelected.map(item => item.gaia_id) || []}
          selectedBVLigne={bvligneIds(sectionLineSelected).flat() || []}
          hoveredIds={hoveredIds}
          viewport={viewport}
        />
        <ConstructionSiteLayer
          selectedConstructionSite={constructionSiteSelected.map(item => item.id) || []}
          hoveredIds={hoveredIds}
          viewport={viewport}
        />

        {hoveredLine && hoveredLineEvent && (
          <Popup
            longitude={hoveredLineEvent.lngLat[0]}
            latitude={hoveredLineEvent.lngLat[1]}
            closeButton={false}
            anchor="left"
          >
            {hoveredLine.properties?.libelle}
          </Popup>
        )}
      </ReactMapGL>
    </div>
  )
})

MapGL.displayName = 'MapGL'

export default MapGL
