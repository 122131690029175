import { ReactElement } from 'react'

import TabElement, { NavElement } from './TabElement'

type Props = {
  tabs: NavElement[];
  appPermissions: string[];
}

const style = {
  flex: 1,
}

export default function TabList({ tabs, appPermissions }: Props): ReactElement {
  return (
    <nav className="d-flex align-item-center h-100 ml-3" style={style}>
      <ul>
        {tabs.map(el => (
          ((el.access || []).length === 0 || el.robAccess.length !== 0
           || appPermissions.some(permission => el.access.includes(permission)))
           && <TabElement key={el.path} element={el} />
        ))}
      </ul>
    </nav>
  )
}
