import {
  Autocomplete,
  FormLabel,
  TextField,
} from '@mui/material'
import terms from 'common/terms'
import React, { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  closeModalConfirmation,
  closeModalErrConstructionSite,
  inputAddConstructionSiteSa,
  onModalAppartConstructionSite,
  onModalAssosConstructionSite,
  hideSnackbarAdd,
  hideSnackbarUpdate,
  hideSnackbarDelete,
  setSaConstructionSite,
  changeSaConstructionSite,
} from 'reducers/Parametrage/ConstructionSites/constructionSites.reducer'
import {
  deleteSaConstructionSite,
  getDefaultJalonsConstructionSite,
  getSaConstructionSites,
  patchSAConstructionSite,
  postSaConstructionSite,
  searchConstructionSites,
} from 'reducers/Parametrage/ConstructionSites/constructionSites.thunk'
import { openModalCreateConstructionSite } from 'reducers/Admin/ConstructionSites/constructionSites'
import { RootState } from 'Store'
import ModalInfo from 'components/Modal/ModalInfo/ModalInfo'
import ModalError from 'components/Modal/ModalError/ModalError'
import ModalConfirmation from 'components/Modal/ModalConfirmation/ModalConfirmation'
import ModalObjectComposants from 'components/Modal/ModalObjectComposants/ModalObjectComposants'
import CustomSnackbar from 'components/Snackbar/Snackbar'
import { PrimaryBtn } from 'themes/theme'
import './constructionSites.scss'
import IconButton from '@mui/material/IconButton'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { getSaById } from 'reducers/SA/sa.thunk'
import { ConstructionSites } from 'reducers/Admin/ConstructionSites/types'
import Loader from 'components/Loader'
import DragConstructionSite from './dragConstructionSite/DragConstructionSite'
import { JALONS_NUMBERS } from '../constants'
import ModalCreateConstructionSite from './modalConstructionSite/ModalCreateConstructionSite'
import AutocompleteOption from '../complexes/AutocompleteOption'

import '../settings.scss'

const ParamConstructionSites = (): ReactElement => {
  const dispatch = useDispatch()
  const {
    modalConfirmation,
    selectedConstructionSite,
    addConstructionSiteSa,
    modalErrAssosConstructionSite,
    modalErrConstructionSite,
    errors,
    displaySnackbarAdd,
    displaySnackbarUpdate,
    displaySnackbarDelete,
    saConstructionSite,
    openComposantsModal,
    defaultComposantsConstructionSite,
  } = useSelector((state: RootState) => state.paramConstructionSites)
  const {
    listConstructionSitesSaOptions,
    isLoadingConstructionSite,
  } = useSelector((state: RootState) => state.paramConstructionSites)
  const { userMeSettings } = useSelector((state: RootState) => state.users)
  const { idSA, labelSA } = useSelector((state: RootState) => state.app)

  const thisSA = userMeSettings.find(item => item.id === idSA)
  useEffect(() => {
    dispatch(searchConstructionSites(''))
    dispatch(getSaConstructionSites(idSA))
  }, [idSA])

  useEffect(() => {
    if (errors) {
      if (errors.data[0].includes('appartient')) {
        dispatch(onModalAppartConstructionSite())
      } else {
        dispatch(onModalAssosConstructionSite())
      }
    }
  }, [errors])

  useEffect(() => {
    JALONS_NUMBERS.forEach(n => dispatch(getDefaultJalonsConstructionSite(n)))
  }, [])

  const handleDelete = () => {
    dispatch(deleteSaConstructionSite(selectedConstructionSite?.id))
    dispatch(closeModalConfirmation())
  }

  const handleOpenCreateCSModal = () => {
    dispatch(openModalCreateConstructionSite())
    dispatch(getSaById(idSA))
  }

  const handlePatchValidate = () => dispatch(patchSAConstructionSite({
    idSA,
    idBV: saConstructionSite?.id,
    constructionSite: {
      sa_jalon: saConstructionSite?.sa_jalon,
    },
  }))

  const handleChangeCT = (newValue: ConstructionSites) => {
    if (
      newValue?.libelle === terms.Settings.constructionSite.addConstructionSite
    ) return handleOpenCreateCSModal()
    return dispatch(inputAddConstructionSiteSa(newValue))
  }

  const readOnlyParamJalon = !!saConstructionSite?.travaux?.eic
  && !labelSA?.includes(saConstructionSite?.travaux?.eic?.libelle || '')

  return (
    <>
      {
        isLoadingConstructionSite ? (
          <div className="setting-loader">
            <Loader message={terms.Common.loading} />
          </div>
        ) : (
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-around">
              <span className=" w-50 ml-3 mt-3 font-weight-bold">
                {terms.Settings.constructionSite.listNotActive}
              </span>
              <span className=" w-50 ml-3 mt-3 font-weight-bold">
                {terms.Settings.constructionSite.listActive}
              </span>
            </div>
            <div className="d-flex">
              <DragConstructionSite />
            </div>
            <div className="searchContainer mt-3">
              <FormLabel required className="labelSearch">
                {terms.Settings.constructionSite.search}
              </FormLabel>
              <div className="d-flex w-100 align-items-center">
                <Autocomplete
                  className="searchConstructionSite w-100"
                  disablePortal
                  id="searchConstructionSite"
                  options={[
                    ...listConstructionSitesSaOptions,
                {
                  libelle: terms.Settings.constructionSite.addConstructionSite,
                  eic: {
                    libelle: '',
                  },
                } as ConstructionSites,
                  ]}
                  value={addConstructionSiteSa || null}
                  onChange={(_e, newValue) => handleChangeCT(newValue as ConstructionSites)}
                  onInputChange={(_e, newValue) => dispatch(searchConstructionSites(newValue))}
                  getOptionLabel={option => option.libelle}
                  renderOption={(props, option) => {
                    if (option.libelle === terms.Settings.constructionSite.addConstructionSite) {
                      return (
                        <AutocompleteOption
                          props={props}
                          label={option.libelle}
                        >
                          <IconButton
                            aria-label="create"
                            className="remove-focus-outline"
                            size="large"
                            disableFocusRipple
                            onClick={handleOpenCreateCSModal}
                          >
                            <AddCircleOutlineIcon />
                          </IconButton>
                        </AutocompleteOption>
                      )
                    }
                    return (
                      <AutocompleteOption
                        props={props}
                        label={option.libelle}
                        option={option?.eic?.libelle}
                      />
                    )
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder={terms.Settings.constructionSite.tab}
                      size="small"
                    />
                  )}
                />
              </div>
              <div className="text-center mt-4">
                <PrimaryBtn
                  disabled={addConstructionSiteSa === null || addConstructionSiteSa === undefined}
                  onClick={() => {
                    dispatch(postSaConstructionSite({
                      saId: idSA,
                      ConstructionSiteId: addConstructionSiteSa?.id,
                    }))
                  }}
                >
                  {terms.Common.add}
                </PrimaryBtn>
              </div>
            </div>
          </div>
        )
      }

      <ModalInfo
        onModal={modalErrConstructionSite}
        titleOption={terms.Settings.constructionSite.error.errorAddConstructionSite.title2}
        content={`${errors?.data[0]}.`}
        contentOption={terms.Settings.constructionSite.error.errorAddConstructionSite.contactName}
        contactMail={terms.Settings.constructionSite.error.errorAddConstructionSite.mail}
        contactPhone={terms.Settings.constructionSite.error.errorAddConstructionSite.tel}
        handleClose={() => dispatch(closeModalErrConstructionSite())}
      />
      <ModalError
        onModal={modalErrAssosConstructionSite}
        titleOption={terms.Settings.constructionSite.error.errorAddConstructionSite.title2}
        content={`${errors?.data[0]}.`}
        handleClose={() => dispatch(closeModalErrConstructionSite())}
      />
      <ModalConfirmation
        handleConfirmClick={handleDelete}
        onModal={modalConfirmation}
        content1={terms.Settings.constructionSite.modalConfirmation.content1}
        content2={terms.Settings.constructionSite.modalConfirmation.content2}
        variable1={selectedConstructionSite?.travaux?.libelle}
        variable2={thisSA?.eic.libelle}
        handleClose={() => dispatch(closeModalConfirmation())}
      />
      <ModalCreateConstructionSite />
      <ModalObjectComposants
        open={openComposantsModal}
        readOnly={readOnlyParamJalon}
        handleClose={() => dispatch(setSaConstructionSite())}
        object={saConstructionSite}
        defaultComponents={defaultComposantsConstructionSite}
        title={terms.Settings.constructionSite.composantModal.title}
        clearText={terms.Settings.resetCSJalon}
        handleChangeObject={object => dispatch(changeSaConstructionSite(object))}
        handleValidate={handlePatchValidate}
      />

      <CustomSnackbar
        message={terms.Settings.constructionSite.snackbarMessage.constructionSiteAdded}
        displaySnackbar={displaySnackbarAdd}
        handleClose={() => dispatch(hideSnackbarAdd())}
      />
      <CustomSnackbar
        message={terms.Settings.constructionSite.snackbarMessage.constructionSiteUpdated}
        displaySnackbar={displaySnackbarUpdate}
        handleClose={() => dispatch(hideSnackbarUpdate())}
      />
      <CustomSnackbar
        message={terms.Settings.constructionSite.snackbarMessage.constructionSiteDeleted}
        displaySnackbar={displaySnackbarDelete}
        handleClose={() => dispatch(hideSnackbarDelete())}
      />
    </>
  )
}

export default ParamConstructionSites
