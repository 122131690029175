import { Autocomplete, TextField } from '@mui/material'
import { ReactElement, SyntheticEvent } from 'react'

import './CustomSelect.scss'

export type ValueSelect = {
  libelle: string
  id: string
}

interface Props {
  options: ValueSelect[]
  inputValue: ValueSelect | null
  handleChange: (e: SyntheticEvent, newValue: ValueSelect | null) => void
  placeholder: string
  disabled?: boolean
}

const CustomSelect = ({
  options, inputValue, handleChange, placeholder, disabled,
}: Props): ReactElement => (
  <div className="wrapper-select">
    <Autocomplete
      disablePortal
      disabled={disabled}
      id="custom-select"
      options={options}
      value={inputValue}
      onChange={handleChange}
      getOptionLabel={option => option.libelle}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={placeholder}
          size="small"
        />
      )}
    />
  </div>

)

CustomSelect.defaultProps = {
  disabled: false,
}

export default CustomSelect
