import { post, get, patch } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { setSavedSa } from 'reducers/app'
import { updateActiveJalonStatus } from 'reducers/Suivi/suivi.reducer'
import { ThunkApi } from 'reducers/types'
import { getUserMePlanAction, getUserMeSettings, getUserMeSuivi } from 'reducers/Users/users.thunk'
import { NewSA, SA, SaJalon } from './types'

const getSa = createAsyncThunk(
  'sa/get',
  async (search: string, thunkApi) => {
    try {
      const response: SA[] = await get('/robustest/sa/', { libelle: search })
      return response
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getSaById = createAsyncThunk(
  'sa/getSaById',
  async (id: string | null, thunkApi) => {
    try {
      const response: SA = await get(`/robustest/sa/${id}`)
      return response
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postSA = createAsyncThunk<SA, NewSA, ThunkApi>(
  'sa/postSA',
  async (newSA: NewSA, thunkApi) => {
    try {
      const response: SA = await post('/robustest/sa/', newSA)

      thunkApi.dispatch(getSa(''))
      await Promise.all([
        thunkApi.dispatch(getUserMeSuivi()),
        thunkApi.dispatch(getUserMeSettings()),
        thunkApi.dispatch(getUserMePlanAction()),
      ])
      thunkApi.dispatch(setSavedSa({
        idSA: response.id,
        labelSA: response.libelle,
        eicLibelle: response.eic.libelle,
      }))
      return response

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

type PatchParams = {
  saId: string,
  jalon: Partial<SaJalon>
}
const patchSa = createAsyncThunk<SA, PatchParams, ThunkApi>(
  'sa/patchSa',
  async (params: PatchParams, thunkApi) => {
    try {
      const response: SA = await patch(`/robustest/sa/${params.saId}`, { jalons: [params.jalon] })
      thunkApi.dispatch(getUserMeSuivi())
      thunkApi.dispatch(updateActiveJalonStatus(response.jalons))
      return response
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getSAJalon = createAsyncThunk(
  'get/sa/jalon',
  async (params: string | null, thunkApi) => {
    try {
      const response = await get(`/robustest/sa/${params}/jalons/`)
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export {
  getSa,
  postSA,
  patchSa,
  getSaById,
  getSAJalon,
}
// eslint-disable-next-line import/prefer-default-export
