import terms from 'common/terms'
import SettingsTab from 'components/SettingsTab/SettingsTab'
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { deleteSaAnimators, postSaAnimators } from 'reducers/Parametrage/Animators/animators.thunks'
import {
  closeModalConfirmation, closeModalErrAnimator, onModalAddAnimator, onModalConfirmation,
  selectAnimator, selectAnimatorEntity, hideSnackbarAdd, hideSnackbarDelete,
} from 'reducers/Parametrage/Animators/animators.reducer'
import ModalError from 'components/Modal/ModalError/ModalError'
import ModalConfirmation from 'components/Modal/ModalConfirmation/ModalConfirmation'
import CustomSnackbar from 'components/Snackbar/Snackbar'
import { setDisplayUserInfos, setUserInfosId } from 'reducers/Users/users.reducer'
import Loader from 'components/Loader'

import '../settings.scss'

const EntityAnimator = (): ReactElement => {
  const dispatch = useDispatch()
  const {
    selectedAnimator, selectedEntity, animators, modalConfirmation, animatorToDeleteId, modalErrAnimator, errors,
    displaySnackbarAdd, displaySnackbarDelete, isloadingEntityAnimators,
  } = useSelector((state: RootState) => state.paramAnimators)
  const { idSA } = useSelector((state: RootState) => state.app)

  useEffect(() => {
    if (errors) {
      dispatch(onModalAddAnimator())
    }
  }, [errors])

  const handleDelete = () => {
    dispatch(deleteSaAnimators({
      saId: idSA,
      saAnimatorId: animatorToDeleteId,
    }))
    dispatch(closeModalConfirmation())
  }

  const handleClickUser = (userId: string) => {
    dispatch(setUserInfosId(userId))
    dispatch(setDisplayUserInfos(true))
  }

  return (
    <>
      {
        isloadingEntityAnimators ? (
          <div className="setting-loader">
            <Loader message={terms.Common.loading} />
          </div>
        ) : (
          <SettingsTab
            user={selectedAnimator}
            entity={selectedEntity}
            disableEntity={false}
            onUserChange={(_e, newValue) => dispatch(selectAnimator(newValue))}
            onEntityChange={(_e, newValue) => dispatch(selectAnimatorEntity(newValue))}
            handleValidate={() => {
              dispatch(postSaAnimators({
                saId: idSA,
                user: selectedAnimator,
                entity: selectedEntity,
              }))
            }}
            handleDelete={(itemId?: string) => () => dispatch(onModalConfirmation(itemId))}
            handleClickUser={handleClickUser}
            emptyLabel={terms.Settings.entityAnimator.noAnimator}
            items={animators}
          />
        )
      }

      <ModalError
        onModal={modalErrAnimator}
        titleOption={terms.Settings.entityAnimator.error.errorAddAnimator.title2}
        content={`${errors?.data[0]}.`}
        handleClose={() => dispatch(closeModalErrAnimator())}
      />
      <ModalConfirmation
        onModal={modalConfirmation}
        handleConfirmClick={handleDelete}
        content1={terms.Settings.entityAnimator.modalConfirmation.content1}
        content2={terms.Settings.entityAnimator.modalConfirmation.content2}
        handleClose={() => dispatch(closeModalConfirmation())}
      />

      <CustomSnackbar
        displaySnackbar={displaySnackbarAdd}
        message={terms.Settings.entityAnimator.snackbarMessage.animatorAdded}
        handleClose={() => dispatch(hideSnackbarAdd())}
      />
      <CustomSnackbar
        displaySnackbar={displaySnackbarDelete}
        message={terms.Settings.entityAnimator.snackbarMessage.animatorDeleted}
        handleClose={() => dispatch(hideSnackbarDelete())}
      />
    </>
  )
}

export default EntityAnimator
