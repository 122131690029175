import { get } from '@osrdata/app_core/dist/requests'
import { UserRole, UserSimpleWithEmail } from 'reducers/Users/types'
import { ParamContributeur } from 'reducers/Parametrage/Contributeurs/types'
import { ParamDecider } from 'reducers/Parametrage/Deciders/types'
import { ParamAnimator } from 'reducers/Parametrage/Animators/types'
import { User } from 'reducers/Admin/Eic/types'
import { ParamUserList, SendMailUser } from './types'

/**
 * Get file link from file path
 * @param file
 * @returns fileLink as string
 */
const fileUrl = async (file: string | null): Promise<string | null> => {
  if (file) {
    try {
      const url = new URL(file)
      const fileLink = await get(
        url.pathname,
        { content_disposition: 'inline' },
        undefined,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0',
          },
        },
      )
      return fileLink as string
    } catch (e) {
      return null
    }
  }
  return null
}

/**
 * Get user ids from user list
 * @param userList
 * @returns userIds as string[]
 */
const getUserIds = (userList: ParamUserList): string[] => {
  const userIds = new Set<string>()
  userList.forEach(user => {
    if (user.utilisateur?.cerbere_id) {
      userIds.add(user.utilisateur.cerbere_id)
    }
  })
  return [...userIds]
}

/**
 * Get function from user
 * @param user
 * @param type UserRole
 * @returns fonction as string
 */
const getFonction = (user: ParamAnimator | ParamContributeur | ParamDecider, type: UserRole): string => {
  switch (type) {
    case UserRole.contributor:
      return (user as ParamContributeur)?.fonction?.entite.libelle || UserRole.contributor
    case UserRole.decider:
      return (user as ParamDecider).entite?.libelle || UserRole.decider
    case UserRole.entityAnimator:
      return (user as ParamAnimator).entite?.libelle || UserRole.entityAnimator
    default:
      return type
  }
}

/**
 * Format animator list
 * @param userList as User[]
 * @param usersResponse as UserSimpleWithEmail[]
 * @param fonction as string
 * @returns users as SendMailUser[]
 */
const formatAnimatorList = (
  userList: User[], usersResponse: UserSimpleWithEmail[], fonction: string,
): SendMailUser[] => {
  const users: SendMailUser[] = []
  userList.forEach(user => {
    const userWithMail = usersResponse.find(userResponse => userResponse.id === user.cerbere_id)
    if (userWithMail) {
      users.push({
        ...userWithMail,
        fonction,
        role: UserRole.animator,
      })
    }
  })
  return users
}

/**
 * Format user list
 * @param userList as ParamUserList
 * @param type as UserRole
 * @param usersResponse as UserSimpleWithEmail[]
 * @returns users as SendMailUser[]
 */
const formatUserList = (
  userList: ParamUserList, type: UserRole, usersResponse: UserSimpleWithEmail[],
): SendMailUser[] => {
  const users: SendMailUser[] = []
  userList.forEach(user => {
    const userWithMail = usersResponse.find(userResponse => userResponse.id === user.utilisateur?.cerbere_id)
    if (userWithMail) {
      users.push({
        ...userWithMail,
        fonction: getFonction(user, type),
        role: type,
      })
    }
  })
  return users
}

export {
  fileUrl, getUserIds, getFonction, formatAnimatorList, formatUserList,
}
