import { ReactElement, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import './componentTile.scss'
import { Action, ActionTileType } from 'reducers/ActionPlan/types'
import { cotationInfo } from 'common/cotation'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import ActionTile from '../ActionTile/ActionTile'

type ComponentTileProps = {
  componentName: string,
  componentId: number,
  numJalon: number,
  year: number,
  cotation: string,
  actions: Action[],
  actionTileType: ActionTileType,
  composantId: string,
  handleComponentClick?: () => void,
  handleActionClick?: (action: Action, componentId?: string) => () => void,
}

const ComponentTile = ({
  componentName,
  componentId,
  numJalon,
  year,
  cotation,
  actions,
  actionTileType,
  composantId,
  handleActionClick,
  handleComponentClick,
}: ComponentTileProps): ReactElement => {
  const { actionExpanded } = useSelector((state: RootState) => state.paramPreferences)
  const [isExpanded, setIsExpanded] = useState(actionExpanded)

  return (
    <div className="component-tile">
      <div className="component-tile-header d-flex justify-content-between align-items-center">
        {!isExpanded ? (
          <div className="actions-recap d-flex align-items-center">
            <span>{`${actions.length} actions`}</span>
          </div>
        ) : (
          <div />
        )}
        <div
          className="component-tile-expand d-flex align-items-center justify-content-center pb-2 ml-1"
          role="presentation"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
        </div>
      </div>
      <div className="component-item d-flex justify-content-between  align-items-end">
        <button
          className="component-item-id d-flex align-items-center"
          type="button"
          disabled={!handleComponentClick}
          onClick={handleComponentClick}
        >
          <div className="component-item-id-item d-flex align-items-center">
            <span>
              {componentId}
            </span>
          </div>
          <div className="component-item-libelle d-flex align-items-center">
            <p className="m-0">{componentName}</p>
          </div>
        </button>
        <div className="d-flex justify-content-between align-items-center">
          <div className="component-item-sa d-flex mr-1 align-items-center">
            <div className="component-item-jalon mr-1">
              {`J${numJalon}`}
            </div>
            <div className="component-item-date">
              {year}
            </div>
          </div>
          {!isExpanded && (
          <div className="component-item-cotation">
            <div
              className="component-item-cotation-item"
              style={{ backgroundColor: cotationInfo(cotation)?.color }}
            >
              {cotation}
            </div>
          </div>
          )}
        </div>
      </div>

      <div className="component-actions d-flex flex-column">
        {isExpanded && actions.map(action => (
          <ActionTile
            type={actionTileType}
            action={action}
            key={action.id}
            handleClick={handleActionClick && handleActionClick(action, composantId)}
          />
        ))}
      </div>
    </div>
  )
}

ComponentTile.defaultProps = {
  handleComponentClick: undefined,
  handleActionClick: undefined,
}

export default ComponentTile
