import { TextField } from '@mui/material'
import terms from 'common/terms'
import PopOver from 'components/PopOver/PopOver'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { ReactElement } from 'react'

import './PonderationInput.scss'

interface Props {
  inputValue: string
  error: boolean
  messageError: string
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const PonderationInput = ({
  inputValue,
  error,
  messageError,
  handleChange,
}: Props): ReactElement => (
  <>
    <TextField
      size="small"
      type="number"
      value={inputValue}
      onChange={handleChange}
      error={error}
    />
    {
      error && (
        <PopOver
          popContent={messageError}
        >
          <span className="error-message">
            <HelpOutlineIcon />
            {terms.Admin.ponderation.message.error.title}
          </span>
        </PopOver>
      )
    }
  </>
)

export default PonderationInput
