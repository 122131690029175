import { RootState } from 'Store'
import terms from 'common/terms'
import Loader from 'components/Loader'
import ModalConfirmation from 'components/Modal/ModalConfirmation/ModalConfirmation'
import ModalError from 'components/Modal/ModalError/ModalError'
import ModalInfo from 'components/Modal/ModalInfo/ModalInfo'
import ModalObjectComposants from 'components/Modal/ModalObjectComposants/ModalObjectComposants'
import CustomSnackbar from 'components/Snackbar/Snackbar'
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { searchComplexes } from 'reducers/Admin/Complexes/complexes.thunk'
import {
  changeSaLigne,
  closeModalConfirmationLigne,
  closeModalErrligne,
  hideSnackbarAdd,
  hideSnackbarDelete,
  hideSnackbarUpdate,
  onModalErrLigneEic,
  onModalErrLigneSa,
  setSaLigne,
} from 'reducers/Parametrage/ParamLignes/paramLignes.reducers'
import {
  deleteSaLigne, getDefaultJalonsLigne, getSaLignes, patchSaLigne,
} from 'reducers/Parametrage/ParamLignes/paramLignes.thunk'
import { JALONS_NUMBERS } from '../constants'
import DragLignes from './DragLignes/DragLignes'
import SearchLigne from './SearchLigne/SearchLigne'
import './paramLignes.scss'
import '../settings.scss'

const ParamLignes = (): ReactElement => {
  const dispatch = useDispatch()
  const { userMeSettings } = useSelector((state: RootState) => state.users)
  const { idSA, labelSA } = useSelector((state: RootState) => state.app)
  const { isLoadingLignes } = useSelector((state: RootState) => state.paramLignes)
  const thisSA = userMeSettings.find(item => item.id === idSA)
  const {
    modalConfirmationLigne, selectedLigne, errors, modalErrLigneSa, modalErrLigneEic,
    displaySnackbarAdd, displaySnackbarUpdate, displaySnackbarDelete, saLigne, openComposantsModal,
    defaultComposantsLigne,
  } = useSelector((state: RootState) => state.paramLignes)

  useEffect(() => {
    dispatch(searchComplexes(''))
    dispatch(getSaLignes(idSA))
  }, [idSA])

  useEffect(() => {
    JALONS_NUMBERS.forEach(n => dispatch(getDefaultJalonsLigne(n)))
  }, [])

  useEffect(() => {
    if (errors) {
      if (errors.data[0].includes('appartient')) {
        dispatch(onModalErrLigneEic())
      } else {
        dispatch(onModalErrLigneSa())
      }
    }
  }, [errors])

  const handleDelete = () => {
    dispatch(deleteSaLigne(selectedLigne?.id))
    dispatch(closeModalConfirmationLigne())
  }

  const handlePatchValidate = () => dispatch(patchSaLigne({
    idSA,
    idLigne: saLigne?.id,
    ligne: {
      sa_jalon: saLigne?.sa_jalon,
    },
  }))

  let libelleLigne = ''
  if (selectedLigne?.ligne?.bv_via?.libelle === undefined || selectedLigne?.ligne.bv_via?.libelle === null) {
    libelleLigne = `${selectedLigne?.ligne?.bv_debut?.libelle} - ${selectedLigne?.ligne?.bv_fin?.libelle}`
  } else {
    libelleLigne = `
    ${selectedLigne?.ligne.bv_debut?.libelle} - 
    ${selectedLigne?.ligne.bv_fin?.libelle} via ${selectedLigne?.ligne.bv_via?.libelle}`
  }

  const readOnlyParamJalon = !!saLigne?.ligne?.eic
  && !labelSA?.includes(saLigne?.ligne?.eic?.libelle || '')

  return (
    <>
      {
        isLoadingLignes ? (
          <div className="setting-loader">
            <Loader message={terms.Common.loading} />
          </div>
        ) : (
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-around">
              <span className=" w-50 ml-3 mt-3 font-weight-bold">
                {terms.Settings.lignes.columns.listNotActive}
              </span>
              <span className=" w-50 ml-3 mt-3 font-weight-bold">
                {terms.Settings.lignes.columns.listActive}
              </span>
            </div>
            <div className="d-flex">
              <DragLignes />
            </div>
            <SearchLigne />
          </div>
        )
      }

      <ModalInfo
        onModal={modalErrLigneEic}
        titleOption={terms.Settings.lignes.error.errorAddLigne.title2}
        content={`${errors?.data[0]}.`}
        contentOption={terms.Settings.lignes.error.errorAddLigne.contactName}
        contactMail={terms.Settings.lignes.error.errorAddLigne.mail}
        contactPhone={terms.Settings.lignes.error.errorAddLigne.tel}
        handleClose={() => dispatch(closeModalErrligne())}
      />
      <ModalError
        onModal={modalErrLigneSa}
        titleOption={terms.Settings.lignes.error.errorAddLigne.title2}
        content={`${errors?.data[0]}.`}
        handleClose={() => dispatch(closeModalErrligne())}
      />
      <ModalConfirmation
        handleConfirmClick={handleDelete}
        onModal={modalConfirmationLigne}
        content1={terms.Settings.lignes.modalConfirmation.content1}
        content2={terms.Settings.lignes.modalConfirmation.content2}
        variable1={libelleLigne}
        variable2={thisSA?.eic.libelle}
        handleClose={() => dispatch(closeModalConfirmationLigne())}
      />
      <ModalObjectComposants
        open={openComposantsModal}
        readOnly={readOnlyParamJalon}
        handleClose={() => dispatch(setSaLigne())}
        object={saLigne}
        defaultComponents={defaultComposantsLigne}
        title={terms.Settings.lignes.composantModal.title}
        clearText={terms.Settings.resetLigneJalon}
        handleChangeObject={object => dispatch(changeSaLigne(object))}
        handleValidate={handlePatchValidate}
      />

      <CustomSnackbar
        message={terms.Settings.lignes.snackbarMessage.ligneAdded}
        displaySnackbar={displaySnackbarAdd}
        handleClose={() => dispatch(hideSnackbarAdd())}
      />
      <CustomSnackbar
        message={terms.Settings.lignes.snackbarMessage.ligneUpdated}
        displaySnackbar={displaySnackbarUpdate}
        handleClose={() => dispatch(hideSnackbarUpdate())}
      />
      <CustomSnackbar
        message={terms.Settings.lignes.snackbarMessage.ligneDeleted}
        displaySnackbar={displaySnackbarDelete}
        handleClose={() => dispatch(hideSnackbarDelete())}
      />
    </>
  )
}

export default ParamLignes
