import { ReactElement } from 'react'
import SettingsTabForm, { SettingsTabFormProps } from './SettingsTabForm'
import './settingsTab.scss'
import SettingsTabList, { SettingsTabListProps } from './SettingsTabList'

interface SettingsTabProps extends SettingsTabListProps, SettingsTabFormProps {}

const SettingsTab = ({
  user, entity, onUserChange, onEntityChange, handleValidate, disableEntity,
  emptyLabel, items, handleDelete, handleClickUser,
}: SettingsTabProps): ReactElement => (
  <div className="d-flex settings-tab-container">
    <div className="d-flex settings-tab-panel flex-column">
      <SettingsTabForm
        user={user}
        entity={entity}
        disableEntity={disableEntity}
        onUserChange={onUserChange}
        onEntityChange={onEntityChange}
        handleValidate={handleValidate}
      />
    </div>
    <div className="d-flex settings-tab-panel">
      <SettingsTabList
        emptyLabel={emptyLabel}
        items={items}
        handleDelete={handleDelete}
        handleClickUser={handleClickUser}
      />
    </div>
  </div>
)

export default SettingsTab
