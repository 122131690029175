import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AccordionDetails, AccordionSummary, Divider } from '@mui/material'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { RootState } from 'Store'
import terms from 'common/terms'
import {
  ReactElement, SyntheticEvent, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Entity } from 'reducers/Admin/Entity/types'
import { getLignes, getPortfolio, getRobustestComplexes } from 'reducers/Admin/Portfolio/portfolio.thunk'
import { ModalType } from 'reducers/Admin/Portfolio/type'
import { getConstructionSites, getEntity } from 'reducers/Admin/admin.thunk'
import { getAllLignes, getConstructionSitesLayer, getTracks } from 'reducers/Map/map.thunk'
import { UserRole } from 'reducers/Users/types'
import Loader from 'components/Loader'
import ModalCreateOrUpdate from './Components/Modals/ModalCreateOrUpdate'
import ModalImport from './Components/Modals/ModalImport'
import SupervisionPortfolioDetail from './Components/SupervisionPortfolioDetail'

import './SupervisionPortfolios.scss'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&::before': {
    display: 'none',
  },
}))

const SupervisionPortfolios = (): ReactElement => {
  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState<string | false>(false)
  const { entities } = useSelector((state: RootState) => state.entity)
  const { robPermission, usersEntitySupervisor } = useSelector((state: RootState) => state.users)
  const { portfolioList, isLoadingPortfolio } = useSelector((state: RootState) => state.portfolio)
  const { allLignes } = useSelector((state: RootState) => state.map)
  const [openModalImport, setOpenModalImport] = useState<boolean>(false)
  const [openModalCreate, setOpenModalCreate] = useState<boolean>(false)
  const [portfolioImported, setPortfolioImported] = useState<string>('')
  const [entitySelected, setEntitySelected] = useState<Entity | null>(null)
  const [possibleEntities, setPossibleEntities] = useState<Entity[]>([])

  useEffect(() => {
    dispatch(getEntity({}))
    dispatch(getRobustestComplexes())
    dispatch(getAllLignes())
    dispatch(getConstructionSites(''))
    dispatch(getConstructionSitesLayer())
    dispatch(getLignes(''))
    dispatch(getPortfolio())
  }, [])

  useEffect(() => {
    if (allLignes) {
      allLignes.forEach(ligne => {
        dispatch(getTracks({
          ligneId: ligne.id,
          libelle: ligne.libelle,
          ids: [
            ...(ligne.bv_debut.gaia_id ? [ligne.bv_debut.gaia_id] : []),
            ...(ligne.bv_via?.gaia_id ? [ligne.bv_via.gaia_id] : []),
            ...(ligne.bv_fin.gaia_id ? [ligne.bv_fin.gaia_id] : []),
          ],
        }))
      })
    }
  }, [allLignes])

  const handleChange = (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  const isSupervisor = !!usersEntitySupervisor

  const portfolioFiltered = (generic: boolean, entityFiltered: boolean) => {
    if (robPermission.includes('admin')) {
      if (generic) {
        if (entityFiltered) {
          return portfolioList.filter(item => item?.generic && item?.entite?.id === entitySelected?.id)
        }
        return portfolioList.filter(item => item?.generic)
      }
      return portfolioList
    }

    return portfolioList.filter(
      item => item.entite?.id === usersEntitySupervisor && (generic ? item?.generic : !item?.generic))
  }

  const handleOpenImport = () => {
    setOpenModalImport(true)
    setPortfolioImported('')
  }

  useEffect(() => {
    if (robPermission.includes(UserRole.admin)) {
      setPossibleEntities(entities)
    } else {
      setPossibleEntities(entities.filter(entity => usersEntitySupervisor?.includes(entity.id as string)))
      setEntitySelected(entities.find(item => item.id === usersEntitySupervisor) || null)
    }
  }, [entities])

  return (
    <div className="supervision-portfolios">
      <div className="title">
        {terms.ParametragePortfolio.title}
      </div>
      {
        isLoadingPortfolio ? (
          <div className="accordion">
            <Loader />
          </div>
        ) : (
          <div className="accordion">
            {
              portfolioFiltered(false, false).map(portfolio => (
                <Accordion
                  key={portfolio.id}
                  expanded={expanded === portfolio.id}
                  onChange={handleChange(portfolio.id)}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>{portfolio.libelle}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Divider variant="middle" className="w-75 divider" />
                    <SupervisionPortfolioDetail portfolio={portfolio} />
                  </AccordionDetails>
                </Accordion>
              ))
            }
          </div>
        )
      }
      <div className="importButton">
        <button
          className="modify"
          type="button"
          onClick={handleOpenImport}
        >
          {terms.ParametragePortfolio.importButton}
        </button>
      </div>

      <ModalImport
        open={openModalImport}
        handleClose={() => setOpenModalImport(false)}
        portfolios={portfolioFiltered(true, true)}
        portfolioSelected={portfolioImported}
        setPortfolioSelected={setPortfolioImported}
        handleOpenModalCreate={() => setOpenModalCreate(true)}
        isSupervisor={isSupervisor}
        possibleEntities={possibleEntities}
        portfolioEntity={entitySelected}
        setPortfolioEntity={setEntitySelected}
      />

      <ModalCreateOrUpdate
        open={openModalCreate}
        handleClose={() => setOpenModalCreate(false)}
        portfolio={portfolioList.find(item => item.id === portfolioImported) || null}
        modalType={ModalType.CREATE}
      />
    </div>
  )
}

export default SupervisionPortfolios
