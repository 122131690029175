import { Box, Modal, Typography } from '@mui/material'
import terms from 'common/terms'
import ObjectJalonTabs from 'components/Jalons/ObjectJalonTabs'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { Composant, DefaultComposant } from 'reducers/Admin/Composant/types'
import { ParamComplexe } from 'reducers/Parametrage/Complexes/types'
import { ParamConstructionSite } from 'reducers/Parametrage/ConstructionSites/types'
import { ParamLigne } from 'reducers/Parametrage/ParamLignes/types'
import { RootState } from 'Store'
import { PrimaryBtn } from 'themes/theme'
import ModalCloseButton from '../ModalCloseButton/ModalCloseButton'
import './modalObjectComposants.scss'

interface ModalObjectComposantsProps {
  object: ParamComplexe | ParamLigne | ParamConstructionSite | undefined,
  open: boolean,
  title: string,
  clearText: string,
  handleClose: () => void,
  handleValidate: () => void,
  defaultComponents: DefaultComposant[],
  handleChangeObject: (object: ParamComplexe | ParamLigne | ParamConstructionSite) => void,
  readOnly?: boolean,
}

const ModalObjectComposants = ({
  object,
  open,
  handleClose,
  title,
  handleValidate,
  clearText,
  defaultComponents,
  handleChangeObject,
  readOnly,
}: ModalObjectComposantsProps): ReactElement => {
  const { composants } = useSelector((state: RootState) => state.composant)
  const getDefaultComposants = (numJalon: number): Composant[] => {
    const defCompo = defaultComponents.find(dc => dc.numero_jalon === numJalon)?.composants || []
    const defCompoIds = defCompo.map(c => c.id)
    return composants.filter(c => defCompoIds.includes(c.id))
  }

  const handleChange = (newComposants: Composant[], numJalon: number, reason?: string) => {
    if (object) {
      newComposants.sort((a, b) => a.id - b.id)
      const newSaJalons = object.sa_jalon?.filter(comp => comp.numero_jalon !== numJalon)
        .concat({
          numero_jalon: numJalon,
          composants: reason === 'clear' ? getDefaultComposants(numJalon) : newComposants,
        })
      handleChangeObject({ ...object, sa_jalon: newSaJalons })
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box className="modal-object-composants d-flex flex-column">
        <div className="modal-object-composants-header d-flex w-100 justify-content-between">
          <Typography>
            {title}
          </Typography>
          <ModalCloseButton handleClose={handleClose} />
        </div>
        <div className="modal-object-composants-body mb-2">
          <p>{terms.Common.composants}</p>
          <ObjectJalonTabs
            disabled={readOnly}
            objectJalonComposants={object?.sa_jalon || []}
            getDefaultComposants={getDefaultComposants}
            handleChange={handleChange}
            clearText={clearText}
          />
        </div>
        <div className="modal-object-composants-button d-flex justify-content-end">
          {
            readOnly ? (
              <div style={{ fontWeight: 'bold' }}>
                {terms.Settings.reserved}
              </div>
            ) : (
              <PrimaryBtn onClick={handleValidate}>
                {terms.Common.confirm}
              </PrimaryBtn>
            )
          }
        </div>
      </Box>
    </Modal>
  )
}

ModalObjectComposants.defaultProps = {
  readOnly: false,
}

export default ModalObjectComposants
