import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import terms from 'common/terms'
import { ConstructionSites } from 'reducers/Admin/ConstructionSites/types'
import {
  deleteSaConstructionSite, getDefaultJalonsConstructionSite, getSaConstructionSiteById,
  getSaConstructionSites, patchSAConstructionSite, postSaConstructionSite, searchConstructionSites,
} from './constructionSites.thunk'
import { ColumnConstructionSite, ParamConstructionSite, ParametrageConstructionSitesState } from './types'

const initialState: ParametrageConstructionSitesState = {
  constructionSites: [
    {
      id: '1',
      title: terms.Settings.constructionSite.listNotActive,
      objectFerList: [],
    },
    {
      id: '2',
      title: terms.Settings.constructionSite.listActive,
      objectFerList: [],
    },
  ],
  listConstructionSitesSa: [],
  listConstructionSitesSaOptions: [],
  modalErrConstructionSite: false,
  modalErrAssosConstructionSite: false,
  modalConfirmation: false,
  defaultComposantsConstructionSite: [],
  openComposantsModal: false,
  saConstructionSite: {},
  displaySnackbarAdd: false,
  displaySnackbarDelete: false,
  displaySnackbarUpdate: false,
  isLoadingConstructionSite: false,
}

export const parametrageConstructionSites = createSlice({
  name: 'parametrageConstructionSites',
  initialState,
  reducers: {
    changeListConstructionSites: (state, action: PayloadAction<ColumnConstructionSite[]>) => {
      state.constructionSites = action.payload
    },
    inputAddConstructionSiteSa: (state, action: PayloadAction<ConstructionSites | null>) => {
      state.addConstructionSiteSa = action.payload
    },
    addNewConstructionSiteSa: state => {
      if (!state.addConstructionSiteSa?.eic?.libelle) {
        state.constructionSites[1].objectFerList = [
          ...state.constructionSites[1].objectFerList,
          {
            id: state.addConstructionSiteSa?.id,
            travaux: {
              libelle: state.addConstructionSiteSa?.libelle,
            },
            active: true,
          },
        ]
        state.listConstructionSitesSa?.push({
          id: state.addConstructionSiteSa?.id,
          travaux: {
            libelle: state.addConstructionSiteSa?.libelle,
          },
          active: true,
        })
      } else {
        state.modalErrConstructionSite = true
      }
    },
    closeModalErrConstructionSite: state => {
      state.errors = undefined
      state.modalErrConstructionSite = false
      state.modalErrAssosConstructionSite = false
    },
    onModalAssosConstructionSite: state => {
      state.modalErrAssosConstructionSite = true
    },
    onModalAppartConstructionSite: state => {
      state.modalErrConstructionSite = true
    },
    onModalConfirmation: (state, action: PayloadAction<ParamConstructionSite>) => {
      state.selectedConstructionSite = action.payload
      state.modalConfirmation = true
    },
    closeModalConfirmation: state => {
      state.modalConfirmation = false
    },
    setSaConstructionSite: state => {
      state.saConstructionSite = undefined
      state.openComposantsModal = false
    },
    changeSaConstructionSite: (state, action: PayloadAction<ParamConstructionSite>) => {
      state.saConstructionSite = action.payload
    },
    hideSnackbarAdd: state => {
      state.displaySnackbarAdd = false
    },
    hideSnackbarUpdate: state => {
      state.displaySnackbarUpdate = false
    },
    hideSnackbarDelete: state => {
      state.displaySnackbarDelete = false
    },
  },
  extraReducers: builder => {
    builder.addCase(getSaConstructionSites.rejected, state => {
      state.isLoadingConstructionSite = false
    })
    builder.addCase(getSaConstructionSites.pending, state => {
      state.constructionSites[0].objectFerList = []
      state.constructionSites[1].objectFerList = []
      state.listConstructionSitesSa = []
      state.isLoadingConstructionSite = true
    })
    builder.addCase(getSaConstructionSites.fulfilled, (state, { payload }) => {
      if (payload) {
        state.constructionSites[0].objectFerList = payload.filter(i => i.active === false)
        state.constructionSites[1].objectFerList = payload.filter(i => i.active === true)
        state.listConstructionSitesSa = payload
      }
      state.isLoadingConstructionSite = false
    })
    builder.addCase(postSaConstructionSite.fulfilled, (state, { payload }) => {
      if (payload) {
        state.constructionSites[1].objectFerList = [
          ...state.constructionSites[1].objectFerList,
          {
            id: payload.id,
            travaux: {
              id: payload.travaux?.id,
              libelle: payload.travaux?.libelle,
              eic: payload.travaux?.eic,
            },
            active: true,
          },
        ]
        if (state.listConstructionSitesSa) {
          const newSaConstructionSite = [...state.listConstructionSitesSa, payload]
          state.listConstructionSitesSa = newSaConstructionSite.sort((a, b) => {
            if (a.travaux?.libelle && b.travaux?.libelle) {
              return a.travaux.libelle.localeCompare(b.travaux.libelle)
            }
            return 0
          })
        }
        state.addConstructionSiteSa = null
        state.displaySnackbarAdd = true
      }
    })
    builder.addCase(postSaConstructionSite.rejected, (state, action) => {
      state.errors = action.payload
    })
    builder.addCase(patchSAConstructionSite.rejected, (state, action) => {
      state.errors = action.payload
    })
    builder.addCase(deleteSaConstructionSite.fulfilled, state => {
      state.constructionSites[0].objectFerList = state.constructionSites[0].objectFerList.filter(
        i => i.travaux?.libelle !== state.selectedConstructionSite?.travaux?.libelle,
      )
      state.displaySnackbarDelete = true
    })
    builder.addCase(getSaConstructionSiteById.fulfilled, (state, { payload }) => {
      state.saConstructionSite = payload
      state.openComposantsModal = true
    })
    builder.addCase(patchSAConstructionSite.fulfilled, (state, { payload }) => {
      if (state.openComposantsModal) {
        state.displaySnackbarUpdate = true
      }
      state.saConstructionSite = payload
      state.openComposantsModal = false
      if (state.listConstructionSitesSa) {
        state.listConstructionSitesSa = state.listConstructionSitesSa.map(item => {
          if (item.id === payload.id) {
            return payload
          }
          return item
        })
      }
    })
    builder.addCase(getDefaultJalonsConstructionSite.fulfilled, (state, action) => {
      const newDefaults = [...state.defaultComposantsConstructionSite].filter(jc => jc.numero_jalon !== action.meta.arg)
      state.defaultComposantsConstructionSite = [
        ...newDefaults, { numero_jalon: action.meta.arg, composants: action.payload },
      ]
    })
    builder.addCase(searchConstructionSites.fulfilled, (state, { payload }) => {
      state.listConstructionSitesSaOptions = payload
    })
  },
})

export const {
  changeListConstructionSites,
  inputAddConstructionSiteSa,
  addNewConstructionSiteSa,
  closeModalErrConstructionSite,
  onModalConfirmation,
  closeModalConfirmation,
  onModalAssosConstructionSite,
  onModalAppartConstructionSite,
  setSaConstructionSite,
  changeSaConstructionSite,
  hideSnackbarAdd,
  hideSnackbarUpdate,
  hideSnackbarDelete,
} = parametrageConstructionSites.actions

export default parametrageConstructionSites.reducer
