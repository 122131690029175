import {
  get,
  patch,
  post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAllLignes } from 'reducers/Map/map.thunk'
import URI from 'reducers/uri'
import { getAxes } from './Axes/axes.thunk'
import { searchComplexes } from './Complexes/complexes.thunk'
import { ConstructionSites } from './ConstructionSites/types'
import { ContributorFunction } from './ContributorFunction/types'
import { getEic } from './Eic/eic.thunk'
import { Entity } from './Entity/types'
import { getPonderationCotation, getPonderationList } from './Ponderation/ponderation.thunk'
import {
  getLignes, getPortfolio, getRobustestComplexes,
} from './Portfolio/portfolio.thunk'
import { ProductionZone } from './ProductionZone/types'

type Jalon = {
  // eslint-disable-next-line camelcase
  active_jalon: boolean,
  // eslint-disable-next-line camelcase
  numero_jalon: number,
  pdf?: string | null,
  perimetre: number
}

type Composant = {
    libelle: string,
    national: boolean,
    active: boolean,
    id: number,
    jalons: Jalon[]
    newId?: string
}

type JalonComposant = {
  // eslint-disable-next-line camelcase
  active_jalon: number
}

type getEntityParams = {
  sa?: string
}
const getEntity = createAsyncThunk(
  'entity/get',
  async (params: getEntityParams, thunkApi) => {
    try {
      const response: Entity[] = await get('/robustest/entites/', params)
      await Promise.all(response.map(async result => {
        if (result.superviseurs.length > 0) {
          const superviseurs = await post(`/${URI.cerbereApi}/${URI.users}/simple`,
            result.superviseurs.map(item => item?.cerbere_id || ''), {
              params: {
                ordering: 'lastName',
              },
            })
          result.superviseurs = superviseurs
        }
      }))
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postEntity = createAsyncThunk(
  'entity/post|patch',
  async (entity: Entity, thunkApi) => {
    try {
      if (entity.id === 'new') {
        const response = await post('/robustest/entites/', entity)
        thunkApi.dispatch(getEntity({}))
        return response as Entity
      }
      const response = await patch(`/robustest/entites/${entity.id}`, entity)
      thunkApi.dispatch(getEntity({}))
      return response as Entity

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getComposants = createAsyncThunk(
  'composants/get',
  async (_, thunkApi) => {
    try {
      const response: Composant[] = await get('/robustest/composants/')
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getJalonComposants = createAsyncThunk(
  'jalonComposants/get',
  async (params: JalonComposant, thunkApi) => {
    try {
      const response: Composant[] = await get('/robustest/composants/', params)
      return { jalon: params, composants: response }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getAllJalonsComposants = createAsyncThunk(
  'get/AllJalonsComposants',
  async (_, thunkApi) => {
    const jalons = [0, 1, 2, 3, 4]
    jalons.map(number => (
      thunkApi.dispatch(getJalonComposants({ active_jalon: number }))
    ))
  },
)

const postComposant = createAsyncThunk(
  'composants/post|patch',
  async (composant: Partial<Composant>, thunkApi) => {
    try {
      if (composant.newId === 'new') {
        const response = await post('/robustest/composants/', composant)
        thunkApi.dispatch(getComposants())
        thunkApi.dispatch(getAllJalonsComposants())
        return response as Composant
      }
      const response = await patch(`/robustest/composants/${composant.id}`, composant)
      thunkApi.dispatch(getComposants())
      thunkApi.dispatch(getAllJalonsComposants())
      return response as Composant

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
type getContributorFunctionsParams = {
  sa?: string
  entity?: string
  active?: boolean
}
const getContributorFunctions = createAsyncThunk(
  'contributorFunction/get',
  async (params: getContributorFunctionsParams, thunkApi) => {
    try {
      const response: ContributorFunction[] = await get('/robustest/fonctions/', params)
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postContributorFunction = createAsyncThunk(
  'contributorFuntion/post|patch',
  async (contributorFunction: Partial<ContributorFunction>, thunkApi) => {
    try {
      if (contributorFunction.id === 'new') {
        const response = await post('/robustest/fonctions/', contributorFunction)
        thunkApi.dispatch(getContributorFunctions({}))
        return response as ContributorFunction
      }
      const response = await patch(`/robustest/fonctions/${contributorFunction.id}`, contributorFunction)
      thunkApi.dispatch(getContributorFunctions({}))
      return response as ContributorFunction
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getProductionZones = createAsyncThunk(
  'productionZones/get',
  async (_, thunkApi) => {
    try {
      const response: ProductionZone[] = await get('/robustest/dzp/')
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postProductionZone = createAsyncThunk(
  'productionZones/post|patch',
  async (zone: Partial<ProductionZone>, thunkApi) => {
    try {
      if (zone.id === 'new') {
        const response = await post('/robustest/dzp/', zone)
        thunkApi.dispatch(getProductionZones())
        return response as ProductionZone
      }
      const response = await patch(`/robustest/dzp/${zone.id}`, zone)
      thunkApi.dispatch(getProductionZones())
      return response as ProductionZone

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

// eslint-disable-next-line import/no-mutable-exports
export let controllerConstructionSites = new AbortController()
const getConstructionSites = createAsyncThunk(
  'constructionSites/get',
  async (params: string, thunkApi) => {
    try {
      controllerConstructionSites = new AbortController()
      const { signal } = controllerConstructionSites
      const response: ConstructionSites[] = await get('/robustest/sa-travaux/', {
        libelle: params,
      }, undefined, { signal })
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const loadPage = createAsyncThunk(
  'admin/loadpage',
  async (_, thunkApi) => {
    await Promise.all(
      [
        thunkApi.dispatch(getRobustestComplexes()),
        thunkApi.dispatch(getConstructionSites('')),
        thunkApi.dispatch(getAllLignes()),
        thunkApi.dispatch(getLignes('')),
        thunkApi.dispatch(getAllJalonsComposants()),
        thunkApi.dispatch(getContributorFunctions({})),
        thunkApi.dispatch(getProductionZones()),
        thunkApi.dispatch(getComposants()),
        thunkApi.dispatch(getEntity({})),
        thunkApi.dispatch(getEic()),
        thunkApi.dispatch(searchComplexes('')),
        thunkApi.dispatch(getPonderationList()),
        thunkApi.dispatch(getPonderationCotation()),
        thunkApi.dispatch(getAxes()),
        thunkApi.dispatch(getPortfolio()),
      ],
    )
    return null
  },
)

export {
  getAllJalonsComposants, getComposants, getConstructionSites, getContributorFunctions, getEntity, getJalonComposants,
  getProductionZones, loadPage, postComposant, postContributorFunction, postEntity, postProductionZone,
}
