import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import terms from 'common/terms'
import { getContributorFunctions, postContributorFunction } from '../admin.thunk'
import { ContributorFunction, ContributorFunctionState } from './types'

const initialState: ContributorFunctionState = {
  contributorFunctions: [],
  displaySnackbar: false,
  isLoadingContributorFunctions: false,
}
function getCustomLibelle(contributorFunction: ContributorFunction) {
  const hasNoComposantLibelle = contributorFunction.jalon_composants && contributorFunction.id !== 'new'
    && contributorFunction.jalon_composants.every(jalon => jalon.active_composants.length === 0)
    ? `<span class="no-composant-msg">
        ${terms.Admin.contributorFunction.hasNoComposants}
      </span>` : ''
  return contributorFunction.libelle + hasNoComposantLibelle
}

export const contributorFunction = createSlice({
  name: 'contributorFunction',
  initialState,
  reducers: {
    addContributorFunction: state => {
      if (state.contributorFunctions.find(stateFunction => stateFunction.id === 'new')) {
        return
      }
      const jalonCompsants = [0, 1, 2, 3, 4].map(number => ({
        numero_jalon: number,
        active_composants: [],
      }))
      state.contributorFunctions = [...state.contributorFunctions, {
        libelle: '',
        active: true,
        id: 'new',
        entite: {
          libelle: '', active: false, id: '', superviseurs: [],
        },
        jalon_composants: jalonCompsants,
      }]
    },
    changeContributorFunction: (state, action: PayloadAction<Partial<ContributorFunction>>) => {
      const index = state.contributorFunctions.findIndex(func => func.id === action.payload.id)
      state.contributorFunctions[index] = {
        ...state.contributorFunctions[index],
        ...action.payload,
        customLibelle: getCustomLibelle(action.payload as ContributorFunction),
      }
    },
    deleteNewContributorFunction: state => {
      const index = state.contributorFunctions.findIndex(entitie => entitie.id === 'new')
      if (index !== -1) { state.contributorFunctions.splice(index, 1) }
    },
    hideSnackbar: state => {
      state.displaySnackbar = false
    },
  },
  extraReducers: builder => {
    builder.addCase(getContributorFunctions.pending, state => { state.isLoadingContributorFunctions = true })
    builder.addCase(getContributorFunctions.rejected, state => { state.isLoadingContributorFunctions = false })
    builder.addCase(getContributorFunctions.fulfilled, (state, { payload }) => {
      state.isLoadingContributorFunctions = false
      state.contributorFunctions = payload.map(payloadFunction => ({
        libelle: payloadFunction.libelle,
        customLibelle: getCustomLibelle(payloadFunction),
        active: payloadFunction.active,
        id: payloadFunction.id,
        entite: payloadFunction.entite,
        jalon_composants: payloadFunction.jalon_composants,
      }))
    })
    builder.addCase(postContributorFunction.fulfilled, state => {
      state.displaySnackbar = true
    })
  },
})

export const {
  addContributorFunction,
  changeContributorFunction,
  deleteNewContributorFunction,
  hideSnackbar,
} = contributorFunction.actions

export default contributorFunction.reducer
