/* eslint-disable camelcase */
import {
  Box, FormControl, FormLabel, Modal, TextField, Typography,
} from '@mui/material'
import terms from 'common/terms'
import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { postConstructionSite } from 'reducers/Admin/ConstructionSites/constructionSites.thunk'
import { closeModalCreateConstructionSite, resetError } from 'reducers/Admin/ConstructionSites/constructionSites'
import { RootState } from 'Store'
import { PrimaryBtn } from 'themes/theme'
import './modalCreateConstructionSite.scss'
import ModalCloseButton from 'components/Modal/ModalCloseButton/ModalCloseButton'
import SearchGaiaLignes from 'components/SearchGaiaLignes/SearchGaiaLignes'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}

export default function ModalCreateConstructionSite(): ReactElement {
  const dispatch = useDispatch()
  const { modalCreateConstrtuctionSite, error } = useSelector((state: RootState) => state.constructionSites)
  const { sa } = useSelector((state: RootState) => state.sa)
  const [libelle, setLibelle] = useState('')
  const [selectedLigne, setSelectedLigne] = useState<{libelle: string, code_ligne: string} | null>(null)
  const [pk, setPk] = useState('')

  const onCloseModal = () => {
    setLibelle('')
    setSelectedLigne(null)
    setPk('')
    dispatch(closeModalCreateConstructionSite())
    dispatch(resetError())
  }

  const disabledButton = libelle === '' || !selectedLigne || pk === ''

  return (
    <Modal
      open={modalCreateConstrtuctionSite}
      onClose={onCloseModal}
    >
      <form onSubmit={evt => {
        evt.preventDefault()
        dispatch(postConstructionSite({
          libelle,
          eic: {
            libelle: '',
            id: sa.eic.id,
            animateurs: [],
            visiteurs: [],
          },
          ligne_libelle: selectedLigne?.libelle,
          ligne_code: selectedLigne?.code_ligne,
          ligne_pk: pk,
        }))
      }}
      >
        <Box className="modal-admin" sx={style}>
          <div className="d-flex justify-content-between w-100">
            <Typography className="" id="modal-modal-title" variant="h6" component="h2">
              {terms.Settings.constructionSite.modalCreateConstructionSite.title}
            </Typography>
            <ModalCloseButton handleClose={onCloseModal} />
          </div>
          <div className="search-container">
            <FormControl fullWidth>
              <FormLabel
                htmlFor="outlined-adornment-amount"
                required
              >
                {terms.Settings.constructionSite.modalCreateConstructionSite.inputPlaceHolder}
              </FormLabel>
              <TextField
                fullWidth
                id="outlined-controlled"
                size="small"
                value={libelle}
                onChange={e => setLibelle(e.target.value)}
              />
            </FormControl>
            <Typography id="modal-modal-error" sx={{ color: 'red' }}>
              <span color="error">{error?.data[0]}</span>
            </Typography>
            <SearchGaiaLignes
              selectedLigne={selectedLigne}
              setSelectedLigne={setSelectedLigne}
              pk={pk}
              setPk={setPk}
              withLabel
            />
          </div>
          <div className="d-flex justify-content-around w-50">
            <PrimaryBtn
              disabled={disabledButton}
              type="submit"
            >
              {terms.Common.add}
            </PrimaryBtn>
          </div>
        </Box>
      </form>
    </Modal>
  )
}
