import { Grid, Switch } from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleSettingPdf } from 'reducers/Suivi/suivi.reducer'
import { SettingPdfToggled } from 'reducers/Suivi/types'
import { RootState } from 'Store'

interface SettingFormattingDataProps {
  termsChecked: boolean,
  jalon: number,
}

const SettingFormattingData = ({ termsChecked, jalon }: SettingFormattingDataProps): ReactElement => {
  const dispatch = useDispatch()
  const { settingPdfToggled } = useSelector((state: RootState) => state.suivi)

  const handleToggle = (value: boolean, stateVariable: keyof SettingPdfToggled) => () => {
    dispatch(toggleSettingPdf({
      value,
      stateVariable,
    }))
  }

  return (
    <div className="settingExportPdf-body-section-block">
      <div>
        <div className="settingExportPdf-body-section-block-title">
          {terms.Suivi.pdfTerms.toggleConditionToDisplay.globalAnimator}
        </div>
        <Grid
          component="label"
          container
          alignItems="center"
          spacing={1}
          className="settingExportPdf-body-section-block-switch"
        >
          <Grid item>{terms.Suivi.pdfTerms.toggleConditionToDisplay.toggleFalse}</Grid>
          <Grid item>
            <Switch
              disabled={!termsChecked}
              checked={settingPdfToggled.globalAnimators}
              onChange={handleToggle(!settingPdfToggled.globalAnimators, 'globalAnimators')}
              value="checked"
            />
          </Grid>
          <Grid item>{terms.Suivi.pdfTerms.toggleConditionToDisplay.toggleTrue}</Grid>
        </Grid>
      </div>
      {jalon !== 0 && (
        <div>
          <div className="settingExportPdf-body-section-block-title">
            {terms.Suivi.pdfTerms.toggleConditionToDisplay.specificAnimator}
          </div>
          <Grid
            component="label"
            container
            alignItems="center"
            spacing={1}
            className="settingExportPdf-body-section-block-switch"
          >
            <Grid item>{terms.Suivi.pdfTerms.toggleConditionToDisplay.toggleFalse}</Grid>
            <Grid item>
              <Switch
                disabled={!termsChecked}
                checked={settingPdfToggled.specificAnimators}
                onChange={handleToggle(!settingPdfToggled.specificAnimators, 'specificAnimators')}
                value="checked"
              />
            </Grid>
            <Grid item>{terms.Suivi.pdfTerms.toggleConditionToDisplay.toggleTrue}</Grid>
          </Grid>
        </div>
      )}
      <div>
        <div className="settingExportPdf-body-section-block-title">
          {(jalon === 0)
            ? terms.Suivi.pdfTerms.toggleConditionToDisplay.decider
            : terms.Suivi.pdfTerms.toggleConditionToDisplay.contributor}
        </div>
        <Grid
          component="label"
          container
          alignItems="center"
          spacing={1}
          className="settingExportPdf-body-section-block-switch"
        >
          <Grid item>{terms.Suivi.pdfTerms.toggleConditionToDisplay.toggleFalse}</Grid>
          <Grid item>
            <Switch
              disabled={!termsChecked}
              checked={settingPdfToggled.contributors}
              onChange={handleToggle(!settingPdfToggled.contributors, 'contributors')}
              value="checked"
            />
          </Grid>
          <Grid item>{terms.Suivi.pdfTerms.toggleConditionToDisplay.toggleTrue}</Grid>
        </Grid>
      </div>
      {jalon !== 0 && (
        <div>
          <div>
            {terms.Suivi.pdfTerms.toggleConditionToDisplay.howToDisplay.title}
          </div>
          <div className="btn-group btn-group-toggle mb-2 settingPdf-display">
            <label
              className={`btn btn-outline-grey settingPdf-display-btn-left 
              ${settingPdfToggled?.display === true ? 'btn-active ' : ' '}
              `}
              htmlFor="button-global"
            >
              <input
                disabled={!termsChecked}
                name="types"
                readOnly
                type="checkbox"
                id="button-global"
                checked={settingPdfToggled?.display === true}
                onClick={handleToggle(true, 'display')}
              />
              {terms.Suivi.pdfTerms.toggleConditionToDisplay.howToDisplay.perObject}
            </label>
            <label
              className={`btn btn-outline-grey settingPdf-display-btn-right 
              ${settingPdfToggled?.display === false ? 'btn-active ' : ' '}
              `}
              htmlFor="button-specific"
            >
              <input
                disabled={!termsChecked}
                name="types"
                readOnly
                type="checkbox"
                id="button-specific"
                checked={settingPdfToggled?.display === false}
                onClick={handleToggle(false, 'display')}
              />
              {terms.Suivi.pdfTerms.toggleConditionToDisplay.howToDisplay.perComponent}
            </label>
          </div>
        </div>
      )}
    </div>
  )
}

export default SettingFormattingData
