import { LAYER_NAMES } from 'services/map'
import { LayerProperties } from '../types'

const regionLayerPaint: LayerProperties = {
  regionLayer: {
    type: 'line',
    id: LAYER_NAMES.regionLayer,
    layout: { 'line-cap': 'round', 'line-join': 'round' },
    paint: {
      'line-color': 'hsla(0, 8%, 62%, 0.51)',
      'line-width': {
        base: 1.3,
        stops: [
          [3, 0.5],
          [22, 15],
        ],
      },
    },
  },
  regionNameLayer: {
    minzoom: 6,
    id: `${LAYER_NAMES.regionLayer}-name`,
    type: 'symbol',
    layout: {
      'text-font': ['Open Sans Regular'],
      'text-size': 22,
      'text-field': ['case', ['has', 'libgeo'], ['get', 'libgeo'], ['get', 'LIBELLE']],
    },
    paint: {
      'text-color': '#25394d',
    },
  },
}

export default regionLayerPaint
