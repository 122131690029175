import { AppDispatch } from 'Store'

export type DispatchFunction = (dispatch: AppDispatch) => void

export type ThunkApi = {
  rejectValue: ResponseError
}

export type ResponseError = {
  code: number;
  data: {
    [key: string]: string[]
  }
}

export type AutocompleteValue = {
  id: string
  libelle: string
}

export enum ObjKey {
  COMPLEXES = 'batiments_voyageurs',
  CONSTRUCTION_SITES = 'travaux',
  LIGNES = 'lignes',
}
