import { RootState } from 'Store'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SelectChangeEvent } from '@mui/material'
import terms from 'common/terms'
import { typeObject } from 'pages/Suivi/utils'
import { postCloneAction } from 'reducers/ActionPlan/actionPlan.thunk'
import {
  setBesoinValidation,
  setDateBouclage,
  setDateOuverture,
  setDescription,
  setEntite,
  setName,
} from 'reducers/ActionPlan/actionPlan.reducer'
import ModalForm from './ModalForm'

interface FormCloneActionProps {
  closeButton: () => void
}

const FormCloneAction = ({
  closeButton,
}: FormCloneActionProps): ReactElement => {
  const dispatch = useDispatch()
  const {
    createAction, selectedCoPilote, selectedPilote, modalAdd,
  } = useSelector((state: RootState) => state.actionPlan)
  const { idSA } = useSelector((state: RootState) => state.app)
  const { entitiesVisibility } = useSelector((state: RootState) => state.contributions)

  const handleChangeEntity = (evt: SelectChangeEvent<string[]>) => {
    const { target: { value } } = evt
    if (typeof value !== 'string') {
      dispatch(setEntite(entitiesVisibility.filter(e => value.includes(e.id as string))))
    }
  }

  const disableAddAction = (): boolean => (
    createAction.nom === '' || createAction.entite === null || createAction.date_ouverture === ''
    || createAction.date_bouclage === '' || createAction.description === ''
  )

  const postForm = () => {
    if (modalAdd.cloneObject) {
      dispatch(postCloneAction({
        saId: idSA,
        objectId: modalAdd.cloneObject.id,
        type: typeObject(modalAdd.cloneObject.type),
        action: createAction,
        composantsId: modalAdd.cloneObject.composant_id,
      }))
    }
  }

  return (
    <ModalForm
      disabledForm={false}
      title={`${terms.actionPlan.modalAddAction.formCloneActionTitle} ${modalAdd.cloneObject?.libelle}`}
      action={createAction}
      entities={entitiesVisibility}
      selectedPilote={selectedPilote}
      selectedCoPilote={selectedCoPilote}
      handleChangeName={e => dispatch(setName(e.target.value))}
      handleChangeEntity={handleChangeEntity}
      handleChangeDescription={e => dispatch(setDescription(e.target.value))}
      handleChangeDateBouclage={setDateBouclage}
      handleChangeDateOuverture={setDateOuverture}
      handleChangeBesoinValidation={() => dispatch(setBesoinValidation(!createAction.besoin_validation))}
      closeButton={closeButton}
      disableButton={disableAddAction()}
      handleValidate={postForm}
      validateLabel={terms.actionPlan.modalAddAction.formCloneActionValidate}
    />
  )
}

export default FormCloneAction
