import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Entity } from 'reducers/Admin/Entity/types'
import { User } from 'reducers/Users/types'
import { deleteSaAnimators, getSaAnimators, postSaAnimators } from './animators.thunks'
import { ParametrageAnimatorsState } from './types'
import { formatSettingsTabList } from '../utils'

const initialState: ParametrageAnimatorsState = {
  selectedAnimator: null,
  selectedEntity: null,
  modalConfirmation: false,
  modalErrAnimator: false,
  animatorToDeleteId: undefined,
  animators: [],
  displaySnackbarAdd: false,
  displaySnackbarDelete: false,
  isloadingEntityAnimators: false,
}

export const parametrageAnimators = createSlice({
  name: 'parametrageAnimators',
  initialState,
  reducers: {
    selectAnimator: (state, action: PayloadAction<User | null>) => {
      state.selectedAnimator = action.payload
    },
    selectAnimatorEntity: (state, action: PayloadAction<Entity | null>) => {
      state.selectedEntity = action.payload
    },
    onModalConfirmation: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload) {
        state.modalConfirmation = true
        state.animatorToDeleteId = action.payload
      }
    },
    closeModalConfirmation: state => {
      state.modalConfirmation = false
    },
    onModalAddAnimator: state => {
      state.modalErrAnimator = true
    },
    closeModalErrAnimator: state => {
      state.errors = undefined
      state.modalErrAnimator = false
    },
    hideSnackbarAdd: state => {
      state.displaySnackbarAdd = false
    },
    hideSnackbarDelete: state => {
      state.displaySnackbarDelete = false
    },
  },
  extraReducers: builder => {
    builder.addCase(getSaAnimators.pending, state => {
      state.animators = []
      state.isloadingEntityAnimators = true
    })
    builder.addCase(getSaAnimators.rejected, state => {
      state.isloadingEntityAnimators = false
    })
    builder.addCase(getSaAnimators.fulfilled, (state, { payload }) => {
      state.animators = formatSettingsTabList(payload)
      state.isloadingEntityAnimators = false
    })
    builder.addCase(postSaAnimators.fulfilled, (state, { payload }) => {
      if (payload) {
        state.animators = formatSettingsTabList([payload, ...state.animators])
        state.selectedAnimator = null
        state.selectedEntity = null
        state.displaySnackbarAdd = true
      }
    })
    builder.addCase(postSaAnimators.rejected, (state, action) => {
      state.errors = action.payload
    })
    builder.addCase(deleteSaAnimators.fulfilled, state => {
      state.animators = state.animators.filter(item => item.id !== state.animatorToDeleteId)
      state.animatorToDeleteId = undefined
      state.displaySnackbarDelete = true
    })
  },
})

export const {
  selectAnimator,
  selectAnimatorEntity,
  onModalConfirmation,
  closeModalConfirmation,
  onModalAddAnimator,
  closeModalErrAnimator,
  hideSnackbarAdd,
  hideSnackbarDelete,
} = parametrageAnimators.actions

export default parametrageAnimators.reducer
