import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum SnackbarSeverity {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

interface AppState {
  error: null | undefined,
  fullscreen: boolean,
  labelSA: string | null,
  eicLibelle: string | null,
  idSA: string | null,
  displaySnackbar: boolean,
  snackbarMessage: string,
  snackbarSeverity: SnackbarSeverity,
}

const initialState: AppState = {
  error: null,
  fullscreen: false,
  labelSA: null,
  eicLibelle: null,
  idSA: null,
  displaySnackbar: false,
  snackbarMessage: '',
  snackbarSeverity: SnackbarSeverity.SUCCESS,

}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleFullscreen: state => {
      state.fullscreen = !state.fullscreen
    },
    getError: (state, action: PayloadAction<null>) => {
      state.error = action.payload
    },
    loadSavedSa: state => {
      const localLabelSa = localStorage.getItem('labelSA')
      const localIdSa = localStorage.getItem('idSA')
      if (localLabelSa && localIdSa) {
        state.labelSA = localLabelSa
        state.idSA = localIdSa
      }
    },
    setSavedSa: (state, action: PayloadAction<{ labelSA: string, idSA: string, eicLibelle: string }>) => {
      localStorage.setItem('labelSA', action.payload.labelSA)
      localStorage.setItem('idSA', action.payload.idSA)
      state.labelSA = action.payload.labelSA
      state.idSA = action.payload.idSA
      state.eicLibelle = action.payload.eicLibelle
    },
    setSnackbar: (state, action: PayloadAction<{ message: string, severity: SnackbarSeverity }>) => {
      state.displaySnackbar = true
      state.snackbarMessage = action.payload.message
      state.snackbarSeverity = action.payload.severity
    },
    hideSnackbar: state => {
      state.displaySnackbar = false
      state.snackbarMessage = ''
      state.snackbarSeverity = SnackbarSeverity.SUCCESS
    },
  },
})

export const {
  toggleFullscreen,
  getError,
  loadSavedSa,
  setSavedSa,
  setSnackbar,
  hideSnackbar,
} = appSlice.actions

export default appSlice.reducer
