import {
  useEffect, Suspense, ReactElement, useState,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Router, Switch, Redirect, Route,
} from 'react-router-dom'
import { auth } from '@osrdata/app_core'
import Loader from 'components/Loader'
import TabList from 'components/TabList/TabList'
import ELEMENTS from 'components/TabList/const'
import TopBar from 'components/TopBar/TopBar'
import { RootState } from 'Store'
import Home from 'Home'
import history from 'customHistory'
import terms from 'terms.json'
import appTerms from 'common/terms'
import {
  getUserConnect,
  getUserMe, getUserMeAxe, getUserMeEntitySupervisor, getUserMePlanAction, getUserMeSettings, getUserMeSuivi,
} from 'reducers/Users/users.thunk'
import { hideSnackbar, loadSavedSa } from 'reducers/app'
import { defineRole } from 'reducers/Users/users.reducer'
import { yourRole } from 'reducers/Users/utils'
import Suivi from 'pages/Suivi/Suivi'
import Contributions from 'pages/Contributions/Contributions'
import Settings from 'pages/SettingsSA/Settings'
import { getSa } from 'reducers/SA/sa.thunk'
import ActionPlan from 'pages/ActionPlan/ActionPlan'
import CustomSnackbar from 'components/Snackbar/Snackbar'
import ModalRecoverBug from 'components/Modal/ModalRecoverBug/ModalRecoverBug'
import ZpCotation from 'pages/Monitoring/ZpCotation/ZpCotation'
import MapAlert from 'pages/Monitoring/MapAlert/MapAlert'
import StatsCotations from 'pages/Monitoring/StatsCotations/StatsCotations'
import SupervisionPortfolios from 'pages/SupervisionPortfolios/SupervisionPortfolios'
import ContributionsStats from 'pages/Monitoring/Contributions/Contributions'
import CreateSA from './pages/CreateSA/CreateSA'
import Admin from './pages/Admin/Admin'

import 'App.scss'

const APP_NAME = 'robustest'
const HOME_PATH = '/accueil/'
const MIN_ACCESS = 'robustest::ACCESS'

export default function App(): ReactElement {
  const dispatch = useDispatch()
  const { isLogged, appPermissions } = useSelector((state: RootState) => state.user)
  const isLoading = !isLogged
  const { territories, axes } = useSelector((state: RootState) => state.users.userMe)
  const {
    userMeSettings,
    userMeSuivi,
    userMePlanAction,
    usersEntitySupervisor,
  } = useSelector((state: RootState) => state.users)
  const { displaySnackbar, snackbarMessage, snackbarSeverity } = useSelector((state: RootState) => state.app)
  const [openModal, setOpenModal] = useState<boolean>(false)

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
    dispatch(loadSavedSa())
  }, [])

  useEffect(() => {
    if (isLogged && appPermissions.includes(MIN_ACCESS)) {
      dispatch(getUserMe())
      dispatch(getUserMeAxe())
      dispatch(getUserMeEntitySupervisor())
      dispatch(getUserMeSettings())
      dispatch(getUserMeSuivi())
      dispatch(getUserMePlanAction())
      dispatch(getSa(''))
      dispatch(getUserConnect())
    }
  }, [appPermissions])

  useEffect(() => {
    if (isLogged) {
      dispatch(defineRole(yourRole(
        appPermissions,
        territories,
        axes,
        userMeSettings,
        userMeSuivi,
        userMePlanAction,
        usersEntitySupervisor,
      )))
    }
  }, [appPermissions, territories, userMeSettings, userMeSuivi, userMePlanAction])

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Router history={history}>
          <TopBar
            appName={APP_NAME}
            innerComponent={<TabList tabs={ELEMENTS} appPermissions={appPermissions} />}
            hasAccess={!isLoading}
          />
          {isLoading && <div id="app"><Loader message={terms.Common.loading} /></div>}
          {!isLoading && (
          <div id="app">
            <Switch>
              <Route exact component={Home} path={HOME_PATH} />
              <Route exact component={Admin} path="/parametrage/configuration-generale/" />
              <Route exact component={CreateSA} path="/parametrage/creation-sa/" />
              <Route exact component={Suivi} path="/tableau-suivi/:id" />
              <Route exact component={Contributions} path="/tableau-suivi/:id/contributions" />
              <Route exact component={Settings} path="/parametrage/parametrage-sa/:id" />
              <Route exact component={SupervisionPortfolios} path="/parametrage/portefeuilles-supervision" />
              <Route exact component={ActionPlan} path="/plan-action/:id" />
              <Route exact component={ZpCotation} path="/supervision/cotations-zp/" />
              <Route exact component={MapAlert} path="/supervision/cartographie-alertes/" />
              <Route exact component={StatsCotations} path="/supervision/evolution-cotations/" />
              <Route exact component={ContributionsStats} path="/supervision/contributions-transporteur/" />
              <Redirect to={HOME_PATH} />
            </Switch>
          </div>
          )}
          <CustomSnackbar
            message={snackbarMessage}
            severity={snackbarSeverity}
            displaySnackbar={displaySnackbar}
            handleClose={() => dispatch(hideSnackbar())}
          />
          <ModalRecoverBug
            open={openModal}
            handleClose={() => setOpenModal(false)}
          />
          <button
            className="bug-recover-button"
            type="button"
            onClick={() => setOpenModal(true)}
          >
            {appTerms.FeedbackMailto.title}
          </button>
        </Router>
      </Suspense>
    </>
  )
}
