import {
  MouseEventHandler, ReactElement, useState,
} from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { RootState } from 'Store'
import { useDispatch, useSelector } from 'react-redux'
import {
  MenuList, Paper, Popper,
} from '@mui/material'
import terms from 'common/terms'
import { deletevalueSA } from 'reducers/SA/sa.reducer'
import { displayAuthorization, getPath } from './utils'
import './TabElement.scss'

export type NavElement = {
  Icon: React.FunctionComponent;
  title: string;
  path: string;
  access: string[];
  robAccess: Array<string>;
  subPaths: {
    name: string;
    path: string;
    robAccess: Array<string>;
  }[];
}

type Props = {
  element: NavElement;
}
export default function TabElement({ element }: Props): ReactElement {
  const {
    Icon,
    title,
    path,
    robAccess,
  } = element
  const dispatch = useDispatch()
  const location = useLocation()
  const { robPermission } = useSelector((state: RootState) => state.users)
  const { labelSA } = useSelector((state: RootState) => state.app)
  const [menuAnchor, setMenuAnchor] = useState<null | Element>(null)
  const [isSelected, setIsSelected] = useState(false)

  const handleMouseEnter: MouseEventHandler<HTMLDivElement> = event => {
    setMenuAnchor(event.currentTarget)
    setIsSelected(true)
  }

  const handleClickClose = (value: string) => () => {
    setMenuAnchor(null)
    setIsSelected(false)
    if (path === value) dispatch(deletevalueSA())
  }

  const isSelectedTab = location.pathname.includes(path)

  const isDropdown = title.includes(terms.Nav.settings) || title.includes(terms.Nav.monitoring.title)
  const displayDropdown = element.subPaths.length && element.subPaths.some(
    item => displayAuthorization(item.robAccess, robPermission),
  )

  const subPathAuthorize = element.subPaths.filter(item => displayAuthorization(item.robAccess, robPermission))

  if (subPathAuthorize.length === 1) {
    return (
      <li>
        <NavLink
          to={`${path}${subPathAuthorize[0].path}`}
          className={`tab-element d-flex justify-content-center align-items-center 
        h-100 px-3 ${isSelectedTab ? 'selected' : ''}`}
          activeClassName="selected"
        >
          <div className="mr-2 d-flex justify-content-center align-items-center ">
            <Icon />
          </div>
          <div className="title">
            {subPathAuthorize[0].name}
          </div>
        </NavLink>
      </li>
    )
  }

  return (
    <li>
      {
        isDropdown && displayDropdown && (
          <div
            className={`tab-element d-flex justify-content-center align-items-center 
            h-100 px-3 ${isSelectedTab || menuAnchor ? 'selected' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleClickClose('')}
          >
            <div className="mr-2 d-flex justify-content-center align-items-center ">
              <Icon />
            </div>
            <div className="title">
              {title}
              <Popper
                className="sub-menu"
                anchorEl={menuAnchor}
                open={isSelected}
              >
                <Paper className="background">
                  <MenuList
                    autoFocusItem={isSelected}
                    id="composition-menu"
                    className="menu-list"
                    aria-labelledby="composition-button"
                  >
                    {element?.subPaths?.map(item => (
                      displayAuthorization(item.robAccess, robPermission) && (
                        <Link
                          to={`${path}${item.path}`}
                          key={item.name}
                          className="link"
                          onClick={handleClickClose(item.path)}
                        >
                          <div>
                            {item.name}
                          </div>
                        </Link>
                      )
                    ))}
                  </MenuList>
                </Paper>
              </Popper>
            </div>
          </div>
        )
      }
      {displayAuthorization(robAccess, robPermission)
        && !isDropdown && (
          <NavLink
            to={getPath(path, labelSA)}
            className={`tab-element d-flex justify-content-center align-items-center 
        h-100 px-3`}
            activeClassName="selected"
          >
            <div className="mr-2 d-flex justify-content-center align-items-center ">
              <Icon />
            </div>
            <div className="title">
              {title}
            </div>
          </NavLink>
      )}
    </li>
  )
}
