import { DragHandle } from '@mui/icons-material'
import { ReactElement, useState } from 'react'
import { Draggable, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd'
import ClearIcon from '@mui/icons-material/Clear'
import './objectFer.scss'
import { useDispatch, useSelector } from 'react-redux'
import { onModalConfirmation } from 'reducers/Parametrage/ConstructionSites/constructionSites.reducer'
import { ParamConstructionSite } from 'reducers/Parametrage/ConstructionSites/types'
import { Popover, Typography } from '@mui/material'
import terms from 'common/terms'
import { getSaConstructionSiteById } from 'reducers/Parametrage/ConstructionSites/constructionSites.thunk'
import { RootState } from 'Store'

interface ObjectFerCSProps {
  libelle?: string,
  objectId?: string,
  index: number,
  list: string,
  obj: ParamConstructionSite,
}

const ObjectFerCS = ({
  libelle,
  objectId = '1',
  index,
  list,
  obj,
}: ObjectFerCSProps): ReactElement => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [composantAvailable, setComposantAvailable] = useState(true)
  const { idSA, labelSA } = useSelector((state: RootState) => state.app)
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setComposantAvailable(false)
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setComposantAvailable(true)
    setAnchorEl(null)
  }

  const getItemStyle = (isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
    background: isDragging ? '#e05206' : 'white',
    color: isDragging ? 'white' : '#878e91',
    ...draggableStyle,
  })

  const onConstructionSiteClick = () => {
    if (composantAvailable) {
      dispatch(getSaConstructionSiteById({
        saId: idSA,
        constructionSiteId: objectId,
      }))
    }
  }

  const shouldDisplayOption = obj?.travaux?.eic && !labelSA?.includes(obj?.travaux?.eic?.libelle || '')

  const open = Boolean(anchorEl)
  return (
    <Draggable draggableId={objectId} index={index}>
      {(provider, snapshot) => (
        <div
          role="button"
          tabIndex={0}
          onClick={() => onConstructionSiteClick()}
        >
          <li
            className="configList_cont--item"
            {...provider.draggableProps}
            {...provider.dragHandleProps}
            ref={provider.innerRef}
            style={getItemStyle(
              snapshot.isDragging,
              provider.draggableProps.style,
            )}
          >
            <div className="firstDiv">
              <DragHandle />
              <div className="spanList">
                <span className="title">
                  {libelle}
                </span>
                {
                  shouldDisplayOption && (
                  <span className="item">
                    {` (${obj?.travaux?.eic?.libelle})`}
                  </span>
                  )
                }
              </div>
            </div>
            {(list === '1') && (
            <div>
              <Typography
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <button
                  className="buttonDeleteComplexeSa"
                  type="button"
                  onClick={() => dispatch(onModalConfirmation(obj))}
                >
                  <ClearIcon fontSize="small" />
                </button>
              </Typography>
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography className="popup-span" sx={{ p: 1 }}>{terms.Settings.constructionSite.popup}</Typography>
              </Popover>
            </div>
            )}
          </li>
        </div>
      )}
    </Draggable>
  )
}
ObjectFerCS.defaultProps = {
  libelle: '',
  objectId: '',
}

export default ObjectFerCS
