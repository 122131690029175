/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from 'Store'
import terms from 'common/terms'
import { setSnackbar, SnackbarSeverity } from 'reducers/app'
import { actionList } from 'reducers/interceptorRedux/action'

type Action = {
  type: string,
  error?: {
    message: string,
  }
  payload: {
    code: number,
    data: string[],
    type: string,
    message?: string,
    response: {
      status: number,
      data?: {
        file?: string[],
      }
    },
  }
  meta?: {
    arg?: any,
  }
}

type Next = any

const retryAction = (
  action: Action,
) => {
  const actionThunk = actionList[action.type.replace('/rejected', '')]
  if (!actionThunk) return
  if (action?.meta?.arg) {
    const { arg } = action.meta
    store.dispatch(actionThunk(arg))
  } else {
    store.dispatch(actionThunk())
  }
}

type ErrorInterceptorMiddleware = () => (next: Next) => (action: Action) => void
const errorInterceptorMiddleware: ErrorInterceptorMiddleware = () => next => action => {
  if (action.type.endsWith('/rejected')) {
    switch (action?.payload?.code) {
      case 400:
        next(setSnackbar({
          message: action.payload?.data?.[0] || terms.errorMessages.badRequest,
          severity: SnackbarSeverity.ERROR,
        }))
        break
      case 403:
        next(setSnackbar({
          message: action.payload?.data?.[0] || terms.errorMessages.forbidden,
          severity: SnackbarSeverity.ERROR,
        }))
        break
      case 404:
        next(setSnackbar({
          message: terms.errorMessages.notFound,
          severity: SnackbarSeverity.ERROR,
        }))
        break
      case 500:
        next(setSnackbar({
          message: terms.errorMessages.internalServerError,
          severity: SnackbarSeverity.ERROR,
        }))
        break
      case 502:
        setTimeout(() => {
          retryAction(action)
        }, 1000)
        next(setSnackbar({
          message: terms.errorMessages.serverDown,
          severity: SnackbarSeverity.ERROR,
        }))
        break
      case 424:
        next(setSnackbar({
          message: terms.errorMessages.failedDependency,
          severity: SnackbarSeverity.ERROR,
        }))
        break
      default:
    }
    next(action)
  }

  return next(action)
}

export default errorInterceptorMiddleware
