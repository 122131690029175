import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Territories } from 'reducers/Users/types'
import { getJalonComposants } from 'reducers/Admin/admin.thunk'
import { convertComposantPerimeter } from 'reducers/Admin/Composant/utils'
import { SAState } from './types'
import {
  getSa, getSaById, patchSa, postSA,
} from './sa.thunk'

const currentDate = new Date()
const currentYear = currentDate.getFullYear() + 2

const initialState: SAState = {
  sa: {
    id: '',
    libelle: '',
    annee: 0,
    eic: {
      id: '',
      libelle: '',
      dzp: {
        id: '',
        libelle: '',
        abrev: '',
      },
      animateurs: [],
      visiteurs: [],
    },
    jalons: [],
  },
  yearNewSa: currentYear,
  territory: {
    id: '',
    libelle: '',
  },
  listSa: [],
  jalonComposants: [],
  onModalError: false,
}

export const sa = createSlice({
  name: 'SA',
  initialState,
  reducers: {
    closeModal: state => {
      state.errors = undefined
      state.onModalError = false
    },
    changeValueCreateSA: (state, action: PayloadAction<Territories>) => {
      state.territory = { id: action.payload.id, libelle: action.payload.libelle }
    },

    changeValueDateNewSA: (state, action: PayloadAction<number>) => {
      state.yearNewSa = action.payload
    },

    deletevalueSA: state => {
      state.sa = {
        id: '',
        libelle: '',
        annee: 0,
        eic: {
          id: '',
          libelle: '',
          dzp: {
            id: '',
            libelle: '',
            abrev: '',
          },
          animateurs: [],
          visiteurs: [],
        },
        jalons: [],
      }
    },
    setCopilDate: (state, action: PayloadAction<string | undefined>) => {
      state.newCopilDate = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(postSA.fulfilled, (state, { payload }) => {
      state.sa = payload
    })
    builder.addCase(postSA.rejected, (state, action) => {
      state.errors = action.payload
      state.onModalError = true
    })
    builder.addCase(getSa.fulfilled, (state, { payload }) => {
      if (payload) {
        state.listSa = payload.map(payloadSa => ({
          libelle: payloadSa.libelle,
          id: payloadSa.id,
          eic: payloadSa.eic,
          annee: payloadSa.annee,
          jalons: payloadSa.jalons,
        }))
      }
    })
    builder.addCase(patchSa.fulfilled, (state, { payload }) => {
      state.newCopilDate = undefined
      state.sa.jalons = [...payload.jalons]
    })
    builder.addCase(patchSa.rejected, (state, action) => {
      state.errors = action.payload
      state.onModalError = true
    })
    builder.addCase(getSaById.fulfilled, (state, { payload }) => {
      state.sa = payload
    })
    builder.addCase(getJalonComposants.fulfilled, (state, { payload }) => {
      if (state.jalonComposants.length === 5) {
        state.jalonComposants = []
      }
      state.jalonComposants.push({
        numero_jalon: payload.jalon.active_jalon,
        composants: convertComposantPerimeter(payload.composants.sort((a, b) => a.id - b.id)),
      })
    })
  },
})

export const {
  changeValueCreateSA, changeValueDateNewSA, closeModal, deletevalueSA, setCopilDate,
} = sa.actions

export default sa.reducer
