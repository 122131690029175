import terms from 'common/terms'
import PopOver from 'components/PopOver/PopOver'
import { ReactElement } from 'react'
import { PrimaryBtn } from 'themes/theme'

interface Props {
  handleSave: () => void;
  handleCloseModal: () => void;
  disabled: boolean;
}

const Buttons = ({
  handleSave,
  handleCloseModal,
  disabled,
}: Props): ReactElement => (
  <div className="contribution-buttons">
    <PopOver
      popContent={terms.Suivi.GlobalContribution.Button.conditionForSave}
      hidden={!disabled}
    >
      <PrimaryBtn
        onClick={handleSave}
        className="button-save"
        disabled={disabled}
      >
        {terms.Suivi.GlobalContribution.Button.save}
      </PrimaryBtn>
    </PopOver>

    <button
      type="button"
      className="button-cancel"
      onClick={handleCloseModal}
    >
      {terms.Suivi.GlobalContribution.Button.cancel}
    </button>
  </div>
)

export default Buttons
