import { TableCell, TableFooter, TableRow } from '@mui/material'
import { cotationInfo } from 'common/cotation'
import terms from 'common/terms'
import PopOver from 'components/PopOver/PopOver'
import { ReactElement } from 'react'
import { DzpFooterTable, ZpMonitoring } from 'reducers/monitoring/types'
import { v4 as uuidv4 } from 'uuid'

interface Props {
  zpMonitorings: ZpMonitoring[];
}

const DzpCotationTableFooter = ({ zpMonitorings }: Props): ReactElement => {
  const dzpFooterTable = (): DzpFooterTable => {
    let newFooterData: DzpFooterTable = {
      globalCotation: [],
      composite: [],
      gravity: [],
    }
    zpMonitorings.forEach(object => {
      newFooterData = {
        globalCotation: [...newFooterData.globalCotation, {
          cotation: object.cotation,
          commentaire: object.commentaire,
        }],
        composite: [...newFooterData.composite, {
          score: object.score,
          max: object.max,
        }],
        gravity: [...newFooterData.gravity, object.gravite],
      }
    })

    return newFooterData
  }

  const compositeLabel = (indiceMax: number, indiceScore: number): string => {
    if (indiceMax > 1) {
      return `${indiceScore} sur ${indiceMax} ${terms.Monitoring.Dzp.cotations.component}s`
    }
    return `${indiceScore} sur ${indiceMax} ${terms.Monitoring.Dzp.cotations.component}`
  }

  return (
    <TableFooter className="footer">
      <TableRow>
        <TableCell className="bold separation sticky-footer-label">{terms.Monitoring.Dzp.Table.global}</TableCell>
        {
          dzpFooterTable().globalCotation.map(cotation => (
            <TableCell className="separation" key={uuidv4()}>
              <PopOver
                popContent={cotation.commentaire || terms.Monitoring.Dzp.noComment}
              >
                <span
                  className="cotation"
                  style={{
                    backgroundColor: cotationInfo(cotation.cotation)?.color || '#EEEEEE',
                    borderColor: cotationInfo(cotation.cotation)?.color || '#C6C6C6',
                  }}
                />
              </PopOver>
            </TableCell>
          ))
        }
      </TableRow>
      <TableRow>
        <TableCell className="bold sticky-footer-label">{terms.Monitoring.Dzp.Table.composite}</TableCell>
        {
          dzpFooterTable().composite.map(indice => (
            <TableCell key={uuidv4()}>
              <div className="indice">
                <PopOver
                  popContent={compositeLabel(indice.max || 0, indice.score || 0)}
                >
                  <div>
                    <span>
                      {indice.score || '0'}
                    </span>
                    <span>
                      {`(${indice.max || '0'})`}
                    </span>
                  </div>
                </PopOver>
              </div>
            </TableCell>
          ))
        }
      </TableRow>
      <TableRow>
        <TableCell className="bold sticky-footer-label">{terms.Monitoring.Dzp.Table.gravity}</TableCell>
        {
          dzpFooterTable().gravity.map(gravite => (
            <TableCell key={uuidv4()}>
              <div className="gravity">
                {
                  gravite ? (
                    <div>
                      <span>
                        G
                      </span>
                      <span>
                        {gravite}
                      </span>
                    </div>
                  ) : (
                    <div>
                      <span>
                        -
                      </span>
                    </div>
                  )
                }
              </div>
            </TableCell>
          ))
        }
      </TableRow>
    </TableFooter>
  )
}

export default DzpCotationTableFooter
