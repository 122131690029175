import {
  Autocomplete, Box, Checkbox, TextField,
} from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import terms from 'common/terms'
import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import { useSelector } from 'react-redux'
import { Composant } from 'reducers/Admin/Composant/types'
import { ObjectJalonComposant } from 'reducers/SA/types'
import ComposantChip from 'components/ComposantChip/ComposantChip'
import { RootState } from 'Store'
import { StyledTab, StyledTabs } from './JalonStyle'
import TabPanel from './TabPanel'
import './jalon.scss'

interface ObjectJalonTabsProps {
  objectJalonComposants: ObjectJalonComposant[]
  handleChange: (newComposants: Composant[], numJalon: number, reason?: string) => void
  getDefaultComposants?: (numJalon: number) => Composant[]
  clearText: string
  disabled?: boolean
}

const ObjectJalonTabs = ({
  objectJalonComposants, handleChange, getDefaultComposants, clearText, disabled,
}: ObjectJalonTabsProps): ReactElement => {
  const [value, setValue] = useState(0)
  const scrollToRef = useRef(null)
  const { jalonComposants } = useSelector((state: RootState) => state.sa)
  const handleTabsChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  const [currentAddComposantId, setCurrentAddComposantId] = useState<number>(0)

  const getOptions = (numJalon: number): Composant[] => {
    const activeJalon = jalonComposants.find(jalon => jalon.numero_jalon === numJalon)
    return activeJalon ? activeJalon.composants.filter(comp => comp.active) : []
  }
  useEffect(() => {
    if (currentAddComposantId && scrollToRef.current) {
      const ref = scrollToRef.current as HTMLElement
      ref.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [currentAddComposantId])
  return (
    <div className="mt-2 multiselect-jalons">
      <Box>
        <StyledTabs value={value} onChange={handleTabsChange} aria-label={terms.Admin.composants.tabsLabel}>
          <StyledTab label="J1" className="tab-button" />
          <StyledTab label="J2" className="tab-button" />
          <StyledTab label="J3" className="tab-button" />
          <StyledTab label="J4" className="tab-button" />
        </StyledTabs>
      </Box>
      {objectJalonComposants.map(jalon => (
        <TabPanel value={value + 1} index={jalon.numero_jalon} key={jalon.numero_jalon}>
          <Autocomplete
            disabled={disabled}
            className="autocomplete-jalon form-control form-control-sm ml-2"
            multiple
            disableCloseOnSelect
            disablePortal
            sx={{ maxHeight: 250, overflowY: 'auto', overflowX: 'hidden' }}
            options={getOptions(jalon.numero_jalon)}
            value={jalon.composants.filter(comp => comp.active)}
            onChange={(_event, newValue, reason) => {
              const element = _event.currentTarget
              const data = element.getAttribute('data-option-index')
              if (data) {
                const correspondingComponent = getOptions(jalon.numero_jalon)[parseInt(data, 10)]
                setCurrentAddComposantId(correspondingComponent.id)
              }
              handleChange([...newValue], jalon.numero_jalon, reason)
            }}
            getOptionLabel={option => `${option.id}.${option.libelle}`}
            renderOption={(props, option, { selected }) => {
              if (!selected) {
                return (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    <p
                      className="object-jalon-composant-label"
                      style={{
                        fontWeight: getDefaultComposants && getDefaultComposants(jalon.numero_jalon)
                          .some(comp => comp.id === option.id) ? '700' : '400',
                      }}
                      title={getDefaultComposants && getDefaultComposants(jalon.numero_jalon)
                        .some(comp => comp.id === option.id) ? terms.Common.defaultComponent : undefined}
                    >
                      {`${option.id}.${option.libelle}`}
                    </p>
                  </li>
                )
              }
              return (
                <li {...props} style={{ padding: 0 }} />
              )
            }}
            renderInput={params => (
              <TextField
                {...params}
                className="vertical-display"
                placeholder={jalon.composants
                  .filter(comp => comp.active).length === 0 ? terms.Common.selectPlaceholder : ''}
              />
            )}
            renderTags={tabValue => (
              <div className="composants-list">
                {tabValue.map(tab => (
                  <ComposantChip
                    readOnly={disabled}
                    composant={tab}
                    isAdded={currentAddComposantId === tab.id}
                    key={tab.id}
                    handleDelete={() => handleChange(
                      tabValue.filter(t => t.id !== tab.id),
                      jalon.numero_jalon,
                    )}
                    ref={currentAddComposantId === tab.id ? scrollToRef : null}
                  />
                ))}
              </div>
            )}
            isOptionEqualToValue={(opt, val) => opt.id === val.id}
            clearText={clearText}
            closeText=""
            openText=""
            noOptionsText={terms.Admin.noSearchText}
          />
        </TabPanel>
      ))}
    </div>
  )
}

ObjectJalonTabs.defaultProps = {
  getDefaultComposants: undefined,
  disabled: false,
}

export default ObjectJalonTabs
