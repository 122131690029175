import { RootState } from 'Store'
import terms from 'common/terms'
import CustomSelect, { ValueSelect } from 'components/CustomSelect/CustomSelect'
import {
  ReactElement, SyntheticEvent, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Composant } from 'reducers/Admin/Composant/types'
import { SA } from 'reducers/SA/types'
import { resetData } from 'reducers/monitoring/monitoring.reducer'
import { getPortfolioSimple, getSupervisionEf } from 'reducers/monitoring/monitoring.thunk'
import { SelectType } from 'reducers/monitoring/types'
import { v4 as uuidV4 } from 'uuid'
import SelectComponent from './SelectComponent'

interface Props {
  activeJalon: number
  selectedEntity: ValueSelect | null
  setSelectedEntity: (value: ValueSelect | null) => void
  isSupervisor: boolean
  selectedPortfolio: ValueSelect | null
  setSelectedPortfolio: (value: ValueSelect | null) => void
}

const ContributionsSelects = ({
  activeJalon,
  selectedEntity,
  setSelectedEntity,
  isSupervisor,
  selectedPortfolio,
  setSelectedPortfolio,
}: Props): ReactElement => {
  const dispatch = useDispatch()
  const { entities } = useSelector((state: RootState) => state.entity)
  const { jalonComposants } = useSelector((state: RootState) => state.composant)
  const { possiblePortfolio } = useSelector((state: RootState) => state.monitoring)
  const { listSa } = useSelector((state: RootState) => state.sa)
  const { robPermission, usersEntitySupervisor } = useSelector((state: RootState) => state.users)
  const [selectedYear, setSelectedYear] = useState<ValueSelect | null>(null)
  const [selectedComponent, setSelectedComponent] = useState<string[]>([])
  const [possibleComponent, setPossibleComponent] = useState<Composant[]>([])
  const [possibleYears, setPossibleYears] = useState<string[]>([])

  const entityList = () => {
    if (robPermission.includes('admin')) {
      return entities
    }
    setSelectedEntity(entities.find(entity => entity.id === usersEntitySupervisor) as ValueSelect || null)
    return entities.filter(entity => entity.id === usersEntitySupervisor)
  }

  useEffect(() => {
    setPossibleComponent(jalonComposants.find(jalon => jalon.numero_jalon === activeJalon)?.active_composants || [])
  }, [activeJalon, jalonComposants])

  const extractUniqueYears = (data: SA[]) => {
    const uniqueYears = new Set()
    data.forEach(item => {
      if (!item.annee) return
      uniqueYears.add(item.annee)
    })
    const sortedUniqueYears = Array.from(uniqueYears).sort() as string[]

    return sortedUniqueYears
  }

  useEffect(() => {
    const listSaLength = listSa.length
    if (listSaLength) {
      setPossibleYears(extractUniqueYears(listSa))
    }
  }, [listSa.length])

  const handleSelect = (value: ValueSelect | null, type: SelectType) => {
    switch (type) {
      case SelectType.ENTITY:
        dispatch(resetData())
        setSelectedEntity(value)
        setSelectedPortfolio(null)
        if (value) {
          dispatch(getPortfolioSimple({
            entityId: value.id,
          }))
        }
        break
      case SelectType.PORTFOLIO:
        setSelectedPortfolio(value)
        break
      case SelectType.YEAR:
        setSelectedYear(value)
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (!entities || !possiblePortfolio) return

    const entity = localStorage.getItem('selectedEntity')
    const portfolio = localStorage.getItem('selectedPortfolio')
    const year = localStorage.getItem('selectedYear')
    const component = localStorage.getItem('selectedComponent')
    const activeJalonMonitoring = localStorage.getItem('activeJalonMonitoring')

    if (!entity || !portfolio || !year || !component || !activeJalonMonitoring) return

    setSelectedEntity(entities.find(e => e.id === entity) as ValueSelect || null)
    setSelectedPortfolio(possiblePortfolio.find(p => p.id === portfolio) as ValueSelect || null)
    setSelectedYear({ id: uuidV4(), libelle: year })
    setSelectedComponent(JSON.parse(component))
  }, [entities])

  useEffect(() => {
    if (!selectedEntity || !selectedPortfolio || !selectedYear || !selectedComponent.length) return
    dispatch(resetData())
    localStorage.setItem('selectedEntity', selectedEntity.id)
    localStorage.setItem('selectedPortfolio', selectedPortfolio.id)
    localStorage.setItem('selectedYear', selectedYear.libelle)
    localStorage.setItem('selectedComponent', JSON.stringify(selectedComponent))
    localStorage.setItem('activeJalonMonitoring', activeJalon.toString())
    dispatch(getSupervisionEf({
      portefeuille: selectedPortfolio.id,
      entite: selectedEntity.id,
      annee: selectedYear.libelle,
      composants: selectedComponent.map(e => parseInt(e, 10)),
      numero_jalon: activeJalon,
    }))
  }, [selectedPortfolio, selectedYear, selectedComponent, activeJalon])

  return (
    <div className="selects">
      <CustomSelect
        options={entityList() as ValueSelect[]}
        inputValue={selectedEntity}
        handleChange={(_: SyntheticEvent, newValue: ValueSelect | null) => handleSelect(newValue, SelectType.ENTITY)}
        placeholder={terms.Monitoring.Contributions.Selects.entity}
        disabled={isSupervisor}
      />
      <CustomSelect
        options={possiblePortfolio}
        inputValue={selectedPortfolio}
        handleChange={(_: SyntheticEvent, newValue: ValueSelect | null) => handleSelect(newValue, SelectType.PORTFOLIO)}
        placeholder={terms.Monitoring.Contributions.Selects.portfolio}
        disabled={!selectedEntity}
      />
      <SelectComponent
        selectedComponent={selectedComponent}
        setSelectedComponent={setSelectedComponent}
        possibleComponent={possibleComponent}
      />
      <CustomSelect
        options={possibleYears.map(year => ({ id: uuidV4(), libelle: year })) as ValueSelect[]}
        inputValue={selectedYear}
        handleChange={(_: SyntheticEvent, newValue: ValueSelect | null) => handleSelect(newValue, SelectType.YEAR)}
        placeholder={terms.Monitoring.Contributions.Selects.year}
      />
    </div>
  )
}

export default ContributionsSelects
