import { Tooltip } from '@mui/material'
import terms from 'common/terms'
import CustomDatePicker from 'components/DatePicker/CustomDatePicker'
import { ReactElement } from 'react'
import { SaJalon } from 'reducers/SA/types'
import { setCopilDate } from 'reducers/SA/sa.reducer'

export default function JalonDate({ activeJalon, setOpen, disabled }:
     { activeJalon: SaJalon, setOpen: (v: boolean) => void, disabled: boolean}): ReactElement {
  return (
    <div>
      <Tooltip
        title={activeJalon.date_copil === activeJalon.date_previsionnelle
          ? terms.Suivi.previsionnelleDate : terms.Suivi.copilDate}
        arrow
        placement="top"
      >
        <div>
          <CustomDatePicker
            copilDate={activeJalon.date_copil}
            setOpen={setOpen}
            disabled={disabled}
            setDate={setCopilDate}
          />
        </div>
      </Tooltip>
      <p className="date-previsionnelle mb-0">
        {activeJalon.date_copil === activeJalon.date_previsionnelle ? terms.Suivi.dateNotice : ''}
      </p>
    </div>
  )
}
