import { deleteRequest, get, post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { SettingsTabListElement } from 'components/SettingsTab/SettingsTabList'
import { ThunkApi } from 'reducers/types'
import { getUsersSimple } from 'reducers/Users/utils'
import { formatSettingsTabListElement } from '../utils'
import { ParamVisitor, ParamDeleteVisitor, ParamPostVisitor } from './types'

const getSaVisitors = createAsyncThunk<SettingsTabListElement[], string | null, ThunkApi>(
  'sa/visitors/get',
  async (saId: string | null, thunkApi) => {
    try {
      const response: ParamVisitor[] = await get(`/robustest/sa/${saId}/visiteurs/`)
      const usersResponse = await getUsersSimple(response.map(visitor => visitor.utilisateur?.cerbere_id))
      return usersResponse.map(user => {
        const visitor = response.find(vis => vis.utilisateur?.cerbere_id === user.id)
        return formatSettingsTabListElement(visitor?.id, user, visitor?.entite)
      })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postSaVisitors = createAsyncThunk<SettingsTabListElement, ParamPostVisitor, ThunkApi>(
  'sa/visitors/post',
  async (newVisitor: ParamPostVisitor, thunkApi) => {
    try {
      const response: ParamVisitor = await post(`/robustest/sa/${newVisitor.saId}/visiteurs/`, {
        utilisateur: {
          cerbere_id: newVisitor.user?.id,
        },
        entite: newVisitor.entity?.id,
      })
      return formatSettingsTabListElement(response.id, newVisitor.user, newVisitor.entity)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteSaVisitors = createAsyncThunk<ParamVisitor, ParamDeleteVisitor, ThunkApi>(
  'sa/visitors/delete',
  async (visitorToDelete: ParamDeleteVisitor, thunkApi) => {
    try {
      const response = await
      deleteRequest(`/robustest/sa/${visitorToDelete.saId}/visiteurs/${visitorToDelete.saVisitorId}/`)
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

// eslint-disable-next-line import/prefer-default-export
export {
  getSaVisitors,
  postSaVisitors,
  deleteSaVisitors,
}
