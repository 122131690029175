import {
  Box,
  FormControl,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material'
import terms from 'common/terms'
import Loader from 'components/Loader'
import CustomSnackbar from 'components/Snackbar/Snackbar'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hideSnackbar } from 'reducers/Parametrage/Preferences/preferences.reducer'
import { postSaPreferences } from 'reducers/Parametrage/Preferences/preferences.thunk'
import { RootState } from 'Store'
import { PrimaryBtn } from 'themes/theme'

import '../settings.scss'

const Preferences = (): ReactElement => {
  const dispatch = useDispatch()
  const {
    mailOuvertureJalon,
    mailRappelClotureJalon,
    displaySnackbar,
    actionExpanded,
    isLoadingPreferences,
  } = useSelector((state: RootState) => state.paramPreferences)
  const { idSA } = useSelector((state: RootState) => state.app)
  const [mailOuverture, setMailOuverture] = useState<boolean>(mailOuvertureJalon)
  const [rappelCloture, setRappelCloture] = useState<boolean>(mailRappelClotureJalon)
  const [isExpanded, setIsExpanded] = useState<boolean>(actionExpanded)
  const [hasModified, setHasModified] = useState(false)

  useEffect(() => {
    if (mailOuvertureJalon !== null) {
      setMailOuverture(mailOuvertureJalon)
    }
  }, [mailOuvertureJalon])

  useEffect(() => {
    if (mailRappelClotureJalon !== null) {
      setRappelCloture(mailRappelClotureJalon)
    }
  }, [mailRappelClotureJalon])

  useEffect(() => {
    if (mailOuverture === mailOuvertureJalon
      && rappelCloture === mailRappelClotureJalon
      && isExpanded === actionExpanded) {
      setHasModified(false)
    } else {
      setHasModified(true)
    }
  }, [mailOuverture, mailOuvertureJalon, rappelCloture, mailRappelClotureJalon, isExpanded, actionExpanded])

  const handleClickValidate = () => {
    if (idSA) {
      dispatch(postSaPreferences({
        saId: idSA,
        preferences: {
          mail_ouverture_jalon: mailOuverture,
          mail_rappel_cloture_jalon: rappelCloture,
          full_action_display: isExpanded,
        },
      }))
    }
  }

  return (
    <>
      {
        isLoadingPreferences ? (
          <div className="setting-loader">
            <Loader message={terms.Common.loading} />
          </div>
        ) : (
          <Box className="mx-3 px-3 my-3 py-3 border rounded d-flex flex-column">
            <Typography variant="h6">
              {terms.Settings.preferences.mailSectionTitle}
            </Typography>
            <FormControl className="mt-3">
              <FormControlLabel
                control={<Switch onChange={evt => setMailOuverture(evt.target.checked)} checked={mailOuverture} />}
                label={terms.Settings.preferences.mailOpenLabel}
              />
            </FormControl>
            <FormControl>
              <FormControlLabel
                control={<Switch onChange={evt => setRappelCloture(evt.target.checked)} checked={rappelCloture} />}
                label={terms.Settings.preferences.mailRemindLabel}
              />
            </FormControl>
            <Typography variant="h6" className="mt-3">
              {terms.Settings.preferences.preferenceSectionTitle}
            </Typography>
            <FormControl className="mt-3">
              <FormControlLabel
                control={(
                  <Switch
                    onChange={evt => setIsExpanded(evt.target.checked)}
                    checked={isExpanded}
                  />
          )}
                label={terms.Settings.preferences.expandAction}
              />
            </FormControl>
            <div className="d-flex justify-content-end mr-2 mb-">
              <PrimaryBtn
                disabled={!hasModified}
                onClick={handleClickValidate}
              >
                {terms.Common.valid}
              </PrimaryBtn>
            </div>
          </Box>
        )
      }

      <CustomSnackbar
        message={terms.Settings.preferences.snackbarMessage}
        displaySnackbar={displaySnackbar}
        handleClose={() => dispatch(hideSnackbar())}
      />
    </>
  )
}

export default Preferences
