import terms from 'common/terms'
import CustomTextArea from 'components/CustomTextArea/CustomTextArea'
import ModalWrapper from 'components/Modal/ModalWrapper/ModalWrapper'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { patchSaJalonObjectFerroviaire } from 'reducers/Suivi/suivi.thunk'
import Composite from '../Composite/Composite'
import Buttons from './Buttons/Buttons'

import './ModalGlobalContribution.scss'
import SelectCotation from './SelectCotation/SelectCotation'
import SelectGravity from './SelectGravity/SelectGravity'

interface Props {
  open: boolean;
  handleClose: () => void;
  idSa: string;
  jalon: number;
  idObjFerroviaire: string;
  type: string;
  comment: string;
  gravity: number;
  cotation: string | null;
  disabled: boolean;
  numerator?: number | null;
  denominator?: number;
  withComposite?: boolean;
}

const ModalGlobalContribution = ({
  open,
  handleClose,
  idSa,
  jalon,
  idObjFerroviaire,
  type,
  comment,
  gravity,
  cotation,
  disabled,
  numerator,
  denominator,
  withComposite,
}: Props): ReactElement => {
  const dispatch = useDispatch()
  const [commentInput, setComment] = useState<string>('')
  const [cotationInput, setCotation] = useState<string>('')
  const [gravityInput, setGravity] = useState<number>(0)

  useEffect(() => {
    setComment(comment || '')
    setCotation(cotation || '')
    setGravity(gravity || 0)
  }, [open])

  const handleSave = () => {
    dispatch(patchSaJalonObjectFerroviaire({
      idSa,
      jalon,
      idObjFerroviaire,
      type,
      contribution: {
        ...(withComposite ? {
          cotation: cotationInput,
          perimetre: gravityInput,
          commentaire: commentInput,
        } : {
          cotation_axe: cotationInput,
          perimetre_axe: gravityInput,
          commentaire_axe: commentInput,
        }),
      },
    }))
    handleClose()
  }

  const disabledButton = () => {
    if (cotationInput) {
      return ['M', 'I'].includes(cotationInput) && (!commentInput || !gravityInput)
    }
    return true
  }

  const handleCloseModal = () => {
    setComment(comment || '')
    setCotation(cotation || '')
    setGravity(gravity || 0)
    handleClose()
  }
  return (
    <ModalWrapper
      open={open}
      onClose={handleCloseModal}
    >
      <div className="modal-global-contribution">
        <SelectCotation
          cotationInput={cotationInput}
          setCotation={setCotation}
          disabled={disabled}
        />
        <SelectGravity
          gravityInput={gravityInput}
          setGravity={setGravity}
          disabled={disabled}
        />
        {
          withComposite && (
            <div className="cotations">
              <div className="libelle-contribution">
                {terms.Suivi.GlobalContribution.labelComposite}
              </div>
              <Composite
                numerator={numerator as number | null}
                denominator={denominator as number}
              />
            </div>
          )
        }
        <div className="separator">
          <span>
            {terms.Suivi.GlobalContribution.labelComment}
          </span>
        </div>
        <div className="comment">
          <CustomTextArea
            inputValue={commentInput}
            setInputValue={setComment}
            disabled={disabled}
            rows={4}
          />
        </div>
        {!disabled && (
          <Buttons
            handleCloseModal={handleCloseModal}
            handleSave={handleSave}
            disabled={disabledButton()}
          />
        )}

      </div>
    </ModalWrapper>
  )
}

ModalGlobalContribution.defaultProps = {
  numerator: null,
  denominator: 0,
  withComposite: false,
}

export default ModalGlobalContribution
