import {
  get, post, deleteRequest, patch,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ComposantWithoutJalon } from 'reducers/Admin/Composant/types'
import { ThunkApi } from 'reducers/types'
import { getSaContributeurs } from '../Contributeurs/contributeurs.thunk'
import { ParamComplexe, ParamPatchComplexe, ParamPostComplexe } from './types'

const getSaComplexes = createAsyncThunk(
  'sa/complexes/get',
  async (saId: string | null, thunkApi) => {
    try {
      const response: ParamComplexe[] = await get(`/robustest/sa/${saId}/batiment-voyageurs/`)
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

type requestParams = {
  saId: string | null
  complexeId: string
}

const getSaComplexeById = createAsyncThunk(
  'sa/complexe/getById',
  async (params: requestParams, thunkApi) => {
    try {
      const response: ParamComplexe = await get(`/robustest/sa/${params.saId}/batiment-voyageurs/${params.complexeId}`)
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postSaComplexe = createAsyncThunk<ParamComplexe, ParamPostComplexe, ThunkApi>(
  'sa/complexe/post',
  async (newComplexeAd: ParamPostComplexe, thunkApi) => {
    try {
      const response: ParamComplexe = await post(`/robustest/sa/${newComplexeAd.saId}/batiment-voyageurs/`, {
        batiment_voyageurs: newComplexeAd.ComplexeId,
      })
      thunkApi.dispatch(getSaContributeurs(newComplexeAd.saId as string))
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const patchSAComplexe = createAsyncThunk<ParamComplexe, ParamPatchComplexe, ThunkApi>(
  'sa/complexe/patch',
  async (updateComplexeSA: ParamPatchComplexe, thunkApi) => {
    try {
      const response: ParamComplexe = await patch(
        `/robustest/sa/${updateComplexeSA.idSA}/batiment-voyageurs/${updateComplexeSA.idBV}`, updateComplexeSA.complexe,
      )
      thunkApi.dispatch(getSaContributeurs(updateComplexeSA.idSA))
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteSaComplexe = createAsyncThunk(
  'sa/complexe/delete',
  async (complexeId: string | undefined, thunkApi) => {
    try {
      const response = await deleteRequest(
        `/robustest/sa/${localStorage.getItem('idSA')}/batiment-voyageurs/${complexeId}`,
      ).then(() => {
        thunkApi.dispatch(getSaComplexes(localStorage.getItem('idSA')))
        thunkApi.dispatch(getSaContributeurs(localStorage.getItem('idSA')))
      })
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getDefaultJalonsComplexe = createAsyncThunk(
  'sa/complexe/jalons',
  async (numeroJalon: number, thunkApi) => {
    try {
      const response: ComposantWithoutJalon[] = await
      get('/robustest/defauts/', { jalon: numeroJalon, type: 'batiment_voyageur' })
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

// eslint-disable-next-line import/prefer-default-export
export {
  getSaComplexes,
  getSaComplexeById,
  postSaComplexe,
  deleteSaComplexe,
  patchSAComplexe,
  getDefaultJalonsComplexe,
}
