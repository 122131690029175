import { ContributionRating } from 'reducers/Contributions/types'

export const GRAVITY = [
  {
    value: 1,
    label: 'G1',
  },
  {
    value: 2,
    label: 'G2',
  },
  {
    value: 3,
    label: 'G3',
  },
]

export const RATINGS = ['S', 'A', 'M', 'I']

export const cotationInfo = (abrev: string | null | undefined): ContributionRating | null => {
  switch (abrev) {
    case 'A':
      return {
        rating: 'Acceptable',
        color: '#0088CE', // blue
        abrev,
      }
    case 'S':
      return {
        rating: 'Satisfaisant',
        color: '#82be00', // green
        abrev,
      }
    case 'M':
      return {
        rating: 'Moyen',
        color: '#ffb612', // yellow
        abrev,
      }
    case 'I':
      return {
        rating: 'Insuffisant',
        color: '#cd0037', // red
        abrev,
      }
    case 'NE':
      return {
        rating: 'Non Evalué',
        color: '#EEEEEE', // grey
        abrev,
      }
    default:
      return null
  }
}
