import { ReactElement } from 'react'
import { ViewportProps } from 'react-map-gl'
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import { DEFAULT_VIEWPORT } from 'services/map'

enum Zoom {
  IN = 'IN',
  OUT = 'OUT',
}

interface Props {
  viewport: ViewportProps
  setViewport: (viewport: ViewportProps) => void
}

const MapNavigationControl = ({ viewport, setViewport }: Props): ReactElement => {
  const handleClick = (zoom?: Zoom) => {
    if (!viewport.zoom) return
    switch (zoom) {
      case Zoom.IN:
        setViewport({ ...viewport, zoom: viewport.zoom + 0.5, transitionDuration: 300 })
        break
      case Zoom.OUT:
        setViewport({ ...viewport, zoom: viewport.zoom - 0.5, transitionDuration: 300 })
        break
      default:
        setViewport({ ...DEFAULT_VIEWPORT, transitionDuration: 500 })
        break
    }
  }
  return (
    <div className="navigation">
      <div className="zoom">
        <button
          type="button"
          onClick={() => handleClick(Zoom.IN)}
        >
          <AddIcon />
        </button>
        <button
          type="button"
          onClick={() => handleClick(Zoom.OUT)}
          disabled={viewport.zoom === 4.5}
        >
          <RemoveIcon />
        </button>
      </div>
      <div className="reset">
        <button
          type="button"
          onClick={() => handleClick()}
        >
          <ZoomOutMapIcon />
        </button>
      </div>
    </div>
  )
}

export default MapNavigationControl
