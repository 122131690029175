import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getEntity, postEntity } from '../admin.thunk'
import { Entity, EntityState } from './types'

const initialState: EntityState = {
  entities: [],
  displaySnackbar: false,
  isLoadingEntities: false,
}

export const entity = createSlice({
  name: 'entity',
  initialState,
  reducers: {
    addEntity: state => {
      if (state.entities.find(stateEntity => stateEntity.id === 'new')) {
        return
      }
      state.entities = [...state.entities, {
        libelle: '', active: true, id: 'new', superviseurs: [],
      }]
    },
    changeEntity: (state, action: PayloadAction<Partial<Entity>>) => {
      const index = state.entities.findIndex(stateEntity => stateEntity.id === action.payload.id)
      state.entities[index] = { ...state.entities[index], ...action.payload }
    },
    deleteNewEntity: state => {
      const index = state.entities.findIndex(entitie => entitie.id === 'new')
      if (index !== -1) { state.entities.splice(index, 1) }
    },
    hideSnackbar: state => {
      state.displaySnackbar = false
    },
  },
  extraReducers: builder => {
    builder.addCase(getEntity.pending, state => { state.isLoadingEntities = true })
    builder.addCase(getEntity.rejected, state => { state.isLoadingEntities = false })
    builder.addCase(getEntity.fulfilled, (state, { payload }) => {
      state.isLoadingEntities = false
      state.entities = payload.map(payloadEntity => ({
        libelle: payloadEntity.libelle,
        active: payloadEntity.active,
        id: payloadEntity.id,
        superviseurs: payloadEntity.superviseurs.map(superviseur => ({
          ...superviseur,
          cerbere_id: superviseur.id,
        })),
      }))
    })
    builder.addCase(postEntity.fulfilled, (state, { payload }) => {
      const index = state.entities.findIndex(({ id }) => id === payload.id)
      const indexToChange = index === -1 ? state.entities.findIndex(({ id }) => id === 'new') : index
      state.entities[indexToChange] = { ...payload }
      state.displaySnackbar = true
    })
  },
})

export const {
  addEntity, changeEntity, deleteNewEntity, hideSnackbar,
} = entity.actions

export default entity.reducer
