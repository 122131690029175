import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import terms from 'common/terms'
import { getConstructionSites } from '../admin.thunk'
import { postConstructionSite, searchGaiaLignes } from './constructionSites.thunk'
import { ConstructionSitesState, ConstructionSites } from './types'

const initialState: ConstructionSitesState = {
  constructionSites: [],
  modalCreateConstrtuctionSite: false,
  isLoadingConstructionSites: false,
  searchDidymeResponse: null,
  gaiaLignes: [],
}

function getCustomLibelle(element: ConstructionSites) {
  let extraLibelle = ''
  if (element.eic && element !== null) {
    extraLibelle = `
      <span class="no-composant-msg">
        (${element.eic?.libelle})
      </span>
    `
  }
  if (element.eic === null) {
    extraLibelle = `
     <span class="no-composant-msg">
        ${terms.Admin.constructionSite.withoutTerritory}
      </span>`
  }
  return `${element.libelle} ${extraLibelle}`
}

export const constructionSites = createSlice({
  name: 'constructionSites',
  initialState,
  reducers: {
    addConstructionSite: state => {
      if (state.constructionSites.find(stateCS => stateCS.id === 'new')) {
        return
      }
      state.constructionSites = [...state.constructionSites, {
        libelle: '',
        id: 'new',
        eic: null,
        sa: [],
        ligne_pk: null,
        ligne_code: null,
        ligne_libelle: null,
      }]
    },
    closeModalCreateConstructionSite: state => {
      state.modalCreateConstrtuctionSite = false
    },
    openModalCreateConstructionSite: state => {
      state.modalCreateConstrtuctionSite = true
    },
    resetError: state => {
      state.error = undefined
    },
    changeConstructionSite: (state, action: PayloadAction<Partial<ConstructionSites>>) => {
      const index = state.constructionSites.findIndex(stateConstruc => stateConstruc.id === action.payload.id)
      state.constructionSites[index] = { ...state.constructionSites[index], ...action.payload }
    },
    deleteConstructionSite: state => {
      const index = state.constructionSites.findIndex(entitie => entitie.id === 'new')
      if (index !== -1) { state.constructionSites.splice(index, 1) }
    },
  },
  extraReducers: builder => {
    builder.addCase(getConstructionSites.pending, state => { state.isLoadingConstructionSites = true })
    builder.addCase(getConstructionSites.rejected, state => { state.isLoadingConstructionSites = false })
    builder.addCase(getConstructionSites.fulfilled, (state, { payload }) => {
      state.isLoadingConstructionSites = false
      state.constructionSites = payload.map(payloadSite => ({
        libelle: payloadSite.libelle,
        customLibelle: getCustomLibelle(payloadSite),
        id: payloadSite.id,
        eic: payloadSite.eic,
        sa: payloadSite.sa,
        ligne_pk: payloadSite.ligne_pk,
        ligne_code: payloadSite.ligne_code,
        ligne_libelle: payloadSite.ligne_libelle,
      }))
    })

    builder.addCase(postConstructionSite.rejected, (state, action) => {
      state.error = action.payload
    })
    builder.addCase(postConstructionSite.fulfilled, state => {
      state.error = undefined
      state.modalCreateConstrtuctionSite = false
    })
    builder.addCase(searchGaiaLignes.fulfilled, (state, { payload }) => {
      state.gaiaLignes = payload
    })
  },
})

export const {
  closeModalCreateConstructionSite,
  openModalCreateConstructionSite,
  resetError,
  changeConstructionSite,
  addConstructionSite,
  deleteConstructionSite,
} = constructionSites.actions

export default constructionSites.reducer
