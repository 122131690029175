import {
  Composant, ComposantJalonWithoutType, ConvertFromPerimetre, Jalon,
} from './types'

export function convertToPerimetre(jalon: Jalon): number {
  let value = ''
  Object.keys(jalon.type).forEach(key => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    value += jalon.type[key] ? '1' : '0'
  })
  return parseInt([...value].reverse().join(''), 2)
}

export function convertFromPerimetre(perimetre: number): ConvertFromPerimetre {
  const binary = [...perimetre.toString(2)].reverse().join('')
  return {
    complexe: binary.charAt(0) === '1',
    ligne: binary.charAt(1) === '1',
    chantier: binary.charAt(2) === '1',
    zoneDeProduction: binary.charAt(3) === '1',
  }
}

export function convertComposantPerimeter(composants: ComposantJalonWithoutType[]): Composant[] {
  return composants.map(composant => ({
    ...composant,
    customLibelle: `<strong>${composant.id}</strong> - ${composant.libelle}`,
    jalons: composant.jalons.map(jalon => ({
      ...jalon,
      type: convertFromPerimetre(jalon.perimetre),
    })),
  }))
}
