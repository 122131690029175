import {
  Autocomplete, Box, Checkbox, FormControl,
  Modal, TextField, Typography,
} from '@mui/material'
import { CancelRounded } from '@mui/icons-material'
import {
  ReactElement, SyntheticEvent, useEffect, useState,
} from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { DupingObject } from 'reducers/Contributions/types'
import terms from 'common/terms'
import { PrimaryBtn } from 'themes/theme'
import './contributionDuping.scss'
import { ObjectFerTab } from 'reducers/SA/types'
import ModalCloseButton from 'components/Modal/ModalCloseButton/ModalCloseButton'

interface ContributionDupingProps {
  setDuping: (arg: string[]) => void
  duping: string[],
  activeObjFer: ObjectFerTab
}

const ContributionDuping = ({ setDuping, duping, activeObjFer }: ContributionDupingProps): ReactElement => {
  const { possibleDuping } = useSelector((state: RootState) => state.contributions)

  const [openModal, setOpenModal] = useState(false)
  const [distinctEic, setDistinctEic] = useState<string[]>([])
  const [filterEic, setFilterEic] = useState<string[]>([])
  const [selectedObjects, setSelectedObjects] = useState<DupingObject[]>([])
  const [possibleDupingFiltered, setPossibleDupingFiltered] = useState<DupingObject[]>([])

  useEffect(() => {
    setPossibleDupingFiltered(possibleDuping.filter(obj => obj.id !== activeObjFer.id))
  }, [possibleDuping])

  useEffect(() => {
    const eicSet = new Set<string>()
    possibleDupingFiltered.forEach(obj => eicSet.add(obj.eic))
    setDistinctEic([...eicSet])
    setFilterEic([])
    setSelectedObjects([])
    setDuping([])
  }, [possibleDupingFiltered])

  useEffect(() => {
    setSelectedObjects(possibleDupingFiltered.filter(obj => duping.includes(obj.id)))
  }, [openModal])

  const handleChangeEic = (_: SyntheticEvent, newvalue: string[]) => {
    setFilterEic([...newvalue])
    setSelectedObjects(prev => prev.filter(obj => newvalue.includes(obj.eic)))
  }

  const getObjectlabel = (obj: DupingObject): string => `${obj.type} - ${obj.libelle} (${obj.eic})`

  const getDupingLabel = () => {
    if (duping.length === 0) {
      return terms.Contributions.duping.noDupeLabel
    }
    return `${terms.Contributions.duping.dupeOn} ${duping.length} ${terms.Contributions.duping.xObjects}`
  }

  return (
    <>
      <TextField
        onClick={() => setOpenModal(true)}
        InputProps={{
          readOnly: true,
        }}
        variant="standard"
        className="mb-3 duping-textfield"
        value={getDupingLabel()}
      />
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box id="modal-duping">
          <div className="d-flex w-100 justify-content-between">
            <Typography variant="h6" className="mb-3">
              {terms.Contributions.duping.modalHeader}
            </Typography>
            <ModalCloseButton handleClose={() => setOpenModal(false)} className="close-modal" />
          </div>
          <FormControl variant="standard" className="w-100 mb-3">
            <Autocomplete
              sx={{ maxHeight: 250, overflowY: 'auto', overflowX: 'hidden' }}
              multiple
              disableCloseOnSelect
              value={[...filterEic]}
              options={distinctEic}
              onChange={handleChangeEic}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} />
                  <Typography>
                    {option}
                  </Typography>
                </li>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="standard"
                  label={terms.Contributions.duping.territories}
                />
              )}
            />
          </FormControl>
          <FormControl variant="standard" className="w-100 mb-3">
            <Autocomplete
              sx={{ height: 250, overflowY: 'auto', overflowX: 'hidden' }}
              multiple
              disableCloseOnSelect
              id="modal-select-objects"
              value={selectedObjects}
              options={possibleDupingFiltered.filter(obj => filterEic.includes(obj.eic))}
              onChange={(_event, newvalue) => setSelectedObjects([...newvalue])}
              getOptionLabel={getObjectlabel}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} />
                  <Typography>
                    {getObjectlabel(option)}
                  </Typography>
                </li>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="standard"
                  label={terms.Contributions.duping.objects}
                />
              )}
              renderTags={objectList => (
                <div className="object-list">
                  {objectList.map(obj => (
                    <div className="d-flex object-chip" key={obj.id}>
                      <CancelRounded
                        className="delete-icon"
                        onClick={() => setSelectedObjects(selectedObjects.filter(o => o.id !== obj.id))}
                      />
                      <Typography className="object-label">
                        {getObjectlabel(obj)}
                      </Typography>
                    </div>
                  ))}
                </div>
              )}
            />
          </FormControl>
          <div className="d-flex w-100 justify-content-end">
            <PrimaryBtn
              className="ml-3"
              onClick={() => {
                setDuping(selectedObjects.map(obj => obj.id))
                setOpenModal(false)
              }}
            >
              {terms.Common.select}
            </PrimaryBtn>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default ContributionDuping
