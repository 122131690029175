import {
  getAll,
  getGroups,
  getGroupMembers,
  getUsersListInfo,
  getUserInfos,
  getUserMe,
  getUserMeSettings,
  getUserMeSuivi,
  getUserMePlanAction,
} from 'reducers/Users/users.thunk'

import {
  getUsersContributeurs,
  getToggleDisplayAllComponent,
  postToggleDisplayAllComponent,
  getSAJalonByNumber,
  getSaJalonNbObject,
  getSAJalonNbObjectArray,
  fetchDataToCreatePdf,
  getPossibleUsersEmailBySa,
  getSaSavedMails,
  postSaSavedMail,
  deleteSaSavedMail,
  patchSaJalonObjectFerroviaire,
} from 'reducers/Suivi/suivi.thunk'

import {
  getSa,
  postSA,
  patchSa,
  getSaById,
  getSAJalon,
} from 'reducers/SA/sa.thunk'

import { getSaVisitors, postSaVisitors, deleteSaVisitors } from 'reducers/Parametrage/Visitors/visitors.thunks'

import { getSaPreferences, postSaPreferences } from 'reducers/Parametrage/Preferences/preferences.thunk'

import {
  getSaLignes,
  postSaLigne,
  patchSaLigne,
  deleteSaLigne,
  getSaLigneById,
  getDefaultJalonsLigne,
} from 'reducers/Parametrage/ParamLignes/paramLignes.thunk'

import {
  getSaDeciders,
  postSaDeciders,
  deleteSaDeciders,
} from 'reducers/Parametrage/Deciders/deciders.thunks'

import {
  getSaContributeurs,
  getSaContributeursById,
  getSaContributeursFunctions,
  deleteSaContributeurs,
  postSaContributeurs,
  patchSaContributeurs,
} from 'reducers/Parametrage/Contributeurs/contributeurs.thunk'

import {
  getSaConstructionSites,
  getSaConstructionSiteById,
  postSaConstructionSite,
  deleteSaConstructionSite,
  patchSAConstructionSite,
  getDefaultJalonsConstructionSite,
} from 'reducers/Parametrage/ConstructionSites/constructionSites.thunk'

import {
  getSaComplexes,
  getSaComplexeById,
  postSaComplexe,
  deleteSaComplexe,
  patchSAComplexe,
  getDefaultJalonsComplexe,
} from 'reducers/Parametrage/Complexes/complexes.thunk'

import {
  getSaAnimators,
  postSaAnimators,
  deleteSaAnimators,
} from 'reducers/Parametrage/Animators/animators.thunks'

import {
  getTerritory,
  getPossibleYears,
  getMonitoringDzp,
} from 'reducers/monitoring/monitoring.thunk'

import {
  getContributionsStateAfterRefresh,
  getContributionsStateAfterRefreshWithObject,
  getContributions,
  postContribution,
  deleteContribution,
  patchContribution,
  getSaEntities,
  getDedoublementObjects,
  getSaPossibleAnimators,
} from 'reducers/Contributions/contributions.thunk'

import {
  getEntity,
  postEntity,
  getComposants,
  postComposant,
  getContributorFunctions,
  postContributorFunction,
  getJalonComposants,
  getProductionZones,
  postProductionZone,
  getConstructionSites,
  getAllJalonsComposants,
  loadPage,
} from 'reducers/Admin/admin.thunk'

import {
  getPonderationList,
  getPonderationCotation,
  patchPonderationCotation,
  postPonderationComposant,
} from 'reducers/Admin/Ponderation/ponderation.thunk'

import { searchComplexes, patchComplexes } from 'reducers/Admin/Complexes/complexes.thunk'

import { getEic, postEic } from 'reducers/Admin/Eic/eic.thunk'

import {
  searchConstructionSites,
  patchConstructionSites,
  postConstructionSite,
} from 'reducers/Admin/ConstructionSites/constructionSites.thunk'

import {
  getObjFerroviaires,
  getComposantsByObjFer,
  getComposantsByObjFerCurJalon,
  postComposantsTodoCurJalon,
  postCreateAction,
  getActionColumn,
  getActionsByComposant,
  patchActionById,
  patchStateAction,
  patchStateActionPilot,
  getClonableObjects,
  postCloneAction,
  loadPdfActionPlan,
} from 'reducers/ActionPlan/actionPlan.thunk'

import { getAxes, postAxe } from 'reducers/Admin/Axes/axes.thunk'

// eslint-disable-next-line import/prefer-default-export, @typescript-eslint/no-explicit-any
export const actionList: Record<string, any> = {
  // * USERS
  'users/getAll': getAll,
  'users/getGroups': getGroups,
  'users/getGroupMembers': getGroupMembers,
  'users/getUsersListInfo': getUsersListInfo,
  'users/getUserInfos': getUserInfos,
  'users/getUserMe': getUserMe,
  'users/getUserMe/settings': getUserMeSettings,
  'users/getUserMe/suivi': getUserMeSuivi,
  'users/getUserMe/planaction': getUserMePlanAction,

  // * SUIVI
  'get/contributeur/suivi': getUsersContributeurs,
  'get/suivi/toggle': getToggleDisplayAllComponent,
  'post/suivi/toggle': postToggleDisplayAllComponent,
  'get/sa/jalon/number': getSAJalonByNumber,
  'get/sa/jalon/object': getSaJalonNbObject,
  'get/sa/jalon/object/array': getSAJalonNbObjectArray,
  'post/suivi/setting/pdf': fetchDataToCreatePdf,
  'get/suivi/possibleUsersEmail/': getPossibleUsersEmailBySa,
  'get/sa/savedMails': getSaSavedMails,
  'post/sa/savedMails': postSaSavedMail,
  'delete/sa/savedMails': deleteSaSavedMail,
  'patch/sa/jalon/objectFerroviaire': patchSaJalonObjectFerroviaire,

  // SA
  'sa/get': getSa,
  'sa/postSA': postSA,
  'sa/patchSa': patchSa,
  'sa/getSaById': getSaById,
  'get/sa/jalon': getSAJalon,

  // Parametrage -------------

  // Visitors
  'sa/visitors/get': getSaVisitors,
  'sa/visitors/post': postSaVisitors,
  'sa/visitors/delete': deleteSaVisitors,

  // Preferences
  'settings/preferences/get': getSaPreferences,
  'settings/preferences/post': postSaPreferences,

  // ParamLignes
  'sa/Lignes/get': getSaLignes,
  'sa/ligne/post': postSaLigne,
  'sa/ligne/patch': patchSaLigne,
  'sa/ligne/delete': deleteSaLigne,
  'sa/ligne/getById': getSaLigneById,
  'sa/ligne/jalons': getDefaultJalonsLigne,

  // Deciders
  'sa/deciders/get': getSaDeciders,
  'sa/deciders/post': postSaDeciders,
  'sa/deciders/delete': deleteSaDeciders,

  // Contributeurs
  'sa/contributeurs/get': getSaContributeurs,
  'sa/contributeurs/getById': getSaContributeursById,
  'sa/contributeurs/functions': getSaContributeursFunctions,
  'sa/contributeurs/delete': deleteSaContributeurs,
  'sa/contributeurs/post': postSaContributeurs,
  'sa/contributeur/patch': patchSaContributeurs,

  // ConstructionSites
  'sa/constructionSites/get': getSaConstructionSites,
  'sa/constructionSite/getById': getSaConstructionSiteById,
  'sa/constructionSite/post': postSaConstructionSite,
  'sa/constructionSite/delete': deleteSaConstructionSite,
  'sa/constructionSite/patch': patchSAConstructionSite,
  'sa/constructionSite/jalons': getDefaultJalonsConstructionSite,

  // Complexes
  'sa/complexes/get': getSaComplexes,
  'sa/complexe/getById': getSaComplexeById,
  'sa/complexe/post': postSaComplexe,
  'sa/complexe/delete': deleteSaComplexe,
  'sa/complexe/patch': patchSAComplexe,
  'sa/complexe/jalons': getDefaultJalonsComplexe,

  // Animators
  'sa/animators/get': getSaAnimators,
  'sa/animators/post': postSaAnimators,
  'sa/animators/delete': deleteSaAnimators,

  // ----------------------------

  // Monitoring
  'get/territory/monitoring': getTerritory,
  'get/possibleYears/montoring': getPossibleYears,
  'get/monitoring/dzp': getMonitoringDzp,

  // Contributions
  'contributions/setRefresh': getContributionsStateAfterRefresh,
  'contributions/setRefreshWithObject': getContributionsStateAfterRefreshWithObject,
  'contributions/get': getContributions,
  'contributions/post': postContribution,
  'contributions/delete': deleteContribution,
  'contributions/patch': patchContribution,
  'contributions/entities': getSaEntities,
  'contributions/objects': getDedoublementObjects,
  'contributions/possibleAnimators': getSaPossibleAnimators,

  // Admin ---------------------
  'entity/get': getEntity,
  'entity/post|patch': postEntity,
  'composants/get': getComposants,
  'composants/post|patch': postComposant,
  'contributorFunction/get': getContributorFunctions,
  'contributorFuntion/post|patch': postContributorFunction,
  'jalonComposants/get': getJalonComposants,
  'productionZones/get': getProductionZones,
  'productionZones/post|patch': postProductionZone,
  'constructionSites/get': getConstructionSites,
  'get/AllJalonsComposants': getAllJalonsComposants,
  'admin/loadPage': loadPage,

  // Ponderation
  'ponderation/list/get': getPonderationList,
  'ponderation/cotation/get': getPonderationCotation,
  'ponderation/cotation/patch': patchPonderationCotation,
  'ponderation/composant/post': postPonderationComposant,

  // Complexes
  'complexes/get': searchComplexes,
  'complexes/patch': patchComplexes,

  // Eic
  'eic/get': getEic,
  'eic/post|patch': postEic,

  // Axes
  'axes/get': getAxes,
  'axes/post|patch': postAxe,

  // ConstructionSites
  'admin/constructionSites/get': searchConstructionSites,
  'admin/constructionSites/patch': patchConstructionSites,
  'admin/constructionSites/post': postConstructionSite,

  // ---------------------------

  // ActionPlan
  'get/actionPlan/ObjFerroviaires': getObjFerroviaires,
  'get/actionPlan/ComposantsByObjFer': getComposantsByObjFer,
  'get/actionPlan/ComposantsByObjFerCurJalon': getComposantsByObjFerCurJalon,
  'post/actionPlan/ComposantsByObjFerCurJalon': postComposantsTodoCurJalon,
  'post/actionPlan/CreateAction': postCreateAction,
  'get/actionPlan/ActionColToClear': getActionColumn,
  'get/actionPlan/ActionsByComposant': getActionsByComposant,
  'patch/actionPlan/ActionById': patchActionById,
  'patch/actionPlan/StateAction': patchStateAction,
  'patch/actionPlan/StateActionPilot': patchStateActionPilot,
  'get/ClonableObjects': getClonableObjects,
  'post/actionPlan/CloneAction': postCloneAction,
  'get/actionPlan/Pdf': loadPdfActionPlan,
}
