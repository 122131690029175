import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone } from '@fortawesome/free-solid-svg-icons'
import terms from 'common/terms'
import PopOver from 'components/PopOver/PopOver'
import { chipAttributes, dateToDisplay, weather } from 'pages/ActionPlan/utils'
import { backgroundColorContributor } from 'pages/Suivi/utils'
import { ReactElement, useState } from 'react'
import {
  Action, ActionTileType, ObjectClonable, Statut,
} from 'reducers/ActionPlan/types'
import { ReactComponent as AxeIcon } from 'assets/icons/axeIcon.svg'
import WeatherIcon from '../component/WeatherIcon'
import './actionTile.scss'

interface ActionTileProps {
  type: ActionTileType
  action: Action
  handleClick?: () => void
  canClone?: boolean
  handleClone?: (object: ObjectClonable) => () => void
  clonableObjects?: ObjectClonable[]
}

const getTileDate = (action: Action): { type: string, value: string } => {
  switch (action.statut) {
    case Statut.FINISHED:
      return {
        type: terms.actionPlan.modalAddAction.tileAction.finishedDate,
        value: action.date_realisation ? dateToDisplay(action.date_realisation).date
          : terms.actionPlan.modalAddAction.tileAction.noDate,
      }
    case Statut.CLOSED:
      return {
        type: terms.actionPlan.modalAddAction.tileAction.closingDate,
        value: action.date_cloture ? dateToDisplay(action.date_cloture).date
          : terms.actionPlan.modalAddAction.tileAction.noDate,
      }
    default:
      return {
        type: terms.actionPlan.modalAddAction.tileAction.plannedDate,
        value: action.date_bouclage ? dateToDisplay(action.date_bouclage).date
          : terms.actionPlan.modalAddAction.tileAction.noDate,
      }
  }
}

const ActionTile = ({
  type, action, handleClick, canClone, handleClone, clonableObjects,
}: ActionTileProps): ReactElement => {
  const [isHoveringActions, setIsHoveringActions] = useState(false)
  const [openDropdown, setOpenDropdown] = useState(false)

  const handleClickTile = () => {
    if (!isHoveringActions && handleClick) {
      handleClick()
    }
  }

  return (
    <>
      <button
        type="button"
        className="actionTile"
        onClick={handleClickTile}
      >
        <div className="actionTile-header d-flex justify-content-between">
          <div className="actionTile-title">
            {action.nom}
          </div>
          {
            action.from_axe && (
            <PopOver popContent={terms.Contributions.tile.axeHabilitation}>
              <AxeIcon className="axe-icon" />
            </PopOver>
            )
          }
          {type === ActionTileType.limited && (
          <WeatherIcon weather={weather(action.meteo_animateur)} />
          )}
          {type === ActionTileType.plusplus && canClone && (
          <PopOver popContent={terms.actionPlan.actionTile.cloneActionPopover}>
            <button
              type="button"
              className="actionTile-clone"
              onClick={() => setOpenDropdown(!openDropdown)}
              disabled={clonableObjects?.length === 0}
              onMouseEnter={() => setIsHoveringActions(true)}
              onMouseLeave={() => setIsHoveringActions(false)}
            >
              <FontAwesomeIcon icon={faClone} />
              {terms.actionPlan.actionTile.cloneAction}
            </button>
          </PopOver>
          )}
          {openDropdown && (
          <div className="actionTile-clone-dropdown">
            {clonableObjects?.map(object => (
              <button
                key={object.id}
                type="button"
                onClick={handleClone && handleClone(object)}
                onMouseEnter={() => setIsHoveringActions(true)}
                onMouseLeave={() => setIsHoveringActions(false)}
              >
                {object.libelle}
              </button>
            ))}
          </div>
          )}
        </div>
        <div className="actionTile-description">
          <p>{action.description}</p>
        </div>
        {[ActionTileType.unlimited, ActionTileType.unlimitedDecider].includes(type) && (
        <div className="action-attribute d-flex flex-column mt-2">
          <div className="d-flex">
            {action.etat !== 'S' && (
              <div
                className="action-attribute-chip"
              >
                <div style={{ backgroundColor: chipAttributes(action.etat).bgColor }} />
                <span>
                  {chipAttributes(action.etat).text}
                </span>
              </div>
            )}
            <div className={`action-attribute-chip ${action.retard && action.statut === Statut.TODO && 'late'}`}>
              <PopOver popContent={getTileDate(action).type}>
                <span>{getTileDate(action).value}</span>
              </PopOver>
            </div>
          </div>
          <div className="delimiter my-2" />
          <div className="d-flex justify-content-between">
            <div className="action-attribute-weather d-flex align-items-center">
              <strong className="mr-2">{terms.actionPlan.actionTile.weatherPA}</strong>
              <WeatherIcon weather={weather(action.meteo_pilote)} />
            </div>
            <div className="action-attribute-weather d-flex align-items-center">
              <strong className="mr-2">{terms.actionPlan.actionTile.weatherA}</strong>
              <WeatherIcon weather={weather(action.meteo_animateur)} />
            </div>
          </div>
        </div>
        )}
        {type === ActionTileType.plusplus && (
        <>
          <div className="actionTile-atribute">

            <div className="actionTile-atribute-status">
              <div className="actionTile-atribute-status-isDo">
                <div style={{ backgroundColor: chipAttributes(action.statut).bgColor }} />
                <span>
                  {chipAttributes(action.statut).text}
                </span>
              </div>
              {action.etat !== 'S' && (
                <div
                  className="actionTile-atribute-status-isValid"
                >
                  <div style={{ backgroundColor: chipAttributes(action.etat).bgColor }} />
                  <span>
                    {chipAttributes(action.etat).text}
                  </span>
                </div>
              )}
            </div>
            <div className="actionTile-atribute-meteo">
              <div className="actionTile-atribute-meteo-pa">
                <span>
                  {terms.actionPlan.actionTile.weatherPA}
                </span>
                <WeatherIcon weather={weather(action.meteo_pilote)} />
              </div>
              <div className="actionTile-atribute-meteo-a">
                <span>
                  {terms.actionPlan.actionTile.weatherA}
                </span>
                <WeatherIcon weather={weather(action.meteo_animateur)} />
              </div>
            </div>
          </div>
          <div className="actionTile-identity">
            <div className="actionTile-identity-name-container">
              <div className="actionTile-identity-name">
                {action.pilote ? (
                  <>
                    <span
                      style={{
                        background: backgroundColorContributor(action.pilote.id),
                      }}
                    >
                      <span>
                        {`${action.pilote.lastName[0].toUpperCase()}${action.pilote.firstName[0].toUpperCase()}`}
                      </span>
                    </span>
                    <div className="-item">
                      <div className="actionTile-identity-name-item-text">
                        {`${action.pilote.lastName.toUpperCase()} ${action.pilote.firstName}`}
                      </div>
                    </div>
                  </>
                ) : (
                  <p className="actionTile-identity-name-no-pilot">
                    {terms.actionPlan.modalAddAction.tileAction.noPilot}
                  </p>
                )}
              </div>
              <div className="actionTile-identity-name-copilote">
                {action.copilote ? (
                  <>
                    <span
                      style={{
                        background: backgroundColorContributor(action.copilote.id),
                      }}
                    >
                      <span>
                        {`${action.copilote.lastName[0].toUpperCase()}${action.copilote.firstName[0].toUpperCase()}`}
                      </span>
                    </span>
                    <div className="actionTile-identity-name-item">
                      <div className="actionTile-identity-name-item-text">
                        {`${action.copilote.lastName.toUpperCase()} ${action.copilote.firstName}`}
                      </div>
                    </div>
                  </>
                ) : (
                  <p className="actionTile-identity-name-no-pilot">
                    {terms.actionPlan.modalAddAction.tileAction.noCoPilot}
                  </p>
                )}
              </div>
            </div>
            <div className="actionTile-identity-date">
              <div className="actionTile-identity-date-open">
                <span className="actionTile-identity-date-open-title">
                  {terms.actionPlan.modalAddAction.tileAction.dateOpen}
                </span>
                <span className="actionTile-identity-date-open-item">
                  {action.date_ouverture ? dateToDisplay(action.date_ouverture).date
                    : terms.actionPlan.modalAddAction.tileAction.noDate}
                </span>
              </div>
              <div className="actionTile-identity-date-close">
                <span className="actionTile-identity-date-close-title">
                  {terms.actionPlan.modalAddAction.tileAction.dateClose}
                </span>
                <span className="actionTile-identity-date-close-operator">
                  =
                </span>
                <span className="actionTile-identity-date-close-item">
                  {action.date_bouclage ? dateToDisplay(action.date_bouclage).date
                    : terms.actionPlan.modalAddAction.tileAction.noDate}
                </span>
              </div>
            </div>
          </div>
          <div className="actionTile-identity-entities">
            {action.entite.length > 0 ? (
              <strong>{terms.actionPlan.modalActionCopile.entities}</strong>
            ) : (
              <strong>{terms.actionPlan.modalActionCopile.noEntities}</strong>
            )}
            <div className="actionTile-identity-entities-container">
              {action.entite.map(entite => (
                <div className="actionTile-identity-entities-item" key={entite.id}>
                  {entite.libelle}
                </div>
              ))}
            </div>

          </div>
        </>

        )}
      </button>
    </>
  )
}

ActionTile.defaultProps = {
  handleClick: undefined,
  canClone: false,
  handleClone: undefined,
  clonableObjects: [],
}

export default ActionTile
