import {
  getComposants,
  getConstructionSites,
  getContributorFunctions,
  getEntity,
  getProductionZones,
  loadPage,
  postComposant,
  postContributorFunction,
  postEntity,
  postProductionZone,
} from 'reducers/Admin/admin.thunk'
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import {
  AdminState, Error,
} from './types'
import { getEic } from './Eic/eic.thunk'
import { searchComplexes } from './Complexes/complexes.thunk'
import { getAxes } from './Axes/axes.thunk'

const initialState: AdminState = {
  isLoading: false,
  expandedElement: '',
}

export const admin = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    closeModal: state => {
      state.errors = undefined
    },
    setExpandedElement: (state, action: PayloadAction<string>) => {
      state.expandedElement = action.payload
    },
  },

  extraReducers: builder => {
    builder.addCase(loadPage.pending, state => {
      state.isLoading = true
    })
    builder.addCase(loadPage.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(loadPage.fulfilled, state => {
      state.isLoading = false
    })
    builder.addMatcher(isAnyOf(
      getComposants.rejected,
      getConstructionSites.rejected,
      getContributorFunctions.rejected,
      getEic.rejected,
      getAxes.rejected,
      getEntity.rejected,
      getProductionZones.rejected,
      searchComplexes.rejected,
      postEntity.rejected,
      postComposant.rejected,
      postContributorFunction.rejected,
      postProductionZone.rejected,
    ), (state, action) => {
      const payload = action.payload as Error
      if (payload) {
        if (Array.isArray(payload?.data)) {
          state.errors = [...(state.errors || []), ...payload.data]
          return
        }
        state.errors = payload?.data?.libelle
        if (payload.data.abrev) {
          state.errors = [...(state.errors || []), ...payload.data.abrev]
        }
      }
    })
  },
})

export const { closeModal, setExpandedElement } = admin.actions

export default admin.reducer
