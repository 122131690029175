import { ReactElement, useEffect, useState } from 'react'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import './Accordion.scss'
import { Divider } from '@mui/material'
import { FaMinusSquare, FaPlusCircle } from 'react-icons/fa'
import Loader from 'components/Loader'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { setExpandedElement } from 'reducers/Admin/admin.reducer'
import { RootState } from 'Store'
import { getExpandIcon } from './utils'
import {
  Accordion, AccordionDetails, AccordionSummary, ListAccordion, ListAccordionDetails,
} from './AccordionStyle'

export type Element = {
    libelle: string;
    id?: string | number;
    [key: string]: unknown;
}

type Props<T> = {
    name: string;
    elementList: T[];
    children?: (value: T, index: number, elements: T[]) => ReactElement,
    onAddEntity?: () => void
    onDeleteEntity?: () => void
    newElementWording?: string
    isLoading?: boolean
    searchElement?: ReactElement
    displaySnackbar?: boolean
}

export default function RobustestAccordion<T extends Element>({
  name, elementList, children, onAddEntity, onDeleteEntity, isLoading, searchElement,
  newElementWording, displaySnackbar,
} : Props<T>): ReactElement {
  const dispatch = useDispatch()
  const { expandedElement } = useSelector((state: RootState) => state.admin)
  const [previousExpandedId, setPreviousExpandedId] = useState<string | number>('')
  const [expandedId, setExpandedId] = useState<string | number>('')

  useEffect(() => {
    if (displaySnackbar) {
      setExpandedId('')
      setPreviousExpandedId('')
    }
  }, [displaySnackbar])

  return (
    <Accordion
      expanded={expandedElement === name}
      onChange={() => {
        dispatch(setExpandedElement(expandedElement === name ? '' : name))
      }}
    >
      <AccordionSummary
        expandIcon={<FaMinusSquare size="18px" />}
        aria-controls={`${name}-content`}
        id={`${name}-header`}
        className={searchElement ? 'search' : ''}
      >
        <Typography><strong>{name}</strong></Typography>
        {searchElement}
      </AccordionSummary>
      {expandedElement === name && (
      <AccordionDetails>
        <>
          {isLoading ? <Loader message={terms.Common.loading} /> : (
            <ul className="list">
              {elementList.map((element, index, elements) => (
                <li key={element.id || element.libelle} className="list-item">
                  <ListAccordion
                    square
                    expanded={expandedId === element.id}
                    onChange={() => {
                      setPreviousExpandedId(expandedId)
                      setExpandedId(element.id === expandedId ? '' : element.id || '')
                    }}
                    className={children ? '' : 'no-expand'}
                  >
                    <MuiAccordionSummary
                      expandIcon={getExpandIcon(element, !!children, onDeleteEntity)}
                      aria-controls={`${name}-content`}
                      id={`${name}-header`}
                      // disable the animation
                      sx={{
                        '& .MuiAccordionSummary-expandIconWrapper': {
                          transition: 'none',
                          '&.Mui-expanded': {
                            transform: 'none',
                          },
                        },
                      }}
                    >
                      <Typography className={element.newId || element.id !== 'new' ? '' : 'new'}>
                        {element.customLibelle
                          ? (
                            // eslint-disable-next-line react/no-danger
                            <span dangerouslySetInnerHTML={{
                              __html: `${element.customLibelle} ${element.libelle ? ''
                                : newElementWording}`,
                            }}
                            />
                          )
                          : element.libelle || newElementWording}
                      </Typography>
                    </MuiAccordionSummary>
                    {children && (expandedId === element.id || previousExpandedId === element.id) && (
                      <ListAccordionDetails>
                        <Divider variant="middle" className="w-75 divider" />
                        {children(element, index, elements)}
                      </ListAccordionDetails>
                    )}
                  </ListAccordion>
                </li>
              ))}
            </ul>
          )}
          {onAddEntity && (
          <div className="d-flex align-items-center justify-content-end">
            <button className="btn" type="button" onClick={onAddEntity}>
              <FaPlusCircle size="18px" />
            </button>
          </div>
          )}
        </>
      </AccordionDetails>
      )}
    </Accordion>
  )
}

RobustestAccordion.defaultProps = {
  children: undefined,
  onAddEntity: undefined,
  onDeleteEntity: undefined,
  newElementWording: '',
  isLoading: true,
  searchElement: undefined,
  displaySnackbar: false,
}
