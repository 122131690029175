import {
  faSun, faCloud, faCloudRain, faQuestion, faThunderstorm,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement } from 'react'
import { Weather } from 'reducers/ActionPlan/types'
import './weatherIcon.scss'

const WeatherIcon = ({ weather }: { weather: Weather }): ReactElement => {
  const getIcon = () => {
    switch (weather) {
      case Weather.SUNNY:
        return faSun
      case Weather.CLOUDY:
        return faCloud
      case Weather.RAINY:
        return faCloudRain
      case Weather.STORMY:
        return faThunderstorm
      default:
        return faQuestion
    }
  }

  return (
    <div className={`weather-icon ${weather || ''}`}>
      <FontAwesomeIcon icon={getIcon()} />
    </div>
  )
}

export default WeatherIcon
