import terms from 'common/terms'
import { dateToDisplay } from 'pages/ActionPlan/utils'
import { backgroundColorContributor } from 'pages/Suivi/utils'
import { ReactElement } from 'react'
import { UserSimple } from 'reducers/Users/types'

interface Props {
  pilote: UserSimple | null
  copilote: UserSimple | null
  dateOpen: string | null
  dateClose: string | null
}

const DateAndUser = ({
  pilote, copilote, dateOpen, dateClose,
}: Props): ReactElement => (
  <div className="date-and-user">
    <div className="user">
      {pilote && (
      <div className="pilote">
        <span
          style={{
            background: backgroundColorContributor(pilote.id),
          }}
        >
          {`${pilote.lastName[0].toUpperCase()}${pilote.firstName[0].toUpperCase()}`}
        </span>
        <div className="name">
          <div className="text">
            {`${pilote.lastName.toUpperCase()} ${pilote.firstName}`}
          </div>
        </div>
      </div>
      )}
      {copilote && (
      <div className="copilote">
        <span
          style={{
            background: backgroundColorContributor(copilote.id),
          }}
        >
          {`${copilote.lastName[0].toUpperCase()}${copilote.firstName[0].toUpperCase()}`}
        </span>
        <div className="name">
          <div className="text">
            {`${copilote?.lastName.toUpperCase()} ${copilote?.firstName}`}
          </div>
        </div>
      </div>
      )}
    </div>
    <div className="date">
      <div className="date-open">
        <span className="title">
          {terms.actionPlan.modalAddAction.tileAction.dateOpen}
        </span>
        <span className="item">
          {dateOpen ? dateToDisplay(dateOpen).date
            : terms.actionPlan.modalAddAction.tileAction.noDate}
        </span>
      </div>
      <div className="date-close">
        <span className="title">
          {terms.actionPlan.modalAddAction.tileAction.dateClose}
        </span>
        <span className="operator">
          =
        </span>
        <span className="item">
          {dateClose ? dateToDisplay(dateClose).date
            : terms.actionPlan.modalAddAction.tileAction.noDate}
        </span>
      </div>
    </div>
  </div>
)

export default DateAndUser
