import { TextField } from '@mui/material'
import { ChangeEvent, ReactElement } from 'react'

import './ContributionImpersonate.scss'

interface EditorTextFieldProps {
  disabled: boolean
  label: string
  placeholder: string
  value: string
  handleChange: (evt: ChangeEvent<HTMLTextAreaElement>) => void
  row?: number
}

const EditorTextField = ({
  disabled, label, placeholder, value, handleChange, row,
}: EditorTextFieldProps): ReactElement => (
  <>
    <span className="editor mb-1">
      {label}
    </span>
    <TextField
      className="contribution-field mb-3"
      disabled={disabled}
      multiline
      rows={row}
      placeholder={placeholder}
      variant="standard"
      InputProps={{
        disableUnderline: true,
      }}
      value={value}
      onChange={handleChange}
    />
  </>
)

EditorTextField.defaultProps = {
  row: 6,
}

export default EditorTextField
