import terms from 'common/terms'
import CustomTabJalon from 'components/CustomTabJalon/CustomTabJalon'
import Loader from 'components/Loader'
import {
  ReactElement, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProductionZones } from 'reducers/Admin/admin.thunk'
import { getMonitoringDzp } from 'reducers/monitoring/monitoring.thunk'
import { RootState } from 'Store'
import DzpCotationSelects from './DzpCotationSelects/DzpCotationSelects'
import DzpCotationTable from './DzpCotationTable/DzpCotationTable'

import './ZpCotation.scss'

const ZpCotation = (): ReactElement => {
  const dispatch = useDispatch()
  const { zpMonitorings, isLoadingData } = useSelector((state: RootState) => state.monitoring)
  const [activeJalon, setActiveJalon] = useState(0)

  const handleChangeJalon = (jalon: number) => {
    setActiveJalon(jalon)
    localStorage.setItem('monitoringDZPjalon', (jalon + 1).toString())
    const monitoringDZPyear = localStorage.getItem('monitoringDZPyear')
    const monitoringDZPeic = localStorage.getItem('monitoringDZPeic')
    if (monitoringDZPyear && monitoringDZPeic) {
      dispatch(getMonitoringDzp({
        eic: monitoringDZPeic.split(','),
        annee: monitoringDZPyear,
        numero_jalon: jalon + 1,
      }))
    }
  }

  useEffect(() => {
    dispatch(getProductionZones())
  }, [])

  return (
    <div className="zp-cotation">
      <div className="head">
        <h2>
          {terms.Monitoring.Dzp.title}
        </h2>
        <DzpCotationSelects activeJalon={activeJalon} setActiveJalon={setActiveJalon} />
      </div>
      <CustomTabJalon
        activeJalon={activeJalon}
        setActiveJalon={handleChangeJalon}
      >
        {
          isLoadingData ? (
            <div className="loading">
              <Loader />
            </div>
          ) : (
            <div className="wrapper-table">
              {
                (zpMonitorings.length > 0) ? (
                  <DzpCotationTable />
                ) : (
                  <div className="no-data">
                    <div>
                      {terms.Monitoring.Dzp.noData}
                    </div>
                  </div>
                )
              }
            </div>
          )
        }

      </CustomTabJalon>
    </div>
  )
}

export default ZpCotation
