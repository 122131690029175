import ModalWrapper from 'components/Modal/ModalWrapper/ModalWrapper'
import { ReactElement } from 'react'
import { PortfolioItem } from 'reducers/Admin/Portfolio/type'
import ErrorIcon from '@mui/icons-material/Error'
import terms from 'common/terms'
import { useDispatch } from 'react-redux'
import { deletePortfolio } from 'reducers/Admin/Portfolio/portfolio.thunk'

import './modal.scss'

interface Props {
  portfolio: PortfolioItem
  handleClose: () => void
  open: boolean
}

const ModalDelete = ({ portfolio, handleClose, open }: Props): ReactElement => {
  const dispatch = useDispatch()

  return (
    <ModalWrapper
      open={open}
      onClose={handleClose}
      className="modal-delete-wrapper"
    >
      <div className="modal-delete">
        <div className="title">
          <div className="icon">
            <ErrorIcon className="item" />
          </div>
          <div className="content">
            <span>
              {terms.ParametragePortfolio.delete.title}
            </span>
            <span className="libelle">
              {`"${portfolio.libelle}" ?`}
            </span>
          </div>
        </div>
        <div className="buttons">
          <button
            type="button"
            className="cancel"
            onClick={handleClose}
          >
            {terms.Common.cancel}
          </button>
          <button
            type="button"
            className="delete"
            onClick={() => dispatch(deletePortfolio(portfolio.id))}
          >
            {terms.Common.delete}
          </button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default ModalDelete
