import {
  Table, TableBody, TableContainer,
} from '@mui/material'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import DzpCotationTableHeader from './DzpCotationTableHeader/DzpCotationTableHeader'

import './DzpCotationTable.scss'
import DzpCotationTableBody from './DzpCotationTableBody/DzpCotationTableBody'
import DzpCotationTableFooter from './DzpCotationTableFooter/DzpCotationTableFooter'

const DzpCotationTable = (): ReactElement => {
  const { zpMonitorings } = useSelector((state: RootState) => state.monitoring)

  return (
    <div className="dzp-cotation-table">
      <TableContainer className="table-container">
        <Table stickyHeader>
          <DzpCotationTableHeader data={zpMonitorings} />
          <TableBody>
            <DzpCotationTableBody zpMonitorings={zpMonitorings} />
          </TableBody>
          <DzpCotationTableFooter zpMonitorings={zpMonitorings} />
        </Table>
      </TableContainer>
    </div>
  )
}

export default DzpCotationTable
