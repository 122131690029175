import { Autocomplete, Stack, TextField } from '@mui/material'
import { Save, Close, Refresh } from '@mui/icons-material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { Mail } from 'reducers/Suivi/types'

interface MailFormProps {
  mailContent: string
  mailObject: string
  savedMails: Mail[]
  handleMailContentChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  handleMailObjectChange: (event: React.SyntheticEvent, value: string | Mail | null, reason: string) => void
  handlePostMail: () => void
  handleRefreshMail: () => void
  handleDeleteMail: () => void
  disabledRefresh: boolean
  disabledDelete: boolean
  disabledSave: boolean
}

const MailForm = ({
  mailContent, mailObject, handleMailContentChange, handleMailObjectChange, handlePostMail, handleDeleteMail,
  handleRefreshMail, disabledRefresh, disabledDelete, disabledSave, savedMails,
}: MailFormProps): ReactElement => (
  <div className="mail-form w-100">
    <div className="d-flex">
      <span className="send-mail-section-title mb-2">
        {terms.Suivi.mailTerms.mail}
      </span>
    </div>
    <div className="d-flex justify-content-between w-100 mb-4">
      <div className="d-flex flex-column w-100">
        <span className="mb-2">
          {terms.Suivi.mailTerms.object}
        </span>
        <div className="d-flex w-100">
          <Autocomplete
            className="mail-object-select mb-3"
            freeSolo
            options={savedMails}
            renderInput={
              params => (
                <TextField
                  {...params}
                  className="mail-form-field"
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                  value={mailObject}
                />
              )
            }
            getOptionLabel={option => (typeof option === 'string' ? option : option.objet)}
            onChange={handleMailObjectChange}
            onInputChange={handleMailObjectChange}
            value={mailObject}
          />
          <Stack direction="row" spacing={2} className="d-flex align-items-center mail-form-actions mb-3 ml-3">
            <button
              onClick={handleRefreshMail}
              className={`btn-rounded btn-refresh ${disabledRefresh ? 'disabled' : ''}`}
              type="button"
              disabled={disabledRefresh}
            >
              <Refresh fontSize="medium" />
            </button>
            <button
              onClick={handleDeleteMail}
              className={`btn-rounded btn-delete ${disabledDelete ? 'disabled' : ''}`}
              type="button"
              disabled={disabledDelete}
            >
              <Close fontSize="medium" />
            </button>
            <button
              onClick={handlePostMail}
              className={`btn-rounded btn-save ${disabledSave ? 'disabled' : ''}`}
              type="button"
              disabled={disabledSave}
            >
              <Save fontSize="medium" />
            </button>
          </Stack>
        </div>
        <span className="mb-2">
          {terms.Suivi.mailTerms.content}
        </span>
        <TextField
          className="mail-form-field mb-3"
          multiline
          rows={6}
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
          value={mailContent}
          onChange={handleMailContentChange}
        />
      </div>
    </div>
  </div>
)

export default MailForm
