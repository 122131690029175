import { Typography } from '@mui/material'
import terms from 'common/terms'
import AntSwitch from 'components/AntSwitch/AntSwitch'
import TabPanel from 'components/Jalons/TabPanel'
import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { changeJalon } from 'reducers/Admin/Composant/composant'
import { Composant } from 'reducers/Admin/Composant/types'
import JalonDescription from './JalonDescription'
import JalonPerimeter from './JalonPerimeter'

export default function ComposantJalon({ composant, value } : {composant: Composant, value: number}): ReactElement {
  const dispatch = useDispatch()
  return (
    <>
      {
          composant.jalons.map(jalon => (
            <TabPanel value={value} key={jalon.numero_jalon} index={jalon.numero_jalon}>
              {value !== 0 && (<JalonPerimeter jalon={jalon} composantId={composant.id} />)}
              <JalonDescription jalon={jalon} composant={composant} />
              <div className="d-flex">
                <label className="d-flex align-items-center" htmlFor={`jalon ${jalon.active_jalon} ${composant.id}`}>
                  <AntSwitch
                    id={`jalon ${jalon.active_jalon} ${composant.id}`}
                    checked={jalon.active_jalon}
                    inputProps={{ 'aria-label': 'activée' }}
                    onChange={() => dispatch(changeJalon(
                      { jalon: { ...jalon, active_jalon: !jalon.active_jalon }, composant },
                    ))}
                  />
                  <Typography className="switch switch-jalon ml-2">
                    {jalon.active_jalon ? terms.Admin.composants.on
                      : terms.Admin.composants.off }
                  </Typography>
                </label>
              </div>
            </TabPanel>
          ))
        }

    </>
  )
}
