import terms from 'common/terms'
import { ReactElement } from 'react'
import './displayToggle.scss'

interface DisplayToggleProps {
  displayToggle: boolean,
  setDisplayToggle: (displayToggle: boolean) => void,
}

const DisplayToggle = ({
  displayToggle, setDisplayToggle,
}: DisplayToggleProps): ReactElement => (
  <div className="display-toggle">
    <div className="display-toggle-options-control d-flex">
      <label htmlFor="display-toggle-1" className={displayToggle ? 'checked' : ''}>
        {terms.actionPlan.displayToggle.roadmap}
        <input
          type="radio"
          id="display-toggle-1"
          name="display-toggle"
          className="sr-only"
          onChange={evt => setDisplayToggle(evt.target.checked)}
          checked={displayToggle}
        />
      </label>
      <label htmlFor="display-toggle-2" className={displayToggle ? '' : 'checked'}>
        {terms.actionPlan.displayToggle.copil}
        <input
          type="radio"
          id="display-toggle-2"
          name="display-toggle"
          className="sr-only"
          onChange={evt => setDisplayToggle(!evt.target.checked)}
          checked={!displayToggle}
        />
      </label>
    </div>

  </div>
)

export default DisplayToggle
