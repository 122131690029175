import { Box, Tab } from '@mui/material'
import { StyledTabs } from 'components/Jalons/JalonStyle'
import { PropsWithChildren, ReactElement } from 'react'
import { GiCircle, GiPlainCircle } from 'react-icons/gi'

interface Props {
  activeJalon: number
  setActiveJalon: (value: number) => void
}

const CustomTabJalon = ({ children, activeJalon, setActiveJalon }: PropsWithChildren<Props>): ReactElement => {
  const jalons = [1, 2, 3, 4]
  return (
    <div className="d-flex" style={{ height: 'calc(100vh - var(--topbar-height) - 114px)', width: '100%' }}>
      <Box
        sx={{
          flexGrow: 1, bgcolor: 'background.paper', display: 'flex',
        }}
        style={{ height: 'calc(100vh - var(--topbar-height) - 54px)', width: '100%' }}
        className="suivi-jalon-side-tabs d-flex"
      >
        <StyledTabs
          value={activeJalon}
          onChange={(_e, newValue) => setActiveJalon(newValue)}
          {...{ orientation: 'vertical' }}
        >
          {jalons.map(jalon => (
            <Tab
              key={jalon}
              icon={activeJalon + 1 === jalon ? <GiPlainCircle /> : <GiCircle />}
              label={`J${jalon}`}
              className="jalon-tab-button"
            />
          ))}
        </StyledTabs>
        {children}
      </Box>
    </div>
  )
}

export default CustomTabJalon
