import { ReactElement } from 'react'
import CancelIcon from '@mui/icons-material/Cancel'

export type SettingsTabListElement = {
  id: string,
  cerbereId: string,
  firstName: string,
  lastName: string,
  entityName: string,
  entityId: string,
}

export interface SettingsTabListProps {
  items: SettingsTabListElement[],
  emptyLabel: string,
  handleDelete: (itemId?: string) => () => void,
  handleClickUser: (userId: string) => void,
}

const SettingsTabList = ({
  items, emptyLabel, handleDelete, handleClickUser,
} : SettingsTabListProps): ReactElement => (
  <>
    {items.length === 0 ? (
      <div className="no-item">
        {emptyLabel}
      </div>
    ) : (
      <ul className="settings-tab-list">
        {items.map(item => (
          <li key={item.id} className="settings-tab-list-item d-flex">
            <div className="settings-tab-list-item-container w-100">
              <button
                className="settings-tab-list-item-delete"
                type="button"
                onClick={handleDelete(item?.id)}
              >
                <CancelIcon fontSize="medium" sx={{ color: '#4d4f53' }} />
              </button>
              <div className="item-name">
                <button type="button" className="button-infos" onClick={() => handleClickUser(item.cerbereId)}>
                  {`${item.lastName} ${item.firstName}`}
                </button>
              </div>
              <div className="item-function">{item.entityName}</div>
            </div>
          </li>
        ))}
      </ul>
    )}
  </>
)

export default SettingsTabList
