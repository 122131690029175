import { ReactElement } from 'react'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { changeListLignes } from 'reducers/Parametrage/ParamLignes/paramLignes.reducers'
import { patchSaLigne } from 'reducers/Parametrage/ParamLignes/paramLignes.thunk'
import { ParamLigne } from 'reducers/Parametrage/ParamLignes/types'
import { RootState } from 'Store'
import ColumnsLignes from '../ColumnsLignes/ColumnsLignes'

const DragLignes = (): ReactElement => {
  const dispatch = useDispatch()
  const { columns, listLignesSa } = useSelector((state: RootState) => state.paramLignes)
  const { idSA } = useSelector((state: RootState) => state.app)

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result

    if (!destination) {
      return
    }

    if (
      destination.droppableId === source.droppableId
      && destination.index === source.index
    ) {
      return
    }
    const listStart = columns.filter(item => item.id === source.droppableId)
    const listFinish = columns.filter(item => item.id === destination.droppableId)
    if (listStart[0].id !== listFinish[0].id) {
      const startObj = listStart[0].lignesList

      if (listStart[0].id === '1') {
        startObj.map((item: ParamLigne) => {
          let nItem = {}
          nItem = {
            ...item,
            active: false,
          }

          return nItem
        })
      } else {
        startObj.map((item: ParamLigne) => {
          let nItem = {}
          nItem = {
            ...item,
            active: true,
          }

          return nItem
        })
      }

      const newStart = {
        ...listStart[0],
        lignesList: startObj.filter(i => i.id !== draggableId),
      }

      const finishObj: ParamLigne[] = []
      finishObj.push(...listFinish[0].lignesList)
      const dragObject = listLignesSa?.filter(item => item.id === draggableId)

      if (dragObject) {
        finishObj.push(dragObject[0])
      }

      if (listFinish[0].id === '1') {
        if (dragObject) {
          const objUpdate = {
            idSA,
            idLigne: dragObject[0].id,
            ligne: {
              active: false,
            },
          }
          dispatch(patchSaLigne(objUpdate))
        }

        finishObj.map((item: ParamLigne) => {
          let nItem = {}
          nItem = {
            ...item,
            active: false,
          }

          return nItem
        })
      } else {
        if (dragObject) {
          dispatch(patchSaLigne({
            idSA,
            idLigne: dragObject[0].id,
            ligne: {
              active: true,
            },
          }))
        }

        finishObj.map((item: ParamLigne) => {
          let nItem = {}
          nItem = {
            ...item,
            active: true,
          }

          return nItem
        })
      }
      const newFinish = {
        ...listFinish[0],
        lignesList: finishObj,
      }

      const newlist = [
        newStart,
        newFinish,
      ].sort((a, b) => {
        if (a.id < b.id) { return -1 }
        if (a.id > b.id) { return 1 }
        return 0
      })

      const newState = [
        ...newlist,
      ]
      dispatch(changeListLignes(newState))
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {columns.map(listId => {
        const objectFer = listId.lignesList
        return (
          <ColumnsLignes
            key={listId.id ? listId.id : '1'}
            listId={listId.id}
            objectFer={objectFer}
          />
        )
      })}
    </DragDropContext>
  )
}

export default DragLignes
