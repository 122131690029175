import { FormControl, FormLabel, OutlinedInput } from '@mui/material'
import PopOver from 'components/PopOver/PopOver'
import { ReactElement } from 'react'

interface Props {
  inputValue: string
  setInputValue: (inputValue: string) => void
  label?: string
  popOverContent?: string
  required?: boolean
}

const CustomTextField = ({
  inputValue, setInputValue, label, popOverContent, required,
}: Props): ReactElement => (
  <FormControl fullWidth>
    {
      label && (
        <PopOver
          popContent={popOverContent}
          hidden={!popOverContent}
          anchorOriginHorizontal="left"
        >
          <FormLabel
            htmlFor="outlined-adornment-amount"
            required={required}
          >
            {label}
          </FormLabel>
        </PopOver>

      )
    }
    <OutlinedInput
      id="outlined-controlled"
      size="small"
      fullWidth
      value={inputValue}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value)
      }}
    />
  </FormControl>
)

CustomTextField.defaultProps = {
  label: undefined,
  popOverContent: undefined,
  required: false,
}

export default CustomTextField
