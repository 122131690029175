import { Popover, PopoverOrigin, Typography } from '@mui/material'
import { ReactElement, ReactNode, useState } from 'react'

export type PopOverProps = {
  children: ReactNode,
  popContent: string | undefined,
  hidden?: boolean,
  anchorOriginVertical?: PopoverOrigin['vertical'],
  anchorOriginHorizontal?: PopoverOrigin['horizontal'],
}

const PopOver = ({
  children,
  popContent,
  hidden,
  anchorOriginVertical,
  anchorOriginHorizontal,
}: PopOverProps): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const openPop = Boolean(anchorEl)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <div
        // aria-owns={openPop ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className="pop-over-div"
      >
        {children}
      </div>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={openPop}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: anchorOriginVertical as PopoverOrigin['vertical'],
          horizontal: anchorOriginHorizontal as PopoverOrigin['horizontal'],
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        disableRestoreFocus
        disableScrollLock
        hidden={hidden}
      >
        <Typography
          className="popup-span"
          sx={{ p: 1 }}
        >
          {popContent}
        </Typography>
      </Popover>
    </>
  )
}

PopOver.defaultProps = {
  hidden: false,
  anchorOriginVertical: 'top',
  anchorOriginHorizontal: 'center',
}

export default PopOver
