import terms from 'common/terms'

export enum JalonStatus {
    Coming = 'AV',
    OnGoing = 'EC',
    ToBeValidated = 'ECV',
    Closed = 'C',
}

export const Status = [
  {
    status: JalonStatus.Coming,
    libelle: terms.Suivi.coming,
    color: '',
    message: terms.Suivi.comingNotice,
  },
  {
    status: JalonStatus.OnGoing,
    libelle: terms.Suivi.onGoing,
    color: '#9bc5da',
    message: terms.Suivi.onGoingNotice,
  },
  {
    status: JalonStatus.ToBeValidated,
    libelle: terms.Suivi.toBeValidated,
    color: '#e05206',
    message: terms.Suivi.toBeValidatedNotice,
  },
  {
    status: JalonStatus.Closed,
    libelle: terms.Suivi.closed,
    color: '#3c3732',
    message: terms.Suivi.closedNotice,
  },
]
