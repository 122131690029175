import { InputBase, Paper } from '@mui/material'
import terms from 'common/terms'
import { ReactElement, useCallback } from 'react'
import { debounce } from 'lodash'
import { FaSearch } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { searchComplexes } from 'reducers/Admin/Complexes/complexes.thunk'

export default function SearchComplexes(): ReactElement {
  const dispatch = useDispatch()
  const debouncedSearch = useCallback(debounce(value => dispatch(searchComplexes(value)), 250), [])

  return (
    <Paper
      component="form"
      sx={{
        p: '2px 4px', display: 'flex', alignItems: 'center', width: 240, height: 34,
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={terms.Admin.complexes.searchPlaceholder}
        onClick={evt => evt.stopPropagation()}
        onChange={evt => {
          debouncedSearch(evt.target.value)
        }}
      />
      <FaSearch size="15px" onClick={evt => evt.stopPropagation()} className="search-icon" />
    </Paper>
  )
}
