import { TableHead, TableRow, TableCell } from '@mui/material'
import terms from 'common/terms'
import PopOver from 'components/PopOver/PopOver'
import { dateToDisplay } from 'pages/ActionPlan/utils'
import { Status } from 'pages/Suivi/JalonStatus'
import { ReactElement } from 'react'
import { ZpMonitoring } from 'reducers/monitoring/types'
import { v4 as uuidv4 } from 'uuid'

interface Props {
  data: ZpMonitoring[];
}

const DzpCotationTableHeader = ({ data }: Props): ReactElement => (
  <TableHead>
    <TableRow>
      <TableCell className="sticky-header bold separate">{terms.Monitoring.Dzp.Table.component}</TableCell>
      {
        data.map(column => (
          <TableCell className="separate" key={uuidv4()}>
            <div className="title">
              <div className="wrapper">
                <PopOver
                  popContent={column.libelle}
                >
                  <span className="type">
                    {column.type}
                  </span>
                  <span className="abrev">
                    {column.abrev}
                  </span>
                </PopOver>
                <PopOver
                  popContent={Status.find(
                    item => item.status === column.status_jalon,
                  )?.libelle || ''}
                >
                  <span
                    className="status"
                    style={{
                      backgroundColor: Status.find(
                        item => item.status === column.status_jalon,
                      )?.color || 'white',
                      borderColor: Status.find(
                        item => item.status === column.status_jalon,
                      )?.color || 'black',
                    }}
                  />
                </PopOver>
              </div>
              <div>
                <PopOver
                  popContent={column.libelle_eic}
                >
                  <span className="eic">
                    {column.libelle_eic}
                  </span>
                </PopOver>

                <span className="date">
                  {dateToDisplay(column.date_copil).date}
                </span>
              </div>
            </div>
          </TableCell>
        ))
      }
    </TableRow>
  </TableHead>
)

export default DzpCotationTableHeader
