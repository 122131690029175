import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserRole, UserSimple } from 'reducers/Users/types'
import { SaJalon } from 'reducers/SA/types'
import { Status } from 'pages/Suivi/JalonStatus'
import {
  SettingPdfList, SettingPdfToggled, FilterContributorActionPayload, SuiviState, SendMailUser, Mail, SelectedObject,
} from './types'
import {
  deleteSaSavedMail,
  fetchDataToCreatePdf,
  getPossibleUsersEmailBySa,
  getSAJalonByNumber, getSaJalonNbObject, getSAJalonNbObjectArray, getSaSavedMails, getToggleDisplayAllComponent,
  getUsersContributeurs, patchSaJalonObjectFerroviaire, postSaSavedMail, postToggleDisplayAllComponent,
} from './suivi.thunk'

const initialState: SuiviState = {
  listUserContributeur: [],
  filterContributeur: null,
  isToggle: false,
  toggleComponentInArray: false,
  settingPdfToggled: {
    globalAnimators: false,
    specificAnimators: false,
    contributors: false,
    display: undefined,
  },
  settingPdfList: {
    complexes: [],
    lignes: [],
    travaux: [],
    entites: [],
  },
  listComponentPerObject: [],
  listComponentPerObjectFiltered: [],
  listComponentPerObjectArray: [],
  listComponentPerObjectArrayFiltered: [],
  sendMailState: {
    possibleRoles: [UserRole.animator, UserRole.contributor, UserRole.decider, UserRole.entityAnimator],
    selectedRoles: [UserRole.animator, UserRole.contributor, UserRole.decider, UserRole.entityAnimator],
    possibleUsers: {
      animators: [],
      contributors: [],
      deciders: [],
      entityAnimators: [],
    },
    filteredUsers: [],
    selectedUsers: [],
    savedMails: [],
    selectedSavedMail: null,
  },
  pdfData: null,
  isLoading: false,
  isLoadingObjectFer: false,
  isLoadingObjectFerArray: false,
  jalonStatus: '',
  activeJalonNumber: 0,
  activeTab: 0,
  selectedObject: null,
  isLoadingJalon: false,
}

const removeDupeUsers = (listWithDupe: SendMailUser[]): SendMailUser[] => listWithDupe
  .filter((user, index, arr) => arr.map(mapUser => mapUser.id).indexOf(user.id) === index)

export const suivi = createSlice({
  name: 'suivi',
  initialState,
  reducers: {
    toggleSwitch: state => {
      state.isToggle = !state.isToggle
    },
    toggleSwitchComponantInArray: state => {
      state.toggleComponentInArray = !state.toggleComponentInArray
    },
    toggleSettingPdf: (state, action: PayloadAction<{
      value: boolean, stateVariable: keyof SettingPdfToggled}>) => {
      state.settingPdfToggled[action.payload.stateVariable] = action.payload.value
    },
    resetPdfData: state => {
      state.pdfData = null
    },
    setActiveTab: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload
    },
    setSelectedObject: (state, action: PayloadAction<SelectedObject | null>) => {
      state.selectedObject = action.payload
    },
    setSettingPdfList: (state, action: PayloadAction<{
      value: string,
      stateVariable: keyof SettingPdfList,
      isChecked: boolean,
    }>) => {
      if (!action.payload.isChecked) {
        state.settingPdfList[action.payload.stateVariable] = state.settingPdfList[action.payload.stateVariable]
          .filter(item => item !== action.payload.value)
      } else if (state.settingPdfList[action.payload.stateVariable].includes(action.payload.value)) {
        state.settingPdfList[action.payload.stateVariable] = [
          ...state.settingPdfList[action.payload.stateVariable],
        ]
      } else {
        state.settingPdfList[action.payload.stateVariable] = [
          ...state.settingPdfList[action.payload.stateVariable],
          action.payload.value,
        ]
      }
    },

    setFilterContributor: (state, action: PayloadAction<FilterContributorActionPayload>) => {
      const { contributor, accountId } = action.payload
      state.filterContributeur = contributor
      if (contributor && contributor.id !== accountId) {
        state.listComponentPerObjectFiltered = state.listComponentPerObject.filter(
          composant => composant.contributeurs?.some(contrib => contrib.contributeur?.id === contributor.id),
        )
        state.listComponentPerObjectArrayFiltered = state.listComponentPerObjectArray.filter(
          composant => composant.contributeurs?.some(contrib => contrib.contributeur?.id === contributor.id),
        )
      } else {
        state.listComponentPerObjectFiltered = state.listComponentPerObject
        state.listComponentPerObjectArrayFiltered = state.listComponentPerObjectArray
      }
    },
    resetComponentList: state => {
      state.listComponentPerObject = []
      state.listComponentPerObjectFiltered = []
      state.listComponentPerObjectArray = []
      state.listComponentPerObjectArrayFiltered = []
    },
    resetSettingPdf: state => {
      state.settingPdfList = {
        complexes: [],
        lignes: [],
        travaux: [],
        entites: [],
      }
      state.settingPdfToggled = {
        globalAnimators: false,
        specificAnimators: false,
        contributors: false,
        display: undefined,
      }
    },
    selectAllUsers: state => {
      state.sendMailState.selectedUsers = removeDupeUsers(state.sendMailState.filteredUsers)
    },
    unselectAllUsers: state => {
      state.sendMailState.selectedUsers = []
    },
    setSelectedRoles: (state, action: PayloadAction<UserRole[]>) => {
      state.sendMailState.selectedRoles = action.payload
      const newFilteredUsers = removeDupeUsers([
        ...(action.payload.includes(UserRole.animator) ? state.sendMailState.possibleUsers.animators : []),
        ...(action.payload.includes(UserRole.contributor) ? state.sendMailState.possibleUsers.contributors : []),
        ...(action.payload.includes(UserRole.decider) ? state.sendMailState.possibleUsers.deciders : []),
      ])
      state.sendMailState.filteredUsers = newFilteredUsers
      state.sendMailState.selectedUsers = state.sendMailState.selectedUsers.filter(user => newFilteredUsers
        .map(filteredUser => filteredUser.id).includes(user.id))
    },
    setSelectedUsers: (state, action: PayloadAction<SendMailUser[]>) => {
      state.sendMailState.selectedUsers = action.payload
    },
    setSelectedSavedMail: (state, action: PayloadAction<Mail | null>) => {
      state.sendMailState.selectedSavedMail = action.payload
    },
    setJalonStatus: (state, action: PayloadAction<string>) => {
      state.jalonStatus = action.payload
    },
    setActiveJalonNumber: (state, action: PayloadAction<number>) => {
      state.activeJalonNumber = action.payload
    },
    updateActiveJalonStatus: (state, action: PayloadAction<SaJalon[]>) => {
      const activeJalon = action.payload.find(jalon => jalon.numero_jalon === state.activeJalonNumber)
      const libelle = Status.find(status => status.status === activeJalon?.status)?.libelle
      if (libelle) {
        state.jalonStatus = libelle
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getUsersContributeurs.fulfilled, (state, { payload }) => {
      if (payload) {
        state.listUserContributeur = payload.map((i: UserSimple) => ({
          id: i.id,
          label: `${i.lastName.toUpperCase()} ${i.firstName}`,
          initials: `${i.lastName[0]}${i.firstName[0]}`,
        })).sort((a, b) => a.label.localeCompare(b.label))
      }
    })
    builder.addCase(getToggleDisplayAllComponent.fulfilled, (state, { payload }) => {
      state.isToggle = payload.all_composants
    })
    builder.addCase(postToggleDisplayAllComponent.fulfilled, (state, { payload }) => {
      state.isToggle = payload.all_composants
    })
    builder.addCase(getSAJalonByNumber.pending, state => {
      state.isLoadingJalon = true
    })
    builder.addCase(getSAJalonByNumber.rejected, state => {
      state.isLoadingJalon = false
    })
    builder.addCase(getSAJalonByNumber.fulfilled, (state, { payload }) => {
      state.isLoadingJalon = false
      state.tabListObjectFer = payload
      if (payload.numero_jalon === 0 && state.activeJalonNumber === 0) {
        state.listComponentPerObject = payload.composants
      }
      const newStatus = Status.find(status => status.status === payload.status)?.libelle
      if (newStatus) {
        state.jalonStatus = newStatus
      }
    })
    builder.addCase(getSaJalonNbObject.fulfilled, (state, { payload, meta }) => {
      if (meta.arg.nJalon === state.activeJalonNumber) {
        state.listComponentPerObject = payload
      }
      state.isLoadingObjectFer = false
    })
    builder.addCase(getSaJalonNbObject.pending, state => {
      state.listComponentPerObject = []
      state.isLoadingObjectFer = true
    })
    builder.addCase(getSAJalonNbObjectArray.pending, state => {
      state.listComponentPerObjectArray = []
      state.isLoadingObjectFerArray = true
    })
    builder.addCase(getSAJalonNbObjectArray.fulfilled, (state, { payload }) => {
      state.listComponentPerObjectArray = payload
      state.isLoadingObjectFerArray = false
    })
    builder.addCase(getPossibleUsersEmailBySa.pending, state => {
      state.sendMailState = initialState.sendMailState
    })
    builder.addCase(getPossibleUsersEmailBySa.fulfilled, (state, { payload }) => {
      state.sendMailState.possibleUsers = payload
      state.sendMailState.filteredUsers = removeDupeUsers([
        ...(state.sendMailState.selectedRoles.includes(UserRole.animator) ? payload.animators : []),
        ...(state.sendMailState.selectedRoles.includes(UserRole.contributor) ? payload.contributors : []),
        ...(state.sendMailState.selectedRoles.includes(UserRole.decider) ? payload.deciders : []),
        ...(state.sendMailState.selectedRoles.includes(UserRole.entityAnimator) ? payload.entityAnimators : []),
      ])
    })
    builder.addCase(getSaSavedMails.fulfilled, (state, { payload }) => {
      state.sendMailState.savedMails = payload
    })
    builder.addCase(postSaSavedMail.fulfilled, (state, { payload }) => {
      state.sendMailState.selectedSavedMail = payload
    })
    builder.addCase(deleteSaSavedMail.fulfilled, state => {
      state.sendMailState.selectedSavedMail = null
    })
    builder.addCase(fetchDataToCreatePdf.pending, state => {
      state.isLoading = true
    })
    builder.addCase(fetchDataToCreatePdf.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(fetchDataToCreatePdf.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.pdfData = payload
    })
    builder.addCase(patchSaJalonObjectFerroviaire.fulfilled, (state, { payload }) => {
      if (state.tabListObjectFer?.obj_ferroviaires) {
        state.tabListObjectFer.obj_ferroviaires[state.activeTab] = {
          ...state.tabListObjectFer.obj_ferroviaires[state.activeTab],
          ...payload,
        }
      }
    })
  },
})

export const {
  toggleSwitch,
  toggleSwitchComponantInArray,
  toggleSettingPdf,
  setFilterContributor,
  resetComponentList,
  setSettingPdfList,
  resetSettingPdf,
  selectAllUsers,
  unselectAllUsers,
  setSelectedRoles,
  setSelectedUsers,
  setSelectedSavedMail,
  resetPdfData,
  setJalonStatus,
  setActiveJalonNumber,
  updateActiveJalonStatus,
  setActiveTab,
  setSelectedObject,
} = suivi.actions
export default suivi.reducer
