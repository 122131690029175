import { ReactElement } from 'react'
import { PrimaryBtn } from 'themes/theme'
import terms from 'common/terms'

interface SendMailActionsProps {
  characterCount: number
  characterLimit: number
  disabledSend: boolean
  handleSend: () => void
}

const SendMailActions = ({
  characterCount, characterLimit, disabledSend, handleSend,
}: SendMailActionsProps): ReactElement => (
  <div className="d-flex justify-content-end w-100">
    <div className="d-flex flex-column align-items-end">
      <span className={`mb-2 char-counter ${characterCount > characterLimit ? 'warning' : ''}`}>
        {`${characterCount} / ${characterLimit}`}
      </span>
      <PrimaryBtn
        onClick={handleSend}
        disabled={disabledSend}
      >
        {terms.Suivi.mailTerms.openMailbox}
      </PrimaryBtn>
    </div>
  </div>
)

export default SendMailActions
