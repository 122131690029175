import { get, post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ParamsGetTracks } from './types'

const getConstructionSitesLayer = createAsyncThunk(
  'map/getConstructionSitesLayer',
  async (_, thunkApi) => {
    try {
      const response = await get('/chartis/v2/layer/robustest_travaux/geojson/geo/')
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getAllLignes = createAsyncThunk(
  'map/getAllLignes',
  async (_, thunkApi) => {
    try {
      const response = await get('/robustest/lignes/')
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getTracks = createAsyncThunk(
  'map/getTracks',
  async (params: ParamsGetTracks, thunkApi) => {
    try {
      const response = await post('/cassini-v2/route/rgi_line_geo/pr/', {
        pr_list: params.ids,
        statuses: [
          'Exploitée',
          'Transférée en voie de service',
          'Fermée avec maintien en place de la voie',
        ],
      })
      if (!response.geometry.coordinates) return null
      return {
        type: 'Feature',
        geometry: {
          type: response.geometry.type,
          coordinates: response.geometry.coordinates,
        },
        properties: {
          id: params.ligneId,
          libelle: params.libelle,
        },
      } as GeoJSON.Feature
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export {
  getConstructionSitesLayer,
  getTracks,
  getAllLignes,
}
