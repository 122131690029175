import { ReactElement } from 'react'
import { Layer, Source, ViewportProps } from 'react-map-gl'
import { MAP_LAYER_SOURCE, formatLayerUrl } from 'services/map'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import BvLayerPaint from './BvLayerPaint'

type Props = {
  selectedBv: string[]
  selectedBVLigne: string[]
  hoveredIds: string[]
  viewport: ViewportProps
}

export function BvLayer({
  selectedBv, selectedBVLigne, hoveredIds, viewport,
}: Props): ReactElement {
  const { robustestComplexes } = useSelector((state: RootState) => state.portfolio)
  const mode = 'mvt'
  const params = ''
  const view = 'full_rgi_line_geo_centroid'
  const bvToShow = [...selectedBv, ...selectedBVLigne, ...robustestComplexes.map(c => c.gaia_id)]
  const bvNamesToShow = viewport.zoom && viewport.zoom > 9 ? bvToShow : hoveredIds
  return (
    <Source
      id="bv-source"
      type="vector"
      url={formatLayerUrl(MAP_LAYER_SOURCE.pr, mode, params, view)}
    >

      <Layer
        {...BvLayerPaint.bvLayer}
        source-layer={MAP_LAYER_SOURCE.pr}
        filter={['in', ['get', 'id'], ['literal', bvToShow]]}
      />
      <Layer
        {...BvLayerPaint.bvLigneLayer}
        source-layer={MAP_LAYER_SOURCE.pr}
        filter={['in', 'id', ...selectedBVLigne]}
      />
      <Layer
        {...BvLayerPaint.bvLayerHighlighted}
        source-layer={MAP_LAYER_SOURCE.pr}
        filter={['in', 'id', ...selectedBv]}
      />
      <Layer
        {...BvLayerPaint.bvLayerName}
        source-layer={MAP_LAYER_SOURCE.pr}
        filter={['in', ['get', 'id'], ['literal', bvNamesToShow]]}
      />
    </Source>
  )
}

export default BvLayer
