import terms from 'common/terms'

type ThisIsPlural = (evaluation: number | undefined, atten: number | undefined) => {
  pluralEval: string,
  pluralAtten: string,
}
const thisIsPlural: ThisIsPlural = (evaluation, atten) => {
  let pluralEval = ''
  let pluralAtten = ''
  if ((typeof evaluation === 'number') && (typeof atten === 'number')) {
    if (evaluation > 1) {
      pluralEval = 's'
    }
    if (atten > 1) {
      pluralAtten = 's'
    }
  }
  return { pluralEval, pluralAtten }
}

type PopOverRatioTerms = (nume: number, denom: number) => string
const popOverRatioTerms: PopOverRatioTerms = (nume, denom) => {
  let displayMessage = `
      ${nume}
      ${terms.Suivi.tileEval}${thisIsPlural(nume, denom).pluralEval}
        (${terms.Suivi.tileWord} ${denom}
          ${terms.Suivi.tileWaiting}${thisIsPlural(nume, denom).pluralAtten})
        `
  if (nume === 0 && denom === 0) {
    displayMessage = terms.Suivi.popOverRatio
  }
  return displayMessage
}

type DisplayreadOnly = (toggle: boolean, read: boolean | undefined) => boolean
const displayReadOnly: DisplayreadOnly = (toggle, read) => !(!toggle && read)

type ColorPicto = (numerator: number, hasAnyInsufisant: boolean | undefined | null) => string

const colorPicto: ColorPicto = (numerator, hasAnyInsufisant) => {
  if (hasAnyInsufisant) {
    return '#EB604D'
  }
  if (numerator === 0) {
    return 'grey'
  }
  return 'black'
}

export {
  popOverRatioTerms,
  thisIsPlural,
  displayReadOnly,
  colorPicto,
}
