import { cotationInfo } from 'common/cotation'
import terms from 'common/terms'
import PopOver from 'components/PopOver/PopOver'
import { ReactElement } from 'react'
import { FaPaperclip } from 'react-icons/fa'
import { ReactComponent as AxeIcon } from 'assets/icons/axeIcon.svg'

export type ContributionTableViewProps = {
  date?: string | number | Date | undefined,
  firstName?: string,
  lastName?: string,
  role?: string,
  analyse?: string,
  proposal?: string,
  cotation?: string | null,
  file?: string | null,
  fromAxe: boolean,
}

const ContributionTableView = ({
  date,
  firstName,
  lastName,
  role,
  analyse,
  proposal = 'Pas de proposition',
  cotation,
  file,
  fromAxe,
}: ContributionTableViewProps): ReactElement => (
  <div className="arrayComponent-body-section-second">
    <div className="arrayComponent-body-col4">
      <div className="arrayComponent-body-col4-username">
        {lastName && (
        <span className="arrayComponent-body-col4-name">
          <span className="username">
            {`${lastName} ${firstName}`}
          </span>
          {
            fromAxe && (
            <PopOver popContent={terms.Contributions.tile.axeHabilitation}>
              <AxeIcon className="axe-icon" />
            </PopOver>
            )
          }
        </span>
        )}
        {role && (
        <span className="arrayComponent-body-col4-function">{role}</span>
        )}
      </div>
      <div className="arrayComponent-body-col4-first">
        <span
          className="arrayComponent-body-col4-cotation"
          style={{ background: cotationInfo(cotation)?.color || 'transparent' }}
        >
          {cotation || ' '}
        </span>
        <span className="arrayComponent-body-col4-date">
          {date && new Date(date).toLocaleDateString('fr')}
        </span>
      </div>
    </div>
    <div className="arrayComponent-body-col5">
      {analyse || terms.Suivi.arrayComponent.noAnalysis}
      {
        file && (
          <div className="arrayComponent-body-col5-file d-flex mb-2">
            <FaPaperclip size="15px" className="paperclip" />
            <a
              target="__blank"
              href={file}
            >
              <button className="see-file" type="button">
                {terms.Contributions.tile.openFile}
              </button>
            </a>
          </div>
        )
      }
    </div>
    <div className="arrayComponent-body-col6">
      {proposal || terms.Suivi.arrayComponent.noProposal}
    </div>
  </div>
)

ContributionTableView.defaultProps = {
  date: undefined,
  firstName: '',
  lastName: '',
  role: '',
  analyse: '',
  proposal: '',
  cotation: null,
  file: null,
}

export default ContributionTableView
