import {
  get, post, deleteRequest, patch,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ComposantWithoutJalon } from 'reducers/Admin/Composant/types'
import { ThunkApi } from 'reducers/types'
import { getSaContributeurs } from '../Contributeurs/contributeurs.thunk'
import { ParamConstructionSite, ParamPatchConstructionSite, ParamPostConstructionSite } from './types'

const getSaConstructionSites = createAsyncThunk(
  'sa/constructionSites/get',
  async (saId: string | null, thunkApi) => {
    try {
      const response: ParamConstructionSite[] = await get(`/robustest/sa/${saId}/travaux/`)
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

type requestParams = {
  saId: string | null
  constructionSiteId: string
}

const getSaConstructionSiteById = createAsyncThunk(
  'sa/constructionSite/getById',
  async (params: requestParams, thunkApi) => {
    try {
      const response: ParamConstructionSite = await
      get(`/robustest/sa/${params.saId}/travaux/${params.constructionSiteId}`)
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postSaConstructionSite = createAsyncThunk<ParamConstructionSite, ParamPostConstructionSite, ThunkApi>(
  'sa/constructionSite/post',
  async (newConstructionSiteAd: ParamPostConstructionSite, thunkApi) => {
    try {
      const response: ParamConstructionSite = await post(`/robustest/sa/${newConstructionSiteAd.saId}/travaux/`, {
        travaux: newConstructionSiteAd.ConstructionSiteId,
      })
      thunkApi.dispatch(getSaContributeurs(newConstructionSiteAd.saId as string))
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const patchSAConstructionSite = createAsyncThunk<ParamConstructionSite, ParamPatchConstructionSite, ThunkApi>(
  'sa/constructionSite/patch',
  async (updateConstructionSiteSA: ParamPatchConstructionSite, thunkApi) => {
    try {
      const response: ParamConstructionSite = await patch(
        `/robustest/sa/${updateConstructionSiteSA.idSA}/travaux/${updateConstructionSiteSA.idBV}`,
        updateConstructionSiteSA.constructionSite,
      )
      thunkApi.dispatch(getSaContributeurs(updateConstructionSiteSA.idSA))
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteSaConstructionSite = createAsyncThunk(
  'sa/constructionSite/delete',
  async (constructionSiteId: string | undefined, thunkApi) => {
    try {
      const response = await deleteRequest(
        `/robustest/sa/${localStorage.getItem('idSA')}/travaux/${constructionSiteId}`,
      ).then(() => {
        thunkApi.dispatch(getSaConstructionSites(localStorage.getItem('idSA')))
        thunkApi.dispatch(getSaContributeurs(localStorage.getItem('idSA')))
      })
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getDefaultJalonsConstructionSite = createAsyncThunk(
  'sa/constructionSite/jalons',
  async (numeroJalon: number, thunkApi) => {
    try {
      const response: ComposantWithoutJalon[] = await
      get('/robustest/defauts/', { jalon: numeroJalon, type: 'travaux' })
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const searchConstructionSites = createAsyncThunk(
  'sa/constructionSites/search',
  async (params: string, thunkApi) => {
    try {
      const response = await get('/robustest/sa-travaux/', { libelle: params })
      return response
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export {
  getSaConstructionSites,
  getSaConstructionSiteById,
  postSaConstructionSite,
  deleteSaConstructionSite,
  patchSAConstructionSite,
  getDefaultJalonsConstructionSite,
  searchConstructionSites,
}
