import terms from 'common/terms'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { objectName } from '../utils'
import CheckboxSettingPdfColumn from './CheckboxSettingPdfColumn'

interface ListObjectFeroviaireProps {
  termsChecked: boolean,
}

const ListObjectFeroviaire = ({
  termsChecked,
}: ListObjectFeroviaireProps): ReactElement => {
  const { tabListObjectFer } = useSelector((state: RootState) => state.suivi)
  const { settingPdfList } = useSelector((state: RootState) => state.suivi)

  const listComplexes = tabListObjectFer?.obj_ferroviaires.filter(item => item.type === 'C')
  const listLignes = tabListObjectFer?.obj_ferroviaires.filter(item => item.type === 'L')
  const listCt = tabListObjectFer?.obj_ferroviaires.filter(item => item.type === 'T')

  return (
    <div className="settingExportPdf-body-section">
      <div className="settingExportPdf-body-section-block">
        <CheckboxSettingPdfColumn
          options={listComplexes}
          columnLabel={terms.Suivi.pdfTerms.bv.complexes.title}
          allLabel={terms.Suivi.pdfTerms.bv.complexes.allComplexes}
          selectedOptions={settingPdfList.complexes}
          payloadKey={objectName(terms.Suivi.pdfTerms.bv.complexes.title)}
          termsAccepted={termsChecked}
          notObject={terms.Suivi.pdfTerms.bv.complexes.notComplex}
        />
      </div>
      <div className="settingExportPdf-body-section-block">
        <CheckboxSettingPdfColumn
          options={listLignes}
          columnLabel={terms.Suivi.pdfTerms.bv.lignes.title}
          allLabel={terms.Suivi.pdfTerms.bv.lignes.allLines}
          selectedOptions={settingPdfList.lignes}
          payloadKey={objectName(terms.Suivi.pdfTerms.bv.lignes.title)}
          termsAccepted={termsChecked}
          notObject={terms.Suivi.pdfTerms.bv.lignes.notLine}
        />
      </div>
      <div className="settingExportPdf-body-section-block mt-3">
        <CheckboxSettingPdfColumn
          options={listCt}
          columnLabel={terms.Suivi.pdfTerms.bv.travaux.title}
          allLabel={terms.Suivi.pdfTerms.bv.travaux.allTravaux}
          selectedOptions={settingPdfList.travaux}
          payloadKey={objectName(terms.Suivi.pdfTerms.bv.travaux.title)}
          termsAccepted={termsChecked}
          notObject={terms.Suivi.pdfTerms.bv.travaux.notTravaux}
        />
      </div>
    </div>
  )
}
export default ListObjectFeroviaire
