import { Box, Modal } from '@mui/material'
import terms from 'common/terms'
import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import {
  cloneAction,
  resetForm, selectUserCoPilote, selectUserPilote, setCloneObject, setModalAddDisplay, setPatchAction,
} from 'reducers/ActionPlan/actionPlan.reducer'
import { Action, ObjectClonable } from 'reducers/ActionPlan/types'
import ModalAddActionTile from './ModalAddActionTile/ModalAddActionTile'
import FormAddAction from './ModalForm/FormAddAction'
import ModalActionList from './ModalActionList/ModalActionList'
import './modalAddAction.scss'
import FormPatchAction from './ModalForm/FormPatchAction'
import FormViewAction from './ModalForm/FormViewAction'
import FormCloneAction from './ModalForm/FormCloneAction'

interface ModalAddActionProps {
  isOpen: boolean,
  onClose: () => void,
  componentId: number | undefined,
  libelle: string | undefined,
  jalon: number | undefined,
  objectType: string | undefined,
  objectFerId: string | undefined,
  componentIdString: string | undefined,
  isPilot: boolean,
  isAnimax: boolean,
  isObjectBorrowed: boolean,
}

const ModalAddAction = ({
  isOpen,
  onClose,
  componentId,
  libelle,
  jalon,
  objectType,
  objectFerId,
  componentIdString,
  isPilot,
  isAnimax,
  isObjectBorrowed,
}: ModalAddActionProps): ReactElement => {
  const dispatch = useDispatch()
  const { contributionList } = useSelector((state: RootState) => state.contributions)
  const { actionList, modalAdd, patchAction } = useSelector((state: RootState) => state.actionPlan)
  const [fromAxe, setFromAxe] = useState<boolean>(false)

  const handleCloseForm = () => {
    dispatch(resetForm())
  }

  const handleClickAddAction = () => {
    dispatch(setModalAddDisplay('add'))
  }

  const handleClickCloneAction = (action: Action) => (object: ObjectClonable) => () => {
    dispatch(setModalAddDisplay('clone'))
    dispatch(cloneAction(action))
    dispatch(setCloneObject(object))
  }

  const handleClickAction = (action: Action) => () => {
    setFromAxe(action.from_axe)
    dispatch(setModalAddDisplay('edit'))
    dispatch(setPatchAction(action))
    dispatch(selectUserPilote(action.pilote ? { ...action.pilote, email: '', cp: '' } : null))
    dispatch(selectUserCoPilote(action.copilote ? { ...action.copilote, email: '', cp: '' } : null))
  }

  const componentToDisplay = (): ReactElement => {
    if (modalAdd.display === 'add' && componentIdString) {
      return (
        <FormAddAction
          closeButton={handleCloseForm}
          composantId={componentIdString}
          objectFerType={objectType}
          objectFerId={objectFerId}
        />
      )
    }
    if (modalAdd.display === 'clone') {
      return (
        <FormCloneAction
          closeButton={handleCloseForm}
        />
      )
    }
    if (modalAdd.display === 'edit' && componentIdString) {
      const canEditAction = !isPilot && patchAction?.statut === 'TD'
      return canEditAction ? (
        <FormPatchAction
          closeButton={handleCloseForm}
          composantId={componentIdString}
          objectFerType={objectType}
          objectFerId={objectFerId}
          isAnimax={isAnimax}
          isObjectBorrowed={isObjectBorrowed}
          fromAxe={fromAxe}
        />
      ) : (
        <FormViewAction closeButton={handleCloseForm} />
      )
    }
    return (
      <ModalActionList
        actionList={actionList}
        handleClose={onClose}
        handleClickAddAction={handleClickAddAction}
        handleClickAction={handleClickAction}
        canAddAction={!isPilot}
        handleClickCloneAction={handleClickCloneAction}
        clonableObjects={modalAdd.clonableObjects}
      />
    )
  }

  const contributionListFiltered = () => {
    if (isAnimax && isObjectBorrowed) {
      return contributionList?.filter(contribution => contribution.axes?.length > 0)
    }
    return contributionList
  }

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        className="modalAddAction-container"
      >
        <Box
          className="modalAddAction"
        >
          <div className="d-flex flex-row h-100">
            <div className="modalAddAction-contribution">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="modalAddAction-contribution-numComposant">
                  {componentId}
                </div>
                <div className="modalAddAction-contribution-jalon">
                  {`J${jalon}`}
                </div>
              </div>
              <div className="modalAddAction-contribution-libelle">
                {libelle}
              </div>
              <div className="modalAddAction-contribution-subtitle">
                <span className="modalAddAction-contribution-subtitle-item">
                  {terms.actionPlan.modalAddAction.term}
                </span>
              </div>
              <div className="modalAddAction-contribution-container">
                {contributionListFiltered()?.map(contribution => (
                  <ModalAddActionTile
                    key={contribution.id}
                    role={contribution.role}
                    authorId={contribution.auteur?.id || ''}
                    user={contribution.auteur}
                    date={contribution.timestamp}
                    analyse={contribution.analyse}
                    proposition={contribution.proposition}
                    fromAxe={contribution.from_axe}
                  />
                ))}
              </div>
            </div>
            <div className="modalAddAction-action d-flex flex-column">
              {componentToDisplay()}
            </div>
          </div>
        </Box>

      </Modal>
    </>
  )
}

export default ModalAddAction
