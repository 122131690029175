import { RATINGS, cotationInfo } from 'common/cotation'
import PopOver from 'components/PopOver/PopOver'
import { ReactElement } from 'react'

interface Props {
  values: string[];
  disabled?: boolean;
  setCotation: (rating: string[]) => void;
}

const SelectCotation = ({ values, disabled, setCotation }: Props): ReactElement => {
  type HandleSelect = (rating: string) => void
  const handleSelect: HandleSelect = rating => {
    if (values.includes(rating)) {
      setCotation(values.filter(value => value !== rating))
    } else {
      setCotation([...values, rating])
    }
  }
  return (
    <div className="select-cotation">
      <div className="cotation-container">
        {
          RATINGS.map((rating: string) => (
            <PopOver
              key={rating}
              popContent={cotationInfo(rating)?.rating}
            >
              <button
                type="button"
                style={{ backgroundColor: values.includes(rating) ? cotationInfo(rating)?.color : '#C6C6C6' }}
                onClick={() => handleSelect(rating)}
                className={values.includes(rating) ? 'selected' : ''}
                disabled={disabled}
              >
                <span>
                  {rating}
                </span>
              </button>
            </PopOver>
          ))
        }
      </div>
    </div>
  )
}

SelectCotation.defaultProps = {
  disabled: false,
}

export default SelectCotation
