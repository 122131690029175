import {
  Box, SelectChangeEvent, Tab, Tabs,
} from '@mui/material'
import { RootState } from 'Store'
import { cotationInfo } from 'common/cotation'
import terms from 'common/terms'
import ContributorFilter from 'components/ContributorFilter/ContributorFilter'
import CustomSwitch from 'components/CustomSwitch/CustomSwitch'
import TabPanel from 'components/Jalons/TabPanel'
import Loader from 'components/Loader'
import ModalConfirmation from 'components/Modal/ModalConfirmation/ModalConfirmation'
import Tile from 'components/Tile/Tile'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setContributionsState } from 'reducers/Contributions/contributions.reducer'
import { setCopilDate } from 'reducers/SA/sa.reducer'
import { patchSa } from 'reducers/SA/sa.thunk'
import { ComposantObject, ObjectFerTab, SaJalon } from 'reducers/SA/types'
import {
  resetComponentList,
  resetSettingPdf,
  setActiveJalonNumber,
  setActiveTab,
  setFilterContributor,
  setJalonStatus,
  setSelectedObject,
  toggleSwitch,
} from 'reducers/Suivi/suivi.reducer'
import {
  getSAJalonNbObjectArray,
  getSaJalonNbObject,
  getUsersContributeurs,
  postToggleDisplayAllComponent,
} from 'reducers/Suivi/suivi.thunk'
import { UserMeRoles, UserRole } from 'reducers/Users/types'
import { postUserMeAxeToggle } from 'reducers/Users/users.thunk'
import ContentTabObject from './ContentTabObject/ContentTabObject'
import SettingExportPdf from './ExportPdf/SettingExportPdf'
import GlobalCotation from './GlobalCotation/GlobalCotation'
import JalonHeader from './JalonHeader'
import { JalonStatus, Status } from './JalonStatus'
import SaJalonComponentTableView from './SaJalonComponentTableView/SaJalonComponentTableView'
import SendMail from './SendMail/SendMail'
import { typeObject } from './utils'

export default function JalonSaContent({
  valueJ,
  selectedSa,
  updateComponents,
}: {
  valueJ: number,
  selectedSa: UserMeRoles,
  updateComponents: (jalon: number) => void
}): ReactElement {
  const dispatch = useDispatch()
  const [newStatus, setnewStatus] = useState<string | null>('')
  const [open, setOpen] = useState<boolean>(false)
  const { contributeurs } = useSelector((state: RootState) => state.paramContributeurs)
  const {
    listUserContributeur, isToggle, toggleComponentInArray, filterContributeur,
    listComponentPerObject, listComponentPerObjectFiltered, tabListObjectFer,
    jalonStatus, activeTab, activeJalonNumber, isLoadingObjectFer, isLoadingObjectFerArray,
  } = useSelector((state: RootState) => state.suivi)
  const { newCopilDate } = useSelector((state: RootState) => state.sa)
  const [openExport, setOpenExport] = useState<boolean>(false)
  const [openMail, setOpenMail] = useState<boolean>(false)
  const { account } = useSelector((state: RootState) => state.user)
  const { idSA } = useSelector((state: RootState) => state.app)
  const [objectFerTab, setObjectFerTab] = useState<ObjectFerTab | undefined>(
    tabListObjectFer?.obj_ferroviaires[activeTab],
  )
  const { isAxeToggle } = useSelector((state: RootState) => state.users)
  const [switchAllContribution, setSwitchAllContribution] = useState<boolean>(isAxeToggle)

  const listContributeurById = contributeurs.map(i => i.cerbereId)

  const handleClearFilter = () => {
    dispatch(setFilterContributor({
      contributor: null,
      accountId: account.id,
    }))
  }

  useEffect(() => {
    dispatch(setFilterContributor({
      contributor: filterContributeur,
      accountId: account.id,
    }))
  }, [listComponentPerObject])

  const activeJalon: SaJalon = selectedSa?.jalons.filter(jalon => jalon.numero_jalon === valueJ)[0]

  useEffect(() => {
    dispatch(getUsersContributeurs(listContributeurById))
  }, [contributeurs])

  const handleChangeChecked = () => {
    dispatch(postToggleDisplayAllComponent({
      all_composants: !isToggle,
    }))
    dispatch(toggleSwitch())
  }

  const handleChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    dispatch(setActiveTab(newValue))
  }

  const handleSelectFilterContributor = (event: SelectChangeEvent) => {
    const selectedContributor = listUserContributeur.find(c => c.id === event.target.value)
    dispatch(setFilterContributor({
      contributor: selectedContributor || null,
      accountId: account.id,
    }))
  }

  const handleRedirectTile = (component: ComposantObject): () => void => () => {
    dispatch(setContributionsState({
      componentList: listComponentPerObject,
      component,
      numJalon: valueJ,
      activeSa: selectedSa,
      ...(valueJ !== 0 && { objFer: tabListObjectFer?.obj_ferroviaires[activeTab] }),
    }))
  }

  const handleClickTab = (element: ObjectFerTab) => () => {
    const params = {
      idSa: idSA,
      nJalon: valueJ,
      idObject: element.id,
      type: typeObject(element.type),
    }
    dispatch(setSelectedObject(params))
    dispatch(resetComponentList())
    dispatch(getSaJalonNbObject(params))
    dispatch(getSAJalonNbObjectArray(params))
    setObjectFerTab(element)
  }

  useEffect(() => {
    if (objectFerTab) {
      const params = {
        idSa: idSA,
        nJalon: valueJ,
        idObject: objectFerTab.id,
        type: typeObject(objectFerTab.type),
      }
      dispatch(resetComponentList())
      dispatch(getSaJalonNbObject(params))
      dispatch(getSAJalonNbObjectArray(params))
    }
  }, [isToggle, isAxeToggle])

  const handleComfirmeChangeJalonStatus = async () => {
    const patchSA = await dispatch(patchSa({
      saId: selectedSa?.id,
      jalon: {
        numero_jalon: activeJalon.numero_jalon,
        status: Status.find(s => s.libelle === newStatus)?.status,
      },
    })) as unknown as { type: string, payload: { status: string } }
    setOpen(false)
    setnewStatus(null)
    if (patchSA.type.endsWith('fulfilled') && newStatus === 'Clôturé' && activeJalonNumber < 4) {
      dispatch(setActiveJalonNumber(activeJalonNumber + 1))
      dispatch(setJalonStatus('En cours'))
      updateComponents(activeJalonNumber + 1)
    }
  }

  const handleSwitchAllContribution = () => {
    setSwitchAllContribution(!switchAllContribution)
    dispatch(postUserMeAxeToggle(!switchAllContribution))
  }

  const handleOpenExport = () => {
    setOpenExport(true)
    dispatch(resetSettingPdf())
  }

  const handleSelectStatus = (evt: SelectChangeEvent) => {
    setnewStatus(evt.target.value)
    setOpen(true)
  }

  return (
    <>
      <div className="tab-panel-suivi">
        <TabPanel value={valueJ} key={valueJ} index={valueJ}>
          <JalonHeader
            valueJ={valueJ}
            selectedSa={selectedSa}
            switchAllContribution={switchAllContribution}
            handleSwitchAllContribution={handleSwitchAllContribution}
            handleOpenExport={handleOpenExport}
            setOpenMail={() => setOpenMail(true)}
            handleSelectStatus={handleSelectStatus}
            jalonStatus={jalonStatus}
            activeJalon={activeJalon}
            setOpen={setOpen}
          />
          <Box>
            {(valueJ !== 0) && (
              <Tabs value={activeTab} onChange={handleChangeTab} className="suivi-tabs">
                {tabListObjectFer?.obj_ferroviaires.map(element => (
                  <Tab
                    onClick={handleClickTab(element)}
                    disabled={isLoadingObjectFer || isLoadingObjectFerArray}
                    key={element.id}
                    label={(
                      <ContentTabObject
                        isAxeAccredited={element.borrowed}
                        type={element.type}
                        abrev={element.abrev}
                        libelle={element.libelle}
                      />
                  )}
                    className="suivi-tab-button"
                  />
                ))}
              </Tabs>
            )}
            {
                isLoadingObjectFer || isLoadingObjectFerArray ? (
                  <Box className="suivi-panel-container">
                    <Loader
                      message={terms.Common.loading}
                    />
                  </Box>
                ) : (
                  <Box className="suivi-panel-container">
                    <div className="select-contrib">
                      {valueJ !== 0 ? (
                        <GlobalCotation
                          libelle={tabListObjectFer?.obj_ferroviaires[activeTab]?.libelle || ''}
                          cotation={tabListObjectFer?.obj_ferroviaires[activeTab]?.cotation || null}
                          cotationAxe={tabListObjectFer?.obj_ferroviaires[activeTab]?.cotation_axe || null}
                          borrowed={tabListObjectFer?.obj_ferroviaires[activeTab]?.borrowed || false}
                          comment={tabListObjectFer?.obj_ferroviaires[activeTab]?.commentaire || ''}
                          commentAxe={tabListObjectFer?.obj_ferroviaires[activeTab]?.commentaire_axe || ''}
                          gravityAxe={tabListObjectFer?.obj_ferroviaires[activeTab]?.perimetre_axe || 0}
                          gravity={tabListObjectFer?.obj_ferroviaires[activeTab]?.perimetre || 0}
                          type={tabListObjectFer?.obj_ferroviaires[activeTab]?.type || ''}
                          jalonStatus={jalonStatus}
                          jalon={activeJalon.numero_jalon}
                          idObjFer={tabListObjectFer?.obj_ferroviaires[activeTab]?.id || ''}
                          numerator={tabListObjectFer?.obj_ferroviaires[activeTab]?.score || null}
                          denominator={tabListObjectFer?.obj_ferroviaires[activeTab]?.max || 0}
                        />
                      ) : <div />}

                      {(valueJ !== 0) && (
                        selectedSa.roles.some(role => role === UserRole.contributor)) && (
                        <CustomSwitch
                          value={isToggle}
                          handleChange={handleChangeChecked}
                          label={terms.Suivi.labelToggleSwitchComponent}
                        />
                      )}
                      <ContributorFilter
                        selectedContributor={filterContributeur}
                        contributorList={listUserContributeur}
                        handleClearClick={handleClearFilter}
                        handleSelectContributor={handleSelectFilterContributor}
                      />
                    </div>
                    {toggleComponentInArray ? (
                      <SaJalonComponentTableView
                        handleclickRedirect={handleRedirectTile}
                      />
                    ) : (
                      <div className="tile-container">
                        {
                      listComponentPerObjectFiltered
                        .map(component => (
                          <Tile
                            isScroll={listComponentPerObjectFiltered.length > 15}
                            key={component.id}
                            color={cotationInfo(component.cotation_anim)?.color || '#fff'}
                            numberTile={component.id}
                            title={component.libelle}
                            ratingAdmin={component.cotation_anim}
                            rating={component.cotation_contrib}
                            numerator={component.numerator}
                            denominator={component.denominator}
                            role={selectedSa.roles}
                            readOnly={component.read_only}
                            handleRedirectTile={handleRedirectTile(component)}
                            hasAnyInsuffisant={component.has_any_insuffisant}
                          />
                        ))
                  }
                      </div>
                    )}
                  </Box>
                )
              }

          </Box>
        </TabPanel>
      </div>

      <ModalConfirmation
        onModal={open && !!newCopilDate}
        content1={terms.Suivi.dateValidationMessage}
        variable1={newCopilDate && new Date(newCopilDate).toLocaleDateString()}
        handleClose={() => {
          setOpen(false)
          dispatch(setCopilDate(undefined))
        }}
        handleConfirmClick={() => {
          dispatch(patchSa({
            saId: selectedSa?.id,
            jalon: {
              numero_jalon: activeJalon.numero_jalon,
              date_copil: newCopilDate,
            },
          }))
        }}
      />
      <ModalConfirmation
        onModal={open && !!newStatus}
        handleClose={() => {
          setOpen(false)
          setnewStatus(null)
        }}
        content1={terms.Suivi.validationMessage}
        variable1={`'${newStatus}'`}
        content3={Status.find(status => status.libelle === newStatus)?.message}
        content4={newStatus === Status.find(stat => stat.status === JalonStatus.ToBeValidated)?.libelle
          ? terms.Suivi.toBeValidatedFollowingNotice : ''}
        handleConfirmClick={handleComfirmeChangeJalonStatus}
      />
      <SettingExportPdf
        onModal={openExport}
        setOpenModal={() => setOpenExport(false)}
        numberJalon={valueJ}
      />
      <SendMail
        openModal={openMail}
        handleClose={() => setOpenMail(false)}
        saId={selectedSa?.id}
      />

    </>
  )
}
