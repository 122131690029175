import { deleteRequest, get, post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { SettingsTabListElement } from 'components/SettingsTab/SettingsTabList'
import { ThunkApi } from 'reducers/types'
import { getUserMeSuivi } from 'reducers/Users/users.thunk'
import { getUsersSimple } from 'reducers/Users/utils'
import { loadRole } from '../Contributeurs/contributeurs.reducer'
import { formatSettingsTabListElement } from '../utils'
import { ParamDecider, ParamDeleteDecider, ParamPostDecider } from './types'

const getSaDeciders = createAsyncThunk<SettingsTabListElement[], string | null, ThunkApi>(
  'sa/deciders/get',
  async (saId: string | null, thunkApi) => {
    try {
      const response: ParamDecider[] = await get(`/robustest/sa/${saId}/decideurs/`)
      const usersResponse = await getUsersSimple(response.map(decider => decider.utilisateur?.cerbere_id))
      return usersResponse.map(user => {
        const decider = response.find(dec => dec.utilisateur?.cerbere_id === user.id)
        return formatSettingsTabListElement(decider?.id, user, decider?.entite)
      })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postSaDeciders = createAsyncThunk<SettingsTabListElement, ParamPostDecider, ThunkApi>(
  'sa/deciders/post',
  async (newDecider: ParamPostDecider, thunkApi) => {
    try {
      const response: ParamDecider = await post(`/robustest/sa/${newDecider.saId}/decideurs/`, {
        utilisateur: {
          cerbere_id: newDecider.user?.id,
        },
        entite: newDecider.entity?.id,
      })
      if (response.utilisateur?.cerbere_id === newDecider.userMeId) {
        thunkApi.dispatch(getUserMeSuivi())
        thunkApi.dispatch(loadRole())
      }
      return formatSettingsTabListElement(response.id, newDecider.user, newDecider.entity)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteSaDeciders = createAsyncThunk<ParamDecider, ParamDeleteDecider, ThunkApi>(
  'sa/deciders/delete',
  async (deciderToDelete: ParamDeleteDecider, thunkApi) => {
    try {
      const response = await
      deleteRequest(`/robustest/sa/${deciderToDelete.saId}/decideurs/${deciderToDelete.saDeciderid}/`)
      if (deciderToDelete.needRefresh) {
        thunkApi.dispatch(getUserMeSuivi())
        thunkApi.dispatch(loadRole())
      }
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

// eslint-disable-next-line import/prefer-default-export
export {
  getSaDeciders,
  postSaDeciders,
  deleteSaDeciders,
}
