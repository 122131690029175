import { ReactElement, useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import terms from 'common/terms'
import ContributionTile from 'components/ContributionTile/ContributionTile'
import { Contribution, SelectedContributor } from 'reducers/Contributions/types'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetNewContribution, setNewContribution, setSelectedContribution,
} from 'reducers/Contributions/contributions.reducer'
import { RootState } from 'Store'
import { setDisplayUserInfos, setUserInfosId } from 'reducers/Users/users.reducer'

interface ContributionsListProps {
  activeJalon: number | null
  jalonContributions: Contribution[]
  history: Contribution[]
  selectedContributor: SelectedContributor | null
}

const ContributionsList = ({
  activeJalon, jalonContributions, history, selectedContributor,
}: ContributionsListProps): ReactElement => {
  const dispatch = useDispatch()
  const [showHistory, setShowHistory] = useState(false)
  const [displayedContributions, setDisplayedContributions] = useState<Contribution[]>([])
  const [displayedHistory, setDisplayedHistory] = useState<Contribution[]>([])
  const { selectedContribution } = useSelector((state: RootState) => state.contributions)

  useEffect(() => {
    if (selectedContributor) {
      setDisplayedContributions(jalonContributions.filter(contri => contri.auteur?.id === selectedContributor.id))
      setDisplayedHistory(history.filter(contri => contri.auteur?.id === selectedContributor.id))
    } else {
      setDisplayedContributions(jalonContributions)
      setDisplayedHistory(history)
    }
  }, [selectedContributor, jalonContributions])

  const handleTileClick = (contribution: Contribution) => () => {
    dispatch(setSelectedContribution(contribution))
    dispatch(resetNewContribution())
  }

  const handleTileActionClick = (contribution: Contribution) => () => {
    dispatch(setSelectedContribution(null))
    dispatch(setNewContribution({
      auteur: contribution.auteur?.id || '',
      analyse: contribution.analyse,
      proposition: contribution.proposition,
      cotation: contribution.cotation,
      visibilite: [],
      file: null,
      file_name: contribution.file,
      ...(contribution.file_url !== null && { file_url: contribution.file_url }),
      axes: contribution.axes,
    }))
  }

  const isSelected = (contribution: Contribution): boolean => {
    if (selectedContribution !== null) {
      return contribution.id === selectedContribution.id
    }
    return false
  }

  const handleClickUser = (userId: string) => {
    dispatch(setUserInfosId(userId))
    dispatch(setDisplayUserInfos(true))
  }

  return (
    <div className="contributions-list">
      {displayedContributions.length > 0 ? displayedContributions.map(contri => (
        <ContributionTile
          key={contri.id}
          data={contri}
          handleClick={handleTileClick(contri)}
          selected={isSelected(contri)}
          handleAction={handleTileActionClick(contri)}
          handleClickUser={handleClickUser}
        />
      )) : (
        <div className="no-contributions border-bottom">
          {displayedHistory.length > 0 ? terms.Contributions.list.onlyHistory : terms.Contributions.list.noList}
        </div>
      )}
      {displayedHistory.length > 0 ? (
        <Typography onClick={() => setShowHistory(!showHistory)} className="history-button border-bottom px-3 py-1">
          {showHistory ? terms.Contributions.list.hideHistory : terms.Contributions.list.showHistory}
        </Typography>
      ) : (
        <div className="no-contributions">
          {activeJalon !== null && activeJalon > 0 && terms.Contributions.list.noHistory}
        </div>
      )}
      <div className="contributions-history">
        {(showHistory && displayedHistory.length > 0) && displayedHistory.map(
          contri => (
            <ContributionTile
              key={contri.id}
              data={contri}
              handleClick={handleTileClick(contri)}
              selected={isSelected(contri)}
              handleAction={handleTileActionClick(contri)}
              handleClickUser={handleClickUser}
            />
          ),
        )}
      </div>
    </div>
  )
}

export default ContributionsList
