import { DragHandle } from '@mui/icons-material'
import { ReactElement, useState } from 'react'
import { Draggable, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd'
import ClearIcon from '@mui/icons-material/Clear'
import './objectLigne.scss'
import { useDispatch, useSelector } from 'react-redux'
import { Popover, Typography } from '@mui/material'
import terms from 'common/terms'
import { ParamLigne } from 'reducers/Parametrage/ParamLignes/types'
import { onModalConfirmationLigne } from 'reducers/Parametrage/ParamLignes/paramLignes.reducers'
import { getSaLigneById } from 'reducers/Parametrage/ParamLignes/paramLignes.thunk'
import { RootState } from 'Store'

interface ObjectLigneProps {
  ligne?: ParamLigne
  objectId?: string;
  index: number;
  list: string;
  obj: ParamLigne;
}

const ObjectLigne = ({
  ligne,
  objectId = '1',
  index,
  list,
  obj,
}: ObjectLigneProps): ReactElement => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [composantAvailable, setComposantAvailable] = useState(true)
  const { idSA, labelSA } = useSelector((state: RootState) => state.app)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setComposantAvailable(false)
    setAnchorEl(event.currentTarget)
  }

  const getItemStyle = (isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
    background: isDragging ? '#e05206' : 'white',
    color: isDragging ? 'white' : '#878e91',
    ...draggableStyle,
  })

  const handlePopoverClose = () => {
    setComposantAvailable(true)
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  let libelleLigne = ''
  if (ligne?.ligne?.bv_via?.libelle === undefined || ligne?.ligne.bv_via?.libelle === null) {
    libelleLigne = `${ligne?.ligne?.bv_debut?.libelle} - ${ligne?.ligne?.bv_fin?.libelle}`
  } else {
    libelleLigne = `
    ${ligne?.ligne.bv_debut?.libelle} - 
    ${ligne?.ligne.bv_fin?.libelle} via ${ligne?.ligne.bv_via?.libelle}`
  }

  const onLigneClick = () => {
    if (composantAvailable) {
      dispatch(getSaLigneById({
        saId: idSA,
        ligneId: objectId,
      }))
    }
  }

  const shouldDisplayOption = obj?.ligne?.eic && !labelSA?.includes(obj?.ligne?.eic?.libelle || '')

  return (
    <Draggable draggableId={objectId} index={index}>
      {(provider, snapshot) => (
        <div
          role="button"
          tabIndex={0}
          onClick={() => onLigneClick()}
        >
          <li
            className="configList_cont--item"
            {...provider.draggableProps}
            {...provider.dragHandleProps}
            ref={provider.innerRef}
            style={getItemStyle(
              snapshot.isDragging,
              provider.draggableProps.style,
            )}
          >
            <div className="firstDiv">
              <DragHandle />
              <div className="spanList">
                <span className="title">
                  {libelleLigne}
                </span>
                {
                  shouldDisplayOption && (
                  <span className="item">
                    {` (${obj?.ligne?.eic?.libelle})`}
                  </span>
                  )
                }
              </div>
            </div>
            {(list === '1') && (
            <div>
              <Typography
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <button
                  className="buttonDeleteComplexeSa"
                  type="button"
                  onClick={() => dispatch(onModalConfirmationLigne(obj))}
                >
                  <ClearIcon fontSize="small" />
                </button>
              </Typography>
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography className="popup-span" sx={{ p: 1 }}>{terms.Settings.lignes.popup}</Typography>
              </Popover>
            </div>
            )}
          </li>
        </div>

      )}
    </Draggable>
  )
}

ObjectLigne.defaultProps = {
  ligne: {},
  objectId: '',
}

export default ObjectLigne
