import ListParametrage from 'components/ListParametrage/ListParametrage'
import { ReactElement } from 'react'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { changeListComplexes } from 'reducers/Parametrage/Complexes/complexes.reducer'
import { patchSAComplexe } from 'reducers/Parametrage/Complexes/complexes.thunk'
import { ParamComplexe } from 'reducers/Parametrage/Complexes/types'
import { RootState } from 'Store'

const DragComplex = (): ReactElement => {
  const dispatch = useDispatch()
  const columns = useSelector((state: RootState) => state.paramComplexes.complexes)
  const { listComplexesSa } = useSelector((state: RootState) => state.paramComplexes)
  const { idSA } = useSelector((state: RootState) => state.app)

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result
    if (!destination) {
      return
    }

    if (
      destination.droppableId === source.droppableId
      && destination.index === source.index
    ) {
      return
    }
    const listStart = columns.filter(item => item.id === source.droppableId)
    const listFinish = columns.filter(item => item.id === destination.droppableId)
    if (listStart[0].id !== listFinish[0].id) {
      const startObj = listStart[0].objectFerList

      if (listStart[0].id === '1') {
        startObj.map((item: ParamComplexe) => {
          let nItem = {}
          nItem = {
            ...item,
            active: false,
          }

          return nItem
        })
      } else {
        startObj.map((item: ParamComplexe) => {
          let nItem = {}
          nItem = {
            ...item,
            active: true,
          }

          return nItem
        })
      }

      const newStart = {
        ...listStart[0],
        objectFerList: startObj.filter(i => i.id !== draggableId),
      }

      const finishObj: ParamComplexe[] = []
      finishObj.push(...listFinish[0].objectFerList)
      const dragObject = listComplexesSa?.filter(item => item.id === draggableId)

      if (dragObject) {
        finishObj.push(dragObject[0])
      }

      if (listFinish[0].id === '1') {
        if (dragObject) {
          const objUpdate = {
            idSA,
            idBV: dragObject[0].id,
            complexe: { active: false },
          }
          dispatch(patchSAComplexe(objUpdate))
        }

        finishObj.map((item: ParamComplexe) => {
          let nItem = {}
          nItem = {
            ...item,
            active: false,
          }

          return nItem
        })
      } else {
        if (dragObject) {
          dispatch(patchSAComplexe({
            idSA,
            idBV: dragObject[0].id,
            complexe: { active: true },
          }))
        }

        finishObj.map((item: ParamComplexe) => {
          let nItem = {}
          nItem = {
            ...item,
            active: true,
          }

          return nItem
        })
      }
      const newFinish = {
        ...listFinish[0],
        objectFerList: finishObj,
      }

      const newlist = [
        newStart,
        newFinish,
      ].sort((a, b) => {
        if (a.id < b.id) { return -1 }
        if (a.id > b.id) { return 1 }
        return 0
      })

      const newState = [
        ...newlist,
      ]
      dispatch(changeListComplexes(newState))
    }
  }
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {columns.map(listId => {
        const objectFer = listId.objectFerList
        return (
          <ListParametrage
            key={listId.id ? listId.id : '1'}
            listId={listId.id}
            title={listId.title}
            objectFer={objectFer}
          />
        )
      })}
    </DragDropContext>
  )
}

export default DragComplex
