import terms from 'common/terms'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { UserRole } from 'reducers/Users/types'
import CheckboxSettingPdfColumn from './CheckboxSettingPdfColumn'

const ListEntity = (): ReactElement => {
  const { entitiesVisibility } = useSelector((state: RootState) => state.contributions)
  const { settingPdfList } = useSelector((state: RootState) => state.suivi)
  const { userMeSuivi } = useSelector((state: RootState) => state.users)
  const { idSA } = useSelector((state: RootState) => state.app)

  const selectedSa = userMeSuivi.find(sa => sa.id === idSA)

  const entitiesList = selectedSa?.roles.some(role => role === UserRole.admin || role === UserRole.animator)
    ? entitiesVisibility : entitiesVisibility.filter(entity => entity.id === selectedSa?.entite)

  return (
    <div className="settingExportPdf-body-section-block">
      <CheckboxSettingPdfColumn
        options={entitiesList}
        columnLabel={terms.Suivi.pdfTerms.entity.title}
        allLabel={terms.Suivi.pdfTerms.entity.allEnities}
        selectedOptions={settingPdfList.entites}
        payloadKey="entites"
        notObject={terms.Suivi.pdfTerms.entity.notEntity}
        termsAccepted
      />
    </div>
  )
}

export default ListEntity
