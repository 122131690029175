import terms from 'common/terms'
import PopOver from 'components/PopOver/PopOver'
import { chipAttributes, dateToDisplay } from 'pages/ActionPlan/utils'
import { backgroundColorContributor } from 'pages/Suivi/utils'
import { ReactElement } from 'react'
import { ActionModalType, Etat, Action } from 'reducers/ActionPlan/types'
import { ReactComponent as AxeIcon } from 'assets/icons/axeIcon.svg'

interface ModalActionInfosProps {
  action: Action
  type: ActionModalType
}

const ModalActionInfos = ({
  action, type,
}: ModalActionInfosProps): ReactElement => (
  <>
    <div className="modalCopileMode-name">
      <div>
        {action.nom}
      </div>
      <div>
        {
          action.from_axe && (
          <PopOver popContent={terms.Contributions.tile.axeHabilitation}>
            <AxeIcon className="axe-icon" />
          </PopOver>
          )
        }
      </div>
    </div>
    <div className="modalCopileMode-description">
      {action.description}
    </div>
    <div className="modalCopileMode-chip">
      <div
        className="modalCopileMode-chip-status"
      >
        <div
          style={{ backgroundColor: chipAttributes(action.statut).bgColor }}
        />
        <span>
          {chipAttributes(action.statut).text}
        </span>
      </div>
      {action.etat !== Etat.SKIPPED && (
      <div
        className="modalCopileMode-chip-state"
      >
        <div style={{ backgroundColor: chipAttributes(action.etat).bgColor }} />
        <span>
          {chipAttributes(action.etat).text}
        </span>
      </div>
      )}
    </div>
    { type !== ActionModalType.limited && (
    <>
      <div className="modalCopileMode-dateAndUser">
        <div className="modalCopileMode-dateAndUser-user">
          {action.pilote && (
          <div className="modalCopileMode-dateAndUser-user-pilote">
            <span
              style={{
                background: backgroundColorContributor(action.pilote.id),
              }}
            >
              {`${action.pilote.lastName[0].toUpperCase()}${action.pilote.firstName[0].toUpperCase()}`}
            </span>
            <div className="modalCopileMode-dateAndUser-user-pilote-name">
              <div className="modalCopileMode-dateAndUser-user-pilote-name-text">
                {`${action.pilote.lastName.toUpperCase()} ${action.pilote.firstName}`}
              </div>
            </div>
          </div>
          )}
          {action.copilote && (
          <div className="modalCopileMode-dateAndUser-user-copilote">
            <span
              style={{
                background: backgroundColorContributor(action.copilote.id),
              }}
            >
              {`${action.copilote.lastName[0].toUpperCase()}${action.copilote.firstName[0].toUpperCase()}`}
            </span>
            <div className="modalCopileMode-dateAndUser-user-copilote-name">
              <div className="modalCopileMode-dateAndUser-user-copilote-name-text">
                {`${action.copilote?.lastName.toUpperCase()} ${action.copilote?.firstName}`}
              </div>
            </div>
          </div>
          )}
        </div>
        <div className="modalCopileMode-dateAndUser-date">
          <div className="modalCopileMode-dateAndUser-date-open">
            <span className="modalCopileMode-dateAndUser-date-open-title">
              {terms.actionPlan.modalAddAction.tileAction.dateOpen}
            </span>
            <span className="modalCopileMode-dateAndUser-date-open-item">
              {action.date_ouverture ? dateToDisplay(action.date_ouverture).date
                : terms.actionPlan.modalAddAction.tileAction.noDate}
            </span>
          </div>
          <div className="modalCopileMode-dateAndUser-date-close">
            <span className="modalCopileMode-dateAndUser-date-close-title">
              {terms.actionPlan.modalAddAction.tileAction.dateClose}
            </span>
            <span className="modalCopileMode-dateAndUser-date-close-operator">
              =
            </span>
            <span className="modalCopileMode-dateAndUser-date-close-item">
              {action.date_bouclage ? dateToDisplay(action.date_bouclage).date
                : terms.actionPlan.modalAddAction.tileAction.noDate}
            </span>
          </div>
        </div>
      </div>
      <div className="modalCopileMode-dateAndUser-entities mt-2">
        {action.entite.length > 0 ? (
          <strong>{terms.actionPlan.modalActionCopile.entities}</strong>
        ) : (
          <strong>{terms.actionPlan.modalActionCopile.noEntities}</strong>
        )}
        <div className="modalCopileMode-entity-list">
          {action.entite.map(entite => (
            <div className="modalCopileMode-dateAndUser-entities-item" key={entite.id}>
              {entite.libelle}
            </div>
          ))}
        </div>
      </div>
    </>
    )}
  </>
)

export default ModalActionInfos
