import { ReactElement } from 'react'
import { postProductionZone } from 'reducers/Admin/admin.thunk'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import terms from 'common/terms'
import { PrimaryBtn } from 'themes/theme'
import {
  addProductionZone,
  changeProductionZone,
  deleteNewProductionZone,
  hideSnackbar,
} from 'reducers/Admin/ProductionZone/productionZone'
import CustomSnackbar from 'components/Snackbar/Snackbar'
import Accordion from '../../../components/Accordion/Accordion'

export default function ProductionZoneAccordion(): ReactElement {
  const dispatch = useDispatch()
  const {
    productionZones, displaySnackbar, isLoadingProductionZones,
  } = useSelector((state : RootState) => state.productionZone)

  return (
    <>
      <Accordion
        name={terms.Admin.productionZones.name}
        elementList={productionZones}
        onAddEntity={() => dispatch(addProductionZone())}
        onDeleteEntity={() => dispatch(deleteNewProductionZone())}
        isLoading={isLoadingProductionZones}
        newElementWording={terms.Admin.productionZones.new}
        displaySnackbar={displaySnackbar}
      >
        {productionZone => (
          <form onSubmit={evt => {
            evt.preventDefault()
            dispatch(postProductionZone(productionZone))
          }}
          >
            <div className="row align-items-center mt-3">
              <label className="col-6 d-flex align-items-center mb-0 flex-column" htmlFor="libelle">
                <p className="d-flex align-self-start ml-2">{terms.Admin.name}</p>
                <input
                  className="input-admin form-control form-control-sm ml-2"
                  placeholder={terms.Admin.productionZones.namePlaceHolder}
                  type="text"
                  id="libelle"
                  name="libelle"
                  value={productionZone.libelle}
                  onChange={evt => dispatch(changeProductionZone({ ...productionZone, libelle: evt.target.value }))}
                />
              </label>
              <label className="col-6 d-flex align-items-center mb-0 flex-column" htmlFor="abrev">
                <p className="d-flex align-self-start ml-2">{terms.Admin.abrev}</p>
                <input
                  className="input-admin form-control form-control-sm ml-2"
                  placeholder={terms.Admin.productionZones.abrevPlaceHolder}
                  type="text"
                  id="abrev"
                  name="abrev"
                  maxLength={5}
                  value={productionZone.abrev}
                  onChange={evt => dispatch(changeProductionZone({ ...productionZone, abrev: evt.target.value }))}
                />
              </label>
            </div>
            <div>
              <div className="row align-items-center mt-3 justify-content-end">
                <PrimaryBtn
                  type="submit"
                  className="btn btn-gradient-blue mr-2"
                  disabled={!productionZone.libelle || !productionZone.abrev}
                >
                  {terms.Admin.validate}
                </PrimaryBtn>
              </div>
            </div>
          </form>
        )}
      </Accordion>
      <CustomSnackbar
        message={terms.Admin.productionZones.validationNotice}
        displaySnackbar={displaySnackbar}
        handleClose={() => dispatch(hideSnackbar())}
      />
    </>
  )
}
