import { RootState } from 'Store'
import { RATINGS } from 'common/cotation'
import terms from 'common/terms'
import { ValueSelect } from 'components/CustomSelect/CustomSelect'
import CustomTabJalon from 'components/CustomTabJalon/CustomTabJalon'
import Loader from 'components/Loader'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEntity, getJalonComposants } from 'reducers/Admin/admin.thunk'
import { UserRole } from 'reducers/Users/types'
import { getPortfolioSimple } from 'reducers/monitoring/monitoring.thunk'
import { OrderingByEnum, SupervisionEfWithUser } from 'reducers/monitoring/types'
import ContributionsSelects from './ContributionsSelects/ContributionsSelects'
import ContributionsTable from './ContributionsTable/ContributionsTable'
import Filters from './Filters/Filters'

import './Contributions.scss'

const Contributions = (): ReactElement => {
  const dispatch = useDispatch()
  const [activeJalon, setActiveJalon] = useState(0)
  const { isLoadingData, isLoadingEf, supervisionEfList } = useSelector((state: RootState) => state.monitoring)
  const { usersEntitySupervisor, robPermission } = useSelector((state: RootState) => state.users)
  const [cotationAnimator, setCotationAnimator] = useState<string[]>([...RATINGS])
  const [cotationContributor, setCotationContributor] = useState<string[]>([...RATINGS])
  const [contributorSelected, setContributorSelected] = useState(true)
  const [orderingBy, setOrderingBy] = useState<OrderingByEnum>(OrderingByEnum.COMPONENT)
  const [portefolio, setPortefolio] = useState<SupervisionEfWithUser[]>([])
  const [selectedEntity, setSelectedEntity] = useState<ValueSelect | null>(null)
  const [globalAnimator, setGlobalAnimator] = useState<boolean>(true)
  const [selectedPortfolio, setSelectedPortfolio] = useState<ValueSelect | null>(null)
  const [speAnimator, setSpeAnimator] = useState<boolean>(true)

  const isSupervisor = !!usersEntitySupervisor && !robPermission.includes(UserRole.admin)

  const listFiltered = () => (supervisionEfList.map(supervisionEf => {
    if (!contributorSelected && speAnimator && globalAnimator) {
      return {
        ...supervisionEf,
        contributions: supervisionEf.contributions.filter(
          contribution => contribution.role === 'Animateur',
        ).filter(
          contrib => cotationAnimator.includes(contrib.cotation) || contrib.cotation === null,
        ).filter(item => {
          if (item.role === 'Animateur') {
            if (!globalAnimator && !speAnimator) {
              return false
            }
            if (!globalAnimator && speAnimator) {
              return !item.is_global
            }
            if (globalAnimator && !speAnimator) {
              return item.is_global
            }
          }
          return true
        }),
      }
    }
    if (!globalAnimator && !speAnimator && contributorSelected) {
      return {
        ...supervisionEf,
        contributions: supervisionEf.contributions.filter(
          contribution => contribution.role !== 'Animateur',
        ).filter(contrib => cotationContributor.includes(contrib.cotation)),

      }
    }
    if (!globalAnimator && !speAnimator && !contributorSelected) {
      return {
        ...supervisionEf,
        contributions: [],
      }
    }
    if (!globalAnimator && speAnimator && !contributorSelected) {
      return {
        ...supervisionEf,
        contributions: supervisionEf.contributions.filter(
          contribution => contribution.role === 'Animateur',
        ).filter(
          contrib => cotationAnimator.includes(contrib.cotation) || contrib.cotation === null,
        ).filter(item => {
          if (item.role === 'Animateur') {
            if (!globalAnimator && !speAnimator) {
              return false
            }
            if (!globalAnimator && speAnimator) {
              return !item.is_global
            }
            if (globalAnimator && !speAnimator) {
              return item.is_global
            }
          }
          return true
        }),
      }
    }
    if (globalAnimator && !speAnimator && !contributorSelected) {
      return {
        ...supervisionEf,
        contributions: supervisionEf.contributions.filter(
          contribution => contribution.role === 'Animateur',
        ).filter(
          contrib => cotationAnimator.includes(contrib.cotation) || contrib.cotation === null,
        ).filter(item => {
          if (item.role === 'Animateur') {
            if (!globalAnimator && !speAnimator) {
              return false
            }
            if (!globalAnimator && speAnimator) {
              return !item.is_global
            }
            if (globalAnimator && !speAnimator) {
              return item.is_global
            }
          }
          return true
        }),
      }
    }
    return {
      ...supervisionEf,
      contributions: supervisionEf.contributions.filter(
        contrib => {
          if (contrib.role === 'Animateur') {
            return cotationAnimator.includes(contrib.cotation) || contrib.cotation === null
          }
          return cotationContributor.includes(contrib.cotation)
        },
      ).filter(item => {
        if (item.role === 'Animateur') {
          if (!globalAnimator && !speAnimator) {
            return false
          }
          if (!globalAnimator && speAnimator) {
            return !item.is_global
          }
          if (globalAnimator && !speAnimator) {
            return item.is_global
          }
        }
        return true
      }),

    }
  }))

  const shouldOrderingBy = (order: OrderingByEnum, list: SupervisionEfWithUser[]) => {
    if (order === OrderingByEnum.COMPONENT) {
      return list.sort((a, b) => a.composant_id - b.composant_id)
    }
    return list.sort((a, b) => a.libelle.localeCompare(b.libelle))
  }

  useEffect(() => {
    dispatch(getEntity({}))
    dispatch(getPortfolioSimple({}))
  }, [])

  useEffect(() => {
    dispatch(getJalonComposants({
      active_jalon: activeJalon + 1,
    }))
  }, [activeJalon])

  const handleChangeJalon = (jalon: number) => {
    setActiveJalon(jalon)
  }

  useEffect(() => {
    const filtered = listFiltered()
    setPortefolio(shouldOrderingBy(orderingBy, filtered))
  }, [
    supervisionEfList,
    cotationAnimator,
    cotationContributor,
    contributorSelected,
    orderingBy,
    globalAnimator,
    speAnimator,
  ])

  const renderingNotice = !selectedPortfolio ? terms.Monitoring.Dzp.noPortfolio : terms.Monitoring.Dzp.noData

  return (
    <div className="contributions">
      <div className="head">
        <h2>
          {terms.Monitoring.Contributions.title}
        </h2>
        <ContributionsSelects
          activeJalon={activeJalon + 1}
          selectedEntity={selectedEntity}
          setSelectedEntity={setSelectedEntity}
          isSupervisor={isSupervisor}
          selectedPortfolio={selectedPortfolio}
          setSelectedPortfolio={setSelectedPortfolio}
        />
      </div>
      <Filters
        cotationAnimator={cotationAnimator}
        setCotationAnimator={setCotationAnimator}
        cotationContributor={cotationContributor}
        setCotationContributor={setCotationContributor}
        contributorSelected={contributorSelected}
        setContributorSelected={setContributorSelected}
        orderingBy={orderingBy}
        setOrderingBy={setOrderingBy}
        globalAnimator={globalAnimator}
        setGlobalAnimator={setGlobalAnimator}
        speAnimator={speAnimator}
        setSpeAnimator={setSpeAnimator}
      />
      <CustomTabJalon
        activeJalon={activeJalon}
        setActiveJalon={handleChangeJalon}
      >
        {
          isLoadingData || isLoadingEf ? (
            <div className="loading">
              <Loader />
            </div>
          ) : (
            <div className="wrapper-table">
              {
                (supervisionEfList.length > 0) && !isLoadingData && !isLoadingEf ? (
                  <ContributionsTable
                    supervisionEfList={portefolio}
                    selectedEntity={selectedEntity}
                  />
                ) : (
                  <div className="no-data">
                    <div>
                      {renderingNotice}
                    </div>
                  </div>
                )
              }
            </div>
          )
        }
      </CustomTabJalon>
    </div>
  )
}

export default Contributions
