import { cotationInfo } from 'common/cotation'
import terms from 'common/terms'
import PopOver from 'components/PopOver/PopOver'
import { ReactElement, useState } from 'react'
import { useSelector } from 'react-redux'
import { UserRole } from 'reducers/Users/types'
import { RootState } from 'Store'
import { typeObject } from '../utils'
import Composite from './Composite/Composite'
import './GlobalCotation.scss'
import ModalGlobalContribution from './ModalGlobalContribution/ModalGlobalContribution'

interface props {
  libelle: string,
  cotation: string | null,
  type: string,
  jalonStatus: string,
  jalon: number,
  idObjFer: string,
  comment: string,
  gravity: number,
  numerator: number | null,
  denominator: number,
  borrowed: boolean,
  cotationAxe: string | null,
  commentAxe: string,
  gravityAxe: number,
}

const GlobalCotation = ({
  libelle,
  cotation,
  type,
  jalonStatus,
  jalon,
  idObjFer,
  comment,
  gravity,
  numerator,
  denominator,
  borrowed,
  cotationAxe,
  commentAxe,
  gravityAxe,
}: props): ReactElement => {
  const { userMeSuivi } = useSelector((state: RootState) => state.users)
  const { idSA } = useSelector((state: RootState) => state.app)
  const [open, setOpen] = useState(false)
  const [openAxe, setOpenAxe] = useState(false)

  const currentSa = userMeSuivi?.find(sa => sa.id === idSA)

  const displayCotation = currentSa?.roles.some(
    i => i === UserRole.admin || i === UserRole.animator || i === UserRole.animatorAxe,
  )
    || jalonStatus === terms.Suivi.toBeValidated
    || jalonStatus === terms.Suivi.closed

  const disabledButtonGlobalContribution = jalonStatus !== terms.Suivi.toBeValidated
    && jalonStatus !== terms.Suivi.closed

  const cotationInfoObjFer = (typeObj: string) => {
    switch (typeObj) {
      case 'L':
        return terms.Suivi.GlobalContribution.PopOver.ligne
      case 'C':
        return terms.Suivi.GlobalContribution.PopOver.complexe
      default:
        return terms.Suivi.GlobalContribution.PopOver.travaux
    }
  }

  const popOverContent = (jalonStatus === terms.Suivi.toBeValidated || jalonStatus === terms.Suivi.closed)
    ? terms.Suivi.GlobalContribution.PopOver.globalCotationActiv
    : cotationInfoObjFer(type)

  return (
    <>
      <div className="global-cotation">
        <div className="libelle">
          {libelle}
        </div>
        {displayCotation && (
        <div className="cotation-animator">
          <div className="title">
            {terms.Suivi.GlobalContribution.globalCotation}
          </div>
          <div className="cotation-container">
            <PopOver
              popContent={popOverContent}
            >
              <button
                disabled={disabledButtonGlobalContribution}
                type="button"
                className="button-cotation"
                onClick={() => setOpen(true)}
              >

                <div
                  className="cotation"
                  style={{
                    backgroundColor: cotation !== null ? cotationInfo(cotation)?.color : '#C6C6C6',
                  }}
                >
                  {cotation === null ? '?' : cotation}
                </div>

              </button>
            </PopOver>
            <Composite
              numerator={numerator}
              denominator={denominator}
            />
          </div>
        </div>
        )}
        {
          displayCotation && borrowed && (
            <div className="cotation-animator">
              <div className="title">
                {terms.Suivi.GlobalContribution.globalCotationAnimaxe}
              </div>
              <div className="cotation-container">
                <PopOver
                  popContent={popOverContent}
                >
                  <button
                    disabled={disabledButtonGlobalContribution}
                    type="button"
                    className="button-cotation"
                    onClick={() => setOpenAxe(true)}
                  >

                    <div
                      className="cotation"
                      style={{
                        backgroundColor: cotationAxe !== null ? cotationInfo(cotationAxe)?.color : '#C6C6C6',
                      }}
                    >
                      {cotationAxe === null ? '?' : cotationAxe}
                    </div>

                  </button>
                </PopOver>
              </div>
            </div>
          )
        }
      </div>
      <ModalGlobalContribution
        open={open}
        handleClose={() => setOpen(false)}
        idSa={idSA as string}
        jalon={jalon}
        idObjFerroviaire={idObjFer}
        type={typeObject(type)}
        comment={comment}
        gravity={gravity}
        cotation={cotation}
        disabled={!currentSa?.roles.some((i: string) => [
          UserRole.admin,
          UserRole.animator,
          ...(!borrowed ? [UserRole.animatorAxe] : []),
        ].includes(i as UserRole)) || jalonStatus === terms.Suivi.closed}
        numerator={numerator}
        denominator={denominator}
        withComposite
      />
      <ModalGlobalContribution
        open={openAxe}
        handleClose={() => setOpenAxe(false)}
        idSa={idSA as string}
        jalon={jalon}
        idObjFerroviaire={idObjFer}
        type={typeObject(type)}
        comment={commentAxe}
        gravity={gravityAxe}
        cotation={cotationAxe}
        disabled={!currentSa?.roles.some((i: string) => i === UserRole.admin
          || i === UserRole.animatorAxe) || jalonStatus === terms.Suivi.closed}
      />
    </>
  )
}

export default GlobalCotation
