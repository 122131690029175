import { ReactElement, useEffect, useState } from 'react'
import {
  Box, Chip, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import terms from 'common/terms'
import { Action, DataPilot } from 'reducers/ActionPlan/types'
import { Entity } from 'reducers/Admin/Entity/types'

interface ModalActionVisibilityProps {
  modalAction: Action
  entitiesVisibility: Entity[]
  disabled: boolean
  handlePatchActionPilot?: (actionId: string, data: DataPilot) => void
}

const ModalActionVisibility = ({
  modalAction, entitiesVisibility, disabled, handlePatchActionPilot,
}: ModalActionVisibilityProps): ReactElement => {
  const [visibility, setVisibility] = useState<string[]>([])
  const [hadChanges, setHadChanges] = useState<boolean>(false)

  useEffect(() => {
    if (modalAction && modalAction.visibilite) {
      setVisibility(modalAction.visibilite.flatMap(entity => entity?.id || []))
    }
    setHadChanges(false)
  }, [modalAction])

  const handleChange = (evt: SelectChangeEvent<string[]>) => {
    const { target: { value } } = evt
    const entityIdList: string[] = typeof value === 'string' ? [value] : value
    setVisibility(entityIdList)
    setHadChanges(true)
  }

  const handleClick = () => {
    if (hadChanges && handlePatchActionPilot) {
      handlePatchActionPilot(modalAction.id, {
        visibilite: entitiesVisibility.filter(entity => visibility.includes(entity.id || '')),
      })
    }
  }

  return (
    <div className="modalCopileMode-visibility d-flex w-100">
      <FormControl variant="standard" className="mb-3">
        <InputLabel id="select-visibility">
          {terms.Contributions.visibility.visibility}
        </InputLabel>
        <Select
          disabled={disabled}
          multiple
          value={visibility}
          onChange={handleChange}
          renderValue={selected => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map(value => (
                <Chip key={value} label={entitiesVisibility.find(e => e.id === value)?.libelle} />
              ))}
            </Box>
          )}
        >
          { entitiesVisibility.map(e => (
            <MenuItem key={e.libelle} value={e.id}>
              {e.libelle}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {!disabled && (
        <button
          className="modalCopileMode-visibility-button"
          type="button"
          disabled={!hadChanges}
          onClick={handleClick}
        >
          <FontAwesomeIcon icon={faCircleCheck} />
        </button>
      )}
    </div>
  )
}

ModalActionVisibility.defaultProps = {
  handlePatchActionPilot: undefined,
}

export default ModalActionVisibility
