import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import terms from 'common/terms'
import { initialDate } from 'pages/ActionPlan/utils'
import { User } from 'reducers/Users/types'
import {
  getActionColumn,
  getActionsByComposant,
  getClonableObjects,
  getComposantsByObjFer,
  getComposantsByObjFerCurJalon,
  getObjFerroviaires,
  loadPdfActionPlan,
  patchActionById,
  patchStateAction,
  patchStateActionPilot,
  postCloneAction,
  postComposantsTodoCurJalon,
  postCreateAction,
} from './actionPlan.thunk'
import {
  Action, ActionPlanState, Etat, ObjectClonable,
} from './types'

const initialState: ActionPlanState = {
  createAction: {
    nom: '',
    description: '',
    // eslint-disable-next-line camelcase
    date_ouverture: initialDate().ouverture,
    // eslint-disable-next-line camelcase
    date_bouclage: initialDate().bouclage,
    entite: [],
    pilote: null,
    copilote: null,
    // eslint-disable-next-line camelcase
    besoin_validation: false,
    date_realisation: null,
    date_cloture: null,
  },
  patchAction: {
    id: '',
    nom: '',
    description: '',
    // eslint-disable-next-line camelcase
    date_ouverture: initialDate().ouverture,
    // eslint-disable-next-line camelcase
    date_bouclage: initialDate().bouclage,
    entite: [],
    pilote: null,
    copilote: null,
    // eslint-disable-next-line camelcase
    besoin_validation: false,
    date_realisation: null,
    date_cloture: null,
    etat: null,
    statut: null,
  },
  selectedPilote: null,
  selectedCoPilote: null,
  copilMode: {},
  roadmap: {},
  actionList: [],
  modalAdd: {
    isOpen: false,
    componentId: undefined,
    libelle: undefined,
    jalon: undefined,
    objectType: undefined,
    objectFerId: undefined,
    componentIdString: undefined,
    display: 'list',
    displaySnackbar: false,
    snackMessage: '',
    snackSeverity: 'success',
    clonableObjects: [],
    cloneObject: null,
  },
  modalAction: null,
  isLoading: false,
  isLoadingObj: false,
  isLoadingColumn: false,
  pdfData: null,
}

export const actionPlan = createSlice({
  name: 'ActionPlan',
  initialState,
  reducers: {
    updateComposantCurJalon: (state, { payload }) => {
      state.composantsCurJalon = payload
    },
    changeFormCreateAction: (state, { payload }) => {
      state.createAction = payload
    },
    setName: (state, { payload }) => { state.createAction.nom = payload },
    setPatchName: (state, { payload }) => { state.patchAction.nom = payload },

    setBesoinValidation: (state, { payload }) => { state.createAction.besoin_validation = payload },
    setPatchBesoinValidation: (state, { payload }) => { state.patchAction.besoin_validation = payload },

    setDateOuverture: (state, { payload }) => { state.createAction.date_ouverture = payload },
    setPatchDateOuverture: (state, { payload }) => { state.patchAction.date_ouverture = payload },

    setDateBouclage: (state, { payload }) => { state.createAction.date_bouclage = payload },
    setPatchDateBouclage: (state, { payload }) => { state.patchAction.date_bouclage = payload },

    setDescription: (state, { payload }) => { state.createAction.description = payload },
    setPatchDescription: (state, { payload }) => { state.patchAction.description = payload },

    setEntite: (state, { payload }) => { state.createAction.entite = payload },
    setPatchEntite: (state, { payload }) => { state.patchAction.entite = payload },

    openAddModal: (state, { payload }) => {
      state.modalAdd = {
        ...initialState.modalAdd,
        isOpen: true,
        componentId: payload.componentId,
        libelle: payload.libelle,
        jalon: payload.jalon,
        objectType: payload.objectType,
        objectFerId: payload.objectFerId,
        componentIdString: payload.componentIdString,
      }
      state.actionList = []
    },
    closeAddModal: state => {
      state.modalAdd = {
        ...initialState.modalAdd,
      }
    },
    setModalAddDisplay: (state, { payload }) => {
      state.modalAdd.display = payload
    },
    hideModalSnackbar: state => {
      state.modalAdd.displaySnackbar = false
      state.modalAdd.snackMessage = ''
    },
    selectUserPilote: (state, action: PayloadAction<User | null>) => {
      state.selectedPilote = action.payload
      state.createAction.pilote = action.payload
      state.patchAction.pilote = action.payload
    },
    selectUserCoPilote: (state, action: PayloadAction<User | null>) => {
      state.selectedCoPilote = action.payload
      state.createAction.copilote = action.payload
      state.patchAction.copilote = action.payload
    },
    resetForm: state => {
      state.createAction = initialState.createAction
      state.patchAction = initialState.patchAction
      state.selectedPilote = null
      state.selectedCoPilote = null
      state.modalAdd.display = 'list'
    },
    setPatchAction: (state, action: PayloadAction<Action>) => {
      state.patchAction.id = action.payload.id
      state.patchAction.nom = action.payload.nom
      state.patchAction.description = action.payload.description
      state.patchAction.date_ouverture = action.payload.date_ouverture || initialDate().ouverture
      state.patchAction.date_bouclage = action.payload.date_bouclage || initialDate().bouclage
      state.patchAction.date_realisation = action.payload.date_realisation
      state.patchAction.date_cloture = action.payload.date_cloture
      state.patchAction.entite = action.payload.entite
      state.patchAction.etat = action.payload.etat
      state.patchAction.statut = action.payload.statut
      state.patchAction.besoin_validation = !action.payload.etat.includes(Etat.SKIPPED)
    },
    setModalAction: (state, action: PayloadAction<Action | null>) => {
      state.modalAction = action.payload
    },
    setCloneObject: (state, action: PayloadAction<ObjectClonable | null>) => {
      state.modalAdd.cloneObject = action.payload
    },
    cloneAction: (state, action: PayloadAction<Action>) => {
      const pilote = action.payload.pilote ? { ...action.payload.pilote, email: '', cp: '' } : null
      const copilote = action.payload.copilote ? { ...action.payload.copilote, email: '', cp: '' } : null
      state.createAction = {
        nom: action.payload.nom,
        description: action.payload.description,
        date_ouverture: initialDate().ouverture,
        date_bouclage: initialDate().bouclage,
        entite: action.payload.entite,
        besoin_validation: false,
        pilote,
        copilote,
        date_cloture: null,
        date_realisation: null,
      }
      state.selectedPilote = pilote
      state.selectedCoPilote = copilote
    },
    resetPdfData: state => {
      state.pdfData = null
    },
  },
  extraReducers(builder) {
    builder.addCase(getObjFerroviaires.pending, state => {
      state.isLoadingObj = true
    })
    builder.addCase(getObjFerroviaires.rejected, state => {
      state.isLoadingObj = false
    })
    builder.addCase(getObjFerroviaires.fulfilled, (state, { payload }) => {
      state.objFerroviaires = payload[0].obj_ferroviaires
      state.isLoadingObj = false
    })
    builder.addCase(getComposantsByObjFer.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getComposantsByObjFer.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(getComposantsByObjFer.fulfilled, (state, { payload }) => {
      state.composants = payload
      state.isLoading = false
    })
    builder.addCase(getComposantsByObjFerCurJalon.fulfilled, (state, { payload }) => {
      state.composantsCurJalon = payload
    })
    builder.addCase(postComposantsTodoCurJalon.fulfilled, (state, { payload }) => {
      state.composants = payload
    })
    builder.addCase(getActionColumn.pending, state => {
      state.isLoadingColumn = true
      state.roadmap = {}
    })
    builder.addCase(getActionColumn.rejected, state => {
      state.isLoadingColumn = false
    })
    builder.addCase(getActionColumn.fulfilled, (state, { payload }) => {
      state.isLoadingColumn = false
      switch (payload.columnName) {
        case 'to-clear':
          state.copilMode.toClearList = payload.data
          break
        case 'validated':
          state.copilMode.validatedList = payload.data
          break
        case 'rejected':
          state.copilMode.rejectedList = payload.data
          break
        case 'todo':
          state.roadmap.todoList = payload.data
          break
        case 'finished':
          state.roadmap.finishedList = payload.data
          break
        case 'closed':
          state.roadmap.closedList = payload.data
          break
        default:
          break
      }
    })
    builder.addCase(getActionsByComposant.fulfilled, (state, { payload }) => {
      state.actionList = payload
    })
    builder.addCase(postCreateAction.fulfilled, state => {
      state.modalAdd.displaySnackbar = true
      state.modalAdd.snackMessage = terms.actionPlan.modalAddAction.addActionSucess
      state.modalAdd.snackSeverity = 'success'
    })
    builder.addCase(postCreateAction.rejected, state => {
      state.modalAdd.displaySnackbar = true
      state.modalAdd.snackMessage = terms.actionPlan.modalAddAction.addActionError
      state.modalAdd.snackSeverity = 'error'
    })
    builder.addCase(postCloneAction.fulfilled, state => {
      state.modalAdd.displaySnackbar = true
      state.modalAdd.snackMessage = terms.actionPlan.modalAddAction.cloneActionSucess
      state.modalAdd.snackSeverity = 'success'
    })
    builder.addCase(postCloneAction.rejected, state => {
      state.modalAdd.displaySnackbar = true
      state.modalAdd.snackMessage = terms.actionPlan.modalAddAction.cloneActionError
      state.modalAdd.snackSeverity = 'error'
    })
    builder.addCase(patchActionById.fulfilled, (state, { payload }) => {
      state.actionList = state.actionList.map(action => (action.id === payload.id ? payload : action))
    })
    builder.addCase(getClonableObjects.fulfilled, (state, { payload }) => {
      state.modalAdd.clonableObjects = payload
    })
    builder.addCase(loadPdfActionPlan.pending, state => {
      state.isLoading = true
    })
    builder.addCase(loadPdfActionPlan.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(loadPdfActionPlan.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.pdfData = payload
    })
    builder.addMatcher(isAnyOf(
      patchStateAction.fulfilled,
      patchStateActionPilot.fulfilled,
    ), (state, { payload }) => {
      state.modalAction = { ...state.modalAction, ...payload }
    })
    builder.addMatcher(isAnyOf(
      patchActionById.fulfilled,
      patchStateAction.fulfilled,
      patchStateActionPilot.fulfilled,
    ), state => {
      state.modalAdd.displaySnackbar = true
      state.modalAdd.snackMessage = terms.actionPlan.successSnackbar.updateAction
      state.modalAdd.snackSeverity = 'success'
    })
    builder.addMatcher(isAnyOf(
      patchActionById.rejected,
      patchStateAction.rejected,
      patchStateActionPilot.rejected,
    ), state => {
      state.modalAdd.displaySnackbar = true
      state.modalAdd.snackMessage = terms.actionPlan.errorSnackbar.updateAction
      state.modalAdd.snackSeverity = 'error'
    })
  },
})

export const {
  updateComposantCurJalon,
  setDateOuverture,
  setPatchDateOuverture,
  setDateBouclage,
  setPatchDateBouclage,
  setDescription,
  setPatchDescription,
  selectUserPilote,
  selectUserCoPilote,
  resetForm,
  setEntite,
  setPatchEntite,
  setName,
  setPatchName,
  setBesoinValidation,
  setPatchBesoinValidation,
  openAddModal,
  closeAddModal,
  hideModalSnackbar,
  setPatchAction,
  setModalAddDisplay,
  setModalAction,
  setCloneObject,
  cloneAction,
  resetPdfData,
} = actionPlan.actions

export default actionPlan.reducer
