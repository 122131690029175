import {
  Checkbox, MenuItem, Select, SelectChangeEvent,
} from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'

interface Props {
  setSelectedComponent: (value: string[]) => void
  selectedComponent: string[]
  possibleComponent: { id: number, libelle: string }[]
}

const SELECT_ALL_VALUE = 'all'

const SelectComponent = ({ setSelectedComponent, selectedComponent, possibleComponent }: Props): ReactElement => {
  const handleSelectComponent = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target
    if (value.includes(SELECT_ALL_VALUE)) {
      if (selectedComponent.length === possibleComponent.length) {
        return setSelectedComponent([])
      }
      return setSelectedComponent([...possibleComponent.map(e => e.id.toString() || '')])
    }
    return setSelectedComponent(value as string[])
  }

  return (
    <div className="component-select">
      <Select
        multiple
        size="small"
        value={selectedComponent}
        onChange={handleSelectComponent}
        displayEmpty
        renderValue={() => (
          <div className="select-wrapper">
            {
              selectedComponent.length === 0
                ? (
                  <span className="placeholder">
                    {terms.Monitoring.Contributions.Table.component}
                  </span>
                )
                : (
                  <span>
                    {terms.Monitoring.Contributions.Selects.componentSelectLabel(selectedComponent)}
                  </span>
                )
            }
          </div>

        )}
      >
        <MenuItem
          key={SELECT_ALL_VALUE}
          value={SELECT_ALL_VALUE}
          className={selectedComponent.length === possibleComponent.length ? 'Mui-selected' : ''}
          style={{ paddingLeft: '5px' }}
        >
          <Checkbox
            size="small"
            checked={selectedComponent.length === possibleComponent.length}
          />
          <span>
            {possibleComponent.length === selectedComponent.length
              ? terms.Contributions.visibility.unSelectAll
              : terms.Contributions.visibility.selectAll}
          </span>
        </MenuItem>
        { possibleComponent.map(e => (
          <MenuItem key={e.libelle} value={e.id}>
            <Checkbox
              size="small"
              checked={selectedComponent && selectedComponent.some(i => i.toString() === e.id.toString())}
            />
            <span>
              {`${e.id} - ${e.libelle}`}
            </span>
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

export default SelectComponent
