import { Box, Modal, Typography } from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { PrimaryBtn } from 'themes/theme'
import InfoIcon from '@mui/icons-material/Info'
import './modalInfo.scss'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}

interface ModalInfoProps {
  onModal: boolean,
  content: string | undefined | string[],
  handleClose: () => void,
  titleOption?: string | string[],
  contentOption?: string | string[],
  contentOption1?: string | string[],
  contactMail?: string,
  contactPhone?: string,
}

const ModalInfo = ({
  onModal,
  content,
  handleClose,
  titleOption,
  contentOption,
  contentOption1,
  contactMail,
  contactPhone,
}: ModalInfoProps): ReactElement => (
  <Modal
    open={onModal}
    onClose={handleClose}
  >
    <Box className="modal-info" sx={style}>
      <InfoIcon className=" modal-info_icon" />
      <Typography className="modal-info_title mt-4" variant="h6" component="h1" color="success">
        {terms.Common.info}
      </Typography>
      {(titleOption !== '') && (
        <Typography className="modal-info_title mt-4" variant="h6" component="h1" color="success">
          {titleOption}
        </Typography>
      )}
      <Typography className="modal-info_content mt-4 mb-4" variant="h6" component="h2">
        {content}
      </Typography>
      {(contentOption !== '') && (
        <Typography className="modal-info_content mt-4 mb-4" variant="h6" component="h2">
          {contentOption}
        </Typography>
      )}
      {(contentOption1 !== '') && (
        <Typography className="modal-info_content mt-4 mb-4" variant="h6" component="h2">
          {contentOption1}
        </Typography>
      )}
      {(contactMail !== '' || contactPhone !== '') && (
        <Typography className="modal-info_content mt-4 mb-4" variant="h6" component="h2">
          {(contactMail !== '') && (
            <p>
              {terms.Common.contactMail}
              <span className="spanModal">
                {contactMail}
              </span>
            </p>
          )}
          {(contactPhone !== '') && (
            <p>
              {terms.Common.contactPhone}
              <span className="spanModal">
                {contactPhone}
              </span>
            </p>
          )}
        </Typography>
      )}

      <PrimaryBtn
        onClick={handleClose}
      >
        {terms.Admin.close}
      </PrimaryBtn>
    </Box>
  </Modal>
)

ModalInfo.defaultProps = {
  titleOption: '',
  contentOption: '',
  contentOption1: '',
  contactMail: '',
  contactPhone: '',
}

export default ModalInfo
