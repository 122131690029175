import terms from 'common/terms'
import { ReactElement } from 'react'

const IfNoContributionTableView = (): ReactElement => (
  <div className="arrayComponent-body-section-second-noProposal">
    {terms.Suivi.arrayComponent.noContribution}
  </div>
)

export default IfNoContributionTableView
