import { RootState } from 'Store'
import { debounce } from 'lodash'
import {
  ReactElement, useCallback, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { controllerComplexes, searchComplexes } from 'reducers/Admin/Complexes/complexes.thunk'
import { Entity } from 'reducers/Admin/Entity/types'
import { controllerLigne, getLignes } from 'reducers/Admin/Portfolio/portfolio.thunk'
import { ModalType, PortfolioItem } from 'reducers/Admin/Portfolio/type'
import { controllerConstructionSites, getConstructionSites } from 'reducers/Admin/admin.thunk'
import { UserRole } from 'reducers/Users/types'
import { AutocompleteValue } from 'reducers/types'
import portfolioService from 'services/portfolioService'
import MapGL from 'components/MapGL/MapGL'
import { MapEvent } from 'react-map-gl'
import ButtonsAction from './ButtonsAction'
import ModalCreateOrUpdate from './Modals/ModalCreateOrUpdate'
import ModalDelete from './Modals/ModalDelete'

import Form from './Form'

interface Props {
  portfolio: PortfolioItem
}

const SupervisionPortfolioDetail = ({ portfolio }: Props): ReactElement => {
  const dispatch = useDispatch()
  const { entities } = useSelector((state: RootState) => state.entity)
  const { complexes } = useSelector((state: RootState) => state.complexes)
  const { robPermission, usersEntitySupervisor } = useSelector((state: RootState) => state.users)
  const { robustestComplexes, lignes } = useSelector((state: RootState) => state.portfolio)
  const { constructionSites } = useSelector((state: RootState) => state.constructionSites)
  const [search, setSearch] = useState('')
  const [selectedObj, setSelectedObj] = useState<AutocompleteValue[]>([])
  const [portfolioEntity, setPortfolioEntity] = useState<Entity | null>(null)
  const [portfolioName, setPortfolioName] = useState('')
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [openModalModify, setOpenModalModify] = useState(false)

  const debouncedSearchObj = useCallback(debounce((value: string) => {
    controllerLigne.abort()
    controllerComplexes.abort()
    controllerConstructionSites.abort()
    setSearch(value)
    dispatch(getLignes(value))
    dispatch(searchComplexes(value))
    dispatch(getConstructionSites(value))
  }, 500), [])

  const possibleEntities = () => {
    if (robPermission.includes(UserRole.admin)) {
      return entities
    }
    return entities.filter(entity => usersEntitySupervisor?.includes(entity.id as string))
  }

  useEffect(() => {
    if (possibleEntities().length === 1) {
      setPortfolioEntity(entities.find(item => item.id === usersEntitySupervisor) || null)
    }
  }, [possibleEntities()])

  useEffect(() => {
    if (portfolio) {
      setPortfolioName(portfolio.libelle)
      setSelectedObj(portfolioService.getObjList(
        portfolio.batiments_voyageurs,
        portfolio.travaux,
        portfolio.lignes,
        robustestComplexes,
      ))
      setPortfolioEntity(portfolio.entite)
    }
  }, [portfolio])

  const onFeatureClick = () => null

  return (
    <div>
      <div className="content">
        <Form
          portfolioEntity={portfolioEntity}
          portfolioName={portfolioName}
          possibleEntities={possibleEntities()}
          setPortfolioEntity={setPortfolioEntity}
          setPortfolioName={setPortfolioName}
          debouncedSearchObj={debouncedSearchObj}
          setSelectedObj={setSelectedObj}
          search={search}
          selectedObj={selectedObj}
          objList={portfolioService.getObjList(complexes, constructionSites, lignes, robustestComplexes)}
          disabled
        />
        <div className="map">
          <MapGL
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onFeatureClick={(_: MapEvent) => onFeatureClick()}
            bvSelected={portfolio.batiments_voyageurs}
            constructionSiteSelected={portfolio.travaux}
            sectionLineSelected={portfolio.lignes}
          />
        </div>
      </div>
      <ButtonsAction
        setOpenModalDelete={setOpenModalDelete}
        setOpenModalModify={setOpenModalModify}
      />
      <ModalDelete
        portfolio={portfolio}
        open={openModalDelete}
        handleClose={() => setOpenModalDelete(false)}
      />
      <ModalCreateOrUpdate
        open={openModalModify}
        handleClose={() => setOpenModalModify(false)}
        portfolio={portfolio}
        modalType={ModalType.UPDATE}
      />
    </div>

  )
}

export default SupervisionPortfolioDetail
