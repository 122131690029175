import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import { styled } from '@mui/material/styles'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(() => ({
  backgroundColor: 'white',
  width: '100%',
  borderRadius: '5px',
  marginTop: '5px',
}))

export const ListAccordion = styled(Accordion)(() => ({
}))

export const ListAccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: '5px 15px',
}))

export const AccordionDetails = styled(ListAccordionDetails)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  borderBottomLeftRadius: '5px',
  WebkitBorderBottomRightRadius: '5px',
}))

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.gray.light,
  borderRadius: '5px',
}))
