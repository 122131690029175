import { Autocomplete, FormLabel, TextField } from '@mui/material'
import terms from 'common/terms'
import {
  ReactElement, SyntheticEvent, useState, useCallback,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { Entity } from 'reducers/Admin/Entity/types'
import { User } from 'reducers/Users/types'
import { controller, getAll } from 'reducers/Users/users.thunk'
import { RootState } from 'Store'
import { PrimaryBtn } from 'themes/theme'
import HighlightMatch from 'components/HighlightMatch'

export interface SettingsTabFormProps {
  user: User | null,
  onUserChange?: (event: SyntheticEvent, value: User | null) => void,
  entity: Entity | null,
  disableEntity?: boolean,
  onEntityChange?: (event: SyntheticEvent, value: Entity | null) => void,
  handleValidate?: () => void,
}

const SettingsTabForm = ({
  user, onUserChange, entity, onEntityChange, handleValidate, disableEntity,
}: SettingsTabFormProps): ReactElement => {
  const dispatch = useDispatch()
  const { users, isLoading } = useSelector((state: RootState) => state.users)
  const { entities } = useSelector((state: RootState) => state.entity)
  const [userSearch, setUserSearch] = useState('')
  const [entitySearch, setEntitySearch] = useState('')
  const debouncedSearch = useCallback(debounce(value => dispatch(getAll(value)), 250), [])

  const handleSearch = (value: string) => {
    setUserSearch(value)
    if (value.length > 2) {
      controller.abort()
      debouncedSearch(value)
    }
  }

  return (
    <div className="settings-tab-form">
      <div className="settings-tab-form-add-user">
        <FormLabel required>
          {userSearch.length < 3 && userSearch.length !== 0
            ? terms.Common.userSearch.condition(terms.Settings.settingsTab.form.user)
            : terms.Settings.settingsTab.form.user}
        </FormLabel>
        <Autocomplete
          disablePortal
          loading={isLoading}
          options={users}
          getOptionLabel={option => `${option?.lastName?.toUpperCase()} ${option?.firstName}`}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              <HighlightMatch
                optionLabel={option.displayName}
                inputValue={userSearch}
              />
            </li>
          )}
          noOptionsText={userSearch ? terms.Common.noResults : terms.Settings.settingsTab.form.userSearch}
          value={user}
          renderInput={params => (
            <TextField
              {...params}
              placeholder={terms.Settings.settingsTab.form.userSearchPlaceholder}
              fullWidth
              size="small"
              error={userSearch.length < 3 && userSearch.length !== 0}
            />
          )}
          onInputChange={(_, newInputValue) => handleSearch(newInputValue)}
          onChange={onUserChange}
        />
      </div>
      <div className="settings-tab-form-add-entity my-4">
        <FormLabel required>
          {terms.Settings.settingsTab.form.entity}
        </FormLabel>
        <Autocomplete
          disablePortal
          disabled={disableEntity}
          options={entities}
          getOptionLabel={option => option.libelle}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          noOptionsText={entitySearch ? terms.Common.noResults : terms.Settings.settingsTab.form.entitySearch}
          value={entity}
          renderInput={params => (
            <TextField
              {...params}
              placeholder={terms.Settings.settingsTab.form.entitySearchPlaceholder}
              fullWidth
              size="small"
            />
          )}
          onInputChange={(_, newInputValue) => setEntitySearch(newInputValue)}
          onChange={onEntityChange}
        />
      </div>
      <div className="text-center mt-4">
        <PrimaryBtn
          disabled={!user || !entity}
          onClick={handleValidate}
        >
          {terms.Common.add}
        </PrimaryBtn>
      </div>
    </div>
  )
}

SettingsTabForm.defaultProps = {
  onUserChange: undefined,
  onEntityChange: undefined,
  handleValidate: undefined,
  disableEntity: false,
}

export default SettingsTabForm
