import { get, patch, post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import terms from 'common/terms'
import { setSnackbar, SnackbarSeverity } from 'reducers/app'
import { PonderationCotation, PonderationListResponse, PonderationResponse } from './types'

const getPonderationList = createAsyncThunk(
  'ponderation/list/get',
  async (_, thunkApi) => {
    try {
      const response: PonderationListResponse = await get('/robustest/ponderations/')
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getPonderationCotation = createAsyncThunk(
  'ponderation/cotation/get',
  async (_, thunkApi) => {
    try {
      const response: PonderationCotation[] = await get('/robustest/cotations/')
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const patchPonderationCotation = createAsyncThunk(
  'ponderation/cotation/patch',
  async (ponderationCotation: Partial<PonderationCotation>, thunkApi) => {
    try {
      const response: PonderationCotation = await patch(`/robustest/cotations/${ponderationCotation.id}`, {
        score: ponderationCotation.score,
      })

      thunkApi.dispatch(setSnackbar({
        message: terms.Admin.ponderation.snackbars.success,
        severity: SnackbarSeverity.SUCCESS,
      }))
      return response
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      thunkApi.dispatch(setSnackbar({
        message: terms.Admin.ponderation.snackbars.error,
        severity: SnackbarSeverity.ERROR,
      }))
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

type ResponsePost = {
  // eslint-disable-next-line camelcase
  num_composant: number
  type: string
  poids: number
}

const postPonderationComposant = createAsyncThunk(
  'ponderation/composant/post',
  async (ponderationComposant: Partial<PonderationResponse>, thunkApi) => {
    try {
      const response: ResponsePost = await post('/robustest/ponderations/', ponderationComposant)

      thunkApi.dispatch(setSnackbar({
        message: terms.Admin.ponderation.snackbars.success,
        severity: SnackbarSeverity.SUCCESS,
      }))
      return response
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      thunkApi.dispatch(setSnackbar({
        message: terms.Admin.ponderation.snackbars.error,
        severity: SnackbarSeverity.ERROR,
      }))
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

// eslint-disable-next-line import/prefer-default-export
export {
  getPonderationList,
  getPonderationCotation,
  patchPonderationCotation,
  postPonderationComposant,
}
