import {
  Autocomplete,
  FormControl, FormLabel, MenuItem, Select,
  TextField,
} from '@mui/material'
import ModalWrapper from 'components/Modal/ModalWrapper/ModalWrapper'
import { ReactElement } from 'react'

import '../../SupervisionPortfolios.scss'
import { PortfolioItem } from 'reducers/Admin/Portfolio/type'
import terms from 'common/terms'
import { Entity } from 'reducers/Admin/Entity/types'

interface Props {
  open: boolean
  handleClose: () => void
  portfolios: PortfolioItem[]
  portfolioSelected: string
  setPortfolioSelected: (value: string) => void
  handleOpenModalCreate: () => void
  possibleEntities: Entity[]
  portfolioEntity: Entity | null
  isSupervisor: boolean
  setPortfolioEntity: (value: Entity | null) => void
}

const ModalImport = ({
  open,
  handleClose,
  portfolios,
  portfolioSelected,
  setPortfolioSelected,
  handleOpenModalCreate,
  possibleEntities,
  portfolioEntity,
  isSupervisor,
  setPortfolioEntity,
}: Props): ReactElement => (
  <ModalWrapper
    title={terms.ParametragePortfolio.importButton}
    open={open}
    onClose={handleClose}
  >
    <div className="modal-import">
      <div className="input">
        <FormControl fullWidth>
          <FormLabel
            htmlFor="outlined-adornment-amount"
            required
          >
            {terms.Admin.portfolio.form.labelEntity}
          </FormLabel>
          <Autocomplete
            disabled={isSupervisor}
            fullWidth
            disablePortal
            loading={false}
            options={possibleEntities}
            getOptionLabel={option => option.libelle}
            isOptionEqualToValue={(option, value) => option.libelle === value.libelle}
            noOptionsText={terms.Common.noResults}
            value={portfolioEntity}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                id="outlined-controlled"
                size="small"
              />
            )}
            onChange={(_, value) => setPortfolioEntity(value)}
          />
        </FormControl>
        <FormControl fullWidth>
          <FormLabel>
            {terms.ParametragePortfolio.genericPortfolio}
          </FormLabel>
          <Select
            size="small"
            onChange={e => setPortfolioSelected(e.target.value)}
            value={portfolioSelected}
          >
            {
              portfolios.map(portfolio => (
                <MenuItem
                  key={portfolio.id}
                  value={portfolio.id}
                >
                  {portfolio.libelle}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </div>
      <div className="button">
        <button
          type="button"
          onClick={() => {
            handleOpenModalCreate()
            handleClose()
          }}
        >
          {terms.Common.import}
        </button>
      </div>
    </div>
  </ModalWrapper>
)

export default ModalImport
