import { Checkbox, FormControlLabel } from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { OrderingByEnum } from 'reducers/monitoring/types'
import OrderingBy from './OrderingBy/OrderingBy'
import SelectCotation from './SelectCotation/SelectCotation'

import './Filters.scss'

interface Props {
  cotationAnimator: string[];
  setCotationAnimator: (rating: string[]) => void;
  cotationContributor: string[];
  setCotationContributor: (rating: string[]) => void;
  contributorSelected: boolean;
  setContributorSelected: (value: boolean) => void;
  orderingBy: OrderingByEnum;
  setOrderingBy: (value: OrderingByEnum) => void;
  globalAnimator: boolean;
  setGlobalAnimator: (value: boolean) => void;
  speAnimator: boolean;
  setSpeAnimator: (value: boolean) => void;
}

const Filters = ({
  cotationAnimator,
  setCotationAnimator,
  cotationContributor,
  setCotationContributor,
  contributorSelected,
  setContributorSelected,
  orderingBy,
  setOrderingBy,
  globalAnimator,
  setGlobalAnimator,
  speAnimator,
  setSpeAnimator,
}: Props): ReactElement => (
  <div className="filters">
    <div className="item">
      <OrderingBy
        value={orderingBy}
        handleClick={() => setOrderingBy(
          orderingBy === OrderingByEnum.COMPONENT
            ? OrderingByEnum.TERRITORY
            : OrderingByEnum.COMPONENT,
        )}
      />
    </div>
    <div className="item">
      <FormControlLabel
        className="checkbox"
        control={(
          <Checkbox
            checked={globalAnimator}
            onChange={() => setGlobalAnimator(!globalAnimator)}
          />
        )}
        label={terms.Monitoring.Contributions.Filters.global}
      />
      <FormControlLabel
        className="checkbox"
        control={(
          <Checkbox
            checked={speAnimator}
            onChange={() => setSpeAnimator(!speAnimator)}
          />
        )}
        label={terms.Monitoring.Contributions.Filters.spe}
      />
      <SelectCotation
        values={cotationAnimator}
        setCotation={setCotationAnimator}
      />
    </div>
    <div className="item">
      <FormControlLabel
        className="checkbox"
        control={(
          <Checkbox
            checked={contributorSelected}
            onChange={() => setContributorSelected(!contributorSelected)}
          />
        )}
        label={terms.Monitoring.Contributions.Filters.contributor}
      />
      <SelectCotation
        values={cotationContributor}
        setCotation={setCotationContributor}
      />
    </div>
  </div>
)

export default Filters
