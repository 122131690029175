/* eslint-disable camelcase */
import { Entity } from 'reducers/Admin/Entity/types'
import { ObjectFerTab } from 'reducers/SA/types'
import { UserSimple, User } from 'reducers/Users/types'

export type ActionPlanState = {
  objFerroviaires?: ObjectFerTab[],
  composants?: ComposantAction[],
  composantsCurJalon?: ComposantAction[],
  createAction: CreateAction,
  patchAction: PatchAction,
  selectedPilote: User | null,
  selectedCoPilote: User | null,
  actionList: Action[],
  copilMode: {
    toClearList?: ComposantWithAction[]
    validatedList?: ComposantWithAction[]
    rejectedList?: ComposantWithAction[]
  }
  roadmap: {
    todoList?: ComposantWithAction[]
    finishedList?: ComposantWithAction[]
    closedList?: ComposantWithAction[]
  }
  modalAdd: {
    isOpen: boolean,
    componentId: number | undefined,
    libelle: string | undefined,
    jalon: number | undefined,
    objectType: string | undefined,
    objectFerId: string | undefined,
    componentIdString: string | undefined,
    display: 'add' | 'edit' | 'list' | 'clone',
    displaySnackbar: boolean,
    snackMessage: string,
    snackSeverity: 'success' | 'error' | 'warning' | 'info',
    clonableObjects: ObjectClonable[],
    cloneObject: ObjectClonable | null,
  }
  modalAction: Action | null,
  isLoading: boolean,
  isLoadingObj: boolean,
  isLoadingColumn: boolean,
  pdfData: ArrayBuffer | null,
}

export enum Weather {
  SUNNY = 'sunny',
  CLOUDY = 'cloudy',
  RAINY = 'rainy',
  STORMY = 'stormy',
  NONE = 'none',
}

export enum ActionTileType {
  limited = 'limited',
  unlimited = 'unlimited',
  unlimitedDecider = 'unlimitedDecider',
  plusplus = '++',
}

export enum ActionModalType {
  limited = 'limited',
  unlimited = 'unlimited',
  unlimitedDecider = 'unlimitedDecider',
}

export type Action = {
  id: string,
  nom: string,
  description: string,
  etat: string,
  statut: string,
  meteo_animateur: number,
  meteo_pilote: number,
  date_ouverture: string | null,
  date_bouclage: string | null,
  date_realisation: string | null,
  date_cloture: string | null,
  decision_validation: string,
  etat_avancement_animateur: string,
  etat_avancement_pilote: string,
  pilote?: UserSimple,
  copilote?: UserSimple,
  entite: Entity[],
  visibilite: Entity[],
  done_by_anim: boolean,
  retard: boolean,
  from_axe: boolean,
}

export type ActionRaw = {
  id: string,
  nom: string,
  description: string,
  etat: string,
  statut: string,
  meteo_animateur: number,
  meteo_pilote: number,
  date_ouverture: string | null,
  date_bouclage: string | null,
  date_realisation: string | null,
  date_cloture: string | null,
  decision_validation: string,
  etat_avancement_animateur: string,
  etat_avancement_pilote: string,
  pilote?: {
    cerbere_id: string,
  },
  copilote?: {
    cerbere_id: string,
  },
  entite: Entity[],
  visibilite: Entity[],
  done_by_anim: boolean,
  retard: boolean,
  from_axe: boolean,
}

export type ResponseAction = {
  id: string,
  nom: string,
  description: string,
  etat?: string,
  meteo_animateur: number,
  meteo_pilote?: number,
  plannedDate?: string,
  doneDate?: string,
  date_ouverture?: string,
  date_cloture?: string,
  date_realisation?: string,
  decision_validation?: string | null,
  etat_avancement_animateur?: string,
  etat_avancement_pilote?: string,
  pilote?: {
    cerbere_id: string,
  },
  copilote?: {
    cerbere_id: string,
  },
  entite?: Entity,
  from_axe: boolean,
}

export type GetParams = {
  saId: string | null,
  objectId: string,
  type: string,
  status?: string,
}

export type GetActionsParams = {
  saId: string | null,
  objectId: string,
  type: string,
  componentId: string,
}

export type PostComposantsTodoCurJalonParams = {
  saId: string | null,
  objectId: string,
  type: string,
  composants: {
    id: string,
    displayed: boolean,
  }[],
}

export type ComposantAction = {
  id: string,
  composant: {
    id: number,
    libelle: string,
    active: boolean,
    national: boolean,
  },
  cotation: string,
  numero_jalon: number,
  displayed: boolean,
  annee: number,
  has_any_action: boolean,
}

export type CreateAction = {
  nom: string,
  description: string,
  date_ouverture: string,
  date_bouclage: string,
  entite: Entity[],
  pilote: User | null,
  copilote: User | null,
  besoin_validation: boolean
  date_realisation: string | null,
  date_cloture: string | null,
}

export type PatchAction = CreateAction & {
  id: string,
  etat: string | null,
  statut: string | null,
}

export type PostCreateActionParams = {
  saId: string | null,
  type: string,
  objectId: string,
  composantsId: string,
  action: CreateAction,
}

export type PatchActionByIdParams = {
  saId: string | null,
  type: string,
  objectId: string,
  composantsId: string,
  action: PatchAction,
}

export type Composant = {
  id: number,
  libelle: string,
  active: boolean,
  national: boolean,
}

export type ComposantWithAction = {
  actions: Action[]
  annee: number
  composant: Composant
  cotation: string
  displayed: true
  id: string
  numero_jalon: number
}

export type ComposantWithActionRaw = {
  actions: ActionRaw[]
  annee: number
  composant: Composant
  cotation: string
  displayed: true
  id: string
  numero_jalon: number
}

export type PatchStateActionParams = {
  saId: string | null,
  type: string,
  objectId: string,
  composantsId: string | null,
  actionId: string,
  data: DataAnimCopil
}

export type DataAnimCopil = {
  statut?: string,
  etat?: string,
  meteo_animateur?: number,
  etat_avancement_animateur?: string,
  meteo_pilote?: number,
  etat_avancement_pilote?: string,
  decision_validation?: string
}

export type PatchStateActionPilotParams = {
  saId: string | null,
  type: string,
  objectId: string,
  composantsId: string | null,
  actionId: string,
  data: DataPilot
}

export type DataPilot = {
  statut?: string,
  meteo_pilote?: number,
  etat_avancement_pilote?: string,
  visibilite?: Entity[],
}

export type GetClonableObjectsParams = {
  saId: string,
  componentId: number,
  jalonNumber: number,
  objectId: string,
}

export type ObjectClonable= {
  id: string,
  libelle: string,
  type: 'C' | 'L' | 'T',
  composant_id: string,
}

export enum Statut {
  TODO = 'TD',
  FINISHED = 'F',
  CLOSED = 'C',
}

export enum Etat {
  TOCLEAR = 'TC',
  VALIDATED = 'V',
  REJECTED = 'R',
  SKIPPED = 'S',
}

export enum ActionPlanTab {
  ROADMAP = 'roadmap',
  COPIL = 'copil',
}

export type LoadPdfActionPlanParams = {
  sa: string | null,
  mode: string,
}
