import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import { ReactElement } from 'react'

interface Props {
  value: boolean
  label: string
  handleChange: () => void
  labelPlacement?: 'top' | 'bottom' | 'end' | 'start'
}

const CustomSwitch = ({
  value, handleChange, label, labelPlacement,
}: Props): ReactElement => (
  <FormGroup className="switch">
    <FormControlLabel
      labelPlacement={labelPlacement}
      control={(
        <Switch
          checked={value}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      )}
      label={label}
    />
  </FormGroup>
)

CustomSwitch.defaultProps = {
  labelPlacement: 'end',
}

export default CustomSwitch
