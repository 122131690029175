import { ReactElement } from 'react'
import {
  MenuItem, Select, SelectChangeEvent,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import './formCreateSa.scss'
import terms from 'common/terms'

type FormCreateSAProps = {
  handleChangeTerritory: (event: SelectChangeEvent) => void,
  handleChangeDate: (event: SelectChangeEvent) => void,
}

const planSAYears = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

const FormCreateSA = ({
  handleChangeTerritory,
  handleChangeDate,
}: FormCreateSAProps): ReactElement => {
  const { territory, yearNewSa } = useSelector((state: RootState) => state.sa)
  const { territories, axes } = useSelector((state: RootState) => state.users.userMe)
  const currentYear = new Date().getFullYear()

  return (
    <div className="formSACreate">
      <Select
        className="formSACreate-item"
        onChange={handleChangeDate}
        value={yearNewSa.toString()}
      >
        {
          planSAYears.map(item => <MenuItem key={item} value={currentYear + item}>{currentYear + item}</MenuItem>)
        }
      </Select>
      <Select
        onChange={handleChangeTerritory}
        value={territory.libelle}
        className="formSACreate-item"
      >
        <div className="select-category">
          <div className="title">
            {terms.CreateSA.axe}
          </div>
        </div>
        {
          axes.map(item => <MenuItem key={item.id} value={item.libelle}>{item.libelle}</MenuItem>)
        }
        <div className="select-category">
          <div className="title">
            {terms.CreateSA.territories}
          </div>
        </div>
        {
          territories.map(item => <MenuItem key={item.id} value={item.libelle}>{item.libelle}</MenuItem>)
        }
      </Select>
    </div>
  )
}

export default FormCreateSA
