import { Eic } from 'reducers/Admin/Eic/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getAxes, postAxe } from './axes.thunk'
import { AxesState } from './types'

const initialState: AxesState = {
  axesList: [],
  displaySnackbar: false,
  isLoadingAxes: false,
}

export const axes = createSlice({
  name: 'axes',
  initialState,
  reducers: {
    addAxe: state => {
      if (state.axesList.find(stateAxe => stateAxe.id === 'new')) {
        return
      }
      state.axesList = [...state.axesList, {
        libelle: '', id: 'new', animateurs: [], visiteurs: [],
      }]
    },
    changeAxe: (state, action: PayloadAction<Partial<Eic>>) => {
      const index = state.axesList.findIndex(stateAxe => stateAxe.id === action.payload.id)
      state.axesList[index] = { ...state.axesList[index], ...action.payload }
    },
    deleteNewAxe: state => {
      const index = state.axesList.findIndex(axe => axe.id === 'new')
      if (index !== -1) { state.axesList.splice(index, 1) }
    },
    hideSnackbar: state => {
      state.displaySnackbar = false
    },
  },
  extraReducers: builder => {
    builder.addCase(getAxes.pending, state => { state.isLoadingAxes = true })
    builder.addCase(getAxes.rejected, state => { state.isLoadingAxes = false })
    builder.addCase(getAxes.fulfilled, (state, { payload }) => {
      state.isLoadingAxes = false
      state.axesList = payload.map(payloadAxe => ({
        libelle: payloadAxe.libelle,
        animateurs: payloadAxe.animateurs,
        visiteurs: payloadAxe.visiteurs,
        id: payloadAxe.id,
      }))
    })
    builder.addCase(postAxe.fulfilled, state => {
      state.displaySnackbar = true
    })
  },
})

export const {
  addAxe, changeAxe, deleteNewAxe, hideSnackbar,
} = axes.actions

export default axes.reducer
