import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { initTranslation } from '@osrdata/app_core/dist/translation'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { frFR } from '@mui/material/locale'
import { store, persistor } from './Store'
import * as serviceWorker from './serviceWorker'

import './variables.scss'
import App from './App'
import './index.css'

import '@sncf/bootstrap-sncf.metier/dist/bootstrap-sncf.min.css'

declare module '@mui/material/styles' {
  interface Theme {
    gray: {
      light: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    gray?: {
      light?: string;
    };
  }
}

Sentry.init({
  dsn: 'https://7978e6c4117747118c388a81e309e2d2@sentry.shared.dgexsol.fr/45',
  environment: process.env.REACT_APP_REALM,
  integrations: [new BrowserTracing()],
  sampleRate: 1.0,
  tracesSampleRate: 0.01,
})
initTranslation()

const theme = createTheme({
  gray: {
    light: '#f2f2f2',
  },
}, frFR)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
