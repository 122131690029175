import { styled, Tab, Tabs } from '@mui/material'
import { StyledTabProps, StyledTabsProps } from './types'

export const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
  () => ({
    fontWeight: 700,
    '&.Mui-selected': {
      color: '#e05206',
      background: '#fff',
      boxShadow: '0 0 5px rgb(217 217 217 / 60%)',
    },
  }),
)

export const StyledTabs = styled((props: StyledTabsProps) => <Tabs {...props} />)(
  () => ({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: 'transparent',
    },
    '&:focus': {
      ouline: 'none',
    },
  }),
)
