import terms from 'common/terms'
import { cotationInfo } from 'common/cotation'
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { ComposantObject } from 'reducers/SA/types'
import { setFilterContributor } from 'reducers/Suivi/suivi.reducer'
import slugify from 'slugify'
import { RootState } from 'Store'
import './arrayComponent.scss'
import ContributionTableView from './ContributionTableView'
import IfNoContributionTableView from './IfNoContributionTableView'

export type SaJalonComponentTableViewProps = {
  handleclickRedirect: (component: ComposantObject) => () => void
}

const SaJalonComponentTableView = ({ handleclickRedirect }: SaJalonComponentTableViewProps): ReactElement => {
  const dispatch = useDispatch()
  const {
    listComponentPerObjectArrayFiltered, listComponentPerObjectArray, filterContributeur,
  } = useSelector((state: RootState) => state.suivi)
  const { account } = useSelector((state: RootState) => state.user)

  useEffect(() => {
    dispatch(setFilterContributor({
      contributor: filterContributeur,
      accountId: account.id,
    }))
  }, [listComponentPerObjectArray])

  const getComposantNumberStyle = (composant: ComposantObject) => {
    if (composant.cotation_anim) {
      return {
        background: cotationInfo(composant.cotation_anim)?.color,
        color: 'white',
      }
    }
    return {}
  }

  return (
    <div className="arrayComponent">
      <div className="arrayComponent-header">
        <div className="arrayComponent-header-first">
          <div className="arrayComponent-header-col1">
            {terms.Suivi.arrayComponent.component}
          </div>
        </div>
        <div className="arrayComponent-header-second">
          <div className="arrayComponent-header-col4">
            {terms.Suivi.arrayComponent.author}
          </div>
          <div className="arrayComponent-header-col5">
            {terms.Suivi.arrayComponent.analysis}
          </div>
          <div className="arrayComponent-header-col6">
            {terms.Suivi.arrayComponent.proposal}
          </div>
        </div>
      </div>
      <div className="arrayComponent-body">
        {listComponentPerObjectArrayFiltered.map(component => (
          <div
            key={component.id}
            className="arrayComponent-body-container"
          >
            <div
              className="arrayComponent-body-section"
            >
              <div className="arrayComponent-body-section-first">
                <div className="arrayComponent-body-col1">
                  <span
                    className="arrayComponent-body-col1-number"
                    style={getComposantNumberStyle(component)}
                  >
                    {component.id}
                  </span>
                  <div className="arrayComponent-body-col1-libelle">
                    <span>
                      {component.libelle}
                    </span>
                    <NavLink
                      key={component.id}
                      to={`/tableau-suivi/${slugify(localStorage?.getItem('labelSA') as string)}/contributions`}
                      onClick={handleclickRedirect(component)}
                    >
                      {terms.Suivi.arrayComponent.linkComponent}
                    </NavLink>
                    {
                    component.read_only && (
                      <span className="arrayComponent-body-col1-readOnly">
                        {terms.Common.readOnly}
                      </span>
                    )
                  }
                  </div>
                </div>
              </div>
              <div className="arrayComponent-body-section-second-container">
                {component.contributions[0]
                  ? component.contributions.map(contribution => (
                    <ContributionTableView
                      key={contribution.id}
                      date={contribution.timestamp}
                      firstName={contribution.auteur?.firstName}
                      lastName={contribution.auteur?.lastName}
                      role={contribution.role}
                      analyse={contribution.analyse}
                      proposal={contribution.proposition}
                      cotation={contribution.cotation}
                      file={contribution.file}
                      fromAxe={contribution.from_axe}
                    />
                  ))
                  : (
                    <IfNoContributionTableView />
                  )}
              </div>
            </div>
            <span className="arrayComponent-separate" />
          </div>

        ))}
      </div>
    </div>
  )
}

export default SaJalonComponentTableView
