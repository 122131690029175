import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import terms from 'common/terms'
import { ComposantObject } from 'reducers/SA/types'
import { ResponseError } from 'reducers/types'
import {
  deleteContribution, getContributions, getContributionsStateAfterRefresh, getContributionsStateAfterRefreshWithObject,
  getDedoublementObjects,
  getSaEntities, getSaPossibleAnimators, patchContribution, postContribution,
} from './contributions.thunk'
import {
  Contribution, ContributionsState, PatchContribution, PostContribution, SelectedContributor,
  setContributionsStateAction,
} from './types'

const initialState: ContributionsState = {
  componentList: [],
  activeComponent: null,
  activeJalon: null,
  activeObjFer: null,
  activeSa: null,
  isLoading: true,
  contributionList: [],
  contributionHistory: [],
  selectedContribution: null,
  newContribution: null,
  addModal: false,
  selectedContributor: null,
  contributorList: [],
  deleteModal: false,
  modifiedContribution: null,
  patchModal: false,
  entitiesVisibility: [],
  modalError: false,
  possibleAnimators: [],
  possibleDuping: [],
  snackbar: false,
  snackbarMessage: '',
  isLoadingContribution: true,
}

export const contributions = createSlice({
  name: 'contributions',
  initialState,
  reducers: {
    resetContributions: state => {
      state.componentList = []
      state.activeComponent = null
      state.activeJalon = null
      state.activeObjFer = null
      state.activeSa = null
    },
    setContributionsState: (state, action: PayloadAction<setContributionsStateAction>) => {
      state.componentList = action.payload.componentList
      state.activeComponent = action.payload.component
      state.activeJalon = action.payload.numJalon
      state.activeObjFer = action.payload.objFer || null
      state.activeSa = action.payload.activeSa
      state.isLoading = false
    },
    setActiveComponent: (state, action: PayloadAction<ComposantObject>) => {
      state.activeComponent = action.payload
      state.contributionList = []
      state.contributionHistory = []
    },
    setSelectedContribution: (state, action: PayloadAction<Contribution | null>) => {
      state.selectedContribution = action.payload
    },
    setNewContribution: (state, action: PayloadAction<PostContribution>) => {
      state.newContribution = action.payload
    },
    resetNewContribution: state => {
      state.newContribution = null
    },
    openAddModal: state => {
      state.addModal = true
    },
    closeAddModal: state => {
      state.addModal = false
    },
    setSelectedContributor: (state, action: PayloadAction<SelectedContributor | null>) => {
      state.selectedContributor = action.payload
    },
    setContributionToDeleteId: (state, action: PayloadAction<string>) => {
      state.contributionToDeleteId = action.payload
      state.deleteModal = true
    },
    closeDeleteModal: state => {
      state.deleteModal = false
    },
    setPatchContribution: (state, action: PayloadAction<PatchContribution>) => {
      state.modifiedContribution = action.payload
      state.patchModal = true
    },
    closePatchModal: state => {
      state.patchModal = false
    },
    closeErrorModal: state => {
      state.modalError = false
      state.errors = undefined
    },
    resetPossibleAnimators: state => {
      state.possibleAnimators = []
    },
    hideSnackbar: state => {
      state.snackbar = false
      state.snackbarMessage = ''
    },
  },
  extraReducers: builder => {
    builder.addCase(getContributionsStateAfterRefresh.fulfilled, (state, action) => {
      state.componentList = action.payload.composants
      state.activeComponent = action.payload.composants.find(c => c.id === action.meta.arg.idComponent) || null
      state.activeJalon = action.meta.arg.numJalon
      state.activeObjFer = null
      state.activeSa = action.meta.arg.activeSa
      state.isLoading = false
    })
    builder.addCase(getContributionsStateAfterRefreshWithObject.fulfilled, (state, action) => {
      state.componentList = action.payload.composants
      state.activeComponent = action.payload.composants.find(c => c.id === action.meta.arg.idComponent) || null
      state.activeJalon = action.payload.jalon.numero_jalon
      state.activeObjFer = action.payload.jalon.obj_ferroviaires.find(o => o.id === action.meta.arg.idObjFer) || null
      state.activeSa = action.meta.arg.activeSa
      state.isLoading = false
    })
    builder.addCase(getContributions.pending, state => { state.isLoadingContribution = true })
    builder.addCase(getContributions.rejected, state => { state.isLoadingContribution = false })
    builder.addCase(getContributions.fulfilled, (state, action) => {
      state.contributionList = action.payload.filter(contr => contr.numero_jalon === action.meta.arg.numJalon)
      state.contributionHistory = action.payload.filter(contr => contr.numero_jalon !== action.meta.arg.numJalon)
      state.selectedContributor = null
      state.contributorList = action.payload.filter((value, index, self) => (
        self.findIndex(v => v.auteur && v.auteur.id === value.auteur?.id) === index
      )).map(contr => ({
        id: contr.auteur?.id as string,
        label: `${contr.auteur?.lastName.toUpperCase()} ${contr.auteur?.firstName} (${contr.role})`,
        initials: `${contr.auteur?.lastName[0].toUpperCase()}${contr.auteur?.firstName[0].toUpperCase()}`,
      })).sort((a, b) => a.label.localeCompare(b.label))
      state.isLoadingContribution = false
    })
    builder.addCase(postContribution.fulfilled, state => {
      state.snackbar = true
      state.snackbarMessage = terms.Contributions.snackbars.add
      state.selectedContribution = null
      state.newContribution = null
    })
    builder.addCase(deleteContribution.fulfilled, state => {
      state.snackbar = true
      state.snackbarMessage = terms.Contributions.snackbars.delete
      state.contributionList = state.contributionList.filter(c => c.id !== state.contributionToDeleteId)
      state.contributionToDeleteId = undefined
      state.selectedContribution = null
    })
    builder.addCase(patchContribution.fulfilled, state => {
      state.snackbar = true
      state.snackbarMessage = terms.Contributions.snackbars.patch
      state.selectedContribution = null
      state.modifiedContribution = null
    })
    builder.addCase(getSaEntities.fulfilled, (state, action) => {
      state.entitiesVisibility = action.payload
    })
    builder.addCase(getSaPossibleAnimators.fulfilled, (state, action) => {
      state.possibleAnimators = action.payload
    })
    builder.addCase(getDedoublementObjects.fulfilled, (state, action) => {
      state.possibleDuping = action.payload
    })
    builder.addMatcher(isAnyOf(
      postContribution.rejected,
      deleteContribution.rejected,
      patchContribution.rejected,
    ), (state, action) => {
      state.modalError = true
      state.errors = action.payload as ResponseError
    })
  },
})

export const {
  resetContributions,
  setContributionsState,
  setActiveComponent,
  setSelectedContribution,
  setNewContribution,
  resetNewContribution,
  openAddModal,
  closeAddModal,
  setSelectedContributor,
  setContributionToDeleteId,
  closeDeleteModal,
  setPatchContribution,
  closePatchModal,
  closeErrorModal,
  resetPossibleAnimators,
  hideSnackbar,
} = contributions.actions

export default contributions.reducer
