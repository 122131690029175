import { Clear } from '@mui/icons-material'
import {
  Autocomplete,
  TextField,
  Typography,
} from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedRoles } from 'reducers/Suivi/suivi.reducer'
import { SendMailUser } from 'reducers/Suivi/types'
import { UserRole } from 'reducers/Users/types'
import { RootState } from 'Store'
import TargetSelector from './TargetSelector'
import { getRoleLabel } from '../utils'

interface AdressPickerProps {
  handleSelectAll: () => void
  handleUnselectAll: () => void
  handleChangeUser: (users: SendMailUser) => void
  possibleUsers: SendMailUser[],
  selectedUsers: SendMailUser[],
}

const AdressPicker = ({
  handleSelectAll, handleUnselectAll, handleChangeUser, possibleUsers, selectedUsers,
}: AdressPickerProps): ReactElement => {
  const dispatch = useDispatch()
  const { possibleRoles, selectedRoles } = useSelector((state: RootState) => state.suivi.sendMailState)

  const handleUnselectRole = (role: UserRole): void => {
    dispatch(setSelectedRoles(selectedRoles.filter(r => r !== role)))
  }

  return (
    <div className="adress-picker w-100 mb-2">
      <div className="d-flex justify-content-between">
        <div className="side-panel">
          <span className="send-mail-section-title">
            {terms.Suivi.mailTerms.targets}
          </span>
          <TargetSelector
            handleSelectAll={handleSelectAll}
            handleUnselectAll={handleUnselectAll}
            handleChangeUser={handleChangeUser}
            possibleUsers={possibleUsers}
            selectedUsers={selectedUsers}
          />
        </div>
        <div>
          <Autocomplete
            className="role-select"
            multiple
            disableCloseOnSelect
            value={selectedRoles}
            options={possibleRoles}
            onChange={(_evt, newValue) => dispatch(setSelectedRoles([...newValue]))}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option} style={{ ...(selected && { padding: 0 }) }}>
                {!selected && (
                <span className="role-menu-item">
                  {getRoleLabel(option)}
                </span>
                )}
              </li>
            )}
            renderInput={params => (
              <TextField {...params} value="" variant="standard" className="role-select-input" />
            )}
            renderTags={value => (
              <>
                {value.map(elem => (
                  <div className="value-chip d-flex" key={elem}>
                    <Clear className="delete-icon" onClick={() => handleUnselectRole(elem)} />
                    <Typography className="value-label">
                      {getRoleLabel(elem)}
                    </Typography>
                  </div>
                ))}
              </>
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default AdressPicker
