import terms from 'common/terms'
import { ReactElement, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Typography } from '@mui/material'
import nextId from 'react-id-generator'
import CancelIcon from '@mui/icons-material/Cancel'
import { ContributorFunction } from 'reducers/Admin/ContributorFunction/types'
import './contributorsList.scss'
import { getSaContributeursById } from 'reducers/Parametrage/Contributeurs/contributeurs.thunk'
import { onModalConfirmation } from 'reducers/Parametrage/Contributeurs/contributeurs.reducer'
import { setDisplayUserInfos, setUserInfosId } from 'reducers/Users/users.reducer'

export interface ContributorListItem {
  id: string
  cerbereId: string
  firstName: string
  lastName: string
  displayName: string
  fonction?: ContributorFunction
  complexes?: string[]
  lignes?: string[]
  travaux?: string[]
}

interface ContributorsListProps {
  items: ContributorListItem[],
  idSA: string | null,
}

const ContributorsList = ({ items, idSA }: ContributorsListProps): ReactElement => {
  const dispatch = useDispatch()
  const [composantAvailable, setComposantAvailable] = useState(true)

  const handleClickUser = (userId: string) => {
    dispatch(setUserInfosId(userId))
    dispatch(setDisplayUserInfos(true))
  }

  return (
    <>
      {items.length === 0 ? (
        <div className="no-contributor">
          {terms.Settings.contributor.noContributor}
        </div>
      ) : (
        <ul className="contributors-list">
          {items.map(item => (
            <li key={item.id} className="contributors-item d-flex" title={terms.Settings.contributor.modifyContributor}>
              <div
                className="item-container w-100"
                role="presentation"
                onClick={() => {
                  if (composantAvailable) {
                    dispatch(getSaContributeursById({
                      saId: idSA,
                      contributorId: item.id || '',
                    }))
                  }
                }}
              >
                <button
                  className="buttonDeleteContributorSa"
                  type="button"
                  onClick={() => dispatch(onModalConfirmation(item?.id))}
                  onMouseEnter={() => setComposantAvailable(false)}
                  onMouseLeave={() => setComposantAvailable(true)}
                >
                  <CancelIcon fontSize="medium" sx={{ color: '#4d4f53' }} />
                </button>
                <div className="item-name">
                  <button
                    type="button"
                    onMouseEnter={() => setComposantAvailable(false)}
                    onMouseLeave={() => setComposantAvailable(true)}
                    className="button-infos"
                    onClick={() => handleClickUser(item.cerbereId)}
                  >
                    {`${item.lastName} ${item.firstName}`}
                  </button>
                </div>
                <div className="item-function">{item.fonction?.libelle}</div>
                <div className="item-data flex-column">
                  {item?.complexes && item.complexes.length > 0 && (
                    <div className="item-complexes">
                      <Typography variant="subtitle1" color="gray">
                        {terms.Settings.contributor.listComplexe}
                      </Typography>
                      {item.complexes.map(complexe => (
                        <div className="item-complexe" key={nextId()}>{complexe}</div>
                      ))}
                    </div>
                  )}
                  {item?.lignes && item.lignes.length > 0 && (
                    <div className="item-lignes">
                      <Typography variant="subtitle1" color="gray">
                        {terms.Settings.contributor.listLigne}
                      </Typography>
                      {item.lignes.map(ligne => (
                        <div className="item-ligne" key={nextId()}>{ligne}</div>
                      ))}
                    </div>
                  )}
                  {item?.travaux && item.travaux.length > 0 && (
                    <div className="item-travaux">
                      <Typography variant="subtitle1" color="gray">
                        {terms.Settings.contributor.listTravaux}
                      </Typography>
                      {item.travaux.map(travaux => (
                        <div className="item-travail" key={nextId()}>{travaux}</div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </>
  )
}

export default ContributorsList
