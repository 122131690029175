import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import terms from 'common/terms'
import {
  Autocomplete, Stack, TextField, Typography,
} from '@mui/material'
import { PrimaryBtn } from 'themes/theme'
import AntSwitch from 'components/AntSwitch/AntSwitch'
import {
  addContributorFunction, changeContributorFunction, deleteNewContributorFunction, hideSnackbar,
} from 'reducers/Admin/ContributorFunction/contributorFunction'
import { Entity } from 'reducers/Admin/Entity/types'
import { postContributorFunction } from 'reducers/Admin/admin.thunk'
import CustomSnackbar from 'components/Snackbar/Snackbar'
import ObjectJalonTabs from 'components/Jalons/ObjectJalonTabs'
import { Composant } from 'reducers/Admin/Composant/types'
import { ContributorFunction } from 'reducers/Admin/ContributorFunction/types'
import Accordion from '../../../components/Accordion/Accordion'

export default function ContributorFunctionAccordion(): ReactElement {
  const dispatch = useDispatch()
  const {
    contributorFunctions, displaySnackbar, isLoadingContributorFunctions,
  } = useSelector((state : RootState) => state.contributorFunction)
  const { entities } = useSelector((state : RootState) => state.entity)

  const handleChange = (contributorFunction: ContributorFunction) => (newComposants: Composant[], numJalon: number) => {
    newComposants.sort((a, b) => a.id - b.id)
    dispatch(changeContributorFunction({
      ...contributorFunction,
      jalon_composants: contributorFunction.jalon_composants.filter(comp => comp.numero_jalon !== numJalon)
        .concat({ numero_jalon: numJalon, active_composants: newComposants }),
    }))
  }

  return (
    <>
      <Accordion
        name={terms.Admin.contributorFunction.name}
        elementList={contributorFunctions}
        onAddEntity={() => dispatch(addContributorFunction())}
        onDeleteEntity={() => dispatch(deleteNewContributorFunction())}
        isLoading={isLoadingContributorFunctions}
        newElementWording={terms.Admin.contributorFunction.new}
        displaySnackbar={displaySnackbar}
      >
        {contributorFunction => (
          <form onSubmit={evt => {
            evt.preventDefault()
            dispatch(postContributorFunction(contributorFunction))
          }}
          >
            <div className="row align-items-center mt-3">
              <label className="col-6 d-flex align-items-center flex-column mb-0" htmlFor="libelle">
                <p className="d-flex align-self-start ml-2">{terms.Admin.name}</p>
                <input
                  className="input-admin form-control form-control-sm ml-2"
                  placeholder={terms.Admin.contributorFunction.namePlaceHolder}
                  type="text"
                  id="libelle"
                  name="libelle"
                  value={contributorFunction.libelle}
                  onChange={evt => dispatch(changeContributorFunction({
                    ...contributorFunction, libelle: evt.target.value,
                  }))}
                />
              </label>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                className="form-control-container col-4 row align-items-center ml-auto"
              >
                <Typography className={`${!contributorFunction.active ? 'switch-active' : ''} switch`}>
                  {terms.Admin.off}
                </Typography>
                <AntSwitch
                  checked={!!contributorFunction.active}
                  inputProps={{ 'aria-label': 'activée' }}
                  onChange={() => dispatch(changeContributorFunction({
                    ...contributorFunction, active: !contributorFunction.active,
                  }))}
                />
                <Typography className={`${contributorFunction.active ? 'switch-active' : ''}
                 switch`}
                >
                  {terms.Admin.on}
                </Typography>
              </Stack>
            </div>
            <div className="row align-items-start mt-3">
              <label className="col-6 d-flex align-items-center flex-column mb-0" htmlFor="entity">
                <p className="d-flex align-self-start ml-2">{terms.Admin.contributorFunction.entity}</p>
                <Autocomplete
                  sx={{ maxHeight: 250, overflowY: 'auto', overflowX: 'hidden' }}
                  className="autocomplete-jalon form-control form-control-sm ml-2"
                  value={contributorFunction.entite}
                  options={entities.filter(entity => entity.id !== 'new')}
                  onChange={(_event, newValue) => {
                    dispatch(changeContributorFunction({
                      ...contributorFunction,
                      entite: newValue as Entity,
                    }))
                  }}
                  getOptionLabel={option => option.libelle}
                  renderInput={params => (
                    <TextField {...params} />
                  )}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
              </label>
              <label className="col-6 d-flex align-items-center flex-column mb-0" htmlFor="composants">
                <p className="d-flex align-self-start ml-2 mt-3">{terms.Common.composants}</p>
                <ObjectJalonTabs
                  objectJalonComposants={contributorFunction.jalon_composants.map(jalonComposant => ({
                    numero_jalon: jalonComposant.numero_jalon,
                    composants: jalonComposant.active_composants,
                  }))}
                  handleChange={handleChange(contributorFunction)}
                  clearText={terms.Settings.resetJalon}
                />

              </label>
            </div>
            <div>
              <div className="row align-items-center mt-3 justify-content-end">
                <PrimaryBtn
                  type="submit"
                  className="btn btn-gradient-blue mr-2"
                  disabled={!contributorFunction.libelle || !contributorFunction.entite?.id}
                >
                  {terms.Admin.validate}
                </PrimaryBtn>
              </div>
            </div>
          </form>
        )}
      </Accordion>
      <CustomSnackbar
        message={terms.Admin.contributorFunction.validationNotice}
        displaySnackbar={displaySnackbar}
        handleClose={() => dispatch(hideSnackbar())}
      />
    </>
  )
}
