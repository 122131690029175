import {
  ReactElement, useEffect, useState,
} from 'react'
import {
  getPossibleUsersEmailBySa, getSAJalonByNumber, getSaSavedMails, getToggleDisplayAllComponent,
} from 'reducers/Suivi/suivi.thunk'
import terms from 'common/terms'
import { getSaEntities } from 'reducers/Contributions/contributions.thunk'
import { getSaContributeurs } from 'reducers/Parametrage/Contributeurs/contributeurs.thunk'
import { getSAJalon } from 'reducers/SA/sa.thunk'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { UserMeRoles } from 'reducers/Users/types'
import { closeModal } from 'reducers/SA/sa.reducer'
import useChangeSaURL from 'common/hooks'
import { getUserMeAxeToggle } from 'reducers/Users/users.thunk'
import {
  resetComponentList, setActiveJalonNumber, setActiveTab, setFilterContributor, toggleSwitchComponantInArray,
} from 'reducers/Suivi/suivi.reducer'
import Loader from 'components/Loader'
import CustomSwitch from 'components/CustomSwitch/CustomSwitch'
import ModalError from 'components/Modal/ModalError/ModalError'
import SaSelector from 'components/SaSelector/SaSelector'
import { JalonStatus } from './JalonStatus'
import JalonsSa from './jalonsSa'

import './Suivi.scss'

export default function Suivi(): ReactElement {
  const dispatch = useDispatch()
  const { errors, onModalError } = useSelector((state: RootState) => state.sa)
  const { userMeSuivi } = useSelector((state: RootState) => state.users)
  const {
    toggleComponentInArray, isLoading, activeTab, isLoadingObjectFer, isLoadingObjectFerArray, isLoadingJalon,
  } = useSelector((state: RootState) => state.suivi)
  const { account } = useSelector((state: RootState) => state.user)
  const { idSA } = useSelector((state: RootState) => state.app)
  const [selectedValue, setSelectedValue] = useState<UserMeRoles | null>(null)

  const changeUrlSAHistory = useChangeSaURL(setSelectedValue, userMeSuivi)

  const handleClickChangeSA = (newValue: UserMeRoles | null) => {
    changeUrlSAHistory(newValue)
    dispatch(setActiveTab(0))
  }

  useEffect(() => {
    dispatch(getToggleDisplayAllComponent())
    dispatch(getUserMeAxeToggle())
  }, [])

  const handleChangeChecked = () => {
    dispatch(toggleSwitchComponantInArray())
  }

  const updateComponents = (jalon: number) => {
    dispatch(setActiveJalonNumber(jalon))
    dispatch(resetComponentList())
    dispatch(getSAJalonByNumber({
      idSa: idSA,
      jalon,
      activeTabObject: activeTab,
    }))
  }

  const handleChangeSa = (newValue: UserMeRoles | null) => {
    changeUrlSAHistory(newValue)
    dispatch(resetComponentList())
    // Request data
    if (newValue) {
      dispatch(getPossibleUsersEmailBySa(newValue))
      dispatch(getSaSavedMails(newValue.id))
      dispatch(getSaEntities({
        activeSA: newValue.id,
        origineSA: null,
      }))
      dispatch(getSAJalon(newValue.id))
      dispatch(getSaContributeurs(newValue.id))
    }
    dispatch(setFilterContributor({
      contributor: null,
      accountId: account.id,
    }))
    // Set correct jalon number
    const onGoingJalon = newValue?.jalons.find(jalon => jalon.status === JalonStatus.OnGoing)
    const jalonToValidate = newValue?.jalons.find(jalon => jalon.status === JalonStatus.ToBeValidated)
    const firstComingJalon = newValue?.jalons.filter(jalon => jalon.status === JalonStatus.Coming)
      .map(jalon => jalon.numero_jalon).sort((a, b) => a - b)[0]
    const closedJalons = newValue?.jalons.filter(jalon => jalon.status === JalonStatus.Closed)
    if (onGoingJalon) {
      updateComponents(onGoingJalon.numero_jalon)
    } else if (jalonToValidate) {
      updateComponents(jalonToValidate.numero_jalon)
    } else if (firstComingJalon !== undefined) { // can be 0
      updateComponents(firstComingJalon)
    } else if (closedJalons && closedJalons?.length === newValue?.jalons.length) {
      updateComponents(4)
    }
  }

  useEffect(() => {
    handleChangeSa(selectedValue)
  }, [selectedValue?.id])

  return (
    userMeSuivi.length !== 0 || localStorage.getItem('idSA') !== '' ? (
      <>
        { !isLoading ? (
          <div>
            <div className="suivi-sa-select">
              {selectedValue && (
                <CustomSwitch
                  value={toggleComponentInArray}
                  handleChange={handleChangeChecked}
                  label={terms.Suivi.labelToggleComponentInArray}
                />
              )}
              <SaSelector
                options={userMeSuivi}
                value={selectedValue}
                disabled={isLoadingObjectFer || isLoadingObjectFerArray || isLoadingJalon}
                handleChange={(_evt, newValue) => handleClickChangeSA(newValue)}
              />
            </div>
            { selectedValue && (<JalonsSa selectedSa={selectedValue} />)}
          </div>
        ) : (
          <Loader
            message={terms.Common.loading}
          />
        )}

        <ModalError
          onModal={onModalError}
          content={errors?.data[0]}
          handleClose={() => dispatch(closeModal())}
        />
      </>
    )
      : <></>
  )
}
