import { ReactElement } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { ReactComponent as AxeIcon } from 'assets/icons/axeIcon.svg'

import './modalAddActionTile.scss'
import { backgroundColorContributor } from 'pages/Suivi/utils'
import terms from 'common/terms'
import { dateToDisplay } from 'pages/ActionPlan/utils'
import { useDispatch } from 'react-redux'
import { setDescription, setModalAddDisplay } from 'reducers/ActionPlan/actionPlan.reducer'
import { UserSimple } from 'reducers/Users/types'
import PopOver from 'components/PopOver/PopOver'

interface ModalAddActionTileProps {
  role: string,
  user: UserSimple | null,
  date: string,
  authorId: string,
  analyse: string | null | undefined,
  proposition: string | null | undefined,
  fromAxe: boolean,
}

const ModalAddActionTile = ({
  role,
  user,
  date,
  analyse,
  proposition,
  authorId,
  fromAxe,
}: ModalAddActionTileProps): ReactElement => {
  const dispatch = useDispatch()
  const handleClickAddAction = () => {
    dispatch(setDescription(`${analyse}\n${proposition || ''}`))
    dispatch(setModalAddDisplay('add'))
  }
  return (
    <div className="modalAddActionTile">
      <div className="modalAddActionTile-title">
        <div className="modalAddActionTile-title-name">
          <div className="modalAddActionTile-title-icon" style={{ background: backgroundColorContributor(authorId) }}>
            <span>
              {`${user?.lastName[0].toUpperCase()}${user?.firstName[0].toUpperCase()}`}
            </span>
          </div>
          <div className="modalAddActionTile-title-text">
            {`${role} (${user?.lastName.toUpperCase()} ${user?.firstName})`}
          </div>
        </div>
        {
          fromAxe && (
            <div>
              <PopOver popContent={terms.Contributions.tile.axeHabilitation}>
                <AxeIcon className="axe-icon" />
              </PopOver>
            </div>
          )
        }
        <div>
          <div className="modalAddActionTile-title-date">
            {dateToDisplay(date).date}
          </div>
          <div className="modalAddActionTile-title-hour">
            {dateToDisplay(date).hours}
          </div>
        </div>
      </div>
      <div>
        <div className="modalAddActionTile-body-analyse">
          {terms.actionPlan.modalAddAction.contribution.analyse}
        </div>
        <p className="modalAddActionTile-body-analyse-text">
          {analyse || terms.actionPlan.modalAddAction.contribution.noAnalyse}
        </p>
      </div>
      <div>
        <div className="modalAddActionTile-body-proposition">
          {terms.actionPlan.modalAddAction.contribution.proposition}
        </div>
        <p className="modalAddActionTile-body-proposition-text">
          {proposition || terms.actionPlan.modalAddAction.contribution.noProposition}
        </p>
      </div>
      <div className="modalAddActionTile-button">
        <button type="button" onClick={handleClickAddAction}>
          <AddCircleOutlineIcon />
          <span>
            {terms.actionPlan.modalAddAction.addAction}
          </span>
        </button>
      </div>
    </div>
  )
}

export default ModalAddActionTile
