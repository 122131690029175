import terms from 'common/terms'
import { displayButtonAction } from 'pages/ActionPlan/utils'
import { ReactElement } from 'react'
import { Action, DataAnimCopil, Etat } from 'reducers/ActionPlan/types'
import { DeleteBtn, PrimaryBtn } from 'themes/theme'

interface ButtonsModalCopilModeProps {
  action: Action;
  handlePatchActionCopil: (actionId: string, data: DataAnimCopil) => void;
}
const ButtonsModalCopilMode = ({ action, handlePatchActionCopil }: ButtonsModalCopilModeProps): ReactElement => (
  <>
    {displayButtonAction(action.statut, action.etat).isValidate && (
      <PrimaryBtn
        onClick={() => handlePatchActionCopil && handlePatchActionCopil(action.id, {
          etat: Etat.VALIDATED,
        })}
      >
        {terms.Common.validate}
      </PrimaryBtn>
    )}
    {displayButtonAction(action.statut, action.etat).isRejected && (
      <DeleteBtn
        onClick={() => handlePatchActionCopil && handlePatchActionCopil(action.id, {
          etat: Etat.REJECTED,
        })}
      >
        {terms.Common.rejected}
      </DeleteBtn>
    )}
  </>
)

export default ButtonsModalCopilMode
