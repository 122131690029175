type ReturnAllyProps = {
  id: string;
  'aria-controls': string
}

type AllyPropsType = (index: number) => ReturnAllyProps

const a11yProps: AllyPropsType = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
})

export default a11yProps
