import { ChangeEvent, ReactElement } from 'react'
import { FaEye, FaPaperclip, FaTimes } from 'react-icons/fa'
import terms from 'common/terms'
import { Contribution } from 'reducers/Contributions/types'

interface EditorFileUploadProps {
  editable: boolean
  selectedContribution: Contribution | null
  fileURL: string | null
  fileName: string | null
  handleAddPdf: (evt: ChangeEvent<HTMLInputElement>) => void
  handleDeletePdf: () => void
}

const EditorFileUpload = ({
  editable, selectedContribution, fileURL, fileName, handleAddPdf, handleDeletePdf,
}: EditorFileUploadProps): ReactElement => (
  <div className="file-upload d-flex mb-3">
    <FaPaperclip size="22px" />
    <label className={`ml-2 ${editable ? '' : 'disabled'}`} htmlFor={`file${selectedContribution?.id}`}>
      {fileURL ? fileName || terms.Contributions.editor.file : terms.Contributions.editor.addFile}
      {editable && (
      <input
        className="sr-only"
        id={`file${selectedContribution?.id}`}
        type="file"
        onChange={handleAddPdf}
      />
      )}
    </label>
    {fileURL && (
    <>
      <a
        className="ml-2 mr-2"
        target="__blank"
        href={fileURL}
        aria-label={terms.Contributions.editor.seeFile}
        rel="noreferrer"
      >
        <FaEye size="22px" />
      </a>
      {editable && (
      <button
        type="button"
        className="btn p-0 delete-button"
        disabled={!editable}
        aria-label={terms.Contributions.editor.deleteFile}
        onClick={handleDeletePdf}
      >
        <FaTimes size="22px" />
      </button>
      )}
    </>
    )}
  </div>
)

export default EditorFileUpload
