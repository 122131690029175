import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getComposants, getJalonComposants, postComposant } from '../admin.thunk'
import { ChangeJalonPayload, Composant, ComposantState } from './types'
import { convertComposantPerimeter, convertFromPerimetre, convertToPerimetre } from './utils'

const initialState: ComposantState = {
  composants: [],
  jalonComposants: [],
  displaySnackbar: false,
  isLoadingComponents: false,
}

export const composant = createSlice({
  name: 'composant',
  initialState,
  reducers: {
    addComposant: state => {
      if (state.composants.find(comp => comp.newId === 'new')) {
        return
      }
      const jalons = [0, 1, 2, 3, 4].map(number => ({
        active_jalon: false,
        numero_jalon: number,
        perimetre: 0,
      }))

      state.composants = [...state.composants, ...convertComposantPerimeter([{
        libelle: '',
        active: true,
        id: state.composants.length + 1,
        national: false,
        jalons,
        newId: 'new',
      }])]
    },
    deleteNewComposant: state => {
      const index = state.composants.findIndex(entitie => entitie.newId === 'new')
      if (index !== -1) { state.composants.splice(index, 1) }
    },
    changeComposant: (state, action: PayloadAction<Partial<Composant>>) => {
      const index = state.composants.findIndex(comp => comp.id === action.payload.id)
      state.composants[index] = {
        ...state.composants[index],
        ...action.payload,
        customLibelle: `<strong>${action.payload.id}</strong> - ${action.payload.libelle}`,
      }
    },
    changeJalon: (state, action: PayloadAction<ChangeJalonPayload>) => {
      const index = state.composants.findIndex(comp => comp.id === action.payload.composant.id)
      const indexJalon = state.composants[index].jalons.findIndex(jalon => action.payload.jalon.numero_jalon
         === jalon.numero_jalon)
      const currentJalon = state.composants[index].jalons[indexJalon]
      const newJalon = {
        ...currentJalon,
        ...action.payload.jalon,
      }
      if (convertToPerimetre(newJalon) > 8 && currentJalon.perimetre < 8) {
        newJalon.type.chantier = false
        newJalon.type.ligne = false
        newJalon.type.complexe = false
      }
      if (convertToPerimetre(newJalon) > 8 && currentJalon.perimetre >= 8) {
        newJalon.type.zoneDeProduction = false
      }

      const newJalonWithPerimeter = {
        ...newJalon,
        perimetre: convertToPerimetre(newJalon),
      }
      state.composants[index].jalons[indexJalon] = {
        ...newJalonWithPerimeter,
        type: convertFromPerimetre(newJalonWithPerimeter.perimetre),
      }
    },
    hideSnackbar: state => {
      state.displaySnackbar = false
    },
  },
  extraReducers: builder => {
    builder.addCase(getComposants.pending, state => { state.isLoadingComponents = true })
    builder.addCase(getComposants.rejected, state => { state.isLoadingComponents = false })
    builder.addCase(getComposants.fulfilled, (state, { payload }) => {
      state.isLoadingComponents = false
      state.composants = convertComposantPerimeter(payload.sort((a, b) => a.id - b.id))
    })
    builder.addCase(postComposant.fulfilled, state => {
      state.displaySnackbar = true
    })
    builder.addCase(getJalonComposants.fulfilled, (state, { payload }) => {
      if (state.jalonComposants.length === 5) {
        state.jalonComposants = []
      }
      state.jalonComposants.push({
        numero_jalon: payload.jalon.active_jalon,
        active_composants: convertComposantPerimeter(payload.composants.sort((a, b) => a.id - b.id)),
      })
    })
  },
})

export const {
  addComposant, changeComposant, changeJalon, deleteNewComposant, hideSnackbar,
} = composant.actions

export default composant.reducer
