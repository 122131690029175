import { MutableRefObject } from 'react'
import { MapRef, MapRequest } from 'react-map-gl'
import station from 'assets/icons/station.png'
import stationSelected from 'assets/icons/stationSelected.png'
import stationEditing from 'assets/icons/stationEditing.png'
import constructionSiteBlue from 'assets/icons/constructionSiteBlue.png'
import constructionSiteSelected from 'assets/icons/constructionSiteSelected.png'
import stationLigne from 'assets/icons/station-black-outlined.png'
import stationLigneSelected from 'assets/icons/station-black.png'
import MAIN_API from 'config/config'

export const DEFAULT_VIEWPORT = {
  latitude: 46.57715038632404,
  longitude: 2.2663057120417496,
  zoom: 4.5,
  bearing: 0,
  pitch: 0,
}

export const MAP_LAYER_SOURCE = {
  pr: 'cassini_v2_gaia_point_remarquable',
  constructionSite: 'constructionSite-source',
  sectionLine: 'section-line-source',
}

export const LAYER_NAMES = {
  bvLayer: 'bv-layer',
  regionLayer: 'region-layer',
  constructionSiteLayer: 'construction-site-layer',
  sectionLineLayer: 'section-line-layer',
}

export const LAYER_URL = `${MAIN_API.proxy}/chartis/v2/layer`

export const formatLayerUrl = (
  sourceLayer: string,
  mode = 'mvt',
  params = '',
  view = 'line_geo_centroid',
): string => `${LAYER_URL}/${sourceLayer}/${mode}/${view}/?${params}`

/**
 * Replace env in request URL and add token if needed
 *
 * @param url to transform
 * @param resourceType use to check if token is needed
 * @returns MapRequest
 */
export const transformRequest = (url?: string, resourceType?: string): MapRequest => {
  if (!url) return { url: '' }
  url = url.replace('https://gateway.dev.dgexsol.fr', MAIN_API.proxy)

  if ((resourceType === 'Source' || resourceType === 'Tile')) {
    return {
      url,
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
    }
  }
  return { url }
}

/**
 * Load assets images in given map to be used in layers
 * @param mapRef
 */
export const loadBvImages = (mapRef: MutableRefObject<MapRef | undefined> | undefined): void => {
  const currentMap = mapRef?.current?.getMap()
  currentMap.loadImage(station, (error: Error, image: HTMLImageElement | ImageBitmap) => {
    if (!error) { currentMap.addImage('station', image) }
  })
  currentMap.loadImage(stationSelected, (error: Error, image: HTMLImageElement | ImageBitmap) => {
    if (!error) { currentMap.addImage('stationSelected', image) }
  })
  currentMap.loadImage(stationEditing, (error: Error, image: HTMLImageElement | ImageBitmap) => {
    if (!error) { currentMap.addImage('stationEditing', image) }
  })
  currentMap.loadImage(constructionSiteBlue, (error: Error, image: HTMLImageElement | ImageBitmap) => {
    if (!error) { currentMap.addImage('constructionSiteBlue', image) }
  })
  currentMap.loadImage(constructionSiteSelected, (error: Error, image: HTMLImageElement | ImageBitmap) => {
    if (!error) { currentMap.addImage('constructionSitePurple', image) }
  })
  currentMap.loadImage(stationLigne, (error: Error, image: HTMLImageElement | ImageBitmap) => {
    if (!error) { currentMap.addImage('stationLigne', image) }
  })
  currentMap.loadImage(stationLigneSelected, (error: Error, image: HTMLImageElement | ImageBitmap) => {
    if (!error) { currentMap.addImage('stationLigneSelected', image) }
  })
}
