import { deleteRequest, get, post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { SettingsTabListElement } from 'components/SettingsTab/SettingsTabList'
import { ThunkApi } from 'reducers/types'
import { getUsersSimple } from 'reducers/Users/utils'
import { formatSettingsTabListElement } from '../utils'
import { ParamAnimator, ParamDeleteAnimator, ParamPostAnimator } from './types'

const getSaAnimators = createAsyncThunk<SettingsTabListElement[], string | null, ThunkApi>(
  'sa/animators/get',
  async (saId: string | null, thunkApi) => {
    try {
      const response: ParamAnimator[] = await get(`/robustest/sa/${saId}/animateur/`)
      const usersResponse = await getUsersSimple(response.map(animator => animator.utilisateur?.cerbere_id))
      return usersResponse.map(user => {
        const animator = response.find(anim => anim.utilisateur?.cerbere_id === user.id)
        return formatSettingsTabListElement(animator?.id, user, animator?.entite)
      })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postSaAnimators = createAsyncThunk<SettingsTabListElement, ParamPostAnimator, ThunkApi>(
  'sa/animators/post',
  async (newAnimator: ParamPostAnimator, thunkApi) => {
    try {
      const response: ParamAnimator = await post(`/robustest/sa/${newAnimator.saId}/animateur/`, {
        utilisateur: {
          cerbere_id: newAnimator.user?.id,
        },
        entite: newAnimator.entity?.id,
      })
      return formatSettingsTabListElement(response.id, newAnimator.user, newAnimator.entity)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteSaAnimators = createAsyncThunk<ParamAnimator, ParamDeleteAnimator, ThunkApi>(
  'sa/animators/delete',
  async (animatorToDelete: ParamDeleteAnimator, thunkApi) => {
    try {
      const response = await
      deleteRequest(`/robustest/sa/${animatorToDelete.saId}/animateur/${animatorToDelete.saAnimatorId}/`)
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

// eslint-disable-next-line import/prefer-default-export
export {
  getSaAnimators,
  postSaAnimators,
  deleteSaAnimators,
}
