import slugify from 'slugify'

type DisplayAuthorizationParam = (
  accessArray: string[],
  permission: string[]
) => boolean

const displayAuthorization: DisplayAuthorizationParam = (accessArray, permission) => {
  if (!accessArray[0]) {
    return true
  } if ((permission as string[]).some(perm => accessArray.includes(perm))) {
    return true
  }
  return false
}

const getPath = (path: string, labelSA?: string | null): string => {
  if ((path === '/parametrage/parametrage-sa/:id' || path === '/tableau-suivi/:id' || path === '/plan-action/:id')
   && labelSA) {
    return `${path.split('/:')[0]}/${slugify(labelSA)}`
  }
  return path
}

// eslint-disable-next-line import/prefer-default-export
export { displayAuthorization, getPath }
