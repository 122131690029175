import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import terms from 'common/terms'
import {
  ChangeEvent, ReactElement,
} from 'react'
import { useDispatch } from 'react-redux'
import { Entity } from 'reducers/Admin/Entity/types'
import { ObjectFerTab } from 'reducers/SA/types'
import { setSettingPdfList } from 'reducers/Suivi/suivi.reducer'
import { SettingPdfList } from 'reducers/Suivi/types'

interface CheckboxSettingProps {
  options: ObjectFerTab[] | undefined | Entity[],
  columnLabel: string,
  allLabel: string,
  selectedOptions: string[],
  payloadKey: keyof SettingPdfList,
  termsAccepted: boolean,
  notObject: string,
}

const CheckboxSettingPdfColumn = ({
  options,
  columnLabel,
  allLabel,
  selectedOptions,
  payloadKey,
  termsAccepted,
  notObject,
}: CheckboxSettingProps): ReactElement => {
  const dispatch = useDispatch()

  const handleAllClick = (_e: ChangeEvent, checked: boolean) => {
    options?.forEach(elem => dispatch(setSettingPdfList({
      value: elem.id as string,
      stateVariable: payloadKey,
      isChecked: checked,
    })))
  }
  return (
    <div className="settingPdf-perimeter-column-container">
      <div className="settingPdf-perimeter-column-label">
        {columnLabel}
      </div>
      <div className={`settingPdf-perimeter-column ${columnLabel === terms.Suivi.pdfTerms.entity.title && 'entity'}`}>
        {(options?.length === 0) ? (
          <div className="settingPdf-permiter-column-empty">
            {notObject}
          </div>
        ) : (
          <FormGroup>
            <FormControlLabel
              className="settingPdf-perimeter-column-label-checkboxAll"
              control={(
                <Checkbox
                  size="small"
                  checked={options?.length === selectedOptions.length}
                  onChange={handleAllClick}
                  disabled={!termsAccepted}
                />
              )}
              label={allLabel}
            />
            {options?.map(elem => (
              <FormControlLabel
                className="settingPdf-perimeter-column-label-checkbox"
                control={(
                  <Checkbox
                    size="small"
                    disabled={!termsAccepted}
                    checked={selectedOptions.some((bvId: string) => bvId === elem.id)}
                    onChange={(_e, checked) => dispatch(setSettingPdfList({
                      value: elem.id as string,
                      stateVariable: payloadKey,
                      isChecked: checked,
                    }))}
                  />
                )}
                label={(columnLabel === 'Complexes')
                  ? `${elem.libelle} (${'abrev' in elem && `${elem.abrev}`})`
                  : elem.libelle}
                key={elem.id}
              />
            ))}
          </FormGroup>
        )}
      </div>
    </div>
  )
}

export default CheckboxSettingPdfColumn
