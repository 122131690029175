import terms from 'common/terms'
import { UserRole } from 'reducers/Users/types'

type TypeObject = (type: string | undefined) => string
const typeObject: TypeObject = type => {
  let result = 'travaux'
  if (type === 'C') {
    result = 'batiments-voyageurs'
  }
  if (type === 'L') {
    result = 'lignes'
  }
  return result
}

type BackgroundColorContributor = (uid: string) => string
const backgroundColorContributor: BackgroundColorContributor = uid => {
  const colors = [
    '#0673C6', // Blue
    '#EE9207', // Orange
    '#09C959', // Green
    '#3C3C3B', // Dark Grey
    '#FD7B62', // Red
    '#009688', // Teal
    '#00008B', // Dark Blue
    '#B241D1', // Purple
  ]
  const index = parseInt(uid, 16) % colors.length
  return colors[index]
}

type ColorNumberTile = (colorNow: string | null | undefined) => string | null | undefined
const colorNumberTile: ColorNumberTile = colorNow => {
  let colorNumber
  if (colorNow === '#fff') {
    colorNumber = 'black'
  } else {
    colorNumber = colorNow
  }
  return colorNumber
}

const getRoleLabel = (role: UserRole): string => {
  switch (role) {
    case UserRole.entityAnimator:
      return terms.Suivi.mailTerms.role.entityAnimator
    case UserRole.animator:
      return terms.Suivi.mailTerms.role.animator
    case UserRole.contributor:
      return terms.Suivi.mailTerms.role.contributor
    case UserRole.decider:
      return terms.Suivi.mailTerms.role.decider
    default:
      return role
  }
}

export {
  typeObject,
  backgroundColorContributor,
  colorNumberTile,
  getRoleLabel,
}
