/* eslint-disable @typescript-eslint/no-explicit-any */
import { get, post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { GetSaPreferencesResponse, ParamGetSaPreferences, ParamPostSaPreferences } from './types'

const getSaPreferences = createAsyncThunk(
  'settings/preferences/get',
  async (params: ParamGetSaPreferences, thunkApi) => {
    try {
      const response: GetSaPreferencesResponse = await get(`/robustest/sa/${params.saId}/preferences/`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postSaPreferences = createAsyncThunk(
  'settings/preferences/post',
  async (params: ParamPostSaPreferences, thunkApi) => {
    try {
      const response: GetSaPreferencesResponse = await post(
        `/robustest/sa/${params.saId}/preferences/`, params.preferences,
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export {
  getSaPreferences,
  postSaPreferences,
}
