import { createSlice } from '@reduxjs/toolkit'
import {
  getMonitoringDzp, getPortfolioSimple, getPossibleYears, getSupervisionEf, getTerritory,
} from './monitoring.thunk'
import { MonitoringState, SupervisionEfWithUser } from './types'

const initialState: MonitoringState = {
  zpMonitorings: [],
  possibleEic: [],
  possibleYears: [],
  isLoadingData: false,
  isLoadingEf: false,
  possiblePortfolio: [],
  supervisionEfList: [],
}

export const monitoring = createSlice({
  name: 'app',
  initialState,
  reducers: {
    resetData: state => { state.supervisionEfList = [] },
    updateLoading: (state, action) => { state.isLoadingData = action.payload },
  },
  extraReducers: builder => {
    builder.addCase(getTerritory.fulfilled, (state, action) => {
      state.possibleEic = action.payload
    })

    builder.addCase(getPossibleYears.fulfilled, (state, action) => {
      state.possibleYears = action.payload
    })
    builder.addCase(getMonitoringDzp.fulfilled, (state, action) => {
      state.zpMonitorings = action.payload
    })

    builder.addCase(getPortfolioSimple.fulfilled, (state, action) => {
      state.possiblePortfolio = action.payload
    })
    builder.addCase(getSupervisionEf.pending, state => {
      state.supervisionEfList = []
      state.isLoadingEf = true
    })
    builder.addCase(getSupervisionEf.fulfilled, (state, action) => {
      state.supervisionEfList = action.payload as SupervisionEfWithUser[]
      state.isLoadingEf = false
    })
    builder.addCase(getSupervisionEf.rejected, state => {
      state.supervisionEfList = []
      state.isLoadingEf = false
    })

    builder.addMatcher(
      action => action.type.endsWith('/pending'),
      state => {
        state.isLoadingData = true
      },
    )
    builder.addMatcher(
      action => action.type.endsWith('/rejected'),
      state => {
        state.isLoadingData = false
      },
    )
    builder.addMatcher(
      action => action.type.endsWith('/fulfilled'),
      state => {
        state.isLoadingData = false
      },
    )
  },
})

export const { resetData, updateLoading } = monitoring.actions

export default monitoring.reducer
