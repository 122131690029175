import { TableCell, TableRow } from '@mui/material'
import PopOver from 'components/PopOver/PopOver'
import { ReactElement } from 'react'
import {
  CotationCell as CotationCellType, CotationsPerComponent, ZpMonitoring,
} from 'reducers/monitoring/types'
import { v4 as uuidv4 } from 'uuid'
import { UserSimple } from 'reducers/Users/types'
import CotationCell from './CotationCell/CotationCell'

interface Props {
  zpMonitorings: ZpMonitoring[]
}

const DzpCotationTableBody = ({ zpMonitorings }: Props): ReactElement => {
  /**
   * @returns an array of components with their ratings
   */
  const generateComponentsWithRatings = (): CotationsPerComponent[] => {
    const componentsWithRatings: CotationsPerComponent[] = []

    zpMonitorings.forEach((monitoringObject, monitoringIndex) => {
      monitoringObject.composants.forEach(composant => {
        const existingComponent = componentsWithRatings.find(item => item.id === composant.id)

        if (existingComponent) {
          existingComponent.cotations[monitoringIndex] = {
            cotation: composant.cotation,
            proposition: composant.proposition,
            analyse: composant.analyse,
            date: composant.date,
            animator: composant.animator as UserSimple | null,
          }
        } else {
          const newComponent: CotationsPerComponent = {
            id: composant.id,
            libelle: composant.libelle,
            cotations: Array(zpMonitorings.length).fill('NE'),
          }
          newComponent.cotations[monitoringIndex] = {
            cotation: composant.cotation,
            proposition: composant.proposition,
            analyse: composant.analyse,
            date: composant.date,
            animator: composant.animator as UserSimple | null,
          }
          componentsWithRatings.push(newComponent)
        }
      })
    })
    componentsWithRatings.sort((a, b) => a.id - b.id)
    // Adjust the "cotations" arrays to not have more values than necessary
    componentsWithRatings.forEach(component => {
      component.cotations = component.cotations.slice(0, zpMonitorings.length)
    })
    return componentsWithRatings
  }

  return (
    <>
      {
        generateComponentsWithRatings().map(i => (
          <TableRow key={uuidv4()}>
            <TableCell className="sticky-component">
              <PopOver popContent={i.libelle}>
                <span className="component">
                  {i.id}
                </span>
              </PopOver>

            </TableCell>
            {
              i.cotations.map((cotation: CotationCellType | string) => (
                <TableCell key={uuidv4()}>
                  <CotationCell
                    cotation={cotation}
                  />
                </TableCell>
              ))
            }
          </TableRow>
        ))
      }
    </>
  )
}

export default DzpCotationTableBody
