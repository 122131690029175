import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ViewportProps } from 'react-map-gl'
import { MapState } from './types'
import { getAllLignes, getConstructionSitesLayer, getTracks } from './map.thunk'

const initialState: MapState = {
  viewport: {
    latitude: 46.57715038632404,
    longitude: 2.2663057120417496,
    zoom: 4.5,
    bearing: 0,
    pitch: 0,
  },
  constructionSitesFeatures: null,
  robustestLigneFeatures: null,
  allLignes: null,
}

export const map = createSlice({
  name: 'map',
  initialState,
  reducers: {
    resetViewport: state => {
      state.viewport = initialState.viewport
    },
    updateViewport: (state, action: PayloadAction<ViewportProps>) => {
      state.viewport = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getConstructionSitesLayer.fulfilled, (state, action) => {
      state.constructionSitesFeatures = action.payload
    })
    builder.addCase(getTracks.fulfilled, (state, action) => {
      if (!action.payload) return
      if (!state.robustestLigneFeatures) {
        state.robustestLigneFeatures = {
          type: 'FeatureCollection',
          features: [action.payload],
        }
      } else {
        state.robustestLigneFeatures = {
          type: 'FeatureCollection',
          features: [...state.robustestLigneFeatures.features, action.payload],
        }
      }
    })
    builder.addCase(getAllLignes.fulfilled, (state, action) => {
      state.allLignes = action.payload
    })
  },
})

export const {
  updateViewport,
  resetViewport,
} = map.actions

export default map.reducer
