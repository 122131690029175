import { ReactElement, useState } from 'react'
import { Box } from '@mui/material'
import terms from 'common/terms'
import { Composant } from 'reducers/Admin/Composant/types'
import ComposantJalon from '../../pages/Admin/components/Jalon/Composant/ComposantJalon'
import { StyledTab, StyledTabs } from './JalonStyle'
import a11yProps from './utils'

import './jalon.scss'

export default function ComposantJalonTabs({ composant }
   : {
    composant: Composant,
  }): ReactElement {
  const [value, setValue] = useState(0)
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  return (
    <div className={`mt-2${composant ? ' jalons' : ' multiselect-jalons'}`}>
      <Box sx={{ }}>
        <StyledTabs value={value} onChange={handleChange} aria-label={terms.Admin.composants.tabsLabel}>
          <StyledTab label="J0" {...a11yProps(0)} className="tab-button" />
          <StyledTab label="J1" {...a11yProps(1)} className="tab-button" />
          <StyledTab label="J2" {...a11yProps(2)} className="tab-button" />
          <StyledTab label="J3" {...a11yProps(3)} className="tab-button" />
          <StyledTab label="J4" {...a11yProps(4)} className="tab-button" />
        </StyledTabs>
      </Box>
      <ComposantJalon composant={composant} value={value} />
    </div>
  )
}
