import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
} from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import './settingExportPdf.scss'
import terms from 'common/terms'
import { PrimaryBtn } from 'themes/theme'
import { RootState } from 'Store'
import { useDispatch, useSelector } from 'react-redux'
import ModalCloseButton from 'components/Modal/ModalCloseButton/ModalCloseButton'
import { UserRole } from 'reducers/Users/types'
import { fetchDataToCreatePdf } from 'reducers/Suivi/suivi.thunk'
import { resetPdfData } from 'reducers/Suivi/suivi.reducer'
import ListObjectFeroviaire from './ComponentSetting/ListObjectFeroviaire'
import ListEntity from './ComponentSetting/ListEntity'
import SettingFormattingData from './ComponentSetting/SettingformattingData'
import { downloadPdf, isFormValid } from './utils'

interface SettingExportPdfProps {
  onModal: boolean,
  setOpenModal: () => void,
  numberJalon: number,
}

const SettingExportPdf = ({
  onModal,
  setOpenModal,
  numberJalon,
}: SettingExportPdfProps): ReactElement => {
  const dispatch = useDispatch()
  const [isChecked, setIsChecked] = useState(false)
  const { settingPdfList, settingPdfToggled } = useSelector((state: RootState) => state.suivi)
  const { userMeSuivi } = useSelector((state: RootState) => state.users)
  const { pdfData } = useSelector((state: RootState) => state.suivi)
  const thisSA = userMeSuivi.find(sa => sa.id === localStorage.getItem('idSA'))
  const myRoleToThisSa = thisSA?.roles.some((role: UserRole) => role === 'admin' || role === 'animateur')

  useEffect(() => {
    if (pdfData) {
      downloadPdf(pdfData, `${thisSA?.libelle}_J${numberJalon}`)
      dispatch(resetPdfData())
    }
  }, [pdfData])

  const handleClick = () => {
    if (numberJalon === 0) {
      dispatch(fetchDataToCreatePdf({
        idSA: localStorage.getItem('idSA') || '',
        numJalon: numberJalon,
        typeToDisplay: '',
        option: {
          entites: settingPdfList.entites,
          contrib_global_anim: settingPdfToggled.globalAnimators,
          contrib_decideur: settingPdfToggled.contributors,
        },
      }))
    } else {
      dispatch(fetchDataToCreatePdf({
        idSA: localStorage.getItem('idSA') || '',
        numJalon: numberJalon,
        typeToDisplay: settingPdfToggled.display ? 'objets' : 'composants',
        option: {
          batiments_voyageurs: settingPdfList.complexes,
          lignes: settingPdfList.lignes,
          travaux: settingPdfList.travaux,
          entites: settingPdfList.entites,
          contrib_global_anim: settingPdfToggled.globalAnimators,
          contrib_spec_anim: settingPdfToggled.specificAnimators,
          contrib_contributeur: settingPdfToggled.contributors,
        },
      }))
    }
  }

  useEffect(() => {
    setIsChecked(!myRoleToThisSa)
  }, [myRoleToThisSa])
  return (
    <Modal
      open={onModal}
      onClose={setOpenModal}
      className="settingExportPdf-modal"
    >
      <Box
        className="settingExportPdf"
      >
        <ModalCloseButton handleClose={setOpenModal} className="close-modal" />
        <h1 className="settingExportPdf-title">
          <FileUploadIcon
            className="settingExportPdf-title-icon"
          />
          <span className="settingExportPdf-title-text">
            {terms.Suivi.pdfTerms.setting.title}
          </span>
        </h1>
        {myRoleToThisSa && (
          <div className="settingExportPdf-condition">
            <p className="settingExportPdf-condition-text">
              {terms.Suivi.pdfTerms.setting.warningTerms}
            </p>
            <FormGroup
              className="settingExportPdf-condition-checkbox"
            >
              <FormControlLabel
                control={(
                  <Checkbox
                    onChange={() => setIsChecked(!isChecked)}
                    defaultChecked={isChecked}
                  />
                )}
                label={terms.Suivi.pdfTerms.setting.checkboxTerms}
              />
            </FormGroup>
          </div>
        )}
        <div className={`settingExportPdf-body ${isChecked ? '' : ' disabled'}`}>
          {numberJalon !== 0 && (
            <ListObjectFeroviaire
              termsChecked={isChecked}
            />
          )}
          <div className="settingExportPdf-body-section">
            <ListEntity />
            <SettingFormattingData
              termsChecked={isChecked}
              jalon={numberJalon}
            />
          </div>
        </div>
        <div className="settingExportPdf-buttons">
          <PrimaryBtn
            disabled={!isFormValid(settingPdfList, settingPdfToggled, isChecked, numberJalon)}
            onClick={handleClick}
          >
            {terms.Suivi.pdfTerms.setting.buttonExport}
          </PrimaryBtn>
        </div>
      </Box>
    </Modal>
  )
}

export default SettingExportPdf
