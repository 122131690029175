import terms from 'common/terms'
import ModalWrapper from 'components/Modal/ModalWrapper/ModalWrapper'
import { ReactElement } from 'react'
import { Action } from 'reducers/ActionPlan/types'
import ChipStatus from './Components/ChipStatus'
import DateAndUser from './Components/DateAndUser'
import ProgressReport from './Components/ProgressReport'

import '../Contributions.scss'

interface Props {
  onClose: () => void;
  open: boolean;
  action: Action | null;
  fullAction: boolean;
}

const ModalAction = ({
  onClose, open, action, fullAction,
}: Props):ReactElement => {
  if (!action) return <></>

  return (
    <ModalWrapper
      title={terms.Monitoring.Contributions.Action.modal.title}
      className="suppervision-modal"
      open={open}
      onClose={onClose}
    >
      <div className="action">
        <div className="libelle">
          {action.nom}
        </div>
        {
          fullAction && (
            <div className="description">
              {action.description}
            </div>
          )
        }

        <ChipStatus
          state={action.etat}
          status={action.statut}
        />
        {
          fullAction && (
            <DateAndUser
              pilote={action.pilote || null}
              copilote={action.copilote || null}
              dateOpen={action.date_ouverture}
              dateClose={action.date_bouclage}
            />
          )
        }

        <ProgressReport
          animatorWeather={action.meteo_animateur}
          pilotWeather={action.meteo_pilote}
          animatorComment={action.etat_avancement_animateur}
          pilotComment={action.etat_avancement_pilote}
          fullAction={fullAction}
        />
      </div>
    </ModalWrapper>
  )
}

export default ModalAction
