import { cotationInfo } from 'common/cotation'
import terms from 'common/terms'
import PopOver from 'components/PopOver/PopOver'
import { ReactElement, useEffect, useState } from 'react'
import { CotationCell as CotationCellType } from 'reducers/monitoring/types'
import CotationPopper from './CotationPopper'

interface Props {
  cotation: CotationCellType | string
}

const CotationCell = ({ cotation }: Props): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [open, setOpen] = useState(false)

  const popOverLibelleCotation = (cot: CotationCellType | string): string => {
    if (cot === 'NE') {
      return terms.Monitoring.Dzp.cotations.notWaiting
    }
    if (typeof cot !== 'string') {
      return cot.cotation === null ? terms.Monitoring.Dzp.cotations.waiting : terms.Monitoring.Dzp.cotations.popCotation
    }
    return ''
  }

  const handleClose = () => {
    setOpen(false)
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (anchorEl !== null) {
      handleClose()
    } else {
      setAnchorEl(event.currentTarget)
      setOpen(true)
    }
  }

  const handleScroll = () => {
    handleClose()
  }

  useEffect(() => {
    const element = document.getElementsByClassName('table-container')[0]
    element.addEventListener('scroll', handleScroll)

    return () => {
      element.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const getBackgroundColor = (cot: string | CotationCellType, border: string): string => cotationInfo(
    typeof cot !== 'string' ? cot.cotation : cot,
  )?.color || border

  return (
    <div>
      <PopOver
        popContent={popOverLibelleCotation(cotation)}
      >
        {
          (typeof cotation === 'string' || cotation.cotation === null) ? (
            <span
              className="cotation"
              style={{
                backgroundColor: getBackgroundColor(cotation, '#fff'),
                borderColor: getBackgroundColor(cotation, '#C6C6C6'),
              }}
            />
          ) : (
            <button
              type="button"
              className="cotation"
              onClick={handleClick}
              style={{
                backgroundColor: getBackgroundColor(cotation, '#fff'),
                borderColor: getBackgroundColor(cotation, '#C6C6C6'),
              }}
            />
          )
        }
      </PopOver>
      {
        open && (typeof cotation !== 'string') && (
          <CotationPopper
            open={open}
            cotation={cotation}
            anchorEl={anchorEl}
            handleClose={handleClose}
          />
        )
      }
    </div>
  )
}

export default CotationCell
