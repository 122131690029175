import { ReactElement, useCallback, useState } from 'react'
import { debounce } from 'lodash'
import { postEntity } from 'reducers/Admin/admin.thunk'
import {
  addEntity, changeEntity, deleteNewEntity, hideSnackbar,
} from 'reducers/Admin/Entity/entity'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import {
  Stack, TextField, Typography,
} from '@mui/material'
import { PrimaryBtn } from 'themes/theme'
import terms from 'common/terms'
import AntSwitch from 'components/AntSwitch/AntSwitch'
import Autocomplete from '@mui/material/Autocomplete'
import { Entity } from 'reducers/Admin/Entity/types'
import CustomSnackbar from 'components/Snackbar/Snackbar'
import HighlightMatch from 'components/HighlightMatch'
import Accordion from '../../../components/Accordion/Accordion'
import { controller, getAll } from '../../../reducers/Users/users.thunk'

type FormProps = {
  entity: Entity
}

function Form({ entity } : FormProps): ReactElement {
  const { users, isLoading } = useSelector(((state : RootState) => state.users))
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()
  const debouncedSearch = useCallback(debounce(value => dispatch(getAll(value)), 250), [])

  const handleSearch = (value: string) => {
    setSearch(value)
    if (value.length > 2) {
      controller.abort()
      debouncedSearch(value)
    }
  }
  return (
    <form
      onSubmit={evt => {
        evt.preventDefault()
        dispatch(postEntity(entity))
      }}
      className="form-container-entity"
    >
      <div className="row align-items-center mt-3">
        <label className="col-6 d-flex align-items-center mb-0" htmlFor="libelle">
          {terms.Admin.name}
          <input
            className="input-admin form-control form-control-sm ml-2"
            placeholder={terms.Admin.entity.placeHolder}
            type="text"
            id="libelle"
            name="libelle"
            value={entity.libelle}
            onChange={evt => dispatch(changeEntity({ ...entity, libelle: evt.target.value }))}
          />
        </label>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          className="form-control-container col-4 row align-items-center ml-auto"
        >
          <Typography className={`${!entity.active ? 'switch-active' : ''} switch`}>{terms.Admin.off}</Typography>
          <AntSwitch
            checked={entity.active as boolean}
            inputProps={{ 'aria-label': 'activée' }}
            onChange={() => dispatch(changeEntity({ ...entity, active: !entity.active }))}
          />
          <Typography className={`${entity.active ? 'switch-active' : ''} switch`}>{terms.Admin.on}</Typography>
        </Stack>
        <div className="mt-3 ml-2">
          <Autocomplete
            className="entity-autocomplete"
            multiple
            sx={{ width: 600 }}
            options={users.filter(user => !entity.superviseurs?.some(
              superviseur => superviseur.cerbere_id === user.id,
            ))}
            filterOptions={options => options}
            loading={isLoading}
            isOptionEqualToValue={(option, superviseur) => option.id === superviseur.id}
            getOptionLabel={option => `${option?.lastName?.toUpperCase()} ${option?.firstName}`}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <HighlightMatch
                  optionLabel={option.displayName}
                  inputValue={search}
                />
              </li>
            )}
            /**
             * it's complicated here autocomplete expect value to be the same type as the Option, but since we use the
             * isOptionEqualToValue we don't need the type to be the same so, it's better to just ignore the error
             *  */
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value={entity.superviseurs}
            noOptionsText={search ? terms.Common.noResults : terms.Admin.entity.noSearch}
            onChange={(_: unknown, newValue) => {
              dispatch(changeEntity({
                ...entity,
                superviseurs: newValue.map(superviseur => ({ ...superviseur, cerbere_id: superviseur.id })),
              }))
            }}
            renderInput={params => (
              <TextField
                className="vertical-display"
                error={search.length < 3 && search.length !== 0}
                {...params}
                label={search.length < 3 && search.length !== 0
                  ? terms.Common.userSearch.condition(terms.Admin.entity.supervisor)
                  : terms.Admin.entity.supervisor}
                fullWidth
              />
            )}
            onInputChange={(_, newInputValue) => handleSearch(newInputValue)}
          />
        </div>

      </div>
      <div>
        <div className="row align-items-center mt-3 justify-content-end">
          <PrimaryBtn
            type="submit"
            className="btn btn-gradient-blue mr-2"
            disabled={!entity.libelle}
          >
            {terms.Admin.validate}
          </PrimaryBtn>
        </div>
      </div>
    </form>
  )
}

export default function EntityAccordion(): ReactElement {
  const dispatch = useDispatch()
  const { entities, displaySnackbar, isLoadingEntities } = useSelector((state : RootState) => state.entity)

  return (
    <>
      <Accordion
        name={terms.Admin.entity.name}
        elementList={entities}
        onAddEntity={() => dispatch(addEntity())}
        onDeleteEntity={() => dispatch(deleteNewEntity())}
        isLoading={isLoadingEntities}
        newElementWording={terms.Admin.entity.new}
        displaySnackbar={displaySnackbar}
      >
        {entity => (
          <Form entity={entity} />
        )}
      </Accordion>
      <CustomSnackbar
        message={terms.Admin.entity.validationNotice}
        displaySnackbar={displaySnackbar}
        handleClose={() => dispatch(hideSnackbar())}
      />
    </>
  )
}
