import {
  Box, Checkbox, Chip, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent,
} from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { Territories } from 'reducers/Users/types'

interface Props {
  possibleAxes: Territories[]
  disabled: boolean
  transfer: string[]
  handleSelectAxes: (value: string[]) => void
  isAxe: boolean
}

const SELECT_ALL_VALUE = 'all'

const ContributionTransfer = ({
  possibleAxes,
  disabled,
  transfer,
  handleSelectAxes,
  isAxe,
}: Props): ReactElement => {
  const handleSelectAll = () => {
    if (transfer.length === possibleAxes.length) {
      handleSelectAxes([])
      return
    }
    handleSelectAxes([...possibleAxes.map(e => e.id as string)])
  }

  const handleChange = (evt: SelectChangeEvent<string[]>) => {
    const { target: { value } } = evt
    if (value.includes(SELECT_ALL_VALUE)) {
      handleSelectAll()
      return
    }
    const axesIdList: string[] = typeof value === 'string' ? [value] : value
    handleSelectAxes([...new Set([
      ...axesIdList,
    ])])
  }

  const emptyPossibleAxes = !possibleAxes.length

  const getLabelTitle = () => {
    if (emptyPossibleAxes) {
      return isAxe ? terms.Contributions.editor.emptyAxeInAxe : terms.Contributions.editor.emptyAxe
    }
    return terms.Contributions.editor.axeTransfert
  }

  return (
    <FormControl variant="standard" className="mb-3">
      <InputLabel id="select-visibility">
        {getLabelTitle()}
      </InputLabel>
      <Select
        disabled={disabled || emptyPossibleAxes}
        multiple
        value={transfer}
        onChange={handleChange}
        renderValue={selected => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.filter(item => possibleAxes.find(e => e.id === item)?.libelle).map(value => (
              <Chip key={value} label={possibleAxes.find(e => e.id === value)?.libelle_eic || ''} />
            ))}
          </Box>
        )}
      >
        <MenuItem
          key={SELECT_ALL_VALUE}
          value={SELECT_ALL_VALUE}
          className={transfer.length === possibleAxes.length ? 'Mui-selected' : ''}
          style={{ paddingLeft: '5px' }}
        >
          <Checkbox
            size="small"
            checked={transfer.length === possibleAxes.length}
          />
          <span>
            {transfer.length === possibleAxes.length
              ? terms.Contributions.visibility.unSelectAll
              : terms.Contributions.visibility.selectAll}
          </span>
        </MenuItem>
        { possibleAxes.map(e => (
          <MenuItem key={e.libelle_eic} value={e.id}>
            <Checkbox
              size="small"
              checked={transfer.includes(e.id || '')}
            />
            <span>
              {e.libelle_eic || ''}
            </span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ContributionTransfer
