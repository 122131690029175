import { getYear } from 'date-fns'
import { range } from 'lodash'

export const years = range(getYear(new Date()) - 10, getYear(new Date()) + 11, 1)

export const months = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
]
