import terms from 'common/terms'
import { ReactElement } from 'react'
import { FaEye, FaFile, FaTimes } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { changeJalon } from 'reducers/Admin/Composant/composant'
import { Composant, Jalon } from 'reducers/Admin/Composant/types'

export default function JalonDescription({ jalon, composant }: { jalon: Jalon, composant: Composant }): ReactElement {
  const dispatch = useDispatch()

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onPdfAdd = (evt: React.ChangeEvent<HTMLInputElement>, jalon: Jalon, composant: Composant) => {
    const fileReader = new FileReader()
    const { files } = evt.currentTarget
    fileReader.onload = fileLoadEvent => {
      dispatch(
        changeJalon({
          jalon: {
            ...jalon,
            pdf_file: fileLoadEvent.target?.result,
            pdf: URL.createObjectURL((files || [])[0]),
          },
          composant,
        }),
      )
    }
    fileReader.readAsDataURL((files || [])[0])
  }

  const getFile = async (url: string) => {
    fetch(`${url}&content_disposition=inline`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(resp => resp.json())
      .then(json => {
        const link = document.createElement('a')
        link.href = json
        link.target = '_blank'
        document.body.appendChild(link)
        link.click()
      })
  }

  const handleClickSeeFile = (pdfLink: string, pdfFile: string | ArrayBuffer | undefined | null) => () => {
    if (pdfFile && pdfLink) {
      const link = document.createElement('a')
      link.href = pdfLink
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
    } else if (pdfLink) {
      getFile(pdfLink)
    }
  }

  return (
    <div className="mb-2">
      <label className="attach mb-0 pointer" htmlFor={`file${composant.id}${jalon.numero_jalon}`}>
        <FaFile size="18px" />
        <em className="ml-2">
          <u>{jalon.pdf ? terms.Admin.composants.updatePdf : terms.Admin.composants.addPdf}</u>
        </em>
        <input
          className="sr-only"
          id={`file${composant.id}${jalon.numero_jalon}`}
          type="file"
          accept="application/pdf"
          onChange={evt => onPdfAdd(evt, jalon, composant)}
        />
      </label>
      {jalon.pdf ? (
        <>
          <button
            className="btn p-0 ml-2 mr-2 see-button"
            type="button"
            aria-label={terms.Admin.composants.pdfLinkLabel}
            onClick={handleClickSeeFile(jalon.pdf, jalon.pdf_file)}
          >
            <FaEye size="18px" />
          </button>
          <button
            type="button"
            className="btn p-0 delete-button"
            aria-label={terms.Admin.composants.deletePdf}
            onClick={() => dispatch(changeJalon({ jalon: { ...jalon, pdf_file: null, pdf: null }, composant }))}
          >
            <FaTimes size="18px" />
          </button>
        </>
      ) : (
        ''
      )}
    </div>
  )
}
