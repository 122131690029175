import { ReactElement, useEffect, useState } from 'react'
import { UserMeRoles, UserRole } from 'reducers/Users/types'
import { getSaComplexes } from 'reducers/Parametrage/Complexes/complexes.thunk'
import { getSaConstructionSites } from 'reducers/Parametrage/ConstructionSites/constructionSites.thunk'
import { getSaLignes } from 'reducers/Parametrage/ParamLignes/paramLignes.thunk'
import { getSaContributeurs } from 'reducers/Parametrage/Contributeurs/contributeurs.thunk'
import { getSaDeciders } from 'reducers/Parametrage/Deciders/deciders.thunks'
import { getSaVisitors } from 'reducers/Parametrage/Visitors/visitors.thunks'
import { getSaPreferences } from 'reducers/Parametrage/Preferences/preferences.thunk'
import { selectDeciderDefaultEntity } from 'reducers/Parametrage/Deciders/deciders.reducer'
import { selectVisitorDefaultEntity } from 'reducers/Parametrage/Visitors/visitors.reducer'
import { getSaAnimators } from 'reducers/Parametrage/Animators/animators.thunks'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { Box, Tab, Tabs } from '@mui/material'
import useChangeSaURL from 'common/hooks'
import SaSelector from 'components/SaSelector/SaSelector'
import ModalUserInfos from 'components/Modal/ModalUserInfos/ModalUserInfos'
import { getAllJalonsComposants, getContributorFunctions, getEntity } from 'reducers/Admin/admin.thunk'
import { defineRole, setDisplayUserInfos } from 'reducers/Users/users.reducer'
import { yourRole } from 'reducers/Users/utils'
import { loadRole } from 'reducers/Parametrage/Contributeurs/contributeurs.reducer'
import { TabElement, TabsElement } from './TabsElements'
import './settings.scss'

export default function Settings(): ReactElement {
  const dispatch = useDispatch()
  const {
    userMeSettings, userMeSuivi, userMePlanAction, displayUserInfos, usersEntitySupervisor,
  } = useSelector((state: RootState) => state.users)
  const { entities } = useSelector((state: RootState) => state.entity)
  const [availableTabs, setAvailableTabs] = useState<TabElement[]>([])
  const [tabValue, setTabValue] = useState<number>(0)
  const [selectedSA, setSelectedSA] = useState<UserMeRoles | null>(null)
  const { appPermissions } = useSelector((state: RootState) => state.user)
  const { territories, axes } = useSelector((state: RootState) => state.users.userMe)
  const { reloadRole } = useSelector((state: RootState) => state.paramContributeurs)
  const { isLoadingComplexe } = useSelector((state: RootState) => state.paramComplexes)

  const changeUrlSAHistory = useChangeSaURL(setSelectedSA, userMeSettings)

  const handleChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const Comp = availableTabs[tabValue]?.Component

  useEffect(() => {
    dispatch(getAllJalonsComposants())
  }, [])

  useEffect(() => {
    if (selectedSA) {
      setTabValue(0)
      setAvailableTabs(TabsElement.filter(
        element => selectedSA.roles.some(role => element.robAccess.includes(role)),
      ))
      dispatch(setDisplayUserInfos(false))
      dispatch(getSaComplexes(selectedSA.id))
      dispatch(getSaLignes(selectedSA.id))
      dispatch(getSaConstructionSites(selectedSA.id))
      dispatch(getSaContributeurs(selectedSA.id))
      dispatch(getSaDeciders(selectedSA.id))
      dispatch(getSaVisitors(selectedSA.id))
      dispatch(getEntity({ sa: selectedSA.id }))
      dispatch(getContributorFunctions({ sa: selectedSA.id }))
      if (!selectedSA.roles.includes(UserRole.entityAnimator)) {
        dispatch(getSaPreferences({ saId: selectedSA.id }))
      }
      if (selectedSA.roles.some(
        role => role === UserRole.admin || role === UserRole.animator || role === UserRole.animatorAxe,
      )) {
        dispatch(getSaAnimators(selectedSA.id))
      }
    }
  }, [selectedSA])

  useEffect(() => {
    if (entities) {
      const userEntity = entities.find(ent => ent.id === selectedSA?.entite)
      dispatch(selectDeciderDefaultEntity(userEntity || null))
      dispatch(selectVisitorDefaultEntity(userEntity || null))
    }
  }, [selectedSA, entities])

  useEffect(() => {
    if (reloadRole) {
      dispatch(defineRole(yourRole(
        appPermissions,
        territories,
        axes,
        userMeSettings,
        userMeSuivi,
        userMePlanAction,
        usersEntitySupervisor,
      )))
      dispatch(loadRole())
    }
  }, [reloadRole])
  return (
    <div className="tabs-container">
      <div className="selectSA">
        <SaSelector
          options={userMeSettings}
          value={selectedSA}
          handleChange={(_evt, newValue) => changeUrlSAHistory(newValue)}
          disabled={isLoadingComplexe}
        />
      </div>
      {selectedSA !== null
        && (
        <Box className="settings-tabs-box">
          <Tabs value={tabValue} onChange={handleChangeTab} className="settings-tabs">
            {availableTabs.map(element => (
              <Tab key={element.key} label={element.value} className="settings-tab-button" />
            ))}
          </Tabs>
          <Box className="tab-panel-container">
            {Comp && (<Comp />)}
          </Box>
        </Box>
        )}
      <ModalUserInfos
        onModal={displayUserInfos}
        handleClose={() => dispatch(setDisplayUserInfos(false))}
      />
    </div>
  )
}
