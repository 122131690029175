import { ReactElement } from 'react'
import { cotationInfo, RATINGS } from 'common/cotation'
import PopOver from 'components/PopOver/PopOver'
import './cotationPicker.scss'

interface CotationPickerProps {
  selectedCotation: string | null
  handlePickCotation: (rating: string) => () => void
  disabled?: boolean
}

const CotationPicker = ({ selectedCotation, handlePickCotation, disabled }: CotationPickerProps): ReactElement => (
  <div className="cotation-picker d-flex justify-content-end mb-3">
    {RATINGS.map(rating => {
      const infos = cotationInfo(rating)
      return infos ? (
        <PopOver popContent={infos.rating} key={infos.abrev}>
          <button
            disabled={disabled}
            onClick={handlePickCotation(rating)}
            type="button"
            className="rating"
            style={{ background: `${selectedCotation === rating ? infos.color : '#b9b9b9'}` }}
          >
            {infos.abrev}
          </button>
        </PopOver>
      ) : (
        <></>
      )
    })}
  </div>
)

CotationPicker.defaultProps = {
  disabled: false,
}

export default CotationPicker
