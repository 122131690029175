import {
  FormControl,
  FormLabel,
  TextField,
} from '@mui/material'
import terms from 'common/terms'
import CustomTextField from 'components/CustomTextfield/CustomTextField'
import PopOver from 'components/PopOver/PopOver'
import { ReactElement, useState } from 'react'
import { PrimaryBtn } from 'themes/theme'
import ModalWrapper from '../ModalWrapper/ModalWrapper'
import DataInformation from './DataInformation/DataInformation'
import './modalRecoverBug.scss'

interface Props {
  open: boolean
  handleClose: () => void
}

const ModalRecoverBug = ({ open, handleClose }: Props): ReactElement => {
  const [inputObject, setInputObject] = useState('')
  const [inputContent, setInputContent] = useState('')
  const [inputCheckbox, setInputCheckox] = useState(false)

  const browser = window.navigator

  const clickClose = () => {
    setInputObject('')
    setInputContent('')
    setInputCheckox(false)
    handleClose()
  }

  const bodyMail = () => {
    if (inputCheckbox) {
      return `${
        inputContent.replace(/\n/g, '%0D%0A')
      }%0D%0A%0D%0A${terms.FeedbackMailto.navigatorInstalled} ${
        browser.userAgent.replace(/\n/g, '%0D%0A')
      }%0D%0A%0D%0A${terms.FeedbackMailto.os}: ${
        browser.platform
      }%0D%0A%0D%0A${terms.FeedbackMailto.url}: ${
        window.location.href
      }%0D%0A%0D%0A${terms.FeedbackMailto.screenWidth} ${
        window.screen.width
      }%0D%0A%0D%0A${terms.FeedbackMailto.screenHeight} ${
        window.screen.height
      }`
    }
    return `${
      inputContent.replace(/\n/g, '%0D%0A')
    }`
  }

  const handleSendMail = () => {
    window.location.href = `mailto:robustest.moe@dgexsol.fr?subject=${inputObject}&body=${bodyMail()}`
    clickClose()
  }

  const isDisabled = !inputObject
  || !inputContent
  || inputContent.length > parseInt(terms.FeedbackMailto.nbCharMax, 10)

  return (
    <ModalWrapper
      open={open}
      className="modal-wrapper"
      onClose={clickClose}
      title={terms.FeedbackMailto.title}
    >
      <div className="feedback">
        <p id="parent-modal-description">
          {terms.FeedbackMailto.subTitle}
        </p>
        <div className="inputs">
          <CustomTextField
            label={terms.FeedbackMailto.labelOject}
            inputValue={inputObject}
            setInputValue={setInputObject}
            popOverContent={terms.FeedbackMailto.popOverObject}
            required
          />
          <FormControl fullWidth>
            <PopOver
              popContent={terms.FeedbackMailto.popOverContent}
              anchorOriginHorizontal="left"
            >
              <FormLabel
                required
                htmlFor="outlined-adornment-amount"
              >
                {terms.FeedbackMailto.labelContent}
              </FormLabel>
            </PopOver>
            <TextField
              id="outlined-controlled"
              fullWidth
              multiline
              rows={5}
              value={inputContent}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setInputContent(event.target.value)
              }}
            />
          </FormControl>
        </div>
        <div className={`count ${
          inputContent.length > parseInt(terms.FeedbackMailto.nbCharMax, 10)
            ? 'feedback-warning'
            : ''
        }`}
        >
          <span>{inputContent.length}</span>
          <span>/</span>
          <span>{terms.FeedbackMailto.nbCharMax}</span>
          <div>{terms.FeedbackMailto.char}</div>
        </div>
        <DataInformation
          inputCheckbox={inputCheckbox}
          setInputCheckox={setInputCheckox}
        />
        <div className="button">
          <PrimaryBtn
            onClick={handleSendMail}
            disabled={isDisabled}
          >
            {terms.FeedbackMailto.send}
          </PrimaryBtn>
        </div>
      </div>
    </ModalWrapper>
  )
}
export default ModalRecoverBug
