import { ReactElement } from 'react'
import { postComposant } from 'reducers/Admin/admin.thunk'
import {
  addComposant, changeComposant, deleteNewComposant, hideSnackbar,
} from 'reducers/Admin/Composant/composant'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import terms from 'common/terms'
import { PrimaryBtn } from 'themes/theme'
import { FaFlag } from 'react-icons/fa'
import { Stack, Typography } from '@mui/material'
import AntSwitch from 'components/AntSwitch/AntSwitch'
import CustomSnackbar from 'components/Snackbar/Snackbar'
import ComposantJalonTabs from 'components/Jalons/JalonTabs'
import Accordion from '../../../components/Accordion/Accordion'

export default function ComposantAccordion(): ReactElement {
  const dispatch = useDispatch()
  const { composants, displaySnackbar, isLoadingComponents } = useSelector((state : RootState) => state.composant)
  return (
    <>
      <Accordion
        name={terms.Admin.composants.name}
        elementList={composants}
        onAddEntity={() => dispatch(addComposant())}
        onDeleteEntity={() => dispatch(deleteNewComposant())}
        isLoading={isLoadingComponents}
        newElementWording={terms.Admin.composants.new}
        displaySnackbar={displaySnackbar}
      >
        {composant => (
          <form onSubmit={evt => {
            evt.preventDefault()
            dispatch(postComposant(composant))
          }}
          >
            <div className="row align-items-center mt-3">
              <label className="col-6 d-flex align-items-center mb-0" htmlFor="libelle">
                {terms.Admin.name}
                <input
                  className="input-admin form-control form-control-sm ml-2"
                  placeholder={terms.Admin.composants.placeHolder}
                  type="text"
                  id="libelle"
                  name="libelle"
                  value={composant.libelle}
                  onChange={evt => dispatch(changeComposant({ ...composant, libelle: evt.target.value }))}
                />
              </label>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                className="form-control-container col-4 row align-items-center ml-auto"
              >
                <Typography className={`${!composant.active ? 'switch-active' : ''} switch`}>
                  {terms.Admin.off}
                </Typography>
                <AntSwitch
                  checked={!!composant.active}
                  inputProps={{ 'aria-label': 'activée' }}
                  onChange={() => dispatch(changeComposant({ ...composant, active: !composant.active }))}
                />
                <Typography className={`${composant.active ? 'switch-active' : ''}
                 switch`}
                >
                  {terms.Admin.on}
                </Typography>
              </Stack>
              <div className="col-4">
                <button
                  className="btn national"
                  type="button"
                  onClick={() => dispatch(changeComposant({ ...composant, national: !composant.national }))}
                >
                  <FaFlag size="18px" className={composant.national ? 'red-flag' : ''} />
                  <span className="ml-2">
                    {!composant.national
                      ? "Ce composant n'est pas une directive nationale" : 'Ce composant est une directive nationale'}
                  </span>
                </button>
              </div>
            </div>
            <ComposantJalonTabs composant={composant} />
            <div>
              <div className="row align-items-center mt-3 justify-content-end">
                <PrimaryBtn
                  type="submit"
                  className="btn btn-gradient-blue mr-2"
                  disabled={!composant.libelle}
                >
                  {terms.Admin.validate}
                </PrimaryBtn>
              </div>
            </div>
          </form>
        )}
      </Accordion>
      <CustomSnackbar
        message={terms.Admin.composants.validationNotice}
        displaySnackbar={displaySnackbar}
        handleClose={() => dispatch(hideSnackbar())}
      />
    </>
  )
}
