import CloseIcon from '@mui/icons-material/Close'
import { RootState } from 'Store'
import terms from 'common/terms'
import MapGL from 'components/MapGL/MapGL'
import ModalWrapper from 'components/Modal/ModalWrapper/ModalWrapper'
import { debounce } from 'lodash'
import {
  ReactElement, useCallback, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { controllerComplexes, searchComplexes } from 'reducers/Admin/Complexes/complexes.thunk'
import { Entity } from 'reducers/Admin/Entity/types'
import { controllerLigne, getLignes, postOrPatchPortfolio } from 'reducers/Admin/Portfolio/portfolio.thunk'
import { Ligne, ModalType, PortfolioItem } from 'reducers/Admin/Portfolio/type'
import { controllerConstructionSites, getConstructionSites } from 'reducers/Admin/admin.thunk'
import { UserRole } from 'reducers/Users/types'
import { AutocompleteValue, ObjKey } from 'reducers/types'
import portfolioService from 'services/portfolioService'

import { MapEvent } from 'react-map-gl'
import { Complexes } from 'reducers/Admin/Complexes/types'
import { ConstructionSites } from 'reducers/Admin/ConstructionSites/types'
import { MAP_LAYER_SOURCE } from 'services/map'
import Form from '../Form'
import './modal.scss'

interface Props {
  open: boolean
  handleClose: () => void
  portfolio: PortfolioItem | null
  modalType: ModalType
}

const ModalCreateOrUpdate = ({
  open,
  handleClose,
  portfolio,
  modalType,
}: Props): ReactElement => {
  const dispatch = useDispatch()
  const { entities } = useSelector((state: RootState) => state.entity)
  const { complexes } = useSelector((state: RootState) => state.complexes)
  const { robPermission, usersEntitySupervisor } = useSelector((state: RootState) => state.users)
  const { robustestComplexes, lignes } = useSelector((state: RootState) => state.portfolio)
  const { constructionSites } = useSelector((state: RootState) => state.constructionSites)
  const [search, setSearch] = useState('')
  const [selectedObj, setSelectedObj] = useState<AutocompleteValue[]>([])
  const [portfolioEntity, setPortfolioEntity] = useState<Entity | null>(null)
  const [portfolioName, setPortfolioName] = useState('')

  const debouncedSearchObj = useCallback(debounce((value: string) => {
    controllerLigne.abort()
    controllerComplexes.abort()
    controllerConstructionSites.abort()
    setSearch(value)
    dispatch(getLignes(value))
    dispatch(searchComplexes(value))
    dispatch(getConstructionSites(value))
  }, 500), [])

  const isSupervisor = !!usersEntitySupervisor

  const possibleEntities = () => {
    if (robPermission.includes(UserRole.admin)) {
      return entities
    }
    return entities.filter(entity => usersEntitySupervisor?.includes(entity.id as string))
  }

  useEffect(() => {
    if (possibleEntities().length === 1) {
      setPortfolioEntity(entities.find(item => item.id === usersEntitySupervisor) || null)
    }
  }, [possibleEntities()])

  useEffect(() => {
    if (portfolio) {
      setPortfolioName(portfolio.libelle)
      setSelectedObj(portfolioService.getObjList(
        portfolio.batiments_voyageurs,
        portfolio.travaux,
        portfolio.lignes,
        robustestComplexes,
      ))
      setPortfolioEntity(portfolio.entite)
    }
  }, [portfolio])

  function getSelectedObj<T>(key: ObjKey):T[] {
    return portfolioService.sortSelectedObj(
      selectedObj,
      robustestComplexes,
      constructionSites,
      lignes,
    )[key] as T[]
  }

  const handleSavePortfolio = async () => {
    if (!portfolioEntity || !portfolioName || !selectedObj.length) return
    const response = await dispatch(postOrPatchPortfolio({
      id: modalType === ModalType.UPDATE ? portfolio?.id : 'new',
      libelle: portfolioName,
      entite: portfolioEntity as Entity,
      batiments_voyageurs: getSelectedObj(ObjKey.COMPLEXES),
      lignes: getSelectedObj(ObjKey.LIGNES),
      travaux: getSelectedObj(ObjKey.CONSTRUCTION_SITES),
      generic: false,
    })as unknown as { type: string, payload: { status: string }})

    if (response.type.endsWith('fulfilled')) {
      handleClose()
    }
  }

  const handleFeatureClick = (
    typeObj: ObjKey,
    objClicked: Complexes | ConstructionSites | Ligne | undefined,
  ) => {
    if (!objClicked) return

    if (selectedObj.find(item => item.id === objClicked.id)) {
      setSelectedObj(selectedObj.filter(item => item.id !== objClicked.id))
    } else {
      setSelectedObj([
        ...selectedObj,
        ...portfolioService.getObjList(
          (typeObj === ObjKey.COMPLEXES) ? [objClicked as Complexes] : complexes,
          (typeObj === ObjKey.CONSTRUCTION_SITES) ? [objClicked as ConstructionSites] : constructionSites,
          (typeObj === ObjKey.LIGNES) ? [objClicked as Ligne] : lignes,
          robustestComplexes,
        ).filter(item => item.id === objClicked.id),
      ])
    }
  }

  const onFeatureClick = (e: MapEvent) => {
    const feature = e.features?.[0]
    if (!feature) return
    const { sourceLayer, source } = feature
    switch (sourceLayer || source) {
      case MAP_LAYER_SOURCE.pr:
        handleFeatureClick(
          ObjKey.COMPLEXES,
          robustestComplexes.find(item => item.gaia_id === e.features?.[0].properties?.id),
        )
        break
      case MAP_LAYER_SOURCE.constructionSite:
        handleFeatureClick(
          ObjKey.CONSTRUCTION_SITES,
          constructionSites.find(item => item.id === e.features?.[0].properties?.id),
        )
        break
      case MAP_LAYER_SOURCE.sectionLine:
        handleFeatureClick(
          ObjKey.LIGNES,
          lignes.find(item => item.id === e.features?.[0].properties?.id),
        )
        break
      default:
    }
  }

  return (
    <ModalWrapper
      open={open}
      onClose={handleClose}
      className="modal-create"
    >
      <div className="create">
        <div className="modal-form">
          <div className="modal-title">
            <span>
              {terms.ParametragePortfolio.create.title}
            </span>
            <button
              type="button"
              onClick={handleClose}
            >
              <CloseIcon />
            </button>
          </div>
          <Form
            portfolioEntity={portfolioEntity}
            portfolioName={portfolioName}
            possibleEntities={possibleEntities()}
            setPortfolioEntity={setPortfolioEntity}
            setPortfolioName={setPortfolioName}
            debouncedSearchObj={debouncedSearchObj}
            setSelectedObj={setSelectedObj}
            search={search}
            selectedObj={selectedObj}
            objList={portfolioService.getObjList(complexes, constructionSites, lignes, robustestComplexes)}
            isSupervisor={isSupervisor}
          />
          <div className="button">
            <button
              type="button"
              disabled={!portfolioEntity || !portfolioName || !selectedObj.length}
              onClick={handleSavePortfolio}
            >
              {modalType === ModalType.CREATE ? terms.ParametragePortfolio.create.createButton : terms.Common.save}
            </button>
          </div>
        </div>
        <div className="map">
          {
            portfolio && (
              <MapGL
                onFeatureClick={onFeatureClick}
                bvSelected={getSelectedObj(ObjKey.COMPLEXES) as Complexes[]}
                constructionSiteSelected={getSelectedObj(ObjKey.CONSTRUCTION_SITES) as ConstructionSites[]}
                sectionLineSelected={getSelectedObj(ObjKey.LIGNES) as Ligne[]}
              />
            )
          }
        </div>
      </div>
    </ModalWrapper>
  )
}

ModalCreateOrUpdate.defaultProps = {
  isCreate: false,
}

export default ModalCreateOrUpdate
