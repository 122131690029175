import React, { ReactElement, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import {
  Autocomplete,
  TextField,
} from '@mui/material'
import { debounce } from 'lodash'
import terms from 'common/terms'
import { PrimaryBtn } from 'themes/theme'
import { controller, getAll } from 'reducers/Users/users.thunk'
import { Axe } from 'reducers/Admin/Axes/types'
import { changeAxe } from 'reducers/Admin/Axes/axes.reducer'
import { postAxe } from 'reducers/Admin/Axes/axes.thunk'
import HighlightMatch from 'components/HighlightMatch'

type Props = {
  axe: Axe
}

const AxeDetail = ({ axe } : Props): ReactElement => {
  const { users, isLoading } = useSelector(((state : RootState) => state.users))
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()
  const debouncedSearch = useCallback(debounce(value => dispatch(getAll(value)), 250), [])

  const handleSearch = (value: string) => {
    setSearch(value)
    if (value.length > 2) {
      controller.abort()
      debouncedSearch(value)
    }
  }
  return (
    <form
      onSubmit={evt => {
        evt.preventDefault()
        dispatch(postAxe({
          ...axe,
          animateurs: axe.animateurs.map(a => ({ ...a, cerbere_id: a.id as string })),
        }))
      }}
      className="form-container-entity"
    >
      <div className="row align-items-center mt-3">
        <label className="col-6 d-flex align-items-center mb-0" htmlFor="libelle">
          {terms.Admin.name}
          <input
            className="input-admin form-control form-control-sm ml-2"
            placeholder={terms.Admin.axes.placeHolder}
            type="text"
            id="libelle"
            name="libelle"
            value={axe.libelle}
            onChange={evt => dispatch(changeAxe({
              ...axe,
              libelle: evt.target.value,
            }))}
          />
        </label>
      </div>
      <div className="row d-flex mt-2">
        <div className="w-50 p-2 d-flex flex-column justify-content-end">
          <Autocomplete
            multiple
            sx={{ width: '100%' }}
            options={users.filter(user => !axe.animateurs?.some(
              animateur => animateur.cerbere_id === user.id,
            ))}
            filterOptions={options => options}
            loading={isLoading}
            isOptionEqualToValue={(option, animateur) => option.id === animateur.id}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value={axe.animateurs}
            noOptionsText={search ? terms.Common.noResults : terms.Admin.entity.noSearch}
            getOptionLabel={option => `${option?.lastName?.toUpperCase()} ${option?.firstName}`}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <HighlightMatch
                  optionLabel={option.displayName}
                  inputValue={search}
                />
              </li>
            )}
            loadingText={terms.Common.loading}
            onChange={(_: unknown, newValue) => {
              dispatch(changeAxe({
                ...axe,
                animateurs: newValue.map(superviseur => ({ cerbere_id: superviseur.id, ...superviseur })),
              }))
            }}
            renderInput={params => (
              <TextField
                className="vertical-display"
                {...params}
                label={search.length < 3 && search.length !== 0
                  ? terms.Common.userSearch.condition(terms.Admin.axes.animator)
                  : terms.Admin.axes.animator}
                fullWidth
                error={search.length < 3 && search.length !== 0}
              />
            )}
            onInputChange={(_, newInputValue) => handleSearch(newInputValue)}
          />
        </div>
      </div>
      <div>
        <div className="row align-items-center mt-3 justify-content-end">
          <PrimaryBtn
            type="submit"
            className="btn btn-gradient-blue mr-2"
            disabled={!axe.libelle}
          >
            {terms.Admin.validate}
          </PrimaryBtn>
        </div>
      </div>
    </form>
  )
}

export default AxeDetail
