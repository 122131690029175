import { createTheme, styled } from '@mui/material/styles'
import Button from '@mui/material/Button'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    title1: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    title1?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title1: true;
    underline: true;
  }
}

export const appTheme = createTheme({
  typography: {
    title1: {
      fontWeight: 900,
      fontSize: 36,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&.Mui-disabled': {
            pointerEvents: 'unset',
            cursor: 'not-allowed',
          },
        },
      },
    },
  },
})

export const PrimaryBtn = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: '1rem',
  fontWeight: 500,
  padding: '.4rem 1rem .35rem',
  lineHeight: 1.5,
  color: '#FFFFFF',
  backgroundColor: '#0aa8f9',
  backgroundImage: 'linear-gradient(to right,rgba(255,255,255,0),#2a65a6 100%)',
  borderRadius: '2rem',
  transition: 'all .5s ease',
  backgroundClip: 'padding-box',
  '&:hover': {
    backgroundColor: '#2a65a6',
    boxShadow: 'none',
  },
  '&:focus': {
    boxShadow: 'none',
    outline: 'none',
  },
})

export const SecondaryBtn = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: '1rem',
  fontWeight: 500,
  padding: '.4rem 1rem .35rem',
  lineHeight: 1.5,
  color: '#FFFFFF',
  backgroundColor: '#747678',
  borderRadius: '2rem',
  transition: 'all .5s ease',
  '&:hover': {
    backgroundColor: '#c2c5c9',
    boxShadow: 'none',
  },
  '&:focus': {
    boxShadow: 'none',
    outline: 'none',
  },
})

export const DeleteBtn = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: '1rem',
  fontWeight: 500,
  padding: '.4rem 1rem .35rem',
  lineHeight: 1.5,
  color: '#FFFFFF',
  backgroundColor: '#CD0037',
  borderRadius: '2rem',
  transition: 'all .5s ease',
  border: '1px solid #CD0037',
  '&:hover': {
    backgroundColor: 'transparent',
    color: '#CD0037',
    boxShadow: 'none',
  },
  '&:focus': {
    boxShadow: 'none',
    outline: 'none',
  },
})

export default appTheme
