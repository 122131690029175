import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import terms from 'common/terms'
import { Complexes } from 'reducers/Admin/Complexes/types'
import {
  deleteSaLigne,
  getDefaultJalonsLigne,
  getSaLigneById,
  getSaLignes,
  patchSaLigne,
  postSaLigne,
} from './paramLignes.thunk'
import { ColumnsLignes, ParametrageLignesState, ParamLigne } from './types'

const initialState: ParametrageLignesState = {
  columns: [
    {
      id: '1',
      title: terms.Settings.lignes.columns.listNotActive,
      lignesList: [],
    },
    {
      id: '2',
      title: terms.Settings.lignes.columns.listActive,
      lignesList: [],
    },
  ],
  viaComplexe: null,
  modalConfirmationLigne: false,
  modalErrLigneSa: false,
  modalErrLigneEic: false,
  openComposantsModal: false,
  defaultComposantsLigne: [],
  displaySnackbarAdd: false,
  displaySnackbarDelete: false,
  displaySnackbarUpdate: false,
  isLoadingLignes: false,
}

export const parametrageLignes = createSlice({
  name: 'parametrageLignes',
  initialState,
  reducers: {
    changeListLignes: (state, action: PayloadAction<ColumnsLignes[]>) => {
      state.columns = action.payload
    },
    inputDebutComplexe: (state, action: PayloadAction<Complexes | null>) => {
      state.debutComplexe = action.payload
    },
    inputFinComplexe: (state, action: PayloadAction<Complexes | null>) => {
      state.finComplexe = action.payload
    },
    inputViaComplexe: (state, action: PayloadAction<Complexes | null>) => {
      state.viaComplexe = action.payload
    },
    onModalConfirmationLigne: (state, action: PayloadAction<ParamLigne>) => {
      state.selectedLigne = action.payload
      state.modalConfirmationLigne = true
    },
    closeModalConfirmationLigne: state => {
      state.modalConfirmationLigne = false
    },
    onModalErrLigneSa: state => {
      state.modalErrLigneSa = true
    },
    onModalErrLigneEic: state => {
      state.modalErrLigneEic = true
    },
    closeModalErrligne: state => {
      state.errors = undefined
      state.modalErrLigneSa = false
      state.modalErrLigneEic = false
    },
    setSaLigne: state => {
      state.saLigne = undefined
      state.openComposantsModal = false
    },
    changeSaLigne: (state, action: PayloadAction<ParamLigne>) => {
      state.saLigne = action.payload
    },
    hideSnackbarAdd: state => {
      state.displaySnackbarAdd = false
    },
    hideSnackbarUpdate: state => {
      state.displaySnackbarUpdate = false
    },
    hideSnackbarDelete: state => {
      state.displaySnackbarDelete = false
    },
  },
  extraReducers: builder => {
    builder.addCase(getSaLignes.pending, state => {
      state.columns[0].lignesList = []
      state.columns[1].lignesList = []
      state.listLignesSa = []
      state.isLoadingLignes = true
    })
    builder.addCase(getSaLignes.rejected, state => {
      state.isLoadingLignes = false
    })
    builder.addCase(getSaLignes.fulfilled, (state, { payload }) => {
      if (payload) {
        state.columns[0].lignesList = payload.filter(i => i.active === false)
        state.columns[1].lignesList = payload.filter(i => i.active === true)
        state.listLignesSa = payload
      }
      state.isLoadingLignes = false
    })

    builder.addCase(getSaLigneById.fulfilled, (state, { payload }) => {
      if (payload) {
        state.saLigne = payload
        state.openComposantsModal = true
      }
    })

    builder.addCase(patchSaLigne.fulfilled, (state, { payload }) => {
      if (state.openComposantsModal) {
        state.displaySnackbarUpdate = true
      }
      state.saLigne = payload
      if (state.listLignesSa) {
        state.listLignesSa = state.listLignesSa.map(item => {
          if (item.id === payload.id) {
            return payload
          }
          return item
        })
      }
      state.openComposantsModal = false
    })
    builder.addCase(postSaLigne.fulfilled, (state, { payload }) => {
      if (payload) {
        state.columns[1].lignesList = [
          ...state.columns[1].lignesList,
          {
            id: payload.id,
            ligne: {
              id: payload.ligne?.id,
              bv_debut: {
                id: payload.ligne?.bv_debut?.id,
                libelle: payload.ligne?.bv_debut?.libelle,
              },
              bv_fin: {
                id: payload.ligne?.bv_fin?.id,
                libelle: payload.ligne?.bv_fin?.libelle,
              },
              bv_via: {
                id: payload.ligne?.bv_via?.id,
                libelle: payload.ligne?.bv_via?.libelle,
              },
              eic: payload.ligne?.eic,
            },
            active: true,
          },
        ]
        if (state.listLignesSa) {
          const newSaLignes = [...state.listLignesSa, payload]
          state.listLignesSa = newSaLignes.sort((a, b) => {
            if (a.ligne?.bv_debut?.libelle && b.ligne?.bv_debut?.libelle) {
              return a.ligne.bv_debut.libelle.localeCompare(b.ligne.bv_debut.libelle)
            }
            return 0
          })
        }
        state.debutComplexe = null
        state.finComplexe = null
        state.viaComplexe = null
        state.displaySnackbarAdd = true
      }
    })
    builder.addCase(postSaLigne.rejected, (state, action) => {
      state.errors = action.payload
    })
    builder.addCase(patchSaLigne.rejected, (state, action) => {
      state.errors = action.payload
    })
    builder.addCase(deleteSaLigne.fulfilled, state => {
      state.columns[0].lignesList = state.columns[0].lignesList?.filter(
        i => i?.ligne?.id !== state.selectedLigne?.ligne?.id,
      )
      state.displaySnackbarDelete = true
    })
    builder.addCase(getDefaultJalonsLigne.fulfilled, (state, action) => {
      const newDefaults = [...state.defaultComposantsLigne].filter(jc => jc.numero_jalon !== action.meta.arg)
      state.defaultComposantsLigne = [
        ...newDefaults, { numero_jalon: action.meta.arg, composants: action.payload },
      ]
    })
  },
})

export const {
  changeListLignes,
  inputDebutComplexe,
  inputFinComplexe,
  inputViaComplexe,
  onModalConfirmationLigne,
  closeModalConfirmationLigne,
  onModalErrLigneEic,
  onModalErrLigneSa,
  closeModalErrligne,
  setSaLigne,
  changeSaLigne,
  hideSnackbarAdd,
  hideSnackbarUpdate,
  hideSnackbarDelete,
} = parametrageLignes.actions

export default parametrageLignes.reducer
