import { Eic } from 'reducers/Admin/Eic/types'
import { SaJalon } from 'reducers/SA/types'
import { User as CerbereUser } from './cerbereTypes'

export type User = {
  email: string,
  firstName: string,
  lastName: string,
  displayName: string,
  cp: string,
  id: string,
}

export type UserSimple = {
  id: string,
  firstName: string,
  lastName: string,
  username: string,
  displayName: string,
}

export type UserSimpleWithEmail = UserSimple & {
  email: string,
}

export type UserMe = {
  id: string,
  territories: Territories[]
  axes: Territories[]
}

export enum UserRole {
  admin = 'admin',
  animator = 'animateur',
  entityAnimator = 'animteur_entite',
  decider = 'decideur',
  contributor = 'contributeur',
  visitor = 'visiteur',
  pilot = 'pilote',
  animatorAxe = 'animateur d\'axe'
}

export type UserMeRoles = {
  id: string,
  eic: Eic,
  libelle: string,
  annee: number,
  roles: UserRole[],
  entite: string,
  jalons: SaJalon[],
}

export type UserState = {
  users: User[]
  userMe: UserMe
  userMeSettings: UserMeRoles[]
  userMeSuivi: UserMeRoles[]
  userMePlanAction: UserMeRoles[]
  usersEntitySupervisor: string | null
  isLoading: boolean
  robPermission: string[]
  usersInfoList: UserSimple[]
  userInfosId: string | null
  userInfos: CerbereUser | null
  displayUserInfos: boolean
  isAxeToggle: boolean
  isAxeActionToggle: boolean
}

export type Territories = {
  id: string;
  libelle: string;
  // eslint-disable-next-line camelcase
  libelle_eic?: string;
}
