import { RootState } from 'Store'
import { ReactElement } from 'react'
import { Layer, Source } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { MAP_LAYER_SOURCE } from 'services/map'
import SectionLinePaint from './SectionLinePaint'

interface Props {
  selectedSection: string[]
}

const SectionLineLayer = ({ selectedSection }: Props): ReactElement => {
  const { robustestLigneFeatures } = useSelector((state: RootState) => state.map)

  return (
    <Source
      id={MAP_LAYER_SOURCE.sectionLine}
      type="geojson"
      data={robustestLigneFeatures as GeoJSON.FeatureCollection<GeoJSON.LineString>}
    >
      <Layer
        {...SectionLinePaint.track}
      />
      <Layer
        {...SectionLinePaint.trackSelected}
        filter={['in', 'id', ...selectedSection]}
      />
    </Source>
  )
}

export default SectionLineLayer
