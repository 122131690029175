import { ReactElement } from 'react'
import {
  FaRssSquare, FaExternalLinkAlt, FaQuestionCircle, FaBookOpen,
} from 'react-icons/fa'
import terms from './common/terms'
import mainImage from './assets/mainImage.jpeg'
import sideImage from './assets/sideImage.jpeg'
import logo from './assets/logo.jpeg'
import banner from './assets/images/home_banner.jpg'

import './Home.scss'

export default function Home(): ReactElement {
  return (
    <main className="home-container">
      <div className="p-3 header" style={{ backgroundImage: `url(${banner})` }}>
        <div className="welcome-container d-flex justify-content-end">
          <div className="header-links d-flex h-100">
            <a
              className="header-link d-flex flex-column align-items-center shadow"
              target="forum"
              href="https://portail-robustest.fr"
            >
              <FaRssSquare size="25px" className="mb-2" />
              <span className="text-center">{terms.Home.portail}</span>
            </a>

            <a
              className="header-link d-flex flex-column align-items-center shadow"
              target="robustest"
              href="https://robustest.sncf.fr/pages/home"
            >
              <FaExternalLinkAlt size="25px" className="mb-2" />
              <span className="text-center">{terms.Home.old_version}</span>
            </a>
            <a
              className="header-link d-flex flex-column align-items-center shadow"
              href="mailto:martin.prieto@sncf.fr;"
            >
              <FaQuestionCircle size="25px" className="mb-2 question-circle" />
              <span className="text-center">{terms.Home.support}</span>
            </a>
            <a
              className="header-link d-flex flex-column align-items-center shadow"
              target="documentation"
            // eslint-disable-next-line max-len
              href="https://sncf.sharepoint.com/:f:/s/RobustestDocs/EsiIh9GpnKVJvl_J0z60CN0BWsBXqg9h3TISOmXVzgMltw?e=67m48G"
            >
              <FaBookOpen size="25px" className="mb-2 book" />
              <span className="text-center">{terms.Home.h00}</span>
            </a>
          </div>
        </div>
      </div>
      <article className="d-flex home-body">
        <div className="w-100">
          <img src={mainImage} alt="Robustest planning" className="mr-4 main-image" />
        </div>
        <div className="d-flex flex-column align-items-left text-start aside-block">
          <p className="text-uppercase aside-text">
            {terms.Home.sideText}
          </p>
          <div className="d-flex flex-column">
            <img
              src={sideImage}
              width="210"
              height="227"
              alt={terms.Home.sideImageAlt}
            />
            <img
              src={logo}
              width="40%"
              alt="robustesse"
            />
          </div>
        </div>
      </article>
    </main>
  )
}
