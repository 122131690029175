import { get, patch, post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import URI from 'reducers/uri'
import { ProductionZone } from '../ProductionZone/types'

type User = {
  // eslint-disable-next-line camelcase
  cerbere_id: string
  email?: string,
  firstName?: string,
  lastName?: string,
  cp?: string,
  id? : string,
}

type Animator = User
type Visitor = User

type eic = {
  id: string,
  libelle: string,
  animateurs: Animator[]
  visiteurs: Visitor[]
  dzp: ProductionZone
}

const getEic = createAsyncThunk(
  'eic/get',
  async (_, thunkApi) => {
    try {
      const response: eic[] = await get('/robustest/eic/')
      await Promise.all(response.map(async result => {
        if (result.animateurs.length > 0) {
          const animateurs = await post(`/${URI.cerbereApi}/${URI.users}/simple`,
            result.animateurs.map(item => item?.cerbere_id || ''), {
              params: {
                ordering: 'lastName',
              },
            })
          result.animateurs = animateurs
          result.visiteurs = []
        }
      }))
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postEic = createAsyncThunk(
  'eic/post|patch',
  async (eic: Partial<eic>, thunkApi) => {
    try {
      if (eic.id === 'new') {
        const response = await post('/robustest/eic/', eic) as eic
        thunkApi.dispatch(getEic())
        return response
      }
      const response = await patch(`/robustest/eic/${eic.id}`, eic) as eic
      thunkApi.dispatch(getEic())
      return response

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export {
  getEic,
  postEic,
}
