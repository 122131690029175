import { post } from '@osrdata/app_core/dist/requests'
import {
  Territories, UserMeRoles, UserSimple,
} from './types'

export type YourRole = (
  appPerm: string[],
  userTerritory: Territories[],
  userAxe: Territories[],
  userMeSettings: UserMeRoles[],
  userMeSuivi: UserMeRoles[],
  userMePlanAction: UserMeRoles[],
  usersEntitySupervisor: string | null,
) => string[]

const yourRole: YourRole = (
  appPerm,
  userTerritory,
  userAxe,
  userMeSettings,
  userMeSuivi,
  userMePlanAction,
  usersEntitySupervisor,
) => {
  const roleList: string[] = []
  if (appPerm.includes('robustest::ADMIN')) {
    roleList.push('admin')
  }
  if (!appPerm.includes('robustest::ADMIN') && userTerritory.length !== 0) {
    roleList.push('animateur')
  }
  if (!appPerm.includes('robustest::ADMIN') && userAxe && userAxe.length !== 0) {
    roleList.push('animateur d\'axe')
  }
  if (!appPerm.includes('robustest::ADMIN') && userMeSettings.length !== 0) {
    roleList.push('settingsAccess')
  }
  if (!appPerm.includes('robustest::ADMIN') && userMeSuivi.length !== 0) {
    roleList.push('suiviAccess')
  }
  if (!appPerm.includes('robustest::ADMIN') && userMePlanAction.length !== 0) {
    roleList.push('planAccess')
  }
  if (!appPerm.includes('robustest::ADMIN') && usersEntitySupervisor) {
    roleList.push('supervisor')
  }

  return roleList
}

const getUsersSimple = async (ids: (string | null | undefined)[]): Promise<UserSimple[]> => {
  const usersIds = new Set<string>()
  ids.forEach(id => {
    if (id) {
      usersIds.add(id)
    }
  })
  return post('/cerbere/users/simple/', [...usersIds])
}

export { yourRole, getUsersSimple }
