import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { SnackbarSeverity, setSnackbar } from 'reducers/app'
import terms from 'common/terms'
import { PortfolioItem } from './type'
import { Complexes } from '../Complexes/types'

// eslint-disable-next-line import/no-mutable-exports
export let controllerLigne = new AbortController()

const getLignes = createAsyncThunk(
  'ligne/get',
  async (params: string, thunkApi) => {
    try {
      controllerLigne = new AbortController()
      const { signal } = controllerLigne
      const response = await get('/robustest/lignes/', {
        libelle: params,
      }, undefined,
      {
        signal,
      },
      )
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getRobustestComplexes = createAsyncThunk(
  'portfolio/complexe/getRobustest',
  async (_, thunkApi) => {
    try {
      const response = await get('/robustest/batiments-voyageurs/', { sa: true })
      return response as Complexes[]
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getPortfolio = createAsyncThunk(
  'portfolio/get',
  async (_, thunkApi) => {
    try {
      const response = await get('/robustest/portefeuilles/')
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postOrPatchPortfolio = createAsyncThunk(
  'portfolio/post|patch',
  async (params: Partial<PortfolioItem>, thunkApi) => {
    try {
      if (params.id === 'new') {
        const response = await post('/robustest/portefeuilles/', {
          libelle: params.libelle,
          entite: params.entite?.id,
          batiments_voyageurs: params.batiments_voyageurs?.map(item => item.id) || [],
          lignes: params.lignes?.map(item => item.id) || [],
          travaux: params.travaux?.map(item => item.id) || [],
          generic: params.generic,
        }) as PortfolioItem
        thunkApi.dispatch(getPortfolio())
        thunkApi.dispatch(setSnackbar({
          message: terms.ParametragePortfolio.create.messageSuccess,
          severity: SnackbarSeverity.SUCCESS,
        }))
        return response
      }
      const response = await patch(`/robustest/portefeuilles/${params.id}`, params) as PortfolioItem
      thunkApi.dispatch(getPortfolio())
      thunkApi.dispatch(setSnackbar({
        message: terms.ParametragePortfolio.modify.messageSuccess,
        severity: SnackbarSeverity.SUCCESS,
      }))
      return response

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deletePortfolio = createAsyncThunk(
  'portfolio/delete',
  async (id: string, thunkApi) => {
    try {
      const response = await deleteRequest(`/robustest/portefeuilles/${id}`)
      thunkApi.dispatch(getPortfolio())
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export {
  getPortfolio,
  postOrPatchPortfolio,
  getLignes,
  getRobustestComplexes,
  deletePortfolio,
}
