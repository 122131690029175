import { Autocomplete, TextField } from '@mui/material'
import terms from 'common/terms'
import { ReactElement, SyntheticEvent } from 'react'
import { UserMeRoles } from 'reducers/Users/types'

interface SaSelectorProps {
  options: UserMeRoles[]
  value: UserMeRoles | null
  handleChange: (evt: SyntheticEvent, newValue: UserMeRoles | null) => void
  disabled?: boolean
}

const SaSelector = ({
  options, value, handleChange, disabled,
}: SaSelectorProps): ReactElement => (
  <Autocomplete
    disablePortal
    id="searchSa"
    disabled={disabled}
    options={options}
    value={value}
    onChange={handleChange}
    getOptionLabel={option => option.libelle}
    renderInput={params => (
      <TextField
        {...params}
        placeholder={terms.Common.selectSaPlaceholder}
        size="small"
      />
    )}
  />
)

SaSelector.defaultProps = {
  disabled: false,
}

export default SaSelector
