import terms from 'common/terms'
import { typeObject } from 'pages/Suivi/utils'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getActionColumn, patchStateAction } from 'reducers/ActionPlan/actionPlan.thunk'
import { RootState } from 'Store'
import {
  Action, ActionModalType, ActionPlanTab, ActionTileType, DataAnimCopil,
} from 'reducers/ActionPlan/types'
import { UserRole } from 'reducers/Users/types'
import { setModalAction } from 'reducers/ActionPlan/actionPlan.reducer'
import ColumnAction from '../ActionContent/ColumnAction/ColumnAction'
import ComponentTile from '../ActionContent/ColumnAction/ComponentTile/ComponentTile'
import { COLUMN_NAMES_COPIL } from '../utils'
import ModalAction from '../ModalAction/ModalAction'

interface CopileModeProps {
  tabValue: number
}

const CopilMode = ({ tabValue }: CopileModeProps): ReactElement => {
  const dispatch = useDispatch()
  const [idComponentSelected, setIdComponentSelected] = useState<string | null>(null)
  const { idSA } = useSelector((state: RootState) => state.app)
  const { objFerroviaires, copilMode, modalAction } = useSelector((state: RootState) => state.actionPlan)
  const [isOpen, setIsOpen] = useState(false)
  const { userMePlanAction, isAxeActionToggle } = useSelector((state: RootState) => state.users)

  const saRole = userMePlanAction?.find(item => item.id === idSA)

  const isAnimax = !!userMePlanAction.find(sa => sa.id === idSA)?.roles.includes(UserRole.animatorAxe)
  const isAnimator = !!userMePlanAction.find(sa => sa.id === idSA)?.roles.includes(UserRole.animator)
  const isObjectBorrowed = !!objFerroviaires?.[tabValue].borrowed

  const defineType = () => {
    if (saRole?.roles.includes(UserRole.decider)
      && (modalAction?.entite.some(entite => entite.id === saRole.entite)
      || modalAction?.visibilite.some(visibilite => visibilite.id === saRole.entite))
    ) {
      return {
        modal: ActionModalType.unlimitedDecider,
        tile: ActionTileType.unlimitedDecider,
      }
    }
    return {
      modal: ActionModalType.limited,
      tile: ActionTileType.limited,
    }
  }

  useEffect(() => {
    if (objFerroviaires?.length) {
      COLUMN_NAMES_COPIL.forEach(item => dispatch(getActionColumn({
        saId: idSA,
        objectId: objFerroviaires[tabValue].id,
        type: typeObject(objFerroviaires[tabValue].type),
        status: item,
      })))
    }
  }, [tabValue, objFerroviaires, isAxeActionToggle])

  const handleClose = () => {
    setIsOpen(false)
    dispatch(setModalAction(null))
  }

  const handleActionClick = (action: Action, componentId: string | undefined) => () => {
    setIsOpen(true)
    dispatch(setModalAction(action))
    if (componentId) {
      setIdComponentSelected(componentId)
    }
  }

  const handlePatchAction = (actionId: string, data: DataAnimCopil) => {
    if (objFerroviaires) {
      dispatch(patchStateAction({
        saId: idSA,
        actionId,
        type: typeObject(objFerroviaires[tabValue].type),
        objectId: objFerroviaires[tabValue].id,
        composantsId: idComponentSelected,
        data,
      }))
    }
  }

  return (
    <>
      <ColumnAction
        title={terms.actionPlan.copilMode.column.action.title}
        tabValue={tabValue}
      >
        {copilMode.toClearList?.length ? (
          <>
            {copilMode.toClearList?.map(element => (
              <ComponentTile
                key={element.id}
                componentName={element.composant.libelle}
                componentId={element.composant.id}
                composantId={element.id}
                numJalon={element.numero_jalon}
                cotation={element.cotation}
                year={element.annee}
                actions={element.actions}
                actionTileType={defineType().tile}
                handleActionClick={handleActionClick}
              />
            ))}
          </>
        ) : (
          <div className="action-panel-content-empty">
            {terms.actionPlan.copilMode.column.action.empty}
          </div>
        )}

      </ColumnAction>
      <ColumnAction
        title={terms.actionPlan.copilMode.column.actionValidate.title}
        tabValue={tabValue}
      >
        {copilMode.validatedList?.length ? (
          <>
            {copilMode.validatedList?.map(element => (
              <ComponentTile
                key={element.id}
                componentName={element.composant.libelle}
                componentId={element.composant.id}
                composantId={element.id}
                numJalon={element.numero_jalon}
                cotation={element.cotation}
                year={element.annee}
                actions={element.actions}
                actionTileType={defineType().tile}
                handleActionClick={handleActionClick}
              />
            ))}
          </>
        ) : (
          <div className="action-panel-content-empty">
            {terms.actionPlan.copilMode.column.actionValidate.empty}
          </div>
        )}
      </ColumnAction>
      <ColumnAction
        title={terms.actionPlan.copilMode.column.actionReject.title}
        tabValue={tabValue}
      >
        {copilMode.rejectedList?.length ? (
          <>
            {copilMode.rejectedList?.map(element => (
              <ComponentTile
                key={element.id}
                componentName={element.composant.libelle}
                componentId={element.composant.id}
                composantId={element.id}
                numJalon={element.numero_jalon}
                cotation={element.cotation}
                year={element.annee}
                actions={element.actions}
                actionTileType={defineType().tile}
                handleActionClick={handleActionClick}
              />
            ))}
          </>
        ) : (
          <div className="action-panel-content-empty">
            {terms.actionPlan.copilMode.column.actionReject.empty}
          </div>
        )}
      </ColumnAction>
      <ModalAction
        isRoute={false}
        isOpen={isOpen}
        onClose={() => handleClose()}
        type={defineType().modal}
        isPilot={false}
        handlePatchActionAnim={handlePatchAction}
        actionPlanTab={ActionPlanTab.COPIL}
        isAnimator={isAnimator}
        isAnimax={isAnimax}
        isObjectBorrowed={isObjectBorrowed}
      />
    </>
  )
}

export default CopilMode
