import { persistCombineReducers } from 'redux-persist'
import { createFilter } from 'redux-persist-transform-filter'
import storage from 'redux-persist/lib/storage' // defaults to localStorage

import { auth } from '@osrdata/app_core'
import appReducer from './app'
import adminReducer from './Admin/admin.reducer'
import entityReducer from './Admin/Entity/entity'
import usersReducer from './Users/users.reducer'
import eicReducer from './Admin/Eic/eic'
import composantReducer from './Admin/Composant/composant'
import contributorFunctionReducer from './Admin/ContributorFunction/contributorFunction'
import productionZoneReducer from './Admin/ProductionZone/productionZone'
import constructionSitesReducer from './Admin/ConstructionSites/constructionSites'
import ComplexesReducer from './Admin/Complexes/complexes'
import SaReducer from './SA/sa.reducer'
import parametrageComplexes from './Parametrage/Complexes/complexes.reducer'
import parametrageLignes from './Parametrage/ParamLignes/paramLignes.reducers'
import parametrageContributeurs from './Parametrage/Contributeurs/contributeurs.reducer'
import parametrageConstructionSites from './Parametrage/ConstructionSites/constructionSites.reducer'
import parametrageDeciders from './Parametrage/Deciders/deciders.reducer'
import parametrageVisitors from './Parametrage/Visitors/visitors.reducer'
import parametrageAnimators from './Parametrage/Animators/animators.reducer'
import parametragePreferences from './Parametrage/Preferences/preferences.reducer'
import suiviReducer from './Suivi/suivi.reducer'
import contributionsReducer from './Contributions/contributions.reducer'
import actionPlanReducer from './ActionPlan/actionPlan.reducer'
import PonderationReducer from './Admin/Ponderation/ponderation.reducer'
import monitoringReducer from './monitoring/monitoring.reducer'
import axesReducer from './Admin/Axes/axes.reducer'
import portfolioReducer from './Admin/Portfolio/portfolio.reducer'
import mapReducer from './Map/map.reducers'

const userReducer = auth.reducer

const userWhiteList = [
  'account',
]

const saveUserFilter = createFilter(
  'user',
  userWhiteList,
)

const persistConfig = {
  key: 'root',
  storage,
  transforms: [saveUserFilter],
  whitelist: ['user'],
}

export const rootReducer = {
  user: userReducer,
  app: appReducer,
  admin: adminReducer,
  entity: entityReducer,
  eic: eicReducer,
  axe: axesReducer,
  composant: composantReducer,
  contributorFunction: contributorFunctionReducer,
  users: usersReducer,
  productionZone: productionZoneReducer,
  constructionSites: constructionSitesReducer,
  complexes: ComplexesReducer,
  sa: SaReducer,
  paramComplexes: parametrageComplexes,
  paramLignes: parametrageLignes,
  paramContributeurs: parametrageContributeurs,
  paramConstructionSites: parametrageConstructionSites,
  paramDeciders: parametrageDeciders,
  paramVisitors: parametrageVisitors,
  paramAnimators: parametrageAnimators,
  paramPreferences: parametragePreferences,
  suivi: suiviReducer,
  contributions: contributionsReducer,
  actionPlan: actionPlanReducer,
  ponderation: PonderationReducer,
  monitoring: monitoringReducer,
  portfolio: portfolioReducer,
  map: mapReducer,
}

const persistedRootReducer = persistCombineReducers(persistConfig, rootReducer)

export default persistedRootReducer
