import { RootState } from 'Store'
import { ReactElement, useEffect } from 'react'
import { Box, Modal } from '@mui/material'
import { Mail } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import terms from 'common/terms'
import { getUserInfos } from 'reducers/Users/users.thunk'
import Loader from 'components/Loader'
import ModalCloseButton from '../ModalCloseButton/ModalCloseButton'
import './ModalUserInfos.scss'

interface Props {
  onModal: boolean,
  handleClose: () => void,
}

const ModalUserInfos = ({
  onModal, handleClose,
}: Props): ReactElement => {
  const dispatch = useDispatch()
  const { userInfosId, userInfos } = useSelector((state: RootState) => state.users)

  useEffect(() => {
    if (userInfosId) {
      dispatch(getUserInfos(userInfosId))
    }
  }, [userInfosId])

  return (
    <Modal
      open={onModal}
      onClose={handleClose}
      className="modal-user-infos-modal"
    >
      <Box className="modal-user-infos">
        <div className="modal-user-infos-header">
          <h2 className="modal-user-infos-title">
            {terms.Common.modalUserInfos.title}
          </h2>
          <ModalCloseButton handleClose={handleClose} />
        </div>
        {userInfos ? (
          <div className="modal-user-infos-content">
            <div className="modal-user-infos-content-row">
              <strong>{terms.Common.modalUserInfos.displayName}</strong>
              <span>{userInfos.displayName}</span>
            </div>
            <div className="modal-user-infos-content-row">
              <strong>{terms.Common.modalUserInfos.username}</strong>
              <span>{userInfos.username}</span>
            </div>
            <div className="modal-user-infos-content-row">
              <strong>{terms.Common.modalUserInfos.email}</strong>
              <span>{userInfos.email}</span>
              <a href={`mailto:${userInfos.email}`} className="modal-user-infos-content-row-mail">
                <Mail />
              </a>
            </div>
          </div>
        ) : (
          <Loader message={terms.Common.modalUserInfos.loadingMessage} />
        )}
      </Box>
    </Modal>
  )
}

export default ModalUserInfos
