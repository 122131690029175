import terms from 'common/terms'
import { typeObject } from 'pages/Suivi/utils'
import { ReactElement, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { getContributions } from 'reducers/Contributions/contributions.thunk'
import {
  getActionColumn, getActionsByComposant, getClonableObjects, patchStateAction, patchStateActionPilot,
} from 'reducers/ActionPlan/actionPlan.thunk'
import {
  Action, ActionModalType, ActionPlanTab, ActionTileType, ComposantWithAction, DataAnimCopil, DataPilot,
} from 'reducers/ActionPlan/types'
import { closeAddModal, openAddModal, setModalAction } from 'reducers/ActionPlan/actionPlan.reducer'
import { UserRole } from 'reducers/Users/types'
import ActionItemToDo from './ColumnAction/ActionItemToDo/ActionItemToDo'
import ColumnAction from './ColumnAction/ColumnAction'
import ComponentTile from './ColumnAction/ComponentTile/ComponentTile'
import ModalAddAction from './ColumnAction/ActionItemToDo/ModalAddAction/ModalAddAction'
import { COLUMN_NAMES_ROADMAP } from '../utils'
import ModalAction from '../ModalAction/ModalAction'

interface ActionContentProps {
  tabValue: number
  isPilot: boolean
}
const ActionContent = ({ tabValue, isPilot }: ActionContentProps): ReactElement => {
  const dispatch = useDispatch()
  const [isModalActionOpen, setIsModalActionOpen] = useState(false)
  const [idComponentSelected, setIdComponentSelected] = useState<string | null>(null)
  const { composants } = useSelector((state: RootState) => state.actionPlan)
  const { objFerroviaires, roadmap, modalAdd } = useSelector((state: RootState) => state.actionPlan)
  const { idSA } = useSelector((state: RootState) => state.app)
  const { userMePlanAction, isAxeActionToggle } = useSelector((state: RootState) => state.users)

  const isAnimax = !!userMePlanAction.find(sa => sa.id === idSA)?.roles.includes(UserRole.animatorAxe)
  const isAnimator = !!userMePlanAction.find(sa => sa.id === idSA)?.roles.includes(UserRole.animator)
  const isObjectBorrowed = !!objFerroviaires?.[tabValue].borrowed

  useEffect(() => {
    if (objFerroviaires?.length) {
      COLUMN_NAMES_ROADMAP.forEach(item => dispatch(getActionColumn({
        saId: idSA,
        objectId: objFerroviaires[tabValue].id,
        type: typeObject(objFerroviaires[tabValue].type),
        status: item,
      })))
    }
  }, [tabValue, objFerroviaires, isAxeActionToggle])

  const handleComponentClick = (element: ComposantWithAction) => () => {
    dispatch(openAddModal({
      componentId: element.composant.id,
      libelle: element.composant.libelle,
      jalon: element.numero_jalon,
      objectType: typeObject(objFerroviaires?.[tabValue]?.type),
      objectFerId: objFerroviaires?.[tabValue]?.id,
      componentIdString: element.id,
    }))
    if (objFerroviaires?.[tabValue]) {
      dispatch(getActionsByComposant({
        saId: idSA,
        objectId: objFerroviaires?.[tabValue]?.id,
        type: typeObject(objFerroviaires?.[tabValue]?.type),
        componentId: element.id,
      }))
    }
    if (idSA && objFerroviaires?.[tabValue]) {
      dispatch(getContributions({
        idSa: idSA,
        numJalon: element.numero_jalon,
        typeObj: typeObject(objFerroviaires?.[tabValue]?.type),
        idObj: objFerroviaires?.[tabValue]?.id,
        idComponent: element.composant.id,
      }))
      dispatch(getClonableObjects({
        saId: idSA,
        componentId: element.composant.id,
        jalonNumber: element.numero_jalon,
        objectId: objFerroviaires?.[tabValue]?.id,
      }))
    }
  }

  const handleActionClick = (action: Action, componentId: string | undefined) => () => {
    dispatch(setModalAction(action))
    setIsModalActionOpen(true)
    if (componentId) {
      setIdComponentSelected(componentId)
    }
  }

  const handlePatchActionAnim = (actionId: string, data: DataAnimCopil) => {
    if (objFerroviaires) {
      dispatch(patchStateAction({
        saId: idSA,
        actionId,
        type: typeObject(objFerroviaires[tabValue].type),
        objectId: objFerroviaires[tabValue].id,
        composantsId: idComponentSelected,
        data,
      }))
    }
  }

  const handlePatchActionPilot = (actionId: string, data: DataPilot) => {
    if (objFerroviaires) {
      dispatch(patchStateActionPilot({
        saId: idSA,
        actionId,
        type: typeObject(objFerroviaires[tabValue].type),
        objectId: objFerroviaires[tabValue].id,
        composantsId: idComponentSelected,
        data,
      }))
    }
  }

  return (
    <>
      {!isPilot && (
        <ColumnAction
          title={terms.actionPlan.column.toDo.title}
          tabValue={tabValue}
        >
          {composants?.length ? composants.map(element => (
            <ActionItemToDo
              key={element.id}
              componentId={element.id}
              idComponent={element.composant.id}
              libelle={element.composant.libelle}
              jalon={element.numero_jalon}
              cotation={element.cotation}
              date={element.annee}
              display={element.displayed}
              tabValue={tabValue}
            />
          )) : (
            <div className="action-panel-content-empty">
              {terms.actionPlan.column.toDo.empty}
            </div>
          )}
        </ColumnAction>
      )}
      <ColumnAction title={terms.actionPlan.column.inProgress.title}>
        {roadmap.todoList?.length ? (
          <>
            {roadmap.todoList?.map(element => (
              <ComponentTile
                key={element.id}
                componentName={element.composant.libelle}
                componentId={element.composant.id}
                composantId={element.id}
                numJalon={element.numero_jalon}
                cotation={element.cotation}
                year={element.annee}
                actions={element.actions}
                actionTileType={ActionTileType.unlimited}
                handleComponentClick={isPilot ? undefined : handleComponentClick(element)}
                handleActionClick={handleActionClick}
              />
            ))}
          </>
        ) : (
          <div className="action-panel-content-empty">
            {terms.actionPlan.column.inProgress.empty}
          </div>
        )}
      </ColumnAction>
      <ColumnAction title={terms.actionPlan.column.done.title}>
        {roadmap.finishedList?.length ? (
          <>
            {roadmap.finishedList?.map(element => (
              <ComponentTile
                key={element.id}
                componentName={element.composant.libelle}
                componentId={element.composant.id}
                composantId={element.id}
                numJalon={element.numero_jalon}
                cotation={element.cotation}
                year={element.annee}
                actions={element.actions}
                actionTileType={ActionTileType.unlimited}
                handleComponentClick={isPilot ? undefined : handleComponentClick(element)}
                handleActionClick={handleActionClick}
              />
            ))}
          </>
        ) : (
          <div className="action-panel-content-empty">
            {terms.actionPlan.column.done.empty}
          </div>
        )}
      </ColumnAction>
      <ColumnAction title={terms.actionPlan.column.closed.title}>
        {roadmap.closedList?.length ? (
          <>
            {roadmap.closedList?.map(element => (
              <ComponentTile
                key={element.id}
                componentName={element.composant.libelle}
                componentId={element.composant.id}
                composantId={element.id}
                numJalon={element.numero_jalon}
                cotation={element.cotation}
                year={element.annee}
                actions={element.actions}
                actionTileType={ActionTileType.unlimited}
                handleComponentClick={isPilot ? undefined : handleComponentClick(element)}
                handleActionClick={handleActionClick}
              />
            ))}
          </>
        ) : (
          <div className="action-panel-content-empty">
            {terms.actionPlan.column.closed.empty}
          </div>
        )}
      </ColumnAction>
      <ModalAddAction
        componentId={modalAdd.componentId}
        libelle={modalAdd.libelle}
        jalon={modalAdd.jalon}
        isOpen={modalAdd.isOpen}
        onClose={() => dispatch(closeAddModal())}
        objectFerId={modalAdd.objectFerId}
        objectType={modalAdd.objectType}
        componentIdString={modalAdd.componentIdString}
        isPilot={isPilot}
        isObjectBorrowed={isObjectBorrowed}
        isAnimax={isAnimax}
      />
      <ModalAction
        isOpen={isModalActionOpen}
        isRoute
        isPilot={isPilot}
        onClose={() => {
          setIsModalActionOpen(false)
          setModalAction(null)
        }}
        isAnimax={isAnimax}
        isAnimator={isAnimator}
        isObjectBorrowed={isObjectBorrowed}
        type={ActionModalType.unlimited}
        handlePatchActionAnim={handlePatchActionAnim}
        handlePatchActionPilot={handlePatchActionPilot}
        actionPlanTab={ActionPlanTab.ROADMAP}
      />
    </>
  )
}

export default ActionContent
