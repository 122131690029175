import { ReactElement, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone, faPeopleArrows, faPeopleGroup } from '@fortawesome/free-solid-svg-icons'
import { FaPaperclip } from 'react-icons/fa'
import { Typography } from '@mui/material'
import terms from 'common/terms'
import { cotationInfo } from 'common/cotation'
import PopOver from 'components/PopOver/PopOver'
import { Contribution, ContributionRating } from 'reducers/Contributions/types'
import { backgroundColorContributor } from 'pages/Suivi/utils'
import { ReactComponent as AxeIcon } from 'assets/icons/axeIcon.svg'
import utils from './utils'
import './contributionTile.scss'

interface ContributionTileProps {
  data: Contribution
  handleClick: () => void
  selected?: boolean
  handleAction: () => void
  handleClickUser: (userId: string) => void
}

const ContributionTile = ({
  data, handleClick, selected, handleAction, handleClickUser,
}: ContributionTileProps): ReactElement => {
  const [isHoveringActions, setIsHoveringActions] = useState(false)
  const cotation: ContributionRating | null = cotationInfo(data.cotation)
  const date = utils.formatDate(data.timestamp)
  const hour = utils.formatHour(data.timestamp)

  const handleClickTile = () => {
    if (!isHoveringActions) {
      handleClick()
    }
  }

  return (
    <div
      className={`contribution-tile border-bottom ${selected ? 'selected' : ''}`}
      role="presentation"
      onClick={handleClickTile}
    >
      <div className="contribution-tile-header d-flex align-items-center justify-content-between mb-2">
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          {`J${data.numero_jalon}`}
        </Typography>
        {cotation && (
          <PopOver popContent={cotation.rating}>
            <div className="rating" style={{ background: `${cotation.color}` }}>
              {cotation.abrev}
            </div>
          </PopOver>
        )}
      </div>
      <div className="contribution-tile-data d-flex flex-column bg-light p-2">
        <div className="contribution-tile-data-header d-flex align-items-center">
          <PopOver popContent={terms.Contributions.tile.tileActionPopover}>
            <div
              className="contribution-tile-action text-nowrap"
              onMouseEnter={() => setIsHoveringActions(true)}
              onMouseLeave={() => setIsHoveringActions(false)}
              onClick={handleAction}
              role="presentation"
            >
              <FontAwesomeIcon icon={faClone} />
              {` ${terms.Contributions.tile.tileAction} `}
            </div>
          </PopOver>
          {data.auteur && (
            <div className="d-flex align-items-center mx-3">
              <div
                className="author-avatar"
                style={{ background: backgroundColorContributor(data.auteur.id) }}
              >
                <span>
                  {`${data.auteur.lastName[0]}${data.auteur.firstName[0]}`}
                </span>
              </div>
              <div className="d-flex flex-column ml-3">
                <strong>
                  {data.role}
                </strong>
                <span>
                  <button
                    type="button"
                    className="button-infos"
                    onClick={() => handleClickUser(data.auteur?.id || '')}
                  >
                    {`${data.auteur.lastName.toUpperCase()} ${data.auteur.firstName}`}
                  </button>
                </span>
              </div>
              {data.createur && data.auteur.id !== data.createur.id && (
                <div className="ml-3">
                  <PopOver
                    popContent={`${terms.Contributions.tile.writtenBy}
                    ${data.createur.lastName} ${data.createur.firstName}`}
                  >
                    <FontAwesomeIcon icon={faPeopleArrows} />
                  </PopOver>
                </div>
              )}
              {data.visibilite.length > 1 && (
                <div className="ml-3">
                  <PopOver popContent={terms.Contributions.tile.multipleEntites}>
                    <FontAwesomeIcon icon={faPeopleGroup} />
                  </PopOver>
                </div>
              )}
              {
                data.from_axe && (
                  <div className="ml-3">
                    <PopOver popContent={terms.Contributions.tile.axeHabilitation}>
                      <AxeIcon className="axe-icon" />
                    </PopOver>
                  </div>
                )
              }
            </div>
          )}
          <div className="d-flex flex-column align-items-end ml-auto">
            {date && (
              <strong>
                {date}
              </strong>
            )}
            {hour && (
              <span>
                {hour}
              </span>
            )}
          </div>
        </div>
        <div className="d-flex flex-column mt-3">
          {data.analyse ? (
            <div className="d-flex flex-column">
              <strong>
                {terms.Contributions.tile.analysis}
              </strong>
              <p style={{ whiteSpace: 'pre-line' }}>
                {data.analyse}
              </p>
            </div>
          ) : (
            <span>
              {terms.Contributions.tile.noAnalysis}
            </span>
          )}
          {data.file && (
            <div className="contribution-tile-file d-flex mb-2">
              <FaPaperclip size="22px" className="paperclip" />
              <a target="__blank" href={data.file}>
                <button className="see-file" type="button">
                  {terms.Contributions.tile.openFile}
                </button>
              </a>
            </div>
          )}
          <hr className="delimiter mt-0" />
          {data.proposition ? (
            <div className="d-flex flex-column">
              <strong>
                {terms.Contributions.tile.proposition}
              </strong>
              <p style={{ whiteSpace: 'pre-line' }}>
                {data.proposition}
              </p>
            </div>
          ) : (
            <span>
              {terms.Contributions.tile.noProposition}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

ContributionTile.defaultProps = {
  selected: false,
}

export default ContributionTile
