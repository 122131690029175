import {
  Autocomplete, FormControl, FormLabel, TextField,
} from '@mui/material'
import { RootState } from 'Store'
import terms from 'common/terms'
import HighlightMatch from 'components/HighlightMatch'
import {
  ReactElement, useCallback, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Entity } from 'reducers/Admin/Entity/types'
import { changePortfolio } from 'reducers/Admin/Portfolio/portfolio.reducer'
import { controllerLigne, getLignes } from 'reducers/Admin/Portfolio/portfolio.thunk'
import { PortfolioItem } from 'reducers/Admin/Portfolio/type'
import { controllerComplexes, searchComplexes } from 'reducers/Admin/Complexes/complexes.thunk'
import { controllerConstructionSites, getConstructionSites } from 'reducers/Admin/admin.thunk'
import { debounce } from 'lodash'
import portfolioService from 'services/portfolioService'

interface Props {
  portfolio: PortfolioItem
}

const PortfolioDetail = ({ portfolio }: Props): ReactElement => {
  const dispatch = useDispatch()
  const { entities } = useSelector((state: RootState) => state.entity)
  const { complexes } = useSelector((state: RootState) => state.complexes)
  const { robustestComplexes, lignes } = useSelector((state: RootState) => state.portfolio)
  const { allLignes } = useSelector((state: RootState) => state.map)
  const { constructionSites } = useSelector((state: RootState) => state.constructionSites)
  const [selected, setSelected] = useState<{id: string, libelle: string}[]>([])
  const [search, setSearch] = useState('')

  const debouncedSearchObj = useCallback(debounce((value: string) => {
    controllerLigne.abort()
    controllerComplexes.abort()
    controllerConstructionSites.abort()
    setSearch(value)
    dispatch(getLignes(value))
    dispatch(searchComplexes(value))
    dispatch(getConstructionSites(value))
  }, 500), [])

  useEffect(() => {
    if (portfolio) {
      setSelected(portfolioService.getObjList(
        portfolio.batiments_voyageurs,
        portfolio.travaux,
        portfolio.lignes,
        robustestComplexes,
      ))
    }
  }, [portfolio])

  const dispatchSelectedObj = (values: {id: string, libelle: string}[]) => {
    setSelected(values)
    if (values.length) {
      const selectedComplexes = robustestComplexes.filter(
        complexe => values.map(value => value.id).includes(complexe.id),
      )
      const selectedConstructionSites = constructionSites.filter(
        constructionSite => values.map(value => value.id).includes(constructionSite.id),
      )
      const selectedLignes = allLignes ? allLignes.filter(
        ligne => values.map(value => value.id).includes(ligne.id),
      ) : []
      dispatch(changePortfolio({
        ...portfolio,
        lignes: selectedLignes.length ? selectedLignes : [],
        travaux: selectedConstructionSites.length ? selectedConstructionSites : [],
        batiments_voyageurs: selectedComplexes.length ? selectedComplexes : [],
      }))
    } else {
      dispatch(changePortfolio({
        ...portfolio,
        batiments_voyageurs: [],
        travaux: [],
        lignes: [],
      }))
    }
  }

  return (
    <div className="portfolio-form">
      <FormControl fullWidth>
        <FormLabel
          htmlFor="outlined-adornment-amount"
          required
        >
          {terms.Admin.portfolio.form.labelEntity}
        </FormLabel>
        <Autocomplete
          fullWidth
          disablePortal
          loading={false}
          options={entities}
          getOptionLabel={option => option.libelle}
          isOptionEqualToValue={(option, value) => option.libelle === value.libelle}
          noOptionsText={terms.Common.noResults}
          value={portfolio.entite}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              id="outlined-controlled"
              size="small"
            />
          )}
          onChange={(_, value) => dispatch(changePortfolio({ ...portfolio, entite: value as Entity }))}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel
          htmlFor="outlined-adornment-amount"
          required
        >
          {terms.Admin.portfolio.form.labelLibelle}
        </FormLabel>
        <TextField
          fullWidth
          id="outlined-controlled"
          size="small"
          value={portfolio.libelle}
          onChange={e => dispatch(changePortfolio({
            ...portfolio,
            libelle: e.target.value,
          }))}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel
          htmlFor="outlined-adornment-amount"
          required
        >
          {terms.Admin.portfolio.form.CLT}
        </FormLabel>
        <Autocomplete
          multiple
          size="small"
          fullWidth
          options={portfolioService.getObjList(complexes, constructionSites, lignes, robustestComplexes)}
          filterOptions={options => options}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={selected}
          noOptionsText={terms.Common.noResults}
          getOptionLabel={option => option.libelle}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              <HighlightMatch
                optionLabel={option.libelle}
                inputValue={search}
              />
            </li>
          )}
          loadingText={terms.Common.loading}
          onChange={(_: unknown, newValue) => dispatchSelectedObj(newValue)}
          renderInput={params => (
            <TextField
              className="vertical-display"
              {...params}
              fullWidth
            />
          )}
          onInputChange={(_, newInputValue) => debouncedSearchObj(newInputValue)}
        />
      </FormControl>
    </div>
  )
}

export default PortfolioDetail
