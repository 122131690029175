import thunk from 'redux-thunk'
import { persistStore } from 'redux-persist'
import persistedReducer, { rootReducer } from 'reducers'
import { configureStore } from '@reduxjs/toolkit'
import errorInterceptorMiddleware from 'reducers/interceptorRedux/errorInterceptorMiddleware'

const store = configureStore({
  reducer: persistedReducer as unknown as typeof rootReducer,
  middleware: [thunk, errorInterceptorMiddleware],
  devTools: process.env.NODE_ENV !== 'production',
})

const persistor = persistStore(store)

export {
  store,
  persistor,
}

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
