import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPenToSquare, faCircleCheck, faCircleXmark, faPeopleArrows,
} from '@fortawesome/free-solid-svg-icons'
import { TextareaAutosize } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import PopOver from 'components/PopOver/PopOver'
import commonTerms from 'common/terms'
import WeatherIcon from '../ActionContent/ColumnAction/component/WeatherIcon'
import { weather } from '../utils'

interface ActionContributionProps {
  meteo?: number,
  text?: string,
  terms: string,
  asWeather: boolean,
  title: string,
  isDecider: boolean | undefined,
  handleClickSaveEdit?: (text: string | undefined, meteo: number | undefined) => void,
  canEdit: boolean
  doneByAnim?: boolean
}

const ActionContribution = ({
  meteo, text, terms, title, handleClickSaveEdit, asWeather, isDecider, canEdit, doneByAnim,
}: ActionContributionProps): ReactElement => {
  const [textValue, setTextValue] = useState<string | undefined>(text)
  const [meteoValue, setMeteoValue] = useState<number | undefined>(meteo)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const weatherArray = [1, 2, 3, 4].filter(item => item !== meteoValue)

  const handleCloseEdit = () => {
    setIsEdit(false)
    setTextValue(text)
    setMeteoValue(meteo)
  }

  useEffect(() => {
    handleCloseEdit()
  }, [text, meteo])

  return (
    <div className="modalCopileMode-avancement-A">
      <div className="modalCopileMode-avancement-A-title">
        {isEdit && asWeather ? (
          <div className="modalCopileMode-avancement-weather-select">
            {weatherArray.map(item => (
              <button
                className="modalCopileMode-avancement-weather-button"
                type="button"
                key={item}
                onClick={() => setMeteoValue(item)}
              >
                <WeatherIcon weather={weather(item)} />
              </button>
            ))}
          </div>
        ) : (
          <div />
        )}
        <span className="modalCopileMode-avancement-A-title-text">
          {title}
        </span>
        {isEdit ? (
          <div className="modalCopileMode-edit-mode">
            <button
              className="modalCopileMode-edit-mode-close"
              type="button"
              onClick={() => handleCloseEdit()}
            >
              <FontAwesomeIcon icon={faCircleXmark} />
            </button>
            <button
              className="modalCopileMode-edit-mode-check"
              type="button"
              onClick={() => handleClickSaveEdit && handleClickSaveEdit(textValue, meteoValue)}
            >
              <FontAwesomeIcon icon={faCircleCheck} />
            </button>
          </div>
        ) : (
          <>
            {(!isDecider && canEdit) && (
              <button
                className="modalCopileMode-edit-icon"
                type="button"
                onClick={() => setIsEdit(true)}
              >
                <FontAwesomeIcon icon={faPenToSquare} />
              </button>
            )}
          </>
        )}
      </div>
      <div className="modalCopileMode-avancement-A-description">
        {
          meteoValue && (
            <span className="mr-2">
              <WeatherIcon weather={weather(meteoValue)} />
            </span>
          )
        }
        {!isEdit && (
          <div>
            {
              text ? (
                <div
                  className="modalCopileMode-avancement-A-description-text"
                  style={{ marginLeft: (meteo) ? '0' : '38px' }}
                >
                  {text}
                </div>
              ) : (
                <div
                  className="modalCopileMode-avancement-A-description-text noComment"
                  style={{ marginLeft: (meteo) ? '0' : '38px' }}
                >
                  {terms}
                </div>
              )
            }
          </div>
        )}
        {isEdit && (
          <TextareaAutosize
            className="modalCopileMode-avancement-A-description-textarea"
            style={{ marginLeft: (meteo && asWeather) ? '0' : '38px' }}
            minRows={3}
            value={textValue}
            onChange={e => setTextValue(e.target.value)}
          />
        )}
        {doneByAnim && (
          <div className="icon-anim">
            <PopOver popContent={commonTerms.actionPlan.modalActionCopile.writtenByAnim}>
              <FontAwesomeIcon icon={faPeopleArrows} />
            </PopOver>
          </div>
        )}
      </div>

    </div>
  )
}

ActionContribution.defaultProps = {
  meteo: undefined,
  text: undefined,
  handleClickSaveEdit: undefined,
  doneByAnim: false,
}
export default ActionContribution
