import { LAYER_NAMES } from 'services/map'
import { LayerProperties } from '../types'

const ConstructionSiteLayerPaint: LayerProperties = {
  constructionSiteLayer: {
    id: LAYER_NAMES.constructionSiteLayer,
    type: 'symbol',
    layout: {
      'icon-image': 'constructionSiteBlue',
      'icon-size': 0.25,
      'icon-allow-overlap': true,
    },
    paint: {},
  },
  constructionSiteLayerHighlighted: {
    id: `${LAYER_NAMES.constructionSiteLayer}-highlighted`,
    type: 'symbol',
    layout: {
      'icon-image': 'constructionSitePurple',
      'icon-size': 0.25,
      'icon-allow-overlap': true,
    },
    paint: {},
  },
  constructionSiteLayerName: {
    id: `${LAYER_NAMES.constructionSiteLayer}-name`,
    type: 'symbol',
    layout: {
      'text-font': ['Open Sans Regular'],
      'text-size': 14,
      'text-anchor': 'left',
      'symbol-placement': 'point',
      'text-allow-overlap': false,
      'text-offset': [1.5, 0],
      'text-field': '{libelle}',
    },
    paint: {
      'text-color': '#0088ce',
      'text-halo-color': '#FFFFFF',
      'text-halo-width': 1,
    },
  },
}

export default ConstructionSiteLayerPaint
