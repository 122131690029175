import { RootState } from 'Store'
import { isAdmin } from 'common/permissions'
import { terms } from 'common/terms'
import PopOver from 'components/PopOver/PopOver'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import {
  DataAnimCopil, DataPilot, Action, Etat, Statut,
} from 'reducers/ActionPlan/types'
import { PrimaryBtn } from 'themes/theme'

interface ButtonsModalRoadmapProps {
  action: Action
  isPilot: boolean
  isAnimator: boolean
  handlePatchActionAnim?: (actionId: string, data: DataAnimCopil) => void
  handlePatchActionPilot?: (actionId: string, data: DataPilot) => void
  isAnimax: boolean
  isObjectBorrowed: boolean
}

const ButtonsModalRoadmap = ({
  action, isPilot, isAnimator, handlePatchActionAnim, handlePatchActionPilot, isAnimax, isObjectBorrowed,
}: ButtonsModalRoadmapProps): ReactElement => {
  const { appPermissions } = useSelector((state: RootState) => state.user)
  const canFinishAction = action.statut === Statut.TODO && action.etat !== Etat.TOCLEAR && action.etat !== Etat.REJECTED
  const canCloseAction = action.statut === Statut.FINISHED
  const canReopenAction = action.statut === Statut.CLOSED && isAdmin(appPermissions)

  const disableFinishAction = action.meteo_pilote === null || action.etat_avancement_pilote === null
  const disableCloseAction = action.meteo_animateur === null || action.etat_avancement_animateur === null

  const shouldDisplayButtonsAnimax = isAnimax && isObjectBorrowed && action.from_axe

  return (
    <>
      {canCloseAction && (isAnimator || shouldDisplayButtonsAnimax) && (
        <PopOver
          popContent={disableCloseAction ? terms.actionPlan.modalActionCopile.hintDisabledClosing
            : terms.actionPlan.modalActionCopile.hintCloseAction}
        >
          <PrimaryBtn
            onClick={() => handlePatchActionAnim && handlePatchActionAnim(action.id, {
              statut: Statut.CLOSED,
            })}
            disabled={disableCloseAction}
          >
            {terms.Common.closeAction}
          </PrimaryBtn>
        </PopOver>
      )}
      {canReopenAction && (isAnimator || shouldDisplayButtonsAnimax) && (
        <PrimaryBtn
          onClick={() => handlePatchActionAnim && handlePatchActionAnim(action.id, {
            statut: Statut.FINISHED,
          })}
        >
          {terms.Common.reopenAction}
        </PrimaryBtn>
      )}
      {canFinishAction && !isPilot && (isAnimator || shouldDisplayButtonsAnimax) && (
        <PopOver
          popContent={disableFinishAction ? terms.actionPlan.modalActionCopile.hintDisabledRealise
            : terms.actionPlan.modalActionCopile.hintRealiseAction}
        >
          <PrimaryBtn
            onClick={() => handlePatchActionAnim && handlePatchActionAnim(action.id, {
              statut: Statut.FINISHED,
            })}
            disabled={disableFinishAction}
          >
            {terms.Common.finishAction}
          </PrimaryBtn>
        </PopOver>
      )}
      {canFinishAction && isPilot && (
        <PopOver
          popContent={disableFinishAction ? terms.actionPlan.modalActionCopile.hintDisabledRealise
            : terms.actionPlan.modalActionCopile.hintRealiseAction}
        >
          <PrimaryBtn
            onClick={() => handlePatchActionPilot && handlePatchActionPilot(action.id, {
              statut: Statut.FINISHED,
            })}
            disabled={disableFinishAction}
          >
            {terms.Common.finishAction}
          </PrimaryBtn>
        </PopOver>
      )}
    </>
  )
}

ButtonsModalRoadmap.defaultProps = {
  handlePatchActionAnim: undefined,
  handlePatchActionPilot: undefined,
}

export default ButtonsModalRoadmap
