import ModalCloseButton from 'components/Modal/ModalCloseButton/ModalCloseButton'
import { ReactElement } from 'react'
import { PrimaryBtn } from 'themes/theme'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { Action, ActionTileType, ObjectClonable } from 'reducers/ActionPlan/types'
import terms from 'common/terms'
import ActionTile from '../../../ActionTile/ActionTile'

interface ModalActionListProps {
  actionList: Action[],
  handleClose: () => void,
  handleClickAddAction: () => void,
  handleClickAction: (action: Action) => () => void,
  canAddAction?: boolean,
  clonableObjects?: ObjectClonable[],
  handleClickCloneAction?: (action: Action) => (object: ObjectClonable) => () => void,
}

const ModalActionList = ({
  actionList, handleClose, handleClickAddAction, handleClickAction, canAddAction,
  handleClickCloneAction, clonableObjects,
}: ModalActionListProps): ReactElement => (
  <>
    <div className="modalAddAction-action-title">
      <div className="modalAddAction-action-title-text">
        {terms.actionPlan.modalAddAction.titlecolAction}
      </div>
      <ModalCloseButton
        handleClose={handleClose}
        className="close-modal modalAddAction-action-title-icon"
      />
    </div>
    {canAddAction && (
      <div className="modalAddAction-action-add-container">
        <PrimaryBtn
          className="modalAddAction-action-add"
          onClick={handleClickAddAction}
        >
          <AddCircleIcon />
          <span>
            {terms.actionPlan.modalAddAction.addAction}
          </span>
        </PrimaryBtn>
      </div>
    )}
    <div className="modalAddAction-action-body">
      {actionList ? actionList.map(action => (
        <ActionTile
          key={action.id}
          action={action}
          type={ActionTileType.plusplus}
          handleClick={handleClickAction(action)}
          canClone={canAddAction}
          clonableObjects={clonableObjects}
          handleClone={handleClickCloneAction ? handleClickCloneAction(action) : undefined}
        />
      )) : (
        <div className="modalAddAction-action-body-empty">
          {terms.actionPlan.noAction}
        </div>
      )}
    </div>
  </>
)

ModalActionList.defaultProps = {
  canAddAction: true,
  clonableObjects: [],
  handleClickCloneAction: undefined,
}

export default ModalActionList
