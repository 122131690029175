import { Mail } from '@mui/icons-material'
import {
  MenuItem, Select, SelectChangeEvent, Typography,
} from '@mui/material'
import terms from 'common/terms'
import CustomSwitch from 'components/CustomSwitch/CustomSwitch'
import { ReactElement } from 'react'
import { UserMeRoles, UserRole } from 'reducers/Users/types'
import { PrimaryBtn, SecondaryBtn } from 'themes/theme'
import { GiCircle, GiPlainCircle } from 'react-icons/gi'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { SaJalon } from 'reducers/SA/types'
import { JalonStatus, Status } from './JalonStatus'
import JalonDate from './JalonDate'

interface Props {
  selectedSa: UserMeRoles
  valueJ: number
  switchAllContribution: boolean
  handleSwitchAllContribution: () => void
  setOpenMail: () => void
  handleOpenExport: () => void
  jalonStatus: string
  handleSelectStatus: (event: SelectChangeEvent) => void
  activeJalon: SaJalon
  setOpen: (v: boolean) => void
}

const JalonHeader = ({
  selectedSa,
  valueJ,
  switchAllContribution,
  handleSwitchAllContribution,
  setOpenMail,
  handleOpenExport,
  jalonStatus,
  handleSelectStatus,
  activeJalon,
  setOpen,

}: Props): ReactElement => {
  const maintainer = selectedSa.roles.some(role => role === UserRole.admin
      || role === UserRole.animator
      || role === UserRole.animatorAxe)

  return (
    <div className="status">
      <Typography>
        {`J${valueJ} ${selectedSa?.libelle}`}
      </Typography>
      <div className="d-flex">
        {
          selectedSa.roles.includes(UserRole.animatorAxe) && (
            <CustomSwitch
              value={switchAllContribution}
              handleChange={handleSwitchAllContribution}
              label={terms.Suivi.labelToggleSwitchAllContribution}
            />
          )
        }
        <PrimaryBtn className="suivi-btn-export" onClick={setOpenMail}>
          <Mail />
        </PrimaryBtn>
        <SecondaryBtn
          onClick={handleOpenExport}
          className="suivi-btn-export"
        >
          <FileUploadIcon />
          <span className="suivi-btn-export-text">
            {terms.Suivi.pdfTerms.button}
          </span>
        </SecondaryBtn>
        <Select
          disabled={!maintainer}
          value={jalonStatus}
          onChange={handleSelectStatus}
        >
          {Status.map((jStatus, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem key={index} value={jStatus.libelle}>
              {jStatus.status === JalonStatus.Coming ? <GiCircle className="mr-2 mb-" />
                : <GiPlainCircle className="mr-2" style={{ color: jStatus.color }} />}
              {jStatus.libelle}
            </MenuItem>
          ))}
        </Select>
        <JalonDate
          activeJalon={activeJalon}
          setOpen={setOpen}
          disabled={!maintainer}
        />
      </div>
    </div>
  )
}

export default JalonHeader
