import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Entity } from 'reducers/Admin/Entity/types'
import { User } from 'reducers/Users/types'
import { formatSettingsTabList } from '../utils'
import { deleteSaVisitors, getSaVisitors, postSaVisitors } from './visitors.thunks'
import { ParametrageVisitorsState } from './types'

const initialState: ParametrageVisitorsState = {
  selectedVisitor: null,
  selectedEntity: null,
  defaultEntity: null,
  modalConfirmation: false,
  modalErrVisitor: false,
  visitorToDeleteId: undefined,
  visitors: [],
  displaySnackbarAdd: false,
  displaySnackbarDelete: false,
  isLoadingVisitors: false,
}

export const parametrageVisitors = createSlice({
  name: 'parametrageVisitors',
  initialState,
  reducers: {
    selectVisitor: (state, action: PayloadAction<User | null>) => {
      state.selectedVisitor = action.payload
    },
    selectVisitorEntity: (state, action: PayloadAction<Entity | null>) => {
      state.selectedEntity = action.payload
    },
    selectVisitorDefaultEntity: (state, action: PayloadAction<Entity | null>) => {
      state.selectedEntity = action.payload
      state.defaultEntity = action.payload
    },
    onModalConfirmation: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload) {
        state.modalConfirmation = true
        state.visitorToDeleteId = action.payload
      }
    },
    closeModalConfirmation: state => {
      state.modalConfirmation = false
    },
    onModalAddVisitor: state => {
      state.modalErrVisitor = true
    },
    closeModalErrVisitor: state => {
      state.errors = undefined
      state.modalErrVisitor = false
    },
    hideSnackbarAdd: state => {
      state.displaySnackbarAdd = false
    },
    hideSnackbarDelete: state => {
      state.displaySnackbarDelete = false
    },
  },
  extraReducers: builder => {
    builder.addCase(getSaVisitors.fulfilled, (state, { payload }) => {
      state.visitors = formatSettingsTabList(payload)
      state.isLoadingVisitors = false
    })
    builder.addCase(getSaVisitors.pending, state => {
      state.visitors = []
      state.isLoadingVisitors = true
    })
    builder.addCase(getSaVisitors.rejected, state => {
      state.isLoadingVisitors = false
    })
    builder.addCase(postSaVisitors.fulfilled, (state, { payload }) => {
      if (payload) {
        state.visitors = formatSettingsTabList([...state.visitors, payload])
        state.selectedVisitor = null
        state.selectedEntity = state.defaultEntity
        state.displaySnackbarAdd = true
      }
    })
    builder.addCase(postSaVisitors.rejected, (state, action) => {
      state.errors = action.payload
    })
    builder.addCase(deleteSaVisitors.fulfilled, state => {
      state.visitors = state.visitors.filter(item => item.id !== state.visitorToDeleteId)
      state.visitorToDeleteId = undefined
      state.displaySnackbarDelete = true
    })
  },
})

export const {
  selectVisitor,
  selectVisitorEntity,
  selectVisitorDefaultEntity,
  onModalConfirmation,
  closeModalConfirmation,
  onModalAddVisitor,
  closeModalErrVisitor,
  hideSnackbarAdd,
  hideSnackbarDelete,
} = parametrageVisitors.actions

export default parametrageVisitors.reducer
