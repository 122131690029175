import { cotationInfo, RATINGS } from 'common/cotation'
import terms from 'common/terms'
import PopOver from 'components/PopOver/PopOver'
import { ReactElement } from 'react'

interface Props {
  cotationInput: string;
  disabled: boolean;
  setCotation: (rating: string) => void;
}

const SelectCotation = ({ cotationInput, disabled, setCotation }: Props): ReactElement => (
  <div className="cotations">
    <div className="libelle-contribution">
      {terms.Suivi.GlobalContribution.labelCotation}
    </div>
    <div className="cotation-container">
      {
        RATINGS.map((rating: string) => (
          <PopOver
            key={rating}
            popContent={cotationInfo(rating)?.rating}
          >
            <button
              type="button"
              style={{ backgroundColor: cotationInput === rating ? cotationInfo(rating)?.color : '#C6C6C6' }}
              onClick={() => setCotation(cotationInput === rating ? '' : rating)}
              className={cotationInput === rating ? 'selected' : ''}
              disabled={disabled}
            >
              <span>
                {rating}
              </span>
            </button>
          </PopOver>
        ))
      }
    </div>
  </div>
)

export default SelectCotation
