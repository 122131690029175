import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Entity } from 'reducers/Admin/Entity/types'
import { User } from 'reducers/Users/types'
import { deleteSaDeciders, getSaDeciders, postSaDeciders } from './deciders.thunks'
import { ParametrageDecidersState } from './types'
import { formatSettingsTabList } from '../utils'

const initialState: ParametrageDecidersState = {
  selectedDecider: null,
  selectedEntity: null,
  defaultEntity: null,
  modalConfirmation: false,
  modalErrDecider: false,
  deciderToDeleteId: undefined,
  deciders: [],
  displaySnackbarAdd: false,
  displaySnackbarDelete: false,
  isLoadingDeciders: false,
}

export const parametrageDeciders = createSlice({
  name: 'parametrageDeciders',
  initialState,
  reducers: {
    selectDecider: (state, action: PayloadAction<User | null>) => {
      state.selectedDecider = action.payload
    },
    selectDeciderEntity: (state, action: PayloadAction<Entity | null>) => {
      state.selectedEntity = action.payload
    },
    selectDeciderDefaultEntity: (state, action: PayloadAction<Entity | null>) => {
      state.selectedEntity = action.payload
      state.defaultEntity = action.payload
    },
    onModalConfirmation: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload) {
        state.modalConfirmation = true
        state.deciderToDeleteId = action.payload
      }
    },
    closeModalConfirmation: state => {
      state.modalConfirmation = false
    },
    onModalAddDecider: state => {
      state.modalErrDecider = true
    },
    closeModalErrDecider: state => {
      state.errors = undefined
      state.modalErrDecider = false
    },
    hideSnackbarAdd: state => {
      state.displaySnackbarAdd = false
    },
    hideSnackbarDelete: state => {
      state.displaySnackbarDelete = false
    },
  },
  extraReducers: builder => {
    builder.addCase(getSaDeciders.pending, state => {
      state.deciders = []
      state.isLoadingDeciders = true
    })
    builder.addCase(getSaDeciders.rejected, state => {
      state.isLoadingDeciders = false
    })
    builder.addCase(getSaDeciders.fulfilled, (state, { payload }) => {
      state.deciders = formatSettingsTabList(payload)
      state.isLoadingDeciders = false
    })
    builder.addCase(postSaDeciders.fulfilled, (state, { payload }) => {
      if (payload) {
        state.deciders = formatSettingsTabList([payload, ...state.deciders])
        state.selectedDecider = null
        state.selectedEntity = state.defaultEntity
        state.displaySnackbarAdd = true
      }
    })
    builder.addCase(postSaDeciders.rejected, (state, action) => {
      state.errors = action.payload
    })
    builder.addCase(deleteSaDeciders.fulfilled, state => {
      state.deciders = state.deciders.filter(item => item.id !== state.deciderToDeleteId)
      state.deciderToDeleteId = undefined
      state.displaySnackbarDelete = true
    })
  },
})

export const {
  selectDecider,
  selectDeciderEntity,
  selectDeciderDefaultEntity,
  onModalConfirmation,
  closeModalConfirmation,
  onModalAddDecider,
  closeModalErrDecider,
  hideSnackbarAdd,
  hideSnackbarDelete,
} = parametrageDeciders.actions

export default parametrageDeciders.reducer
