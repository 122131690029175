import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import terms from 'common/terms'
import { IconButton } from '@mui/material'
import { IoIosClose } from 'react-icons/io'
import { Complexes } from 'reducers/Admin/Complexes/types'
import { patchComplexes } from 'reducers/Admin/Complexes/complexes.thunk'
import ModalConfirmation from 'components/Modal/ModalConfirmation/ModalConfirmation'
import Accordion from '../../../../components/Accordion/Accordion'
import SearchComplexes from './searchComplexes'

export default function ComplexesAccordion(): ReactElement {
  const dispatch = useDispatch()
  const { complexes, searchValue, isLoadingComplexes } = useSelector((state : RootState) => state.complexes)
  const [open, setOpen] = useState<boolean>(false)
  const [complexeToDessociate, setComplexeToDessociate] = useState<Complexes | undefined>(undefined)

  return (
    <>
      <Accordion
        name={terms.Admin.complexes.name}
        elementList={complexes}
        isLoading={isLoadingComplexes}
        searchElement={<SearchComplexes />}
      >
        {complexe => (
          <>
            <div className="row align-items-center mt-3">
              <label className="col-8 d-flex align-items-center mb-0 flex-column" htmlFor="libelle">
                <p className="d-flex align-self-start ml-2">{terms.Admin.name}</p>
                <input
                  className="input-admin form-control form-control-sm ml-2"
                  type="text"
                  id="libelle"
                  name="libelle"
                  readOnly
                  value={complexe.libelle}
                />
              </label>
              {complexe.eic !== null && (
              <div>
                <span className="text-warning font-weight-bold ml-2">
                  {terms.Admin.complexes.associatedTo}
                  {' '}
                  {complexe.eic?.libelle}
                </span>
                <IconButton
                  onClick={() => {
                    setOpen(!open)
                    setComplexeToDessociate(complexe)
                  }}
                  disableRipple
                  disableFocusRipple
                  className="dessociate-btn"
                >
                  <IoIosClose />
                </IconButton>
              </div>
              )}
            </div>
            <div className="row align-items-center mt-3">
              <label className="col-4 d-flex align-items-center mb-0 flex-column" htmlFor="abrev">
                <p className="d-flex align-self-start ml-2">{terms.Admin.abrev}</p>
                <input
                  className="input-admin form-control form-control-sm ml-2"
                  type="text"
                  id="abrev"
                  name="abrev"
                  value={complexe.abrev}
                  readOnly
                />
              </label>
              <label className="col-4 d-flex align-items-center mb-0 flex-column" htmlFor="codeCi">
                <p className="d-flex align-self-start ml-2">{terms.Admin.complexes.codeCi}</p>
                <input
                  className="input-admin form-control form-control-sm ml-2"
                  type="text"
                  id="codeCi"
                  name="codeCi"
                  value={complexe.code_ci}
                  readOnly
                />
              </label>
            </div>
          </>
        )}
      </Accordion>
      <ModalConfirmation
        onModal={open}
        handleClose={() => setOpen(false)}
        content1={terms.Admin.complexes.confirmationText}
        variable1={`'${complexeToDessociate?.libelle}'`}
        content2={terms.Admin.complexes.confirmationText2}
        variable2={`'${complexeToDessociate?.eic?.libelle}'`}
        handleConfirmClick={() => {
          dispatch(patchComplexes(
            { complexe: { id: complexeToDessociate?.id, eic: null }, search: searchValue },
          ))
          setOpen(false)
        }}

      />
    </>
  )
}
