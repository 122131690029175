import { ReactElement } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { ParamLigne } from 'reducers/Parametrage/ParamLignes/types'
import ObjectLigne from '../ObjectLigne/ObjectLigne'
import './columnsLignes.scss'

interface listComp {
  listId: string,
  objectFer: ParamLigne[]
}

const ColumnsLignes = ({ listId, objectFer }: listComp): ReactElement => (

  <Droppable droppableId={listId}>
    {provider => (
      <ul
        className="configList_cont"
        {...provider.droppableProps}
        ref={provider.innerRef}
      >
        {objectFer.map((item, index) => (
          <ObjectLigne
            key={item.id}
            obj={item}
            list={listId}
            ligne={item}
            objectId={item.id}
            index={index}
          />
        ))}

      </ul>
    )}
  </Droppable>
)

export default ColumnsLignes
