/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { typeObject } from 'pages/Suivi/utils'
import { Entity } from 'reducers/Admin/Entity/types'
import { ComposantObject, SaJalon } from 'reducers/SA/types'
import { getSAJalonByNumber, getSaJalonNbObject } from 'reducers/Suivi/suivi.thunk'
import { ThunkApi } from 'reducers/types'
import { UserSimple } from 'reducers/Users/types'
import {
  Contribution,
  ContributionRaw,
  DupingObject,
  ParamsGetSAEntities,
  getContributionsByIdParams,
  getContributionsParams,
  getContributionsStateAfterRefreshArgs,
  getContributionsStateAfterRefreshWithObjectArgs,
  patchContributionsParams,
  postContributionsParams,
} from './types'

const getContributionsStateAfterRefresh = createAsyncThunk(
  'contributions/setRefresh',
  async (saveState: getContributionsStateAfterRefreshArgs, thunkApi) => {
    try {
      const response = await thunkApi.dispatch(getSAJalonByNumber({
        idSa: saveState.idSa || null,
        jalon: saveState.numJalon,
      }))
      return response.payload as SaJalon
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getContributionsStateAfterRefreshWithObject = createAsyncThunk(
  'contributions/setRefreshWithObject',
  async (saveState: getContributionsStateAfterRefreshWithObjectArgs, thunkApi) => {
    try {
      const response = await Promise.all([
        thunkApi.dispatch(getSAJalonByNumber({
          idSa: saveState.idSa,
          jalon: saveState.numJalon,
        })),
        thunkApi.dispatch(getSaJalonNbObject({
          idSa: saveState.idSa,
          nJalon: saveState.numJalon,
          idObject: saveState.idObjFer,
          type: typeObject(saveState.typeObjFer),
        })),
      ])
      return { jalon: response[0].payload as SaJalon, composants: response[1].payload as ComposantObject[] }
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const contributionsURL = (p: getContributionsParams): string => {
  if (p.numJalon === 0) {
    return `/robustest/sa/${p.idSa}/jalons/0/composants/${p.idComponent}/contributions/`
  }
  // eslint-disable-next-line max-len
  return `/robustest/sa/${p.idSa}/jalons/${p.numJalon}/${p.typeObj}/${p.idObj}/composants/${p.idComponent}/contributions/`
}

const getContributions = createAsyncThunk<Contribution[], getContributionsParams, ThunkApi>(
  'contributions/get',
  async (params: getContributionsParams, thunkApi) => {
    try {
      const userIdsSet = new Set<string>()
      const response: ContributionRaw[] = await get(contributionsURL(params))
      response.forEach(contri => {
        userIdsSet.add(contri.auteur)
        userIdsSet.add(contri.createur)
      })
      const usersResponse: UserSimple[] = await post('/cerbere/users/simple/', [...userIdsSet])
      await Promise.all(
        response.map(async contr => {
          if (contr.file) {
            try {
              const url = new URL(contr.file)
              const fileLink = await get(
                url.pathname,
                { content_disposition: 'inline' },
                undefined,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                    'Cache-Control': 'no-cache',
                    Pragma: 'no-cache',
                    Expires: '0',
                  },
                },
              )
              contr.file_url = contr.file
              contr.file = fileLink as string
            } catch (e) {
              contr.file_url = null
              contr.file = null
            }
          }
        }),
      )
      return response.map(contri => ({
        ...contri,
        auteur: usersResponse.find(u => u.id === contri.auteur) || null,
        createur: usersResponse.find(u => u.id === contri.createur) || null,
        visibilite: contri.visibilite.map(en => en?.id || '') || [],
      }))
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postContribution = createAsyncThunk<ContributionRaw, postContributionsParams, ThunkApi>(
  'contributions/post',
  async (params: postContributionsParams, thunkApi) => {
    try {
      const response: ContributionRaw = await post(contributionsURL(params), {
        auteur: params.newContribution.auteur,
        analyse: params.newContribution.analyse,
        proposition: params.newContribution.proposition,
        file: params.newContribution.file,
        file_name: params.newContribution.file_name,
        cotation: params.newContribution.cotation,
        visibilite: params.newContribution.visibilite,
        is_global: params.newContribution.is_global,
        dedoublement: params.newContribution.dedoublement,
        axes: params.newContribution.axes,
        ...(typeof params.newContribution.file_url === 'string' && { file_url: params.newContribution.file_url }),
      })
      thunkApi.dispatch(getContributions(params))
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const contributionsByIdURL = (p: getContributionsByIdParams): string => {
  if (p.numJalon === 0) {
    return `/robustest/sa/${p.idSa}/jalons/0/composants/${p.idComponent}/contributions/${p.idContribution}/`
  }
  // eslint-disable-next-line max-len
  return `/robustest/sa/${p.idSa}/jalons/${p.numJalon}/${p.typeObj}/${p.idObj}/composants/${p.idComponent}/contributions/${p.idContribution}/`
}

const deleteContribution = createAsyncThunk(
  'contributions/delete',
  async (params: getContributionsByIdParams, thunkApi) => {
    try {
      const response = deleteRequest(contributionsByIdURL(params))
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const patchContribution = createAsyncThunk(
  'contributions/patch',
  async (params: patchContributionsParams, thunkApi) => {
    try {
      const response = await patch(contributionsByIdURL(params), {
        analyse: params.contribution.analyse,
        proposition: params.contribution.proposition,
        file: params.contribution.file,
        file_name: params.contribution.file_name,
        cotation: params.contribution.cotation,
        visibilite: params.contribution.visibilite,
        axes: params.contribution.axes,
      })
      thunkApi.dispatch(getContributions(params))
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getSaEntities = createAsyncThunk(
  'contributions/entities',
  async (params: ParamsGetSAEntities, thunkApi) => {
    try {
      const response: Entity[] = await get(`/robustest/sa/${params.activeSA}/entites/`)
      const uniqueEntities = new Set<string>()

      // filter entities to avoid duplicates
      const filteredResponse = response.filter(entity => {
        if (!uniqueEntities.has(entity.libelle)) {
          uniqueEntities.add(entity.libelle)
          return true
        }
        return false
      })

      if (params.origineSA) {
        const origineResponse: Entity[] = await get(`/robustest/sa/${params.origineSA}/entites/`)
        // filter entities to avoid duplicates
        origineResponse.forEach(entity => {
          if (!uniqueEntities.has(entity.libelle)) {
            uniqueEntities.add(entity.libelle)
            filteredResponse.push(entity)
          }
        })
      }
      return filteredResponse
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

type getDedoublementObjectsParams = {
  composant: number
  jalon: number
  annee: number
}

const getDedoublementObjects = createAsyncThunk(
  'contributions/objects',
  async (params: getDedoublementObjectsParams, thunkApi) => {
    try {
      const response: DupingObject[] = await get('/robustest/users/me/objets', params)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getSaPossibleAnimators = createAsyncThunk(
  'contributions/possibleAnimators',
  async (animatorsIds: string[], thunkApi) => {
    try {
      const response: UserSimple[] = await post('/cerbere/users/simple/', animatorsIds)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

// eslint-disable-next-line import/prefer-default-export
export {
  getContributionsStateAfterRefresh,
  getContributionsStateAfterRefreshWithObject,
  getContributions,
  postContribution,
  deleteContribution,
  patchContribution,
  getSaEntities,
  getDedoublementObjects,
  getSaPossibleAnimators,
}
