import { ReactElement } from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { OrderingByEnum } from 'reducers/monitoring/types'
import terms from 'common/terms'

interface Props {
  value: OrderingByEnum
  handleClick: () => void
}

const OrderingBy = ({ handleClick, value }: Props): ReactElement => (
  <div className="ordering">
    <div>
      {terms.Monitoring.Contributions.Filters.ordering}
    </div>
    <button
      type="button"
      onClick={handleClick}
    >
      <span>
        {value}
      </span>
      {
        value === OrderingByEnum.COMPONENT
          ? <ArrowDownwardIcon className="icon" />
          : <ArrowUpwardIcon className="icon" />
      }
    </button>
  </div>
)

export default OrderingBy
