import {
  Home, Settings, Assignment, Insights,
} from '@mui/icons-material'
import { FaTachometerAlt } from 'react-icons/fa'
import { NavElement } from './TabElement'
import terms from '../../common/terms'

const ELEMENTS: NavElement[] = [
  {
    Icon: () => <Home className="iconNav" fontSize="medium" />,
    title: terms.Nav.home,
    path: '/accueil/',
    access: [],
    robAccess: [],
    subPaths: [],
  },
  {
    Icon: () => <Settings className="iconNav" fontSize="medium" />,
    title: 'Paramétrage',
    path: '/parametrage/',
    access: [],
    robAccess: [],
    subPaths: [
      {
        name: terms.Nav.addSa,
        path: 'creation-sa/',
        robAccess: ['animateur', 'admin', 'animateur d\'axe'],
      },
      {
        name: terms.Nav.settingsSa,
        path: 'parametrage-sa/:id',
        robAccess: ['animateur', 'admin', 'settingsAccess', 'animateur d\'axe'],
      },
      {
        name: 'Portefeuilles de supervision',
        path: 'portefeuilles-supervision/',
        robAccess: ['supervisor', 'admin'],
      },
      {
        name: terms.Nav.admin,
        path: 'configuration-generale/',
        robAccess: ['admin'],
      },
    ],
  },
  {
    Icon: () => <FaTachometerAlt size="20px" color="white" />,
    title: terms.Nav.trackingBoard,
    path: '/tableau-suivi/:id',
    access: ['robustest::ADMIN'],
    robAccess: ['animateur', 'admin', 'suiviAccess', 'animateur d\'axe'],
    subPaths: [],
  },
  {
    Icon: () => <Assignment className="iconNav" fontSize="medium" />,
    title: 'Plan d\'action',
    path: '/plan-action/:id',
    access: ['robustest::ADMIN'],
    robAccess: ['animateur', 'admin', 'planAccess', 'animateur d\'axe'],
    subPaths: [],
  },
  {
    Icon: () => <Insights className="iconNav" fontSize="medium" />,
    title: terms.Nav.monitoring.title,
    path: terms.Nav.monitoring.path,
    access: [],
    robAccess: [],
    subPaths: [
      {
        name: 'Cotations par ZP',
        path: 'cotations-zp/',
        robAccess: [],
      },
      {
        name: 'Contributions par transporteur',
        path: 'contributions-transporteur/',
        robAccess: ['admin', 'supervisor'],
      },
      {
        name: 'Cartographie des alertes',
        path: 'cartographie-alertes/',
        robAccess: [],
      },
      {
        name: 'Evolution des cotations',
        path: 'evolution-cotations/',
        robAccess: [],
      },
    ],
  },
]

export default ELEMENTS
