import * as React from 'react'
import slugify from 'slugify'
import Card from '@mui/material/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserEdit } from '@fortawesome/free-solid-svg-icons'
import './tile.scss'
import { colorNumberTile } from 'pages/Suivi/utils'
import terms from 'common/terms'
import { NavLink } from 'react-router-dom'
import PopOver from 'components/PopOver/PopOver'
import { UserRole } from 'reducers/Users/types'
import { cotationInfo } from 'common/cotation'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { colorPicto, displayReadOnly, popOverRatioTerms } from './utils'

type TileProps = {
  color: string | undefined | null,
  numberTile: number | undefined | null,
  title: string | undefined | null,
  ratingAdmin: string | undefined | null,
  rating: string | undefined | null,
  denominator: number | undefined | null,
  numerator: number | undefined | null,
  role: UserRole[],
  readOnly: boolean | undefined,
  hasAnyInsuffisant: boolean | undefined |null,
  handleRedirectTile(): void,
  isScroll: boolean
}

const Tile = ({
  color,
  isScroll,
  numberTile,
  title = '',
  rating,
  numerator,
  denominator,
  ratingAdmin,
  role,
  readOnly,
  hasAnyInsuffisant,
  handleRedirectTile,
}: TileProps): React.ReactElement => {
  const { isToggle } = useSelector((state: RootState) => state.suivi)
  const { labelSA } = useSelector((state: RootState) => state.app)

  const colorTile = readOnly ? '#EEEEEE' : color
  return (
    <>
      {displayReadOnly(isToggle, readOnly) && (
        <div className={`tile${isScroll ? '-isScroll' : ''}`}>
          <Card className={`tile-card${readOnly ? '-readOnly' : ''}`}>
            <div className="tile-a">
              <NavLink
                key={numberTile}
                className="tile-link"
                to={`/tableau-suivi/${slugify(labelSA as string)}/contributions`}
                onClick={handleRedirectTile}
              >
                <div className="card-orient">
                  <div className="tile-section">
                    <div
                      className="card-orient-span"
                      style={{ background: `${colorTile}` }}
                    />
                    <div className="tile-title">
                      <div className="tile-title-section">
                        <h3
                          className="number-component"
                          style={{ color: `${colorNumberTile(color)}` }}
                        >
                          {numberTile}
                        </h3>
                        {readOnly && (
                        <div className="tile-read-only">{terms.Common.readOnly}</div>
                        )}
                      </div>
                      <p className="title-title-p mt-2">{title}</p>
                    </div>
                  </div>
                  <div className="tile-section-2">
                    {(typeof numerator === 'number'
                    && typeof denominator === 'number') && (
                    <PopOver
                      popContent={popOverRatioTerms(numerator, denominator)}
                    >
                      <div className="tile-header-contr">
                        <FontAwesomeIcon
                          icon={faUserEdit}
                          style={{ color: colorPicto(numerator, hasAnyInsuffisant) }}
                        />
                        <div className="ml-1">
                          <span className="tile-numerator">
                            {numerator}
                          </span>
                          /
                          <span className="tile-denominator">
                            {denominator}
                          </span>
                        </div>
                      </div>
                    </PopOver>
                    )}
                    <div className="rating-container">
                      {ratingAdmin && (
                      <PopOver popContent={cotationInfo(ratingAdmin)?.rating}>
                        <div
                          className={(rating || typeof numerator === 'number') ? 'contr-admin' : 'contr-admin-only'}
                          style={{ background: `${cotationInfo(ratingAdmin)?.color}` }}
                        >
                          {ratingAdmin}
                        </div>
                      </PopOver>
                      )}
                      {(rating) && (!(role.includes(UserRole.admin))) && (!(role.includes(UserRole.animator))) && (
                      <PopOver popContent={cotationInfo(rating)?.rating}>
                        <div className="contr" style={{ background: `${cotationInfo(rating)?.color}` }}>
                          {rating}
                        </div>
                      </PopOver>
                      )}
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
          </Card>
        </div>
      )}
    </>
  )
}

export default Tile
