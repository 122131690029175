import {
  Checkbox,
  MenuItem, Select, SelectChangeEvent,
} from '@mui/material'
import terms from 'common/terms'
import CustomSelect, { ValueSelect } from 'components/CustomSelect/CustomSelect'
import {
  ReactElement, SyntheticEvent, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMonitoringDzp, getPossibleYears, getTerritory } from 'reducers/monitoring/monitoring.thunk'
import { RootState } from 'Store'

interface Props {
  activeJalon: number
  setActiveJalon: (jalon: number) => void
}

const DzpCotationSelects = ({ activeJalon, setActiveJalon }: Props): ReactElement => {
  const dispatch = useDispatch()
  const SELECT_ALL_VALUE = 'all'
  const { productionZones } = useSelector((state: RootState) => state.productionZone)
  const { possibleEic, possibleYears } = useSelector((state: RootState) => state.monitoring)
  const [selectedZp, setSelectedZp] = useState<ValueSelect | null>(null)
  const [selectedEic, setSelectedEic] = useState<string[]>([])
  const [selectedYear, setSelectedYear] = useState<string>('')

  const handleSelectZP = (_: SyntheticEvent, newValue: ValueSelect | null) => {
    setSelectedZp(newValue)
    setSelectedYear('')
    setSelectedEic([])
    dispatch(getTerritory(newValue?.id || ''))
  }

  useEffect(() => {
    const monitoringDZPyear = localStorage.getItem('monitoringDZPyear')
    const monitoringDZPjalon = localStorage.getItem('monitoringDZPjalon')
    const monitoringDZPeic = localStorage.getItem('monitoringDZPeic')
    if (monitoringDZPyear && monitoringDZPjalon && monitoringDZPeic) {
      setSelectedYear(monitoringDZPyear)
      setActiveJalon(parseInt(monitoringDZPjalon, 10) - 1)
      setSelectedEic(monitoringDZPeic.split(','))
      dispatch(getMonitoringDzp({
        eic: monitoringDZPeic.split(','),
        annee: monitoringDZPyear,
        numero_jalon: parseInt(monitoringDZPjalon, 10),
      }))
    }
  }, [])

  useEffect(() => {
    const monitoringDZP = localStorage.getItem('monitoringDZP')
    if (monitoringDZP) {
      setSelectedZp(productionZones.find(zp => zp.id === monitoringDZP) || null)
      dispatch(getTerritory(monitoringDZP))
    }
  }, [productionZones.length])

  useEffect(() => {
    if (selectedZp) {
      setSelectedEic(possibleEic.flatMap(e => e.id || []))
    }
  }, [possibleEic.length])

  const handleChangeYear = (event: SelectChangeEvent<string>) => {
    setSelectedYear(event.target.value)
    localStorage.setItem('monitoringDZP', selectedZp?.id || '')
    localStorage.setItem('monitoringDZPyear', event.target.value)
    localStorage.setItem('monitoringDZPjalon', (activeJalon + 1).toString())
    localStorage.setItem('monitoringDZPeic', selectedEic.join(','))
    dispatch(getMonitoringDzp({
      eic: selectedEic,
      annee: event.target.value,
      numero_jalon: activeJalon + 1,
    }))
  }

  useEffect(() => {
    if (selectedEic.length) {
      dispatch(getPossibleYears({
        eic: selectedEic,
      }))
    }
  }, [selectedEic])

  const handleSelectEic = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target
    if (value.includes(SELECT_ALL_VALUE)) {
      if (selectedEic.length === possibleEic.length) {
        setSelectedEic([])
      } else {
        setSelectedEic([...possibleEic.map(e => e.id || '')])
      }
    } else {
      setSelectedEic(value as string[])
    }

    setSelectedYear('')
  }

  const selectLabel = () => {
    if (!selectedEic.length) {
      return terms.Monitoring.Dzp.Selects.territory
    }
    return `${selectedEic.length} 
      ${terms.Monitoring.Dzp.Selects.territory}${selectedEic.length > 1 ? 's' : ''} 
      ${terms.Monitoring.Dzp.Selects.select}${selectedEic.length > 1 ? 's' : ''}`
  }
  return (
    <div className="selects">
      <CustomSelect
        options={productionZones}
        inputValue={selectedZp}
        handleChange={handleSelectZP}
        placeholder={terms.Monitoring.Dzp.Selects.zp}
      />
      <Select
        multiple
        size="small"
        value={selectedEic}
        onChange={handleSelectEic}
        displayEmpty
        renderValue={() => (
          <div className="select-wrapper">
            {selectLabel()}
          </div>
        )}
      >
        <MenuItem
          key={SELECT_ALL_VALUE}
          value={SELECT_ALL_VALUE}
          className={selectedEic.length === possibleEic.length ? 'Mui-selected' : ''}
          style={{ paddingLeft: '5px' }}
        >
          <Checkbox
            size="small"
            checked={selectedEic.length === possibleEic.length}
          />
          <span>
            {possibleEic.length === selectedEic.length
              ? terms.Contributions.visibility.unSelectAll
              : terms.Contributions.visibility.selectAll}
          </span>
        </MenuItem>
        { possibleEic.map(e => (
          <MenuItem key={e.libelle} value={e.id}>
            <Checkbox
              size="small"
              checked={selectedEic.includes(e.id || '')}
            />
            <span>
              {e.libelle}
            </span>
          </MenuItem>
        ))}
      </Select>
      <Select
        size="small"
        value={selectedYear}
        onChange={handleChangeYear}
        displayEmpty
        renderValue={() => (
          <div className="select-wrapper">
            {selectedYear || terms.Monitoring.Dzp.Selects.year}
          </div>
        )}
      >
        {
          possibleYears.map(year => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))
        }
      </Select>
    </div>
  )
}

export default DzpCotationSelects
