import terms from 'common/terms'
import Loader from 'components/Loader'
import ModalConfirmation from 'components/Modal/ModalConfirmation/ModalConfirmation'
import ModalError from 'components/Modal/ModalError/ModalError'
import SettingsTab from 'components/SettingsTab/SettingsTab'
import CustomSnackbar from 'components/Snackbar/Snackbar'
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  closeModalConfirmation, closeModalErrDecider, onModalAddDecider, onModalConfirmation,
  selectDecider, selectDeciderEntity, hideSnackbarAdd, hideSnackbarDelete,
} from 'reducers/Parametrage/Deciders/deciders.reducer'
import { deleteSaDeciders, postSaDeciders } from 'reducers/Parametrage/Deciders/deciders.thunks'
import { UserRole } from 'reducers/Users/types'
import { setDisplayUserInfos, setUserInfosId } from 'reducers/Users/users.reducer'
import { RootState } from 'Store'

import '../settings.scss'

const Deciders = (): ReactElement => {
  const dispatch = useDispatch()
  const {
    selectedDecider, selectedEntity, deciders, modalConfirmation, deciderToDeleteId, modalErrDecider, errors,
    displaySnackbarAdd, displaySnackbarDelete, isLoadingDeciders,
  } = useSelector((state: RootState) => state.paramDeciders)
  const { userMeSettings } = useSelector((state: RootState) => state.users)
  const { idSA } = useSelector((state: RootState) => state.app)
  const saRoles = userMeSettings.find(elem => elem.id === idSA)?.roles || []
  const { account } = useSelector((state: RootState) => state.user)

  useEffect(() => {
    if (errors) {
      dispatch(onModalAddDecider())
    }
  }, [errors])

  const handleDelete = () => {
    dispatch(deleteSaDeciders({
      saId: idSA,
      saDeciderid: deciderToDeleteId,
      needRefresh: account.id === deciders.find(decider => decider.id === deciderToDeleteId)?.cerbereId,
    }))
    dispatch(closeModalConfirmation())
  }

  const handleClickUser = (userId: string) => {
    dispatch(setUserInfosId(userId))
    dispatch(setDisplayUserInfos(true))
  }

  return (
    <>
      {
        isLoadingDeciders ? (
          <div className="setting-loader">
            <Loader message={terms.Common.loading} />
          </div>
        ) : (
          <SettingsTab
            user={selectedDecider}
            entity={selectedEntity}
            disableEntity={!saRoles.some(role => [
              UserRole.admin,
              UserRole.animator,
              UserRole.animatorAxe,
              UserRole.entityAnimator,
            ].includes(role))}
            onUserChange={(_e, newValue) => dispatch(selectDecider(newValue))}
            onEntityChange={(_e, newValue) => dispatch(selectDeciderEntity(newValue))}
            handleValidate={() => {
              dispatch(postSaDeciders({
                saId: idSA,
                user: selectedDecider,
                entity: selectedEntity,
                userMeId: account.id,
              }))
            }}
            handleDelete={(itemId?: string) => () => dispatch(onModalConfirmation(itemId))}
            handleClickUser={handleClickUser}
            emptyLabel={terms.Settings.decider.noDecider}
            items={deciders}
          />
        )
      }
      <ModalError
        onModal={modalErrDecider}
        titleOption={terms.Settings.decider.error.errorAddDecider.title2}
        content={`${errors?.data[0]}.`}
        handleClose={() => dispatch(closeModalErrDecider())}
      />
      <ModalConfirmation
        onModal={modalConfirmation}
        handleConfirmClick={handleDelete}
        content1={terms.Settings.decider.modalConfirmation.content1}
        content2={terms.Settings.decider.modalConfirmation.content2}
        handleClose={() => dispatch(closeModalConfirmation())}
      />

      <CustomSnackbar
        message={terms.Settings.decider.snackbarMessage.deciderAdded}
        displaySnackbar={displaySnackbarAdd}
        handleClose={() => dispatch(hideSnackbarAdd())}
      />
      <CustomSnackbar
        message={terms.Settings.decider.snackbarMessage.deciderDeleted}
        displaySnackbar={displaySnackbarDelete}
        handleClose={() => dispatch(hideSnackbarDelete())}
      />
    </>
  )
}

export default Deciders
