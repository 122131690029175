import { ReactElement, useEffect } from 'react'
import { closeModal, setExpandedElement } from 'reducers/Admin/admin.reducer'
import { loadPage } from 'reducers/Admin/admin.thunk'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import terms from 'common/terms'
import ModalError from 'components/Modal/ModalError/ModalError'
import EntityAccordion from './components/entity'
import ComponentAccordion from './components/composant'
import ContributorFunctionAccordion from './components/contributorFunction'
import ProductionZoneAccordion from './components/productionZone'
import EicAccordion from './components/eic'
import ConstrtuctionSiteAccordion from './components/constructionSite'
import ComplexesAccordion from './components/Complexes/complexes'
import PonderationAccordion from './components/Ponderation/Ponderation'
import AxesAccordion from './components/Axes/Axes'
import PortfolioAccordion from './components/Portfolio/Portfolio'

import './Admin.scss'

export const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}

export default function Admin(): ReactElement {
  const dispatch = useDispatch()
  const errors = useSelector((state : RootState) => state.admin.errors)

  useEffect(() => {
    dispatch(loadPage())
    dispatch(setExpandedElement(''))
  }, [])

  return (
    <main className="main">
      <h1>
        {terms.Admin.title}
      </h1>
      <EntityAccordion />
      <ComponentAccordion />
      <ContributorFunctionAccordion />
      <ProductionZoneAccordion />
      <ComplexesAccordion />
      <ConstrtuctionSiteAccordion />
      <EicAccordion />
      <AxesAccordion />
      <PonderationAccordion />
      <PortfolioAccordion />
      <ModalError
        onModal={!!errors}
        content={errors}
        handleClose={() => dispatch(closeModal())}
      />
    </main>
  )
}
