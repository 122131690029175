import {
  Autocomplete, FormControl, FormLabel, TextField,
} from '@mui/material'
import terms from 'common/terms'
import HighlightMatch from 'components/HighlightMatch'
import { ReactElement } from 'react'
import { Entity } from 'reducers/Admin/Entity/types'
import { AutocompleteValue } from 'reducers/types'

interface Props {
  portfolioEntity: Entity | null
  possibleEntities: Entity[]
  setPortfolioEntity: (entity: Entity | null) => void
  portfolioName: string
  setPortfolioName: (name: string) => void
  debouncedSearchObj: (value: string) => void
  setSelectedObj: (value: AutocompleteValue[]) => void,
  search: string
  selectedObj: AutocompleteValue[]
  objList: AutocompleteValue[]
  disabled?: boolean
  isSupervisor?: boolean
}

const Form = ({
  portfolioEntity,
  portfolioName,
  possibleEntities,
  setPortfolioEntity,
  setPortfolioName,
  debouncedSearchObj,
  setSelectedObj,
  selectedObj,
  search,
  objList,
  disabled,
  isSupervisor,
}: Props): ReactElement => (
  <div className="portfolio-form">
    <FormControl fullWidth>
      <FormLabel
        htmlFor="outlined-adornment-amount"
        required
      >
        {terms.Admin.portfolio.form.labelEntity}
      </FormLabel>
      <Autocomplete
        disabled={disabled || isSupervisor}
        fullWidth
        disablePortal
        loading={false}
        options={possibleEntities}
        getOptionLabel={option => option.libelle}
        isOptionEqualToValue={(option, value) => option.libelle === value.libelle}
        noOptionsText={terms.Common.noResults}
        value={portfolioEntity}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            id="outlined-controlled"
            size="small"
          />
        )}
        onChange={(_, value) => setPortfolioEntity(value)}
      />
    </FormControl>
    <FormControl fullWidth>
      <FormLabel
        htmlFor="outlined-adornment-amount"
        required
      >
        {terms.Admin.portfolio.form.labelLibelle}
      </FormLabel>
      <TextField
        disabled={disabled}
        fullWidth
        id="outlined-controlled"
        size="small"
        value={portfolioName}
        onChange={e => setPortfolioName(e.target.value)}
      />
    </FormControl>
    <FormControl fullWidth>
      <FormLabel
        htmlFor="outlined-adornment-amount"
        required
      >
        {terms.Admin.portfolio.form.CLT}
      </FormLabel>
      <Autocomplete
        disabled={disabled}
        multiple
        size="small"
        fullWidth
        options={objList}
        filterOptions={options => options}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={selectedObj}
        noOptionsText={terms.Common.noResults}
        getOptionLabel={option => option.libelle}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            <HighlightMatch
              optionLabel={option.libelle}
              inputValue={search}
            />
          </li>
        )}
        loadingText={terms.Common.loading}
        onChange={(_: unknown, newValue) => setSelectedObj(newValue)}
        renderInput={params => (
          <TextField
            className="vertical-display"
            {...params}
            fullWidth
          />
        )}
        onInputChange={(_, newInputValue) => debouncedSearchObj(newInputValue)}
      />
    </FormControl>
  </div>
)

Form.defaultProps = {
  isSupervisor: false,
  disabled: false,
}

export default Form
