import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ContributorFunction } from 'reducers/Admin/ContributorFunction/types'
import { User } from 'reducers/Users/types'
import { ParamComplexe } from '../Complexes/types'
import { ParamConstructionSite } from '../ConstructionSites/types'
import { ParamLigne } from '../ParamLignes/types'
import {
  deleteSaContributeurs, getSaContributeurs, getSaContributeursById, getSaContributeursFunctions,
  patchSaContributeurs, postSaContributeurs,
} from './contributeurs.thunk'
import { ParametrageContributeursState, ParamContributeur } from './types'
import { formatSettingsTabList } from '../utils'

const initialState: ParametrageContributeursState = {
  contributeurs: [],
  selectedContributor: null,
  selectedFunction: null,
  selectedPatchFunction: null,
  entityFunctions: [],
  saComplexes: [],
  saLignes: [],
  saTravaux: [],
  addContributorSa: {},
  patchContributorSa: {},
  patchEntityId: undefined,
  modalErrContributor: false,
  modalConfirmation: false,
  modalPatch: false,
  reloadRole: false,
  displaySnackbarAdd: false,
  displaySnackbarDelete: false,
  displaySnackbarUpdate: false,
  isLoadingContributors: false,
}

export const parametrageContributeurs = createSlice({
  name: 'parametrageContributeurs',
  initialState,
  reducers: {
    loadRole: state => {
      state.reloadRole = !state.reloadRole
    },
    selectUser: (state, action: PayloadAction<User | null>) => {
      state.selectedContributor = action.payload
      state.addContributorSa = {
        fonction: state.addContributorSa.fonction,
        id: state.addContributorSa.id,
        batiments_voyageurs: state.addContributorSa.batiments_voyageurs,
        lignes: state.addContributorSa.lignes,
        travaux: state.addContributorSa.travaux,
        jalons: state.addContributorSa.jalons,
        ...(action.payload?.id && { utilisateur: { cerbere_id: action.payload.id } }),
      }
    },
    selectFunction: (state, action: PayloadAction<ContributorFunction | null>) => {
      state.selectedFunction = action.payload
      state.addContributorSa = {
        utilisateur: state.addContributorSa.utilisateur,
        id: state.addContributorSa.id,
        batiments_voyageurs: state.addContributorSa.batiments_voyageurs,
        lignes: state.addContributorSa.lignes,
        travaux: state.addContributorSa.travaux,
        jalons: state.addContributorSa.jalons,
        ...(action.payload?.id && { fonction: action.payload }),
      }
    },
    selectPatchFunction: (state, action: PayloadAction<ContributorFunction | null>) => {
      state.selectedPatchFunction = action.payload
      state.patchContributorSa = {
        utilisateur: state.patchContributorSa.utilisateur,
        id: state.patchContributorSa.id,
        batiments_voyageurs: state.patchContributorSa.batiments_voyageurs,
        lignes: state.patchContributorSa.lignes,
        travaux: state.patchContributorSa.travaux,
        jalons: state.patchContributorSa.jalons,
        ...(action.payload?.id && { fonction: action.payload }),
      }
    },
    closeModalErrContributor: state => {
      state.errors = undefined
      state.modalErrContributor = false
    },
    onModalAddContributor: state => {
      state.modalErrContributor = true
    },
    onModalConfirmation: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload) {
        state.modalConfirmation = true
        state.contributorToDeleteId = action.payload
      }
    },
    closeModalConfirmation: state => {
      state.modalConfirmation = false
    },
    closeModalPatch: state => {
      state.modalPatch = false
      state.patchEntityId = undefined
      state.entityFunctions = []
    },
    setSaComplexes: (state, action: PayloadAction<ParamComplexe[]>) => {
      state.saComplexes = action.payload
      state.addContributorSa.batiments_voyageurs = action.payload
    },
    setSaLignes: (state, action: PayloadAction<ParamLigne[]>) => {
      state.saLignes = action.payload
      state.addContributorSa.lignes = action.payload
    },
    setSaTravaux: (state, action: PayloadAction<ParamConstructionSite[]>) => {
      state.saTravaux = action.payload
      state.addContributorSa.travaux = action.payload
    },
    checkComplexe: (state, action: PayloadAction<ParamComplexe>) => {
      state.addContributorSa.batiments_voyageurs = [
        ...state.addContributorSa?.batiments_voyageurs?.filter(bv => bv.id !== action.payload.id) || [],
        action.payload,
      ]
    },
    uncheckComplexe: (state, action: PayloadAction<ParamComplexe>) => {
      state.addContributorSa.batiments_voyageurs = state.addContributorSa.batiments_voyageurs
        ?.filter(bv => bv.id !== action.payload.id)
    },
    checkLigne: (state, action: PayloadAction<ParamLigne>) => {
      state.addContributorSa.lignes = [
        ...state.addContributorSa?.lignes?.filter(l => l.id !== action.payload.id) || [],
        action.payload,
      ]
    },
    uncheckLigne: (state, action: PayloadAction<ParamLigne>) => {
      state.addContributorSa.lignes = state.addContributorSa.lignes
        ?.filter(l => l.id !== action.payload.id)
    },
    checkTravaux: (state, action: PayloadAction<ParamConstructionSite>) => {
      state.addContributorSa.travaux = [
        ...state.addContributorSa?.travaux?.filter(l => l.id !== action.payload.id) || [],
        action.payload,
      ]
    },
    uncheckTravaux: (state, action: PayloadAction<ParamConstructionSite>) => {
      state.addContributorSa.travaux = state.addContributorSa.travaux
        ?.filter(l => l.id !== action.payload.id)
    },
    checkPatchComplexe: (state, action: PayloadAction<ParamComplexe>) => {
      state.patchContributorSa.batiments_voyageurs = [
        ...state.patchContributorSa?.batiments_voyageurs?.filter(bv => bv.id !== action.payload.id) || [],
        action.payload,
      ]
    },
    uncheckPatchComplexe: (state, action: PayloadAction<ParamComplexe>) => {
      state.patchContributorSa.batiments_voyageurs = state.patchContributorSa.batiments_voyageurs
        ?.filter(bv => bv.id !== action.payload.id)
    },
    checkPatchLigne: (state, action: PayloadAction<ParamLigne>) => {
      state.patchContributorSa.lignes = [
        ...state.patchContributorSa?.lignes?.filter(l => l.id !== action.payload.id) || [],
        action.payload,
      ]
    },
    uncheckPatchLigne: (state, action: PayloadAction<ParamLigne>) => {
      state.patchContributorSa.lignes = state.patchContributorSa.lignes
        ?.filter(l => l.id !== action.payload.id)
    },
    checkPatchTravaux: (state, action: PayloadAction<ParamConstructionSite>) => {
      state.patchContributorSa.travaux = [
        ...state.patchContributorSa?.travaux?.filter(l => l.id !== action.payload.id) || [],
        action.payload,
      ]
    },
    uncheckPatchTravaux: (state, action: PayloadAction<ParamConstructionSite>) => {
      state.patchContributorSa.travaux = state.patchContributorSa.travaux
        ?.filter(l => l.id !== action.payload.id)
    },
    changePatchContributorsSa: (state, action: PayloadAction<ParamContributeur>) => {
      state.patchContributorSa = action.payload
    },
    hideSnackbarAdd: state => {
      state.displaySnackbarAdd = false
    },
    hideSnackbarUpdate: state => {
      state.displaySnackbarUpdate = false
    },
    hideSnackbarDelete: state => {
      state.displaySnackbarDelete = false
    },
  },
  extraReducers: builder => {
    builder.addCase(getSaContributeurs.pending, state => {
      state.contributeurs = []
      state.isLoadingContributors = true
    })
    builder.addCase(getSaContributeurs.rejected, state => {
      state.isLoadingContributors = false
    })
    builder.addCase(getSaContributeurs.fulfilled, (state, { payload }) => {
      state.contributeurs = formatSettingsTabList(payload)
      state.isLoadingContributors = false
    })
    builder.addCase(getSaContributeursById.fulfilled, (state, { payload }) => {
      state.patchContributorSa = payload
      state.selectedPatchFunction = payload.fonction || null
      state.patchEntityId = payload.fonction?.entite.id
      state.modalPatch = true
    })
    builder.addCase(postSaContributeurs.fulfilled, (state, { payload }) => {
      if (payload) {
        state.contributeurs = formatSettingsTabList([...state.contributeurs, payload])
        state.selectedFunction = null
        state.selectedContributor = null
        state.addContributorSa = {
          batiments_voyageurs: state.saComplexes,
          lignes: state.saLignes,
          travaux: state.saTravaux,
        }
        state.displaySnackbarAdd = true
      }
    })
    builder.addCase(postSaContributeurs.rejected, (state, action) => {
      state.errors = action.payload
    })
    builder.addCase(deleteSaContributeurs.fulfilled, state => {
      state.contributeurs = state.contributeurs.filter(item => item.id !== state.contributorToDeleteId)
      state.contributorToDeleteId = undefined
      state.displaySnackbarDelete = true
    })
    builder.addCase(deleteSaContributeurs.rejected, (state, action) => {
      state.errors = action.payload
      state.contributorToDeleteId = undefined
    })
    builder.addCase(patchSaContributeurs.fulfilled, (state, action) => {
      const patchedIndex = state.contributeurs.findIndex(c => c.id === action.payload.id)
      const newContributeurs = [...state.contributeurs]
      newContributeurs.splice(patchedIndex, 1, action.payload)
      state.contributeurs = newContributeurs
      state.modalPatch = false
      state.displaySnackbarUpdate = true
    })
    builder.addCase(getSaContributeursFunctions.fulfilled, (state, action) => {
      state.entityFunctions = action.payload
    })
  },
})

export const {
  selectUser,
  selectFunction,
  selectPatchFunction,
  closeModalErrContributor,
  onModalAddContributor,
  onModalConfirmation,
  closeModalConfirmation,
  closeModalPatch,
  setSaComplexes,
  setSaLignes,
  setSaTravaux,
  checkComplexe,
  uncheckComplexe,
  checkLigne,
  uncheckLigne,
  checkTravaux,
  uncheckTravaux,
  checkPatchComplexe,
  uncheckPatchComplexe,
  checkPatchLigne,
  uncheckPatchLigne,
  checkPatchTravaux,
  uncheckPatchTravaux,
  changePatchContributorsSa,
  loadRole,
  hideSnackbarAdd,
  hideSnackbarUpdate,
  hideSnackbarDelete,
} = parametrageContributeurs.actions

export default parametrageContributeurs.reducer
