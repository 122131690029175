import ObjectFer from 'components/ObjectFer/ObjectFer'
import { ReactElement } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { ParamComplexe } from 'reducers/Parametrage/Complexes/types'
import './listParametrage.scss'

interface listComp {
  title: string,
  listId: string,
  objectFer: ParamComplexe []
}

const ListParametrage = ({ listId, objectFer }: listComp): ReactElement => (
  <Droppable droppableId={listId}>
    {provider => (
      <ul
        className="configListContainer"
        {...provider.droppableProps}
        ref={provider.innerRef}
      >
        {objectFer.map((item, index) => (
          <ObjectFer
            key={item.id}
            obj={item}
            list={listId}
            libelle={item.batiment_voyageurs?.libelle}
            objectId={item.id}
            index={index}
          />
        ))}
      </ul>
    )}
  </Droppable>
)

export default ListParametrage
