import { cotationInfo } from 'common/cotation'
import PopOver from 'components/PopOver/PopOver'
import { ReactElement } from 'react'

interface Props {
  numComposant: number
  eicLibelle: string
  typeComposant: string
  abrev: string
  numerator: number
  denominator: number
  cotation: string
  objectFerLibelle: string
  componentLibelle: string
}

const ContributionsTableBodyFirst = ({
  numComposant,
  eicLibelle,
  typeComposant,
  abrev,
  numerator,
  denominator,
  cotation,
  objectFerLibelle,
  componentLibelle,
}: Props): ReactElement => (
  <div className="arrayComponent-body-section-first">
    <div className="arrayComponent-body-col1">
      <div className="libelle">
        <PopOver
          popContent={componentLibelle}
        >
          <span>
            {numComposant}
          </span>
        </PopOver>

      </div>
      <div className="eic">
        <span>
          {eicLibelle}
        </span>
      </div>
      <div className="component">
        <span className="type">
          {typeComposant}
        </span>
        <PopOver
          popContent={objectFerLibelle}
        >
          <span className="abrev">
            {abrev}
          </span>
        </PopOver>

      </div>
    </div>
    <div>
      <div className="arrayComponent-body-col2">
        <div
          className="cotation-global"
          style={{
            backgroundColor: cotationInfo(cotation)?.color || 'transparent',
            color: cotationInfo(cotation) ? '#FFF' : 'black',
          }}
        >
          <span>
            {numerator}
          </span>
          <span>
            /
          </span>
          <span>
            {denominator}
          </span>
        </div>
      </div>
    </div>
  </div>
)

export default ContributionsTableBodyFirst
