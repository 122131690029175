/* eslint-disable camelcase */
import { RootState } from 'Store'
import terms from 'common/terms'
import {
  ReactElement,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  addConstructionSite,
  changeConstructionSite,
  deleteConstructionSite,
} from 'reducers/Admin/ConstructionSites/constructionSites'
import { PrimaryBtn } from 'themes/theme'
import { postOrPatchConstructionSite } from 'reducers/Admin/ConstructionSites/constructionSites.thunk'
import { ConstructionSites } from 'reducers/Admin/ConstructionSites/types'
import SearchGaiaLignes from 'components/SearchGaiaLignes/SearchGaiaLignes'
import Accordion from '../../../components/Accordion/Accordion'

type Ligne = {
  libelle: string,
  code_ligne: string,
}

export default function ConstrtuctionSiteAccordion(): ReactElement {
  const dispatch = useDispatch()
  const {
    constructionSites,
    isLoadingConstructionSites,
  } = useSelector((state : RootState) => state.constructionSites)

  const onLigneChange = (
    value: Ligne | null,
    construction: ConstructionSites,
  ) => {
    dispatch(changeConstructionSite({
      ...construction,
      ligne_libelle: value?.libelle,
      ligne_code: value?.code_ligne,
    }))
  }

  const handlePostOrPatch = (element: ConstructionSites) => {
    const sendElement = {
      id: element.id,
      libelle: element.libelle,
      ligne_pk: element.ligne_pk,
      ligne_code: element.ligne_code,
      ligne_libelle: element.ligne_libelle,
      eic: element.eic,
      sa: element.sa,
    }
    dispatch(postOrPatchConstructionSite(sendElement))
  }

  const selectedLigne = (element: ConstructionSites) => {
    if (element.ligne_code && element.ligne_libelle) {
      return {
        libelle: element.ligne_libelle,
        code_ligne: element.ligne_code,
      }
    }
    return null
  }

  return (
    <Accordion
      name={terms.Admin.constructionSite.name}
      elementList={constructionSites}
      isLoading={isLoadingConstructionSites}
      onAddEntity={() => dispatch(addConstructionSite())}
      onDeleteEntity={() => dispatch(deleteConstructionSite())}
      newElementWording={terms.Admin.constructionSite.new}
    >
      {constructionSite => (
        <div className="mt-4 construction-site-admin">
          <label className="col-6 d-flex align-items-center mb-0" htmlFor="libelle">
            {terms.Admin.name}
            <input
              className="input-admin form-control form-control-sm ml-2"
              placeholder={terms.Admin.entity.placeHolder}
              type="text"
              id="libelle"
              name="libelle"
              value={constructionSite.libelle}
              onChange={evt => dispatch(changeConstructionSite({ ...constructionSite, libelle: evt.target.value }))}
            />
          </label>

          <div className="input-ligne col-6 d-flex">
            <SearchGaiaLignes
              selectedLigne={selectedLigne(constructionSite)}
              setSelectedLigne={
                (value: { libelle: string, code_ligne: string } | null) => onLigneChange(value, constructionSite)
              }
              pk={constructionSite.ligne_pk || ''}
              setPk={(value: string) => dispatch(changeConstructionSite({ ...constructionSite, ligne_pk: value }))}
            />
          </div>

          <div className="row align-items-center mt-3 justify-content-end">
            <PrimaryBtn
              type="submit"
              className="btn btn-gradient-blue mr-2"
              disabled={!constructionSite.libelle}
              onClick={() => handlePostOrPatch(constructionSite)}
            >
              {terms.Admin.validate}
            </PrimaryBtn>
          </div>
        </div>
      )}
    </Accordion>
  )
}
