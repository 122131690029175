import terms from 'common/terms'
import { ReactElement } from 'react'
import { FaPencilAlt, FaTimes } from 'react-icons/fa'
import { Element } from './Accordion'

// eslint-disable-next-line import/prefer-default-export
export function getExpandIcon(element: Element, hasChildren: boolean, onDeleteEntity?: () => void): ReactElement {
  if (hasChildren) {
    return element.newId || element.id === 'new' ? (
      <div className="new-edit">
        <span className="mr-2">{`(${terms.Admin.toSave})`}</span>
        <FaPencilAlt />
        <button className="btn new-close" type="button" onClick={onDeleteEntity}>
          <FaTimes />
        </button>
      </div>
    ) : <FaPencilAlt />
  }
  return <></>
}
