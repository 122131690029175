/* eslint-disable camelcase */
import { RootState } from 'Store'
import { ValueSelect } from 'components/CustomSelect/CustomSelect'
import WeatherIcon from 'pages/ActionPlan/ActionContent/ColumnAction/component/WeatherIcon'
import { weather } from 'pages/ActionPlan/utils'
import ContributionTableView from 'pages/Suivi/SaJalonComponentTableView/ContributionTableView'
import IfNoContributionTableView from 'pages/Suivi/SaJalonComponentTableView/IfNoContributionTableView'
import { ReactElement, useState } from 'react'
import { useSelector } from 'react-redux'
import { Action } from 'reducers/ActionPlan/types'
import { SupervisionEfWithUser } from 'reducers/monitoring/types'
import { v4 as uuidV4 } from 'uuid'
import ModalAction from '../ModalAction/ModalAction'
import './ContributionsTable.scss'
import ContributionsTableBodyFirst from './ContributionsTableBodyFirst'
import ContributionsTableHeader from './ContributionsTableHeader'

interface Props {
  supervisionEfList: SupervisionEfWithUser[]
  selectedEntity: ValueSelect | null
}

const ContributionsTable = ({ supervisionEfList, selectedEntity }: Props): ReactElement => {
  const [open, setOpen] = useState(false)
  const [currentAction, setCurrentAction] = useState<Action | null>(null)
  const { robPermission } = useSelector((state: RootState) => state.users)

  const shouldFullAction = () => {
    if (robPermission.includes('admin')) return true
    if (currentAction && selectedEntity) {
      return !!currentAction.entite.find(entity => entity.id === selectedEntity.id)
    }
    return false
  }

  const handleOpenAction = (element: Action) => {
    setCurrentAction(element)
    setOpen(true)
  }

  const handleCloseAction = () => {
    setOpen(false)
    setCurrentAction(null)
  }
  return (
    <div className="contributions-table">
      <ContributionsTableHeader />
      <div className="arrayComponent-body">
        {supervisionEfList.map(component => (
          <div
            key={uuidV4()}
            className="arrayComponent-body-container"
          >
            <div
              className="arrayComponent-body-section"
            >
              <ContributionsTableBodyFirst
                numComposant={component.composant_id}
                eicLibelle={component.libelle_eic}
                typeComposant={component.type}
                abrev={component.abrev}
                numerator={component.numerator}
                denominator={component.denominator}
                cotation={component.cotation}
                objectFerLibelle={component.libelle}
                componentLibelle={component.composant_libelle}
              />
              <div className="arrayComponent-body-section-second-container">
                {component?.contributions?.length
                  ? component.contributions.map(contribution => (
                    <ContributionTableView
                      key={contribution.id}
                      date={contribution.timestamp}
                      firstName={contribution.auteur?.firstName || ''}
                      lastName={contribution.auteur?.lastName || ''}
                      role={contribution.role}
                      analyse={contribution.analyse}
                      proposal={contribution.proposition}
                      cotation={contribution.cotation}
                      file={contribution.file}
                      fromAxe={contribution.from_axe}
                    />
                  ))
                  : (
                    <IfNoContributionTableView />
                  )}
              </div>
              <div className="arrayComponent-body-section-third-container">
                {
                  component.actions.map(action => (
                    <div key={action.id} className="action-container">
                      <button
                        type="button"
                        className="action-button"
                        onClick={() => handleOpenAction(action)}
                      >
                        {action.nom}
                      </button>

                      {
                        action.meteo_animateur && (
                          <span className="icons">
                            <WeatherIcon weather={weather(action.meteo_animateur)} />
                          </span>
                        )
                      }
                    </div>
                  ))
                }
              </div>
            </div>
            <span className="arrayComponent-separate" />
          </div>
        ))}
      </div>
      <ModalAction
        onClose={handleCloseAction}
        open={open}
        action={currentAction as Action}
        fullAction={shouldFullAction()}
      />
    </div>
  )
}

export default ContributionsTable
