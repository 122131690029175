import { ReactElement, useState } from 'react'
import Typography from '@mui/material/Typography'
import 'components/Accordion/Accordion.scss'
import { FaMinusSquare } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { setExpandedElement } from 'reducers/Admin/admin.reducer'
import { RootState } from 'Store'
import {
  Accordion, AccordionDetails, AccordionSummary,
} from 'components/Accordion/AccordionStyle'
import terms from 'common/terms'
import { ErrorInput } from 'reducers/Admin/Ponderation/types'
import PonderationTable from './PonderationTable/PonderationTable'
import CotationDetails from './CotationDetails/CotationDetails'

import './Ponderation.scss'

const Ponderation = (): ReactElement => {
  const dispatch = useDispatch()
  const { expandedElement } = useSelector((state: RootState) => state.admin)
  const [error, setError] = useState<ErrorInput[]>([])

  const isValidNumber = (item: string) => /^([0-9]\d*)$/.test(item)
  return (
    <Accordion
      expanded={expandedElement === terms.Admin.ponderation.name}
      onChange={() => dispatch(setExpandedElement(
        expandedElement === terms.Admin.ponderation.name ? '' : terms.Admin.ponderation.name,
      ))}
    >
      <AccordionSummary
        expandIcon={<FaMinusSquare size="18px" />}
        aria-controls="ponderation-content"
        id="ponderation-header"
      >
        <Typography><strong>{terms.Admin.ponderation.name}</strong></Typography>
      </AccordionSummary>
      {expandedElement === terms.Admin.ponderation.name && (
      <AccordionDetails>
        <div className="ponderation-container">
          <PonderationTable checkInput={isValidNumber} error={error} setError={setError} />
          <CotationDetails checkInput={isValidNumber} error={error} setError={setError} />
        </div>
      </AccordionDetails>
      )}
    </Accordion>
  )
}

export default Ponderation
