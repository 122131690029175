import terms from 'common/terms'
import { ReactElement } from 'react'

const ContributionsTableHeader = (): ReactElement => (
  <div className="arrayComponent-header">
    <div className="arrayComponent-header-first">
      <div className="arrayComponent-header-col1">
        {terms.Monitoring.Contributions.Table.component}
      </div>
      <div className="arrayComponent-header-col2">
        {terms.Monitoring.Contributions.Table.evaluation}
      </div>
    </div>
    <div className="arrayComponent-header-second">
      <div className="arrayComponent-header-col4">
        {terms.Monitoring.Contributions.Table.author}
      </div>
      <div className="arrayComponent-header-col5">
        {terms.Monitoring.Contributions.Table.analysis}
      </div>
      <div className="arrayComponent-header-col6">
        {terms.Monitoring.Contributions.Table.proposal}
      </div>
    </div>
    <div className="arrayComponent-header-third">
      {terms.Monitoring.Contributions.Table.action}
    </div>
  </div>
)

export default ContributionsTableHeader
