import terms from 'common/terms'
import { ReactElement } from 'react'

interface Props {
  setOpenModalDelete: (value: boolean) => void
  setOpenModalModify: (value: boolean) => void
}

const ButtonsAction = ({ setOpenModalDelete, setOpenModalModify }: Props): ReactElement => (
  <div className="buttons">
    <div>
      <button
        className="delete"
        type="button"
        onClick={() => setOpenModalDelete(true)}
      >
        {terms.Common.delete}
      </button>
    </div>
    <div>
      <button
        className="modify"
        type="button"
        onClick={() => setOpenModalModify(true)}
      >
        {terms.Common.modify}
      </button>
    </div>
  </div>
)

export default ButtonsAction
