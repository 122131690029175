import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import {
  FormControl, IconButton, MenuItem, Select, SelectChangeEvent,
} from '@mui/material'
import { ReactElement } from 'react'
import terms from 'common/terms'
import { SelectedContributor } from 'reducers/Contributions/types'
import './contributorFilter.scss'
import { backgroundColorContributor } from 'pages/Suivi/utils'

interface ContributeurFilterProps {
  selectedContributor: SelectedContributor | null
  contributorList: SelectedContributor[]
  handleSelectContributor: (event: SelectChangeEvent) => void
  handleClearClick: () => void
}

const ContributorFilter = ({
  selectedContributor, contributorList, handleSelectContributor, handleClearClick,
}: ContributeurFilterProps): ReactElement => (
  <FormControl id="filter-contributor" variant="standard" sx={{ m: 1, minWidth: 300, maxWidth: 400 }}>
    <Select
      displayEmpty
      labelId="filter-contributor"
      value={selectedContributor?.id || ''}
      onChange={handleSelectContributor}
      sx={{ '& .MuiSelect-icon': { display: selectedContributor ? 'none' : '' } }}
      endAdornment={(
        <IconButton
          sx={{
            display: selectedContributor ? '' : 'none',
            paddingY: '5px',
            '&:focus': { outline: 'none' },
          }}
          onClick={handleClearClick}
        >
          <FontAwesomeIcon icon={faXmark} fontSize={15} />
        </IconButton>
      )}
      renderValue={value => {
        const selectedValue = contributorList.find(c => c.id === value)
        return (
          <span className="filter-contributor-name">
            {selectedValue?.label || terms.ContributorFilter.allContributors}
          </span>
        )
      }}
    >
      {contributorList.map(contributor => (
        <MenuItem key={contributor.id} value={contributor.id}>
          <div key={contributor.id} className="d-flex align-items-center">
            <span
              className="mr-2 filter-contributor-avatar"
              style={{ background: backgroundColorContributor(contributor.id) }}
            >
              {contributor.initials}
            </span>
            <span className="filter-contributor-name">
              {contributor.label}
            </span>
          </div>
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default ContributorFilter
