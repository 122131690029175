import {
  Select, MenuItem, InputLabel, FormControl,
} from '@mui/material'
import terms from 'common/terms'
import { backgroundColorContributor } from 'pages/Suivi/utils'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { ComposantContributeur } from 'reducers/SA/types'
import { UserSimple } from 'reducers/Users/types'
import './ContributionImpersonate.scss'
import { RootState } from 'Store'

interface ContributionImpersonateProps {
  possibleContributors: ComposantContributeur[]
  possibleAnimators: UserSimple[]
  value: {cerbereId: string, entityId: string} | null
  setValue: (arg: {cerbereId: string, entityId: string} | null) => void
}

const ContributionImpersonate = ({
  possibleContributors, possibleAnimators, value, setValue,
}: ContributionImpersonateProps):ReactElement => {
  const { activeJalon } = useSelector((state: RootState) => state.contributions)
  const { deciders } = useSelector((state: RootState) => state.paramDeciders)

  const SELF_CONTRIB_VALUE = 'self'

  const possibleUsers = [
    ...possibleAnimators.map(animator => ({
      id: animator.id,
      label: `${animator.lastName.toUpperCase()} ${animator.firstName} (${terms.Contributions.impersonate.animator})`,
      initials: `${animator.lastName[0]}${animator.firstName[0]}`.toUpperCase(),
      entityId: '',
    })),
    ...possibleContributors.map(c => ({
      id: c.contributeur?.id,
      label: `${c.contributeur?.lastName.toUpperCase()} ${c.contributeur?.firstName} (${c.fonction})`,
      initials: `${c.contributeur?.lastName[0]}${c.contributeur?.firstName[0]}`.toUpperCase(),
      entityId: c.entite_id,
    })),
    ...(activeJalon === 0 ? deciders.map(d => ({
      id: d.cerbereId,
      label: `${d.lastName.toUpperCase()} ${d.firstName} (${d.entityName})`,
      initials: `${d.lastName[0]}${d.firstName[0]}`.toUpperCase(),
      entityId: d.entityId,
    })) : []),
  ].sort((a, b) => a.label.localeCompare(b.label))

  return (
    <FormControl className="mb-3" variant="standard">
      <InputLabel id="select-impersonate">
        {terms.Contributions.impersonate.label}
      </InputLabel>
      <Select
        value={value?.cerbereId || SELF_CONTRIB_VALUE}
        onChange={evt => setValue(
          evt.target.value === SELF_CONTRIB_VALUE ? null : {
            cerbereId: evt.target.value,
            entityId: possibleUsers.find(u => u.id === evt.target.value)?.entityId || '',
          },
        )}
      >
        <MenuItem key="" value={SELF_CONTRIB_VALUE}>
          {terms.Contributions.impersonate.selfContribute}
        </MenuItem>
        { possibleUsers.map(user => (
          <MenuItem key={user.id} value={user.id}>
            <div className="d-flex align-items-center">
              <span
                className="mr-2 contribution-impersonate-avatar"
                style={{ background: backgroundColorContributor(user.id || '') }}
              >
                {user.initials}
              </span>
              <span>
                {user.label}
              </span>
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ContributionImpersonate
