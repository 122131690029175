import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import terms from 'common/terms'
import { Complexes } from 'reducers/Admin/Complexes/types'
import {
  deleteSaComplexe, getDefaultJalonsComplexe, getSaComplexeById, getSaComplexes, patchSAComplexe, postSaComplexe,
} from './complexes.thunk'
import { ColumnComplexe, ParamComplexe, ParametrageComplexesState } from './types'

const initialState: ParametrageComplexesState = {
  complexes: [
    {
      id: '1',
      title: terms.Settings.complexe.listNotActive,
      objectFerList: [],
    },
    {
      id: '2',
      title: terms.Settings.complexe.listActive,
      objectFerList: [],
    },
  ],
  listComplexesSa: [],
  modalErrComplexe: false,
  modalErrAssosComplexe: false,
  modalConfirmation: false,
  defaultComposantsComplexes: [],
  openComposantsModal: false,
  displaySnackbarAdd: false,
  displaySnackbarDelete: false,
  displaySnackbarUpdate: false,
  isLoadingComplexe: false,
}

export const parametrageComplexes = createSlice({
  name: 'parametrageComplexes',
  initialState,
  reducers: {
    changeListComplexes: (state, action: PayloadAction<ColumnComplexe[]>) => {
      state.complexes = action.payload
    },
    inputAddComplexeSa: (state, action: PayloadAction<Complexes | null>) => {
      state.addComplexeSa = action.payload
    },
    addNewComplexeSa: state => {
      if (!state.addComplexeSa?.eic?.libelle) {
        state.complexes[1].objectFerList = [
          ...state.complexes[1].objectFerList,
          {
            id: state.addComplexeSa?.id,
            batiment_voyageurs: {
              libelle: state.addComplexeSa?.libelle,
            },
            active: true,
          },
        ]
        state.listComplexesSa?.push({
          id: state.addComplexeSa?.id,
          batiment_voyageurs: {
            libelle: state.addComplexeSa?.libelle,
          },
          active: true,
        })
      } else {
        state.modalErrComplexe = true
      }
    },
    closeModalErrComplexe: state => {
      state.errors = undefined
      state.modalErrComplexe = false
      state.modalErrAssosComplexe = false
    },
    onModalAssosComplex: state => {
      state.modalErrAssosComplexe = true
    },
    onModalAppartComplex: state => {
      state.modalErrComplexe = true
    },
    onModalConfirmation: (state, action: PayloadAction<ParamComplexe>) => {
      state.selectedComplexe = action.payload
      state.modalConfirmation = true
    },
    closeModalConfirmation: state => {
      state.modalConfirmation = false
    },
    setSaComplexe: state => {
      state.saComplexe = undefined
      state.openComposantsModal = false
    },
    changeSaComplexe: (state, action: PayloadAction<ParamComplexe>) => {
      state.saComplexe = action.payload
    },
    hideSnackbarAdd: state => {
      state.displaySnackbarAdd = false
    },
    hideSnackbarUpdate: state => {
      state.displaySnackbarUpdate = false
    },
    hideSnackbarDelete: state => {
      state.displaySnackbarDelete = false
    },
  },
  extraReducers: builder => {
    builder.addCase(getSaComplexes.pending, state => {
      state.complexes[0].objectFerList = []
      state.complexes[1].objectFerList = []
      state.listComplexesSa = []
      state.isLoadingComplexe = true
    })
    builder.addCase(getSaComplexes.rejected, state => {
      state.isLoadingComplexe = false
    })
    builder.addCase(getSaComplexes.fulfilled, (state, { payload }) => {
      if (payload) {
        state.complexes[0].objectFerList = payload.filter(i => i.active === false)
        state.complexes[1].objectFerList = payload.filter(i => i.active === true)
        state.listComplexesSa = payload
      }
      state.isLoadingComplexe = false
    })
    builder.addCase(postSaComplexe.fulfilled, (state, { payload }) => {
      if (payload) {
        state.complexes[1].objectFerList = [
          ...state.complexes[1].objectFerList,
          {
            id: payload.id,
            batiment_voyageurs: {
              id: payload.batiment_voyageurs?.id,
              libelle: payload.batiment_voyageurs?.libelle,
              eic: payload.batiment_voyageurs?.eic,
            },
            active: true,
          },
        ]
        if (state.listComplexesSa) {
          const newSaComplexes = [...state.listComplexesSa, payload]
          state.listComplexesSa = newSaComplexes.sort((a, b) => {
            if (a.batiment_voyageurs?.libelle && b.batiment_voyageurs?.libelle) {
              return a.batiment_voyageurs.libelle.localeCompare(b.batiment_voyageurs.libelle)
            }
            return 0
          })
        }
        state.addComplexeSa = null
        state.displaySnackbarAdd = true
      }
    })
    builder.addCase(postSaComplexe.rejected, (state, action) => {
      state.errors = action.payload
    })
    builder.addCase(patchSAComplexe.rejected, (state, action) => {
      state.errors = action.payload
    })
    builder.addCase(deleteSaComplexe.fulfilled, state => {
      state.complexes[0].objectFerList = state.complexes[0].objectFerList.filter(
        i => i.batiment_voyageurs?.libelle !== state.selectedComplexe?.batiment_voyageurs?.libelle,
      )
      state.displaySnackbarDelete = true
    })
    builder.addCase(getSaComplexeById.fulfilled, (state, { payload }) => {
      state.saComplexe = payload
      state.openComposantsModal = true
    })
    builder.addCase(patchSAComplexe.fulfilled, (state, { payload }) => {
      if (state.openComposantsModal) {
        state.displaySnackbarUpdate = true
      }
      state.saComplexe = payload
      if (state.listComplexesSa) {
        state.listComplexesSa = state.listComplexesSa.map(item => {
          if (item.id === payload.id) {
            return payload
          }
          return item
        })
      }
      state.openComposantsModal = false
    })
    builder.addCase(getDefaultJalonsComplexe.fulfilled, (state, action) => {
      const newDefaults = [...state.defaultComposantsComplexes].filter(jc => jc.numero_jalon !== action.meta.arg)
      state.defaultComposantsComplexes = [
        ...newDefaults, { numero_jalon: action.meta.arg, composants: action.payload },
      ]
    })
  },
})

export const {
  changeListComplexes,
  inputAddComplexeSa,
  addNewComplexeSa,
  closeModalErrComplexe,
  onModalConfirmation,
  closeModalConfirmation,
  onModalAssosComplex,
  onModalAppartComplex,
  setSaComplexe,
  changeSaComplexe,
  hideSnackbarAdd,
  hideSnackbarUpdate,
  hideSnackbarDelete,
} = parametrageComplexes.actions

export default parametrageComplexes.reducer
