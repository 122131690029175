import terms from 'common/terms'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setPatchBesoinValidation, setPatchDescription, setPatchName, setPatchEntite,
  setPatchDateBouclage, setPatchDateOuverture,
} from 'reducers/ActionPlan/actionPlan.reducer'
import { patchActionById } from 'reducers/ActionPlan/actionPlan.thunk'
import { Etat } from 'reducers/ActionPlan/types'
import { RootState } from 'Store'
import { SelectChangeEvent } from '@mui/material'
import ModalForm from './ModalForm'

interface FormPatchActionProps {
  closeButton: () => void
  composantId: string | undefined
  objectFerType: string | undefined
  objectFerId: string | undefined
  isAnimax: boolean
  isObjectBorrowed: boolean
  fromAxe: boolean
}

const FormPatchAction = ({
  closeButton, composantId, objectFerType, objectFerId, isAnimax, isObjectBorrowed, fromAxe,
}: FormPatchActionProps): ReactElement => {
  const dispatch = useDispatch()
  const { patchAction, selectedPilote, selectedCoPilote } = useSelector((state: RootState) => state.actionPlan)
  const { idSA } = useSelector((state: RootState) => state.app)
  const { entitiesVisibility } = useSelector((state: RootState) => state.contributions)

  const patchForm = () => {
    if (objectFerType && objectFerId && composantId) {
      dispatch(patchActionById({
        saId: idSA,
        type: objectFerType,
        objectId: objectFerId,
        composantsId: composantId,
        action: patchAction,
      }))
    }
  }

  const handleChangeEntity = (evt: SelectChangeEvent<string[]>) => {
    const { target: { value } } = evt
    if (typeof value !== 'string') {
      dispatch(setPatchEntite(entitiesVisibility.filter(e => value.includes(e.id as string))))
    }
  }

  return (
    <ModalForm
      disabledForm={isAnimax && isObjectBorrowed && !fromAxe}
      title={terms.actionPlan.modalAddAction.formPatchActionTitle}
      action={patchAction}
      entities={entitiesVisibility}
      selectedPilote={selectedPilote}
      selectedCoPilote={selectedCoPilote}
      handleChangeName={e => dispatch(setPatchName(e.target.value))}
      handleChangeEntity={handleChangeEntity}
      handleChangeDateBouclage={setPatchDateBouclage}
      handleChangeDateOuverture={setPatchDateOuverture}
      handleChangeDescription={e => dispatch(setPatchDescription(e.target.value))}
      handleChangeBesoinValidation={() => dispatch(setPatchBesoinValidation(!patchAction.besoin_validation))}
      disabledCheckBox={patchAction.besoin_validation && patchAction.etat === Etat.VALIDATED}
      closeButton={closeButton}
      disableButton={isAnimax && isObjectBorrowed && !fromAxe}
      handleValidate={patchForm}
      validateLabel={terms.actionPlan.modalAddAction.formPatchActionValidate}
    />
  )
}

export default FormPatchAction
