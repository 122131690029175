import { chipAttributes } from 'pages/ActionPlan/utils'
import { ReactElement } from 'react'
import { Etat } from 'reducers/ActionPlan/types'

interface Props {
  state: string;
  status: string;
}

const ChipStatus = ({ state, status }: Props): ReactElement => (
  <div className="chip">
    <div className="item">
      <div
        className="circle"
        style={{ backgroundColor: chipAttributes(status).bgColor }}
      />
      <div>
        {chipAttributes(status).text}
      </div>
    </div>
    {
      state !== Etat.SKIPPED && (
        <div className="item">
          <div
            className="circle"
            style={{ backgroundColor: chipAttributes(state).bgColor }}
          />
          <div>
            {chipAttributes(state).text}
          </div>
        </div>
      )
    }
  </div>
)

export default ChipStatus
