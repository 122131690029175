import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import {
  ChangeEvent, ReactElement, useEffect, useState,
} from 'react'
import nextId from 'react-id-generator'

type Element = {
  id?: string,
  libelle?: string
}

type Props<T> = {
  columnLabel: string,
  allLabel: string,
  options: (T | undefined)[],
  selectedOptions: (T | undefined)[],
  handleCheck(element?: T): (event?: ChangeEvent, checked?: boolean) => void,
  getId(element?: T): string | undefined,
  getLabel(element?: T): string | undefined,
}

export default function PerimeterColumn<T extends Element>({
  columnLabel, allLabel, options, selectedOptions, handleCheck, getId, getLabel,
}: Props<T>): ReactElement {
  const [allChecked, setAllChecked] = useState(true)
  const handleAllClick = (_e: ChangeEvent, checked: boolean) => {
    options.forEach(elem => handleCheck(elem)(_e, checked))
    setAllChecked(checked)
  }

  useEffect(() => {
    if (options && selectedOptions) {
      setAllChecked(options.length === selectedOptions.length)
    }
  }, [options, selectedOptions])

  return (
    <div className="perimeter-column-container">
      <div className="perimeter-column-label">
        {columnLabel}
      </div>
      <div className="perimeter-column">
        <FormGroup>
          <FormControlLabel
            control={(<Checkbox checked={allChecked} onChange={handleAllClick} />)}
            label={allLabel}
          />
          {options.map(elem => (
            <FormControlLabel
              control={(
                <Checkbox
                  checked={selectedOptions.some(bv => getId(bv) === getId(elem))}
                  onChange={handleCheck(elem)}
                />
              )}
              label={getLabel(elem)}
              key={nextId()}
            />
          ))}
        </FormGroup>
      </div>
    </div>
  )
}
