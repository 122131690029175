import { createAsyncThunk } from '@reduxjs/toolkit'
import { get, patch, post } from '@osrdata/app_core/dist/requests'
import { postSaConstructionSite } from 'reducers/Parametrage/ConstructionSites/constructionSites.thunk'
import { ConstructionSites, SearchGaiaLigne } from './types'
import { getConstructionSites } from '../admin.thunk'

const searchConstructionSites = createAsyncThunk(
  'admin/constructionSites/get',
  async (params: string, thunkApi) => {
    try {
      const response = await get('/robustest/sa-travaux/', { libelle: params })
      return response
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const patchConstructionSites = createAsyncThunk(
  'admin/constructionSites/patch',
  async (params: { constructionSite: Partial<ConstructionSites>, search: string }, thunkApi) => {
    try {
      const response = await patch(`/robustest/sa-travaux/${params.constructionSite.id}`, params.constructionSite)
      thunkApi.dispatch(searchConstructionSites(params.search))
      return response as ConstructionSites

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postConstructionSite = createAsyncThunk(
  'admin/constructionSites/post',
  async (constructionSite: Partial<ConstructionSites>, thunkApi) => {
    try {
      const response = await post('/robustest/sa-travaux/', constructionSite)
      thunkApi.dispatch(searchConstructionSites(''))
      thunkApi.dispatch(postSaConstructionSite({
        saId: localStorage.getItem('idSA'),
        ConstructionSiteId: response?.id,
      }))
      return response

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const searchGaiaLignes = createAsyncThunk(
  'admin/gaia/lignes/get',
  async (params: SearchGaiaLigne, thunkApi) => {
    try {
      const response = await get('/robustest/gaia-ligne/', params)
      return response
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postOrPatchConstructionSite = createAsyncThunk(
  'eic/post|patch',
  async (params: Partial<ConstructionSites>, thunkApi) => {
    try {
      if (params.id === 'new') {
        const response = await post('/robustest/sa-travaux/', {
          libelle: params.libelle,
          ligne_pk: params.ligne_pk,
          ligne_code: params.ligne_code,
          ligne_libelle: params.ligne_libelle,
          eic: params.eic,
          sa: params.sa,
        }) as ConstructionSites
        thunkApi.dispatch(getConstructionSites(''))
        return response
      }
      const response = await patch(`/robustest/sa-travaux/${params.id}`, params) as ConstructionSites
      thunkApi.dispatch(getConstructionSites(''))
      return response

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export {
  searchConstructionSites,
  patchConstructionSites,
  postConstructionSite,
  searchGaiaLignes,
  postOrPatchConstructionSite,
}
