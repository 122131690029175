import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SelectChangeEvent, Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft, faChevronCircleRight, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { ComposantObject } from 'reducers/SA/types'
import {
  setActiveComponent,
  setSelectedContribution,
  setSelectedContributor,
} from 'reducers/Contributions/contributions.reducer'
import PopOver from 'components/PopOver/PopOver'
import terms from 'common/terms'
import { RootState } from 'Store'
import ContributorFilter from 'components/ContributorFilter/ContributorFilter'
import { SelectedContributor } from 'reducers/Contributions/types'

type ComponentNavigatorProps = {
  activeJalon: number | null
  activeComponent: ComposantObject | null,
  componentList: ComposantObject[],
  selectedContributor: SelectedContributor | null,
  contributorList: SelectedContributor[]

}

export default function ComponentNavigator({
  activeJalon, activeComponent, componentList, selectedContributor, contributorList,
}: ComponentNavigatorProps): ReactElement {
  const dispatch = useDispatch()
  const { composants } = useSelector((state: RootState) => state.composant)
  const [pdfLink, setPdfLink] = useState<string | null>(null)

  useEffect(() => {
    if (activeJalon !== null && activeComponent && composants) {
      const component = composants.find(c => c.id === activeComponent.id)
      setPdfLink(component?.jalons.find(j => j.numero_jalon === activeJalon)?.pdf || null)
    }
  }, [composants, activeJalon, activeComponent])

  const componentIndex = componentList.findIndex(comp => comp.id === activeComponent?.id)

  const handleClickPrevComponent = () => {
    if (componentIndex > 0) {
      dispatch(setActiveComponent(componentList[componentIndex - 1]))
      dispatch(setSelectedContribution(null))
    }
  }

  const handleClickNextComponent = () => {
    if (componentIndex >= 0 && componentIndex < componentList.length - 1) {
      dispatch(setActiveComponent(componentList[componentIndex + 1]))
      dispatch(setSelectedContribution(null))
    }
  }

  const handleSelectContributor = (event: SelectChangeEvent) => {
    const selectedContr = contributorList.find(c => c.id === event.target.value)
    dispatch(setSelectedContributor(selectedContr || null))
  }
  const handleClearContributor = () => {
    dispatch(setSelectedContributor(null))
  }

  const handleClickSeeFile = (url: string) => () => {
    fetch(`${url}&content_disposition=inline`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(resp => resp.json())
      .then(json => {
        const link = document.createElement('a')
        link.href = json
        link.target = '_blank'
        document.body.appendChild(link)
        link.click()
      })
  }

  return (
    <div className="component-navigator d-flex flex-column border-bottom">
      <div className="d-flex">
        <div className="d-flex align-items-center">
          <PopOver hidden={componentIndex <= 0} popContent={terms.Contributions.navigator.previousComponent}>
            <FontAwesomeIcon
              icon={faChevronCircleLeft}
              fontSize={30}
              className={componentIndex > 0 ? 'button' : 'disabled'}
              onClick={handleClickPrevComponent}
            />
          </PopOver>
        </div>
        <div className="component-name d-flex align-items-center">
          <Typography variant="h6">
            {`${activeComponent?.id} - ${activeComponent?.libelle}`}
          </Typography>
          {pdfLink && (
            <PopOver popContent={terms.Contributions.navigator.info}>
              <button type="button" onClick={handleClickSeeFile(pdfLink)}>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  fontSize={30}
                  className="button"
                />
              </button>
            </PopOver>
          )}
        </div>
        <div className="d-flex align-items-center">
          <PopOver
            hidden={componentIndex >= componentList.length - 1}
            popContent={terms.Contributions.navigator.nextComponent}
          >
            <FontAwesomeIcon
              icon={faChevronCircleRight}
              fontSize={30}
              className={componentIndex >= 0 && componentIndex < componentList.length - 1 ? 'button' : 'disabled'}
              onClick={handleClickNextComponent}
            />
          </PopOver>
        </div>
      </div>
      <ContributorFilter
        selectedContributor={selectedContributor}
        contributorList={contributorList}
        handleSelectContributor={handleSelectContributor}
        handleClearClick={handleClearContributor}
      />
    </div>
  )
}
