import { Eic } from 'reducers/Admin/Eic/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getEic, postEic } from './eic.thunk'
import { EicState } from './types'

const initialState: EicState = {
  eicList: [],
  displaySnackbar: false,
  isLoadingEic: false,
}

export const teritory = createSlice({
  name: 'eic',
  initialState,
  reducers: {
    addEic: state => {
      if (state.eicList.find(stateEic => stateEic.id === 'new')) {
        return
      }
      state.eicList = [...state.eicList, {
        libelle: '', id: 'new', animateurs: [], visiteurs: [],
      }]
    },
    changeEic: (state, action: PayloadAction<Partial<Eic>>) => {
      const index = state.eicList.findIndex(stateEic => stateEic.id === action.payload.id)
      state.eicList[index] = { ...state.eicList[index], ...action.payload }
    },
    deleteNewEic: state => {
      const index = state.eicList.findIndex(entitie => entitie.id === 'new')
      if (index !== -1) { state.eicList.splice(index, 1) }
    },
    hideSnackbar: state => {
      state.displaySnackbar = false
    },
  },
  extraReducers: builder => {
    builder.addCase(getEic.pending, state => { state.isLoadingEic = true })
    builder.addCase(getEic.rejected, state => { state.isLoadingEic = false })
    builder.addCase(getEic.fulfilled, (state, { payload }) => {
      state.isLoadingEic = false
      state.eicList = payload.map(payloadEic => ({
        libelle: payloadEic.libelle,
        animateurs: payloadEic.animateurs,
        visiteurs: payloadEic.visiteurs,
        dzp: payloadEic.dzp,
        id: payloadEic.id,
      }))
    })
    builder.addCase(postEic.fulfilled, state => {
      state.displaySnackbar = true
    })
  },
})

export const {
  addEic, changeEic, deleteNewEic, hideSnackbar,
} = teritory.actions

export default teritory.reducer
