import terms from 'common/terms'
import React from 'react'
import { UserRole } from 'reducers/Users/types'
import ParamComplexes from './complexes/ParamComplexes'
import Contributors from './contributors/Contributors'
import Deciders from './deciders/Deciders'
import EntityAnimator from './entityAnimator/EntityAnimator'
import ParamLignes from './lignes/ParamLignes'
import Preferences from './preferences/Preferences'
import ParamConstructionSites from './constructionSites/ParamConstructionSites'
import Visitors from './visitors/Visitors'

export type TabElement = {
  key: string;
  value: string;
  Component: React.FunctionComponent;
  robAccess: UserRole[];
}

// eslint-disable-next-line import/prefer-default-export
export const TabsElement: TabElement[] = [
  {
    key: 'complex',
    value: terms.Settings.complexe.tab,
    Component: ParamComplexes,
    robAccess: [UserRole.admin, UserRole.animator, UserRole.animatorAxe],
  },
  {
    key: 'lignes',
    value: terms.Settings.lignes.tab,
    Component: ParamLignes,
    robAccess: [UserRole.admin, UserRole.animator, UserRole.animatorAxe],
  },
  {
    key: 'constructionSite',
    value: terms.Settings.constructionSite.tab,
    Component: ParamConstructionSites,
    robAccess: [UserRole.admin, UserRole.animator, UserRole.animatorAxe],
  },
  {
    key: 'contributors',
    value: terms.Settings.contributor.tab,
    Component: Contributors,
    robAccess: [UserRole.admin, UserRole.animator, UserRole.entityAnimator, UserRole.animatorAxe],
  },
  {
    key: 'deciders',
    value: terms.Settings.decider.tab,
    Component: Deciders,
    robAccess: [UserRole.admin, UserRole.animator, UserRole.entityAnimator, UserRole.animatorAxe],
  },
  {
    key: 'visitors',
    value: terms.Settings.visitor.tab,
    Component: Visitors,
    robAccess: [UserRole.admin, UserRole.animator, UserRole.entityAnimator, UserRole.animatorAxe],
  },
  {
    key: 'entityAnimator',
    value: terms.Settings.entityAnimator.tab,
    Component: EntityAnimator,
    robAccess: [UserRole.admin, UserRole.animator, UserRole.animatorAxe],
  },
  {
    key: 'preferences',
    value: terms.Settings.preferences.tab,
    Component: Preferences,
    robAccess: [UserRole.admin, UserRole.animator, UserRole.animatorAxe],
  },
]
