import terms from 'common/terms'
import { ReactElement } from 'react'
import { SecondaryBtn, DeleteBtn, PrimaryBtn } from 'themes/theme'

interface EditorActionsProps {
  hasSelectedContribution: boolean
  editable: boolean
  addButtonDisabled: boolean
  handleUnselect: () => void
  handleDeleteContribution: () => void
  handlePatchContribution: () => void
  handleAddContribution: () => void
  canModifyAxeHabilitations: boolean
}

const EditorActions = ({
  hasSelectedContribution, editable, addButtonDisabled, handleUnselect,
  handleDeleteContribution, handlePatchContribution, handleAddContribution, canModifyAxeHabilitations,
}: EditorActionsProps): ReactElement => (
  <div className="contributions-actions d-flex justify-content-end">
    {hasSelectedContribution ? (
      <>
        <SecondaryBtn
          className="ml-3"
          onClick={handleUnselect}
        >
          {terms.Common.cancel}
        </SecondaryBtn>
        {
          editable && (
            <DeleteBtn
              className="ml-3"
              onClick={handleDeleteContribution}
            >
              {terms.Common.delete}
            </DeleteBtn>
          )
        }

        {
          (editable || canModifyAxeHabilitations) && (
            <PrimaryBtn
              className="ml-3"
              onClick={handlePatchContribution}
            >
              {terms.Common.modify}
            </PrimaryBtn>
          )
        }
      </>
    ) : (
      <PrimaryBtn
        disabled={addButtonDisabled}
        onClick={handleAddContribution}
      >
        {terms.Common.add}
      </PrimaryBtn>
    )}
  </div>

)

export default EditorActions
