import { get, post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { getUsersListInfo } from 'reducers/Users/users.thunk'
import { UserSimple } from 'reducers/Users/types'
import { fileUrl } from 'reducers/Suivi/utils'
import { ActionRaw } from 'reducers/ActionPlan/types'
import {
  ComposantMonitoring,
  ContributionRes,
  ParamsMonitoringDzp,
  ParmasGetSupervisionEf,
  SupervisionEf,
  ZpMonitoring,
} from './types'

const getTerritory = createAsyncThunk(
  'get/territory/montoring',
  async (id: string, thunkApi) => {
    try {
      const response = await get('/robustest/eic/', {
        dzp: id,
      })
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getPossibleYears = createAsyncThunk(
  'get/possibleYears/montoring',
  async (params: { eic: string[] }, thunkApi) => {
    try {
      const response = await post('/robustest/annee/', params)
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getMonitoringDzp = createAsyncThunk(
  'get/monitoring/dzp',
  async (params: ParamsMonitoringDzp, thunkApi) => {
    try {
      const response = await post('/robustest/supervision/dzp/', params)
      const userIds = response.map(
        (monitoring: ZpMonitoring) => monitoring.composants.map((composant: ComposantMonitoring) => composant.animator),
      ).flat().filter((id: string) => id !== null)

      const users = (await thunkApi.dispatch(getUsersListInfo(userIds))).payload as UserSimple[]
      const responseWithUsers = response.map(
        (monitoring: ZpMonitoring) => ({
          ...monitoring,
          composants: monitoring.composants.map((composant: ComposantMonitoring) => ({
            ...composant,
            animator: users.find((user: UserSimple) => user.id === composant.animator) || null,
          })),
        }),
      )

      return responseWithUsers
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getPortfolioSimple = createAsyncThunk(
  'get/portfolio/simple',
  async (params: {
    entityId?: string
  }, thunkApi) => {
    try {
      const response = await get('/robustest/portefeuilles/simple', {
        entite: params.entityId,
      })
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getSupervisionEf = createAsyncThunk(
  'get/supervision/ef',
  async (params: ParmasGetSupervisionEf, thunkApi) => {
    try {
      const response = await post('/robustest/supervision/ef/', params)
      const responseFlatMaped: SupervisionEf[] = response.flatMap((item: SupervisionEf[]) => item)
      const userIds = new Set<string | null>()

      responseFlatMaped.forEach((item: SupervisionEf) => {
        if (item?.contributions.length) {
          item.contributions.forEach((contrib: ContributionRes) => {
            userIds.add(contrib.auteur)
          })
        }
        if (item?.actions) {
          item.actions.forEach((action: ActionRaw) => {
            if (action?.pilote) {
              userIds.add(action.pilote.cerbere_id)
            }
            if (action?.copilote) {
              userIds.add(action.copilote.cerbere_id)
            }
          })
        }
      })

      const usersResponse: UserSimple[] = await post('/cerbere/users/simple/', [...userIds])
      const responseWithUrl = await Promise.all(
        responseFlatMaped.map(async item => ({
          ...item,
          contributions: await Promise.all(item.contributions.map(async contrib => {
            if (contrib.file === null) {
              return {
                ...contrib,
                auteur: usersResponse.find((user: UserSimple) => user.id === contrib.auteur) || null,
                file: null,
              }
            }
            const file = await fileUrl(contrib.file)
            return {
              ...contrib,
              auteur: usersResponse.find((user: UserSimple) => user.id === contrib.auteur),
              file,
            }
          })),
          actions: await Promise.all(item.actions.map(async action => {
            const pilote = action?.pilote ? usersResponse.find(
              (user: UserSimple) => user.id === action.pilote?.cerbere_id,
            ) : null
            const copilote = action?.copilote ? usersResponse.find(
              (user: UserSimple) => user.id === action.copilote?.cerbere_id,
            ) : null
            return {
              ...action,
              pilote,
              copilote,
            }
          })),
        })),
      )
      return responseWithUrl
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const createPortfolio = createAsyncThunk(
  'create/portfolio',
  async (_, thunkApi) => {
    try {
      // TODO: add params to create portfolio next time
      const response = await post('/robustest/portefeuilles/', {
        libelle: 'Portefeuille sup entity',
        entite: 'b76dc0f3-f281-4bf8-a564-553a8abd5d0d',
        batiments_voyageurs: [
          '73cfa6ad-70a8-4303-a36d-12dbed679d2e',
          'fc53364b-7dd5-47ed-9d53-fa488eed237b',
          'cc860c4c-1fa3-4297-91b1-39ab579bdff1',
          'c3429cf5-18f0-4e59-83e0-9601f41273c9',
          '265085a0-55ae-48cd-9b68-5271011f7634',
        ],
        lignes: [],
        travaux: [],
        generic: true,
      })
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export {
  getTerritory,
  getPossibleYears,
  getMonitoringDzp,
  getPortfolioSimple,
  getSupervisionEf,
  createPortfolio,
}
