import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Tab } from '@mui/material'
import { StyledTabs } from 'components/Jalons/JalonStyle'
import { GiCircle, GiPlainCircle } from 'react-icons/gi'
import { UserMeRoles } from 'reducers/Users/types'
import {
  resetComponentList, setActiveJalonNumber, setFilterContributor, setJalonStatus, setActiveTab,
} from 'reducers/Suivi/suivi.reducer'
import { getSAJalonByNumber } from 'reducers/Suivi/suivi.thunk'
import { RootState } from 'Store'
import a11yProps from 'components/Jalons/utils'
import Loader from 'components/Loader'
import JalonSaContent from './JalonSaContent'
import { JalonStatus, Status } from './JalonStatus'

export default function JalonsSa({ selectedSa }: {selectedSa: UserMeRoles}): ReactElement {
  const dispatch = useDispatch()
  const { idSA } = useSelector((state: RootState) => state.app)
  const { account } = useSelector((state: RootState) => state.user)
  const { activeJalonNumber, activeTab, isLoadingJalon } = useSelector((state: RootState) => state.suivi)

  const updateComponents = (jalon: number) => {
    dispatch(resetComponentList())
    dispatch(getSAJalonByNumber({
      idSa: idSA,
      jalon,
      activeTabObject: activeTab,
    }))
  }

  const handleChangeJalonNumber = (newValue: number) => {
    updateComponents(newValue)
    dispatch(setFilterContributor({
      contributor: null,
      accountId: account.id,
    }))
    const activeJalon = selectedSa?.jalons.find(jalon => jalon.numero_jalon === newValue)
    dispatch(setJalonStatus(Status.find(s => s.status === activeJalon?.status)?.libelle || ''))
  }

  const getTabIcon = (numJalon: number) => {
    const activeJalon = selectedSa?.jalons.filter(jalon => jalon.numero_jalon === numJalon)[0]
    return activeJalon?.status === JalonStatus.Coming ? <GiCircle /> : <GiPlainCircle />
  }

  const getTabColor = (jalonStatus: string) => {
    const jalonColor = Status.filter(s => s.status === jalonStatus)[0]?.color
    return jalonColor !== '' ? jalonColor : '#333'
  }

  const handleChangeJalonValue = (newValue: number) => {
    dispatch(setActiveJalonNumber(newValue))
    dispatch(setActiveTab(0))
    handleChangeJalonNumber(newValue)
  }

  return (
    <div className="d-flex">
      <Box
        sx={{
          flexGrow: 1, bgcolor: 'background.paper', display: 'flex',
        }}
        style={{ height: 'calc(100vh - var(--topbar-height) - 54px)', width: '100%' }}
        className="suivi-jalon-side-tabs d-flex"
      >
        <StyledTabs
          value={activeJalonNumber}
          onChange={(_e, newValue) => handleChangeJalonValue(newValue)}
          {...{ orientation: 'vertical' }}
        >
          {selectedSa?.jalons.map(jalon => (
            <Tab
              disableRipple
              key={jalon.numero_jalon}
              icon={getTabIcon(jalon.numero_jalon)}
              label={`J${jalon.numero_jalon}`}
              {...a11yProps(activeJalonNumber)}
              className="jalon-tab-button"
              style={{
                color: getTabColor(jalon.status),
              }}
            />
          ))}
        </StyledTabs>
        {selectedSa
          && (

            isLoadingJalon ? (
              <Loader />
            ) : (
              <JalonSaContent
                valueJ={activeJalonNumber}
                selectedSa={selectedSa}
                updateComponents={updateComponents}
              />
            )

          )}
      </Box>
    </div>
  )
}
