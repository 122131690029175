import { Modal, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ReactElement } from 'react'

import './ModalWrapper.scss'

interface Props {
  children: ReactElement;
  className?: string;
  open: boolean;
  onClose: () => void;
  variant?: 'primary';
  title?: string;
}

const ModalWrapper = ({
  children, className, open, onClose, title, variant,
}: Props): ReactElement => (
  <Modal
    open={open}
    onClose={onClose}
    className={className}
  >
    <Box className={`custom-modal ${className}`}>
      {
        title && (
          <div className={`custom-modal-header ${variant}`}>
            <h2>
              {title}
            </h2>
            <button
              type="button"
              onClick={onClose}
              tabIndex={0}
            >
              <CloseIcon />
            </button>
          </div>
        )
      }

      <div className={`custom-modal-content ${variant}`}>
        {children}
      </div>
    </Box>

  </Modal>
)

ModalWrapper.defaultProps = {
  className: '',
  title: undefined,
  variant: '',
}

export default ModalWrapper
