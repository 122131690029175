import terms from 'common/terms'
import PopOver from 'components/PopOver/PopOver'
import { ReactElement } from 'react'

import './Composite.scss'

interface Props {
  numerator: number | null;
  denominator: number;
}

const Composite = ({ numerator, denominator }: Props): ReactElement => (
  <div className="composite">
    <PopOver
      popContent={terms.Suivi.GlobalContribution.PopOver.composite}
    >
      <span>
        {numerator || '?'}
      </span>
      <span>
        /
      </span>
      <span>
        {denominator}
      </span>
    </PopOver>

  </div>
)

export default Composite
