import { RootState } from 'Store'
import terms from 'common/terms'
import Accordion from 'components/Accordion/Accordion'
import CustomSnackbar from 'components/Snackbar/Snackbar'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addAxe, deleteNewAxe, hideSnackbar } from 'reducers/Admin/Axes/axes.reducer'
import AxeDetail from './AxeDetail'

const AxesAccordion = (): ReactElement => {
  const dispatch = useDispatch()
  const { axesList, displaySnackbar, isLoadingAxes } = useSelector((state : RootState) => state.axe)

  return (
    <>
      <Accordion
        name={terms.Admin.axes.name}
        elementList={axesList}
        onAddEntity={() => dispatch(addAxe())}
        onDeleteEntity={() => dispatch(deleteNewAxe())}
        isLoading={isLoadingAxes}
        newElementWording={terms.Admin.axes.new}
        displaySnackbar={displaySnackbar}
      >
        {axe => (
          <AxeDetail axe={axe} />
        )}
      </Accordion>
      <CustomSnackbar
        message={terms.Admin.eic.validationNotice}
        displaySnackbar={displaySnackbar}
        handleClose={() => dispatch(hideSnackbar())}
      />
    </>
  )
}

export default AxesAccordion
