import { FormControlLabel, FormGroup, Checkbox } from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { SendMailUser } from 'reducers/Suivi/types'
import { getRoleLabel } from '../utils'

interface TargetSelectorProps {
  handleSelectAll: () => void
  handleUnselectAll: () => void
  handleChangeUser: (users: SendMailUser) => void
  possibleUsers: SendMailUser[]
  selectedUsers: SendMailUser[]
}

const getUserLabel = (user: SendMailUser) => `
${user.lastName.toUpperCase()} ${user.firstName} (${getRoleLabel(user.role)} - ${user.fonction})
`

const TargetSelector = ({
  handleSelectAll, handleUnselectAll, handleChangeUser, possibleUsers, selectedUsers,
}: TargetSelectorProps): ReactElement => (
  <div className="target-selector w-100">
    <div className="target-selector-actions d-flex mb-2">
      <div className="pointer" onClick={handleSelectAll} role="button" tabIndex={0}>
        {terms.Suivi.mailTerms.selectAll}
      </div>
      <div className="pointer ml-2" onClick={handleUnselectAll} role="button" tabIndex={0}>
        {terms.Suivi.mailTerms.unselectAll}
      </div>
    </div>
    <FormGroup>
      {possibleUsers.sort((a, b) => getUserLabel(a).localeCompare(getUserLabel(b)))
        .map(user => (
          <FormControlLabel
            key={user.id}
            className="target-selector-item"
            control={(
              <Checkbox
                checked={selectedUsers.some(u => u.id === user.id)}
                onChange={() => { handleChangeUser(user) }}
              />
            )}
            label={getUserLabel(user)}
          />
        ))}
    </FormGroup>

  </div>
)

export default TargetSelector
