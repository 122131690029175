import { Box, Modal } from '@mui/material'
import {
  ChangeEvent, ReactElement, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { RootState } from 'Store'
import terms from 'common/terms'
import ModalCloseButton from 'components/Modal/ModalCloseButton/ModalCloseButton'
import {
  selectAllUsers, setSelectedSavedMail, setSelectedUsers, unselectAllUsers,
} from 'reducers/Suivi/suivi.reducer'
import { deleteSaSavedMail, postSaSavedMail } from 'reducers/Suivi/suivi.thunk'
import { Mail, SendMailUser } from 'reducers/Suivi/types'
import AdressPicker from './AdressPicker'
import MailForm from './MailForm'
import SendMailActions from './SendMailActions'
import './sendMail.scss'

const CHAR_LIMIT = 1750

interface SendMailProps {
  openModal: boolean
  handleClose: () => void
  saId: string
}

const SendMail = ({
  openModal, handleClose, saId,
}: SendMailProps): ReactElement => {
  const dispatch = useDispatch()
  const {
    filteredUsers, selectedUsers, selectedSavedMail, savedMails,
  } = useSelector((state: RootState) => state.suivi.sendMailState)

  const [mailObject, setMailObject] = useState<string>('')
  const [mailContent, setMailContent] = useState<string>('')
  const [characterCount, setCharacterCount] = useState<number>(0)

  const updateMail = (object: string, content: string) => {
    setMailObject(object)
    setMailContent(content)
  }

  const handleChangeUser = (user: SendMailUser): void => {
    if (selectedUsers.some(u => u.id === user.id)) {
      dispatch(setSelectedUsers(selectedUsers.filter(u => u.id !== user.id)))
    } else {
      dispatch(setSelectedUsers([...selectedUsers, user]))
    }
  }
  const handleSelectAll = () => dispatch(selectAllUsers())
  const handleUnselectAll = () => dispatch(unselectAllUsers())

  const handleMailObjectChange = (_event: React.SyntheticEvent, value: string | Mail | null, reason: string) => {
    if (reason === 'clear') {
      dispatch(setSelectedSavedMail(null))
      updateMail('', '')
    } else if (typeof value === 'string') {
      const mail = savedMails.find(m => m.objet === value)
      if (mail) {
        dispatch(setSelectedSavedMail(mail))
        updateMail(mail.objet, mail.contenu)
      } else {
        setMailObject(value)
      }
    } else if (value) {
      dispatch(setSelectedSavedMail(value))
      updateMail(value.objet, value.contenu)
    }
  }
  const handleMailContentChange = (event: ChangeEvent<HTMLTextAreaElement>) => setMailContent(event.target.value)

  const handlePostMail = () => {
    dispatch(postSaSavedMail({
      idSA: saId,
      mail: {
        objet: mailObject,
        contenu: mailContent,
      },
    }))
  }
  const disabledPostMail = () => mailObject === '' || mailContent === ''

  const handleRefreshMail = () => {
    if (selectedSavedMail) {
      updateMail(selectedSavedMail.objet, selectedSavedMail.contenu)
    }
  }
  const disabledRefreshMail = () => {
    if (selectedSavedMail && selectedSavedMail.objet === mailObject) {
      return selectedSavedMail.contenu === mailContent
    }
    return true
  }

  const handleDeleteMail = () => {
    if (selectedSavedMail) {
      dispatch(deleteSaSavedMail({
        idSA: saId,
        idMail: selectedSavedMail.id,
      }))
      updateMail('', '')
    }
  }
  const disabledDeleteMail = () => selectedSavedMail === null

  const handleSend = () => {
    const mailto = `mailto:${selectedUsers.map(u => u.email).join(';')}?subject=${mailObject}&body=${mailContent}`
    window.open(mailto)
  }

  const disabledSend = () => selectedUsers.length === 0 || characterCount > CHAR_LIMIT
  || mailObject === ''

  useEffect(() => {
    const mailUsersString = selectedUsers.map(u => u.email).join(';')
    setCharacterCount(mailUsersString.length + mailObject.length + mailContent.length)
  }, [selectedUsers, mailObject, mailContent])

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
    >
      <Box className="send-mail-container">
        <ModalCloseButton handleClose={handleClose} className="close-modal" />
        <div className="modal-header d-flex flex-column align-items-center">
          <FontAwesomeIcon icon={faEnvelope} className="title-icon" />
          <strong className="title mt-3">
            {terms.Suivi.mailTerms.sendMail}
          </strong>
        </div>
        <div className="modal-content">
          <AdressPicker
            possibleUsers={[...filteredUsers]}
            selectedUsers={[...selectedUsers]}
            handleChangeUser={handleChangeUser}
            handleSelectAll={handleSelectAll}
            handleUnselectAll={handleUnselectAll}
          />
          <MailForm
            mailContent={mailContent}
            mailObject={mailObject}
            savedMails={savedMails}
            handleMailContentChange={handleMailContentChange}
            handleMailObjectChange={handleMailObjectChange}
            handlePostMail={handlePostMail}
            handleRefreshMail={handleRefreshMail}
            handleDeleteMail={handleDeleteMail}
            disabledDelete={disabledDeleteMail()}
            disabledRefresh={disabledRefreshMail()}
            disabledSave={disabledPostMail()}
          />
          <SendMailActions
            characterCount={characterCount}
            characterLimit={CHAR_LIMIT}
            disabledSend={disabledSend()}
            handleSend={handleSend}
          />
        </div>
      </Box>
    </Modal>
  )
}

export default SendMail
