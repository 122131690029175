import { CancelRounded } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { forwardRef } from 'react'
import { Composant } from 'reducers/Admin/Composant/types'
import { Entity } from 'reducers/Admin/Entity/types'
import './composantChip.scss'

interface ComposantChipProps {
  composant: Composant | Entity,
  handleDelete: () => void,
  isAdded: boolean,
  readOnly?: boolean,
}

const ComposantChip = forwardRef<HTMLDivElement, ComposantChipProps>(({
  composant, handleDelete, isAdded, readOnly,
}: ComposantChipProps, ref) => (
  <div
    className={`d-flex composant-chip ${isAdded ? 'highlight' : ''}`}
    key={composant.id}
    ref={ref}
  >
    {
      !readOnly && (
        <CancelRounded className="delete-icon" onClick={handleDelete} />
      )
    }
    <Typography className="composant-label">
      {`${(composant as Composant).id}.${composant.libelle}`}
    </Typography>
  </div>
))

ComposantChip.displayName = 'ComposantChip'

ComposantChip.defaultProps = {
  readOnly: false,
}

export default ComposantChip
