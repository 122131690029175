/* eslint-disable @typescript-eslint/no-explicit-any */
import { get, post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { GroupListItem, Role, User } from './cerbereTypes'
import URI from '../uri'
import { UserMeRoles, UserSimple } from './types'

type GetGroupMembersParams = {
  id: string;
  role: Role;
}
type GetGroupMembersRes = {
  role: Role;
  results: User[];
}

// eslint-disable-next-line import/no-mutable-exports
export let controller = new AbortController()

const getAll = createAsyncThunk(
  'users/getAll',
  async (search: string, thunkApi) => {
    try {
      controller = new AbortController()
      const { signal } = controller
      const response = await get(`/${URI.cerbereApi}/${URI.users}/`, {
        search,
        ordering: 'lastName',
      }, undefined,
      {
        signal,
      })
      return response.results as User[]
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getUserInfos = createAsyncThunk(
  'users/getUserInfos',
  async (userId: string, thunkApi) => {
    try {
      const response: User[] = await post(`/${URI.cerbereApi}/${URI.users}/simple_with_email/`, [userId])
      return response.find((user: User) => user.id === userId)
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGroups = createAsyncThunk(
  'users/getGroups',
  async (_unused, thunkApi) => {
    try {
      const response: GroupListItem[] = await get(`/${URI.cerbereApi}/groups/`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGroupMembers = createAsyncThunk(
  'users/getGroupMembers',
  async ({ id, role } : GetGroupMembersParams, thunkApi) => {
    try {
      const response: User[] = await get(`/${URI.cerbereApi}/groups/${id}/members/`)
      return {
        results: response,
        role,
      } as GetGroupMembersRes
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getUsersListInfo = createAsyncThunk(
  'users/getUsersListInfo',
  async (users: string[], thunkApi) => {
    try {
      const response: UserSimple[] = await post(`/${URI.cerbereApi}/users/simple/`, [
        ...users,
      ], {
        params: {
          ordering: 'lastName',
        },
      })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
const getUserMe = createAsyncThunk(
  'users/getUserMe',
  async (_, thunkApi) => {
    try {
      const response = await get('/robustest/users/me/territoires/')
      return response
    } catch (e:any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getUserMeAxe = createAsyncThunk(
  'users/getUserMe/axe',
  async (_, thunkApi) => {
    try {
      const response = await get('/robustest/users/me/axes/')
      return response
    } catch (e:any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getUserMeSettings = createAsyncThunk(
  'users/getUserMe/settings',
  async (_, thunkApi) => {
    try {
      const response: UserMeRoles[] = await get('/robustest/users/me/paramsa/')
      return response
    } catch (e:any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getUserMeSuivi = createAsyncThunk(
  'users/getUserMe/suivi',
  async (_, thunkApi) => {
    try {
      const response: UserMeRoles[] = await get('/robustest/users/me/suivisa/')
      return response
    } catch (e:any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getUserMePlanAction = createAsyncThunk(
  'users/getUserMe/planaction',
  async (_, thunkApi) => {
    try {
      const response: UserMeRoles[] = await get('/robustest/users/me/planaction/')
      return response
    } catch (e:any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getUserMeEntitySupervisor = createAsyncThunk(
  'users/getUserMe/entityAnimator',
  async (_, thunkApi) => {
    try {
      const response = await get('/robustest/users/me/superviseur/')
      return response
    } catch (e:any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getUserMeAxeToggle = createAsyncThunk(
  'users/getUserMe/axeToggle',
  async (_, thunkApi) => {
    try {
      const response = await get('/robustest/users/me/axe-toggle/')
      return response
    } catch (e:any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postUserMeAxeToggle = createAsyncThunk(
  'users/postUserMe/axeToggle',
  async (params: boolean, thunkApi) => {
    try {
      const response = await post('/robustest/users/me/axe-toggle/', {
        axe_toggle: params,
      })
      return response
    } catch (e:any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getUserMeActionAxeToggle = createAsyncThunk(
  'users/getUserMe/actionAxeToggle',
  async (_, thunkApi) => {
    try {
      const response = await get('/robustest/users/me/action-axe/')
      return response
    } catch (e:any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postUserMeActionAxeToggle = createAsyncThunk(
  'users/postUserMe/actionAxeToggle',
  async (params: boolean, thunkApi) => {
    try {
      const response = await post('/robustest/users/me/action-axe/', {
        action_axe: params,
      })
      return response
    } catch (e:any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getUserConnect = createAsyncThunk(
  'users/getUserConnect',
  async (_, thunkApi) => {
    try {
      const response = await get('/robustest/users/me/connect/')
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export {
  getAll,
  getGroups,
  getGroupMembers,
  getUsersListInfo,
  getUserInfos,
  getUserMe,
  getUserMeSettings,
  getUserMeSuivi,
  getUserMePlanAction,
  getUserMeEntitySupervisor,
  getUserMeAxe,
  getUserMeAxeToggle,
  postUserMeAxeToggle,
  getUserMeActionAxeToggle,
  postUserMeActionAxeToggle,
  getUserConnect,
}
