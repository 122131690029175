import { get, patch, post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import URI from 'reducers/uri'
import { Axe } from './types'

const getAxes = createAsyncThunk(
  'axes/get',
  async (_, thunkApi) => {
    try {
      const response: Axe[] = await get('/robustest/axe/')
      await Promise.all(response.map(async result => {
        if (result.animateurs.length > 0) {
          const animateurs = await post(`/${URI.cerbereApi}/${URI.users}/simple`,
            result.animateurs.map(item => item?.cerbere_id || ''), {
              params: {
                ordering: 'lastName',
              },
            })
          result.animateurs = animateurs
          result.visiteurs = []
        }
      }))
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postAxe = createAsyncThunk(
  'axes/post|patch',
  async (axe: Partial<Axe>, thunkApi) => {
    try {
      if (axe.id === 'new') {
        const response = await post('/robustest/axe/', axe) as Axe
        thunkApi.dispatch(getAxes())
        return response
      }
      const response = await patch(`/robustest/axe/${axe.id}`, axe) as Axe
      thunkApi.dispatch(getAxes())
      return response

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export {
  getAxes,
  postAxe,
}
