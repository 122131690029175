import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getContributionsStateAfterRefreshWithObject,
  getContributionsStateAfterRefresh,
} from 'reducers/Contributions/contributions.thunk'
import { ContributionsSaveState } from 'reducers/Contributions/types'
import { ComposantObject, ObjectFerTab } from 'reducers/SA/types'
import { UserMeRoles } from 'reducers/Users/types'
import { RootState } from 'Store'

type useRefreshContributionsHandlerProps = {
  activeJalon: number | null,
  activeComponent: ComposantObject | null,
  activeSa: UserMeRoles | null,
  activeObjFer: ObjectFerTab | null,
}

const setSaveState = (
  activeJalon: number | null, activeComponent: ComposantObject | null,
  activeSa: UserMeRoles | null, activeObjFer: ObjectFerTab | null,
): ContributionsSaveState | null => {
  if (activeJalon !== null && activeComponent && activeComponent.id && activeSa) {
    return {
      numJalon: activeJalon,
      idComponent: activeComponent.id,
      idSa: activeSa.id,
      idObjFer: activeObjFer?.id || null,
      typeObjFer: activeObjFer?.type || null,
    }
  }
  return null
}

const useRefreshContributionsHandler = ({
  activeJalon,
  activeComponent,
  activeSa,
  activeObjFer,
}: useRefreshContributionsHandlerProps): void => {
  const dispatch = useDispatch()
  const { userMeSuivi } = useSelector((state: RootState) => state.users)
  const [contributionsSaveState, setContributionsSaveState] = useState<ContributionsSaveState | null>(setSaveState(
    activeJalon, activeComponent, activeSa, activeObjFer,
  ))

  useEffect(() => {
    if (!contributionsSaveState) {
      const stateToRestore: ContributionsSaveState = JSON.parse(localStorage.getItem('contributionSaveState') || '{}')
      const stateSa = userMeSuivi.find(sa => sa.id === stateToRestore?.idSa) || null
      if (stateToRestore && stateSa) {
        if (stateToRestore.idObjFer && stateToRestore.typeObjFer) {
          dispatch(getContributionsStateAfterRefreshWithObject({
            ...stateToRestore,
            idObjFer: stateToRestore.idObjFer,
            typeObjFer: stateToRestore.typeObjFer,
            activeSa: stateSa,
          }))
        } else {
          dispatch(getContributionsStateAfterRefresh({
            ...stateToRestore,
            activeSa: stateSa,
          }))
        }
      }
    }
  }, [userMeSuivi])

  useEffect(() => {
    setContributionsSaveState(setSaveState(activeJalon, activeComponent, activeSa, activeObjFer))
  }, [activeJalon, activeComponent, activeSa, activeObjFer])

  useEffect(() => {
    if (contributionsSaveState) {
      localStorage.setItem('contributionSaveState', JSON.stringify(contributionsSaveState))
    }
  }, [contributionsSaveState])
}

export default {
  useRefreshContributionsHandler,
}
