/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit'
import {
  getPonderationCotation,
  getPonderationList,
  patchPonderationCotation,
  postPonderationComposant,
} from './ponderation.thunk'
import { PonderationItem, PonderationState } from './types'

const initialState: PonderationState = {
  ponderationList: [] as PonderationItem[],
  cotationsList: [],
}

export const gravity = createSlice({
  name: 'gravity',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getPonderationList.fulfilled, (state, { payload }) => {
      state.ponderationList = payload.reduce((transformedData, item) => {
        const {
          num_composant, libelle, type, poids,
        } = item

        const existingItem: PonderationItem | undefined = transformedData.find(
          (resultItem: PonderationItem) => resultItem.num_composant === num_composant,
        )

        if (existingItem) {
          existingItem.typeObject.push({ type, poids })
        } else {
          transformedData.push({
            num_composant,
            libelle,
            typeObject: [{ type, poids }],
          })
        }

        return transformedData
      }, [] as PonderationItem[]) // Ajoutez un type explicite ici
    })
    builder.addCase(getPonderationCotation.fulfilled, (state, { payload }) => {
      state.cotationsList = payload
    })
    builder.addCase(patchPonderationCotation.fulfilled, (state, { payload }) => {
      state.cotationsList = state.cotationsList.map(item => {
        if (item.id === payload.id) {
          return payload
        }
        return item
      })
    })
    builder.addCase(postPonderationComposant.fulfilled, (state, { payload }) => {
      state.ponderationList = state.ponderationList.map(item => {
        if (item.num_composant === payload.num_composant) {
          return ({
            ...item,
            typeObject: item.typeObject.map(type => {
              if (type.type === payload.type) {
                return ({
                  ...type,
                  poids: payload.poids,
                })
              }
              return type
            }),
          })
        }
        return item
      }) as PonderationItem[]
    })
  },
})

export default gravity.reducer
