import { RootState } from 'Store'
import terms from 'common/terms'
import Loader from 'components/Loader'
import ModalConfirmation from 'components/Modal/ModalConfirmation/ModalConfirmation'
import ModalError from 'components/Modal/ModalError/ModalError'
import ModalUserInfos from 'components/Modal/ModalUserInfos/ModalUserInfos'
import CustomSnackbar from 'components/Snackbar/Snackbar'
import { typeObject } from 'pages/Suivi/utils'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getComposants } from 'reducers/Admin/admin.thunk'
import {
  closeAddModal, closeDeleteModal, closeErrorModal, closePatchModal,
  hideSnackbar,
  resetNewContribution, setSelectedContribution,
} from 'reducers/Contributions/contributions.reducer'
import {
  deleteContribution, getContributions, getDedoublementObjects, getSaEntities, patchContribution, postContribution,
} from 'reducers/Contributions/contributions.thunk'
import { getSaDeciders } from 'reducers/Parametrage/Deciders/deciders.thunks'
import { UserRole } from 'reducers/Users/types'
import { setDisplayUserInfos } from 'reducers/Users/users.reducer'
import { postUserMeAxeToggle } from 'reducers/Users/users.thunk'
import ComponentNavigator from './ComponentNavigator'
import ContributionEditor from './ContributionEditor'
import ContributionsHeader from './ContributionsHeader'
import ContributionsList from './ContributionsList'
import './contributions.scss'
import hooks from './hooks'

export default function Contributions(): ReactElement {
  const dispatch = useDispatch()
  const {
    activeSa, activeObjFer, activeJalon, activeComponent, componentList, isLoading, contributionList, possibleAnimators,
    contributionHistory, selectedContribution, newContribution, addModal, selectedContributor, contributorList,
    contributionToDeleteId, deleteModal, modifiedContribution, patchModal, entitiesVisibility, modalError, errors,
    snackbar, snackbarMessage, isLoadingContribution,
  } = useSelector((state: RootState) => state.contributions)
  const { isAxeToggle } = useSelector((state: RootState) => state.users)
  const { userMeSuivi, displayUserInfos } = useSelector((state: RootState) => state.users)
  const { account } = useSelector((state: RootState) => state.user)
  const [switchAllContribution, setSwitchAllContribution] = useState<boolean>(isAxeToggle)

  hooks.useRefreshContributionsHandler({
    activeJalon, activeComponent, activeSa, activeObjFer,
  })

  useEffect(() => {
    dispatch(getComposants())
    dispatch(resetNewContribution())
    dispatch(setSelectedContribution(null))
  }, [])

  const getOrigneSA = () => {
    if (activeSa && activeSa.roles.some(role => [
      UserRole.admin, UserRole.animator, UserRole.animatorAxe].includes(role))) {
      return activeObjFer?.sa_origine || null
    }
    return null
  }

  useEffect(() => {
    if (activeSa) {
      dispatch(getSaEntities({
        activeSA: activeSa.id,
        origineSA: getOrigneSA(),
      }))
      if (activeSa.roles.some(role => [UserRole.admin, UserRole.animator, UserRole.animatorAxe].includes(role))) {
        dispatch(getSaDeciders(activeSa.id))
      }
    }
  }, [activeSa, activeObjFer])

  useEffect(() => {
    if (activeSa && activeComponent?.id && activeJalon) {
      dispatch(getDedoublementObjects({
        annee: activeSa.annee,
        jalon: activeJalon,
        composant: activeComponent.id,
      }))
    }
  }, [activeSa, activeComponent, activeJalon])

  useEffect(() => {
    if (
      activeSa && activeJalon !== null && activeComponent && activeComponent.id
      && (activeJalon === 0 || activeObjFer !== null)
    ) {
      dispatch(getContributions({
        idSa: activeSa.id,
        numJalon: activeJalon,
        typeObj: activeObjFer !== null ? typeObject(activeObjFer.type) : null,
        idObj: activeObjFer !== null ? activeObjFer.id : null,
        idComponent: activeComponent.id,
      }))
    }
  }, [activeSa, activeJalon, activeObjFer, activeComponent, isAxeToggle])

  const handlePostContribution = () => {
    if (
      activeSa && activeJalon !== null && activeComponent && activeComponent.id
      && (activeJalon === 0 || activeObjFer !== null) && newContribution
    ) {
      dispatch(postContribution({
        idSa: activeSa.id,
        numJalon: activeJalon,
        typeObj: activeObjFer !== null ? typeObject(activeObjFer.type) : null,
        idObj: activeObjFer !== null ? activeObjFer.id : null,
        idComponent: activeComponent.id,
        newContribution,
      }))
      dispatch(closeAddModal())
    }
  }

  const handleDeleteContribution = () => {
    if (
      activeSa && activeJalon !== null && activeComponent && activeComponent.id
      && (activeJalon === 0 || activeObjFer !== null) && contributionToDeleteId
    ) {
      dispatch(deleteContribution({
        idSa: activeSa.id,
        numJalon: activeJalon,
        typeObj: activeObjFer !== null ? typeObject(activeObjFer.type) : null,
        idObj: activeObjFer !== null ? activeObjFer.id : null,
        idComponent: activeComponent.id,
        idContribution: contributionToDeleteId,
      }))
      dispatch(closeDeleteModal())
    }
  }

  const handlePatchContribution = () => {
    if (
      activeSa && activeJalon !== null && activeComponent && activeComponent.id
      && (activeJalon === 0 || activeObjFer !== null) && modifiedContribution && selectedContribution
    ) {
      dispatch(patchContribution({
        idContribution: selectedContribution.id,
        idSa: activeSa.id,
        numJalon: activeJalon,
        typeObj: activeObjFer !== null ? typeObject(activeObjFer.type) : null,
        idObj: activeObjFer !== null ? activeObjFer.id : null,
        idComponent: activeComponent.id,
        contribution: modifiedContribution,
      }))
    }
    dispatch(closePatchModal())
  }

  const canEditContribution = (): boolean => {
    if (activeComponent?.read_only) {
      return false
    }
    if (selectedContribution !== null && activeJalon !== null && selectedContribution.numero_jalon < activeJalon) {
      return false
    }
    const sa = userMeSuivi.find(s => s.id === activeSa?.id)
    const jalonStatus = sa?.jalons.find(j => j.numero_jalon === activeJalon)?.status
    if (sa && jalonStatus) {
      const correctJalonStatus = jalonStatus === 'EC'
      || (jalonStatus === 'ECV' && sa.roles.some(
        r => [
          UserRole.admin,
          UserRole.animator,
          ...(activeObjFer?.axes.length ? [] : [UserRole.animatorAxe]),
        ].includes(r),
      ))
      const correctUser = selectedContribution === null
      || selectedContribution.auteur?.id === account.id
      || sa.roles.includes(UserRole.admin)
      return correctJalonStatus && correctUser
    }
    return false
  }

  const handleSwitchAllContribution = () => {
    setSwitchAllContribution(!switchAllContribution)
    dispatch(postUserMeAxeToggle(!switchAllContribution))
  }

  return (
    <>
      {isLoading ? <Loader message={terms.Common.loading} /> : (
        <div id="contributions" className="d-flex flex-column align-items-center">
          <ContributionsHeader
            activeJalon={activeJalon}
            activeObjFer={activeObjFer}
            activeSa={activeSa}
            switchAllContribution={switchAllContribution}
            handleSwitchAllContribution={handleSwitchAllContribution}
          />
          <div className="contributions-main-container d-flex w-100">
            <div className="panel left-panel d-flex flex-column">
              <ComponentNavigator
                activeJalon={activeJalon}
                activeComponent={activeComponent}
                componentList={componentList}
                selectedContributor={selectedContributor}
                contributorList={contributorList}
              />
              {
                isLoadingContribution ? (
                  <Loader message={terms.Common.loading} />
                ) : (
                  <ContributionsList
                    activeJalon={activeJalon}
                    jalonContributions={contributionList}
                    history={contributionHistory}
                    selectedContributor={selectedContributor}
                  />
                )
              }
            </div>
            <div className="panel right-panel flex-column p-3">
              <ContributionEditor
                activeJalon={activeJalon}
                activeComponent={activeComponent}
                selectedContribution={selectedContribution}
                newContribution={newContribution}
                editable={canEditContribution()}
                entitiesVisibility={entitiesVisibility}
                activeSa={activeSa}
                possibleAnimators={possibleAnimators}
                activeObjFer={activeObjFer}
              />
            </div>
          </div>
        </div>
      )}
      <ModalConfirmation
        onModal={addModal}
        content1={terms.Contributions.modalConfirmation.content1}
        content2={terms.Contributions.modalConfirmation.content2Add}
        handleConfirmClick={handlePostContribution}
        handleClose={() => dispatch(closeAddModal())}
      />
      <ModalConfirmation
        onModal={deleteModal}
        content1={terms.Contributions.modalConfirmation.content1}
        content2={terms.Contributions.modalConfirmation.content2Delete}
        handleConfirmClick={handleDeleteContribution}
        handleClose={() => dispatch(closeDeleteModal())}
      />
      <ModalConfirmation
        onModal={patchModal}
        content1={terms.Contributions.modalConfirmation.content1}
        content2={terms.Contributions.modalConfirmation.content2Patch}
        handleConfirmClick={handlePatchContribution}
        handleClose={() => dispatch(closePatchModal())}
      />
      <ModalError
        onModal={modalError}
        content={errors?.data?.detail}
        handleClose={() => dispatch(closeErrorModal())}
      />

      <ModalUserInfos
        onModal={displayUserInfos}
        handleClose={() => dispatch(setDisplayUserInfos(false))}
      />

      <CustomSnackbar
        message={snackbarMessage}
        displaySnackbar={snackbar}
        handleClose={() => dispatch(hideSnackbar())}
      />
    </>
  )
}
