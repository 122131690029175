import { createAsyncThunk } from '@reduxjs/toolkit'
import { get, patch } from '@osrdata/app_core/dist/requests'
import { Complexes } from './types'

// eslint-disable-next-line import/no-mutable-exports
export let controllerComplexes = new AbortController()
const searchComplexes = createAsyncThunk(
  'complexes/get',
  async (params: string, thunkApi) => {
    try {
      controllerComplexes = new AbortController()
      const { signal } = controllerComplexes
      const response = await get('/robustest/batiments-voyageurs/', { libelle: params }, undefined, { signal })
      return { search: params, complexes: response as Complexes[] }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const patchComplexes = createAsyncThunk(
  'complexes/patch',
  async (params: { complexe: Partial<Complexes>, search: string }, thunkApi) => {
    try {
      const response = await patch(`/robustest/batiments-voyageurs/${params.complexe.id}`, params.complexe)
      thunkApi.dispatch(searchComplexes(params.search))
      return response as Complexes

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export {
  searchComplexes,
  patchComplexes,
}
