import { cotationInfo } from 'common/cotation'
import terms from 'common/terms'
import PopOver from 'components/PopOver/PopOver'
import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { patchPonderationCotation } from 'reducers/Admin/Ponderation/ponderation.thunk'
import { CommonProps } from 'reducers/Admin/Ponderation/types'
import { RootState } from 'Store'
import PonderationInput from '../PonderationInput/PonderationInput'

const CotationDetails = ({ error, setError, checkInput }: CommonProps): ReactElement => {
  const dispatch = useDispatch()
  const { cotationsList } = useSelector((state: RootState) => state.ponderation)
  const [cotations, setCotations] = useState(cotationsList)

  const handleChange = (inputValue: string, itemId: string) => {
    setCotations(prevState => prevState.map(cotation => {
      if (cotation.id === itemId) {
        return {
          ...cotation,
          score: parseInt(inputValue, 10),
        }
      }
      return cotation
    }))
    if (!checkInput(inputValue)) {
      setError([
        ...error,
        {
          id: itemId,
        },
      ])
      return
    }
    setError(error.filter(err => err.id !== itemId))
    dispatch(patchPonderationCotation({
      id: itemId,
      score: parseInt(inputValue, 10),
    }))
  }

  return (
    <div className="cotation">
      {
      cotations.map(cotationItem => (
        <div key={cotationItem.id} className="cotation-container">
          <PopOver
            popContent={cotationInfo(cotationItem.cotation)?.rating}
          >
            <span
              className="cotation-item"
              style={{ backgroundColor: cotationInfo(cotationItem.cotation)?.color }}
            >
              {cotationItem.cotation}
            </span>
          </PopOver>

          <div>
            <PonderationInput
              inputValue={cotationItem.score.toString()}
              error={error.some(err => err.id === cotationItem.id)}
              messageError={terms.Admin.ponderation.message.error.text}
              handleChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(
                event.target.value, cotationItem.id,
              )}
            />
          </div>

        </div>
      ))
    }

    </div>
  )
}

export default CotationDetails
