/* eslint-disable camelcase */
import {
  Autocomplete, FormControl, FormLabel,
  TextField,
} from '@mui/material'
import { RootState } from 'Store'
import terms from 'common/terms'
import HighlightMatch from 'components/HighlightMatch'
import { debounce } from 'lodash'
import { ReactElement, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { searchGaiaLignes } from 'reducers/Admin/ConstructionSites/constructionSites.thunk'

interface Props {
  selectedLigne: {libelle: string, code_ligne: string} | null
  setSelectedLigne: (value: {libelle: string, code_ligne: string} | null) => void
  pk: string
  setPk: (value: string) => void
  withLabel?: boolean
}

const SearchGaiaLignes = ({
  selectedLigne,
  setSelectedLigne,
  pk,
  setPk,
  withLabel,
}: Props): ReactElement => {
  const dispatch = useDispatch()
  const { gaiaLignes } = useSelector((state: RootState) => state.constructionSites)
  const debouncedSearch = useCallback(debounce(value => dispatch(searchGaiaLignes(value)), 250), [])
  const [inputLibelle, setInputLibelle] = useState('')
  const [inputCode, setInputCode] = useState('')

  const handleSearchLigne = (input: string, isCode: boolean) => {
    if (isCode) {
      setInputCode(input)
    } else {
      setInputLibelle(input)
    }
    debouncedSearch({
      ...(isCode ? { code_ligne: input } : { libelle: input }),
    })
  }
  return (
    <div className="search-gaia-ligne">
      <FormControl fullWidth>
        {
          withLabel && (
            <FormLabel
              htmlFor="outlined-adornment-amount"
              required
            >
              {terms.SearchGaiaLigne.libelle}
            </FormLabel>
          )
        }
        <Autocomplete
          fullWidth
          disablePortal
          loading={false}
          options={gaiaLignes}
          getOptionLabel={option => option.libelle}
          isOptionEqualToValue={(option, value) => option.libelle === value.libelle}
          noOptionsText={gaiaLignes ? terms.Common.noResults : terms.SearchGaiaLigne.placeholderLibelle}
          value={selectedLigne}
          renderOption={(props, option) => (
            <li {...props} key={option.code_ligne}>
              <HighlightMatch
                optionLabel={option.libelle}
                inputValue={inputLibelle}
              />
            </li>
          )}
          renderInput={params => (
            <TextField
              {...params}
              placeholder={terms.SearchGaiaLigne.placeholderLibelle}
              fullWidth
              id="outlined-controlled"
              size="small"
              label={withLabel ? '' : 'Libellé Ligne'}
            />
          )}
          onInputChange={(_, newInputValue) => handleSearchLigne(newInputValue, false)}
          onChange={(_, value) => setSelectedLigne(value)}
        />
      </FormControl>
      <FormControl fullWidth>
        {
          withLabel && (
            <FormLabel
              htmlFor="outlined-adornment-amount"
              required
            >
              {terms.SearchGaiaLigne.code}
            </FormLabel>
          )
        }
        <Autocomplete
          fullWidth
          disablePortal
          loading={false}
          options={gaiaLignes}
          getOptionLabel={option => option.code_ligne}
          isOptionEqualToValue={(option, value) => option.code_ligne === value.code_ligne}
          noOptionsText={gaiaLignes ? terms.Common.noResults : terms.SearchGaiaLigne.placeholderCode}
          value={selectedLigne}
          renderOption={(props, option) => (
            <li {...props} key={option.code_ligne}>
              <HighlightMatch
                optionLabel={option.code_ligne}
                inputValue={inputCode}
              />
            </li>
          )}
          renderInput={params => (
            <TextField
              {...params}
              placeholder={terms.SearchGaiaLigne.placeholderCode}
              fullWidth
              id="outlined-controlled"
              size="small"
              label={withLabel ? '' : terms.SearchGaiaLigne.code}
            />
          )}
          onInputChange={(_, newInputValue) => handleSearchLigne(newInputValue, true)}
          onChange={(_, value) => setSelectedLigne(value)}
        />
      </FormControl>
      <FormControl fullWidth>
        {
          withLabel && (
            <FormLabel
              htmlFor="outlined-adornment-amount"
              required
            >
              {terms.SearchGaiaLigne.pk}
            </FormLabel>
          )
        }
        <TextField
          fullWidth
          id="outlined-controlled"
          size="small"
          value={pk}
          label={withLabel ? '' : terms.SearchGaiaLigne.pk}
          onChange={e => setPk(e.target.value)}
        />
      </FormControl>
    </div>
  )
}

SearchGaiaLignes.defaultProps = {
  withLabel: false,
}
export default SearchGaiaLignes
