import { ReactElement } from 'react'
import imgConstruct from 'assets/images/page-en-construction.png'

import './StatsCotations.scss'

const StatsCotations = (): ReactElement => (
  <div className="stats-cotations">
    <img src={imgConstruct} alt="En construction" />
  </div>
)

export default StatsCotations
