import PopOver from 'components/PopOver/PopOver'
import { ReactComponent as France } from 'assets/icons/france.svg'
import { ReactElement } from 'react'
import terms from 'common/terms'

type ContentTabObjectProps = {
  type: string,
  abrev: string,
  libelle: string,
  isAxeAccredited: boolean,
}

const ContentTabObject = ({
  type,
  abrev,
  libelle,
  isAxeAccredited,
}: ContentTabObjectProps): ReactElement => (
  <div className="d-flex flex-row">
    <span className="mr-3 type-tab">
      {type}
    </span>
    <PopOver
      popContent={libelle}
    >
      <span className="abrev-tab">
        {abrev}
      </span>
    </PopOver>
    <div className="ml-3">
      {
        isAxeAccredited && (
          <PopOver popContent={terms.Suivi.tabAxe}>
            <France className="axe-icon" />
          </PopOver>
        )
      }
    </div>
  </div>
)

export default ContentTabObject
