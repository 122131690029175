import { Autocomplete, FormLabel, TextField } from '@mui/material'
import terms from 'common/terms'
import { debounce } from 'lodash'
import { ReactElement, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { controller, getAll } from 'reducers/Users/users.thunk'
import { User } from 'reducers/Users/types'
import { RootState } from 'Store'
import HighlightMatch from '../HighlightMatch'

interface UserSearchProps {
  selectedUser: User | null
  placeholder: string
  size: 'small' | 'medium'
  setSelectedUser: (value: User | null) => void
  className?: string
  label?: string
  readOnlyUser?: boolean
  disabled?: boolean
}
const UserSearch = ({
  selectedUser,
  placeholder,
  size,
  setSelectedUser,
  className,
  readOnlyUser,
  label,
  disabled,
}: UserSearchProps): ReactElement => {
  const dispatch = useDispatch()
  const { users, isLoading } = useSelector((state: RootState) => state.users)
  const [userSearch, setUserSearch] = useState('')
  const debouncedSearch = useCallback(debounce(value => dispatch(getAll(value)), 250), [])

  const handleSearch = (value: string) => {
    setUserSearch(value)
    if (disabled !== true && value.length > 2) {
      controller.abort()
      debouncedSearch(value)
    }
  }

  return (
    <div className={`user-search ${className}`}>
      {
        label ? (
          <FormLabel required={!readOnlyUser}>
            {userSearch.length < 3 && userSearch.length !== 0 ? terms.Common.userSearch.condition(label) : label}
          </FormLabel>
        ) : (
          <div className="info">
            {userSearch.length < 3 && userSearch.length !== 0 ? terms.Common.userSearch.condition('') : ''}
          </div>
        )
      }
      <Autocomplete
        disablePortal
        disabled={disabled}
        loading={isLoading}
        options={users}
        filterOptions={options => options}
        getOptionLabel={option => `${option?.lastName?.toUpperCase()} ${option?.firstName}`}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            <HighlightMatch
              optionLabel={option.displayName}
              inputValue={userSearch}
            />
          </li>
        )}
        noOptionsText={userSearch ? terms.Common.noResults : terms.Settings.contributor.noUserSearch}
        value={selectedUser}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={placeholder}
            fullWidth
            size={size}
            error={userSearch.length < 3 && userSearch.length !== 0}
          />
        )}
        onInputChange={(_, newInputValue) => handleSearch(newInputValue)}
        onChange={(_e, newValue) => dispatch(setSelectedUser(newValue))}
      />
    </div>
  )
}

UserSearch.defaultProps = {
  className: '',
  label: '',
  readOnlyUser: false,
  disabled: false,
}

export default UserSearch
