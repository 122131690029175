import { ReactElement } from 'react'

import './CustomTextArea.scss'

interface Props {
  rows: number;
  inputValue: string;
  setInputValue: (inputValue: string) => void;
  disabled: boolean;
  className?: string;
}

const CustomTextArea = ({
  rows,
  inputValue,
  setInputValue,
  disabled,
  className,
}: Props): ReactElement => (
  <textarea
    className={className}
    rows={rows}
    value={inputValue}
    onChange={e => setInputValue(e.target.value)}
    disabled={disabled}
  />
)

CustomTextArea.defaultProps = {
  className: 'custom-text-area',
}

export default CustomTextArea
