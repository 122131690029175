import { HTMLAttributes, ReactElement } from 'react'

interface Props {
  label: string;
  option?: string;
  props: HTMLAttributes<HTMLLIElement>;
  children?: ReactElement;
}

const AutocompleteOption = ({
  props, label, option, children,
}: Props): ReactElement => {
  // eslint-disable-next-line react/prop-types
  const { className, ...otherProps } = props
  return (
    <li {...otherProps} className={`autocomplete-option ${className}`}>
      <span className="title">
        {label}
      </span>
      {
        option && (
          <span className="option">
            {` (${option})`}
          </span>
        )
      }
      <div className="children-container">
        {children}
      </div>
    </li>
  )
}

AutocompleteOption.defaultProps = {
  option: '',
}

export default AutocompleteOption
