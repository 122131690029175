import { typeObject } from 'pages/Suivi/utils'
import { getComposantsByObjFer, getComposantsByObjFerCurJalon } from 'reducers/ActionPlan/actionPlan.thunk'
import { format } from 'date-fns'
import { ObjectFerTab } from 'reducers/SA/types'
import { ThunkAction } from 'redux-thunk'
import { Action as ActionRedux } from 'redux'
import { RootState } from 'Store'
import {
  Etat,
  Statut,
  Weather,
} from 'reducers/ActionPlan/types'
import terms from 'common/terms'
import { UserRole } from 'reducers/Users/types'

type ColorTileType = (
  isSelected: boolean,
  cotation: string,
  action: boolean,
) => {
  bgColor: string,
  text: string,
}
const colorTile: ColorTileType = (isSelected, cotation, action) => {
  let bgColor = 'white'
  let text = '#838383'
  if (isSelected && cotation !== 'I') {
    bgColor = '#575F6F'
    text = 'white'
  }
  if (cotation === 'I' || action) {
    bgColor = '#C6C6C6'
    text = '#838383'
  }
  return { bgColor, text }
}

type ThunkResult<R> = ThunkAction<R, RootState, undefined, ActionRedux>;
type GetComposantType = (
  sa: string | null,
  objectFer: ObjectFerTab,
) => void
const getComposant: GetComposantType = (sa, objectFer): ThunkResult<void> => dispatch => {
  dispatch(getComposantsByObjFer({
    saId: sa,
    objectId: objectFer.id,
    type: typeObject(objectFer.type),
  }))
  dispatch(getComposantsByObjFerCurJalon({
    saId: sa,
    objectId: objectFer.id,
    type: typeObject(objectFer.type),
  }))
}

const dateToDisplay = (date: string): {date: string, hours: string} => {
  if (!date) return { date: '-', hours: '-' }
  const dateObj = new Date(date)
  const dateFr = dateObj.toLocaleDateString('fr-FR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
  const hours = dateObj.getHours()
  const minutes = dateObj.getMinutes().toString().padStart(2, '0')

  return {
    date: dateFr,
    hours: `${hours}:${minutes}`,
  }
}

type InitialDateType = () => {
  ouverture: string,
  bouclage: string,
}
const initialDate: InitialDateType = () => {
  const initialDateOuverture = format(new Date(), 'yyyy-MM-dd')
  const date = new Date()
  const dateTwoMonth = date.setMonth(date.getMonth() + 2)
  const initialDateBouclage = format(new Date(dateTwoMonth), 'yyyy-MM-dd')
  return {
    ouverture: initialDateOuverture,
    bouclage: initialDateBouclage,
  }
}

type ChipAttributesType = (
  item: string,
) => {
  bgColor: string,
  color: string,
  text: string,
}
const chipAttributes: ChipAttributesType = item => {
  let bgColor
  let color
  let text
  switch (item) {
    case Etat.TOCLEAR:
      bgColor = '#EE9207' // orange
      color = 'black'
      text = terms.actionPlan.state.toClear
      break
    case Etat.VALIDATED:
      bgColor = '#53B533' // green
      color = 'white'
      text = terms.actionPlan.state.validated
      break
    case Etat.REJECTED:
      bgColor = '#F94128' // red
      color = 'white'
      text = terms.actionPlan.state.rejected
      break
    case Statut.TODO:
      bgColor = '#FECC00' // yellow
      color = 'black'
      text = terms.actionPlan.status.toDo
      break
    case Statut.FINISHED:
      bgColor = '#2877F9  ' // blue
      color = 'black'
      text = terms.actionPlan.status.finished
      break
    case Statut.CLOSED:
      bgColor = 'black' // black
      color = 'black'
      text = terms.actionPlan.status.closed
      break
    default:
      bgColor = 'white'
      color = 'black'
      text = terms.actionPlan.state.none
  }

  return {
    bgColor,
    color,
    text,
  }
}

type WeatherType = (
  weatherNum: number,
) => Weather
const weather: WeatherType = weatherNum => {
  switch (weatherNum) {
    case 1:
      return Weather.STORMY
    case 2:
      return Weather.RAINY
    case 3:
      return Weather.CLOUDY
    case 4:
      return Weather.SUNNY
    default:
      return Weather.NONE
  }
}

type DisplayButtonActionType = (
  status: string,
  state: string,
) => {
  isValidate: boolean,
  isRejected: boolean,
}
const displayButtonAction: DisplayButtonActionType = (status, state) => {
  let isValidate = false
  let isRejected = false

  switch (status) {
    case Statut.TODO:
      switch (state) {
        case Etat.TOCLEAR:
          isValidate = true
          isRejected = true
          break
        case Etat.VALIDATED:
          isRejected = true
          break
        case Etat.REJECTED:
          isValidate = true
          break
        default:
          break
      }
      break
    case Statut.FINISHED:
    case Statut.CLOSED:
      // Les deux cas ont la même logique
      break
    default:
      isValidate = false
      isRejected = false
      break
  }

  return { isValidate, isRejected }
}

const COLUMN_NAMES_ROADMAP = ['todo', 'finished', 'closed']
const COLUMN_NAMES_COPIL = ['to-clear', 'validated', 'rejected']

type DisplayPdfButtonType = (toggle: boolean, saRole: UserRole[] | undefined) => boolean
const displayPdfButton: DisplayPdfButtonType = (toggle, saRole) => {
  if (!toggle
    && saRole?.some(role => [
      UserRole.admin,
      UserRole.animator,
      UserRole.animatorAxe,
      UserRole.decider,
    ].includes(role),
    )) {
    return true
  }
  if (toggle && saRole?.some(role => [
    UserRole.admin,
    UserRole.animator,
    UserRole.animatorAxe,
  ].includes(role))) {
    return true
  }

  return false
}

export {
  colorTile,
  getComposant,
  dateToDisplay,
  initialDate,
  chipAttributes,
  weather,
  displayButtonAction,
  COLUMN_NAMES_ROADMAP,
  COLUMN_NAMES_COPIL,
  displayPdfButton,
}
