import { ReactElement } from 'react'

interface Props {
  optionLabel: string
  inputValue: string
}

const highlightMatch = ({ optionLabel, inputValue }: Props): ReactElement => {
  const normalizeText = (text: string): string => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()

  const normalizedInput = normalizeText(inputValue)
  const normalizedOptionLabel = normalizeText(optionLabel)

  const index = normalizedOptionLabel.indexOf(normalizedInput)

  if (index === -1) {
    return <span>{optionLabel}</span>
  }

  return (
    <span>
      {optionLabel.substring(0, index)}
      <strong>{optionLabel.substring(index, index + inputValue.length)}</strong>
      {optionLabel.substring(index + inputValue.length)}
    </span>
  )
}

export default highlightMatch
