import terms from 'common/terms'
import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { changeJalon } from 'reducers/Admin/Composant/composant'
import { Jalon } from 'reducers/Admin/Composant/types'

export default function JalonPerimeter({ jalon, composantId }:
     {jalon: Jalon, composantId: number | undefined}): ReactElement {
  const dispatch = useDispatch()

  const handleChange = (jalonName: string, jalonType: boolean) => {
    dispatch(changeJalon(
      {
        jalon: {
          ...jalon,
          type: {
            ...jalon.type,
            [`${jalonName}`]: !jalonType,
          },
        },
        composant: { id: composantId },
      },
    ))
  }
  return (
    <div className="mb-2" key={jalon.numero_jalon} data-id={composantId}>
      <div className="btn-group btn-group-toggle">
        <label
          className={`btn btn-outline-grey ${jalon.type?.complexe
            ? 'btn-active' : ''}`}
          htmlFor={`complexe${composantId}${jalon.numero_jalon}`}
        >
          <input
            name="types"
            type="checkbox"
            id={`complexe${composantId}${jalon.numero_jalon}`}
            checked={jalon.type?.complexe}
            onChange={() => handleChange('complexe', jalon.type?.complexe)}
          />
          {terms.Admin.composants.complexe}
        </label>
        <label
          className={`btn btn-outline-grey ${jalon.type?.ligne
            ? 'btn-active' : ''}`}
          htmlFor={`ligne${composantId}${jalon.numero_jalon}`}
        >
          <input
            name="types"
            type="checkbox"
            id={`ligne${composantId}${jalon.numero_jalon}`}
            checked={jalon.type?.ligne}
            onChange={() => handleChange('ligne', jalon.type?.ligne)}
          />
          {terms.Admin.composants.ligne}
        </label>
        <label
          className={`btn btn-outline-grey ${jalon.type?.chantier
            ? 'btn-active' : ''}`}
          htmlFor={`travaux${composantId}${jalon.numero_jalon}`}
        >
          <input
            name="types"
            type="checkbox"
            id={`travaux${composantId}${jalon.numero_jalon}`}
            checked={jalon.type?.chantier}
            onChange={() => handleChange('chantier', jalon.type?.chantier)}
          />
          {terms.Admin.composants.chantier}
        </label>
      </div>
    </div>
  )
}
