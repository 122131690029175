import { Box, Modal } from '@mui/material'
import terms from 'common/terms'
import ActionContribution from 'pages/ActionPlan/ActionContribution/ActionContribution'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import {
  Action,
  ActionModalType,
  ActionPlanTab,
  DataAnimCopil,
  DataPilot,
  Etat,
  Statut,
} from 'reducers/ActionPlan/types'
import { UserRole } from 'reducers/Users/types'
import { RootState } from 'Store'
import ButtonsModalCopilMode from './ButtonsModalCopilMode'
import ModalActionInfos from './ModalActionInfos'

import './modalAction.scss'
import ButtonsModalRoadmap from './ButtonsModalRoadmap'
import ModalActionVisibility from './ModalActionVisibility'

interface ModalCopileModeProps {
  isOpen: boolean,
  onClose: () => void,
  type: ActionModalType
  isPilot: boolean
  handlePatchActionAnim?: (actionId: string, data: DataAnimCopil) => void
  handlePatchActionPilot?: (actionId: string, data: DataPilot) => void
  actionPlanTab: ActionPlanTab
  isRoute: boolean
  isAnimator: boolean
  isAnimax: boolean
  isObjectBorrowed: boolean
}

const ModalAction = ({
  isOpen,
  onClose,
  type,
  handlePatchActionAnim,
  handlePatchActionPilot,
  isPilot,
  actionPlanTab,
  isRoute,
  isAnimator,
  isAnimax,
  isObjectBorrowed,
}: ModalCopileModeProps): ReactElement => {
  const { idSA } = useSelector((state: RootState) => state.app)
  const { userMePlanAction } = useSelector((state: RootState) => state.users)
  const { modalAction } = useSelector((state: RootState) => state.actionPlan)
  const { entitiesVisibility } = useSelector((state: RootState) => state.contributions)
  const { account } = useSelector((state: RootState) => state.user)
  const saRole = userMePlanAction?.find(item => item.id === idSA)?.roles

  const handlePatchAction = isPilot ? handlePatchActionPilot : handlePatchActionAnim

  const canEditEtatA = (a: Action) => a.statut === Statut.FINISHED
  && !isPilot
  && !(isAnimax && isObjectBorrowed && !a.from_axe)

  const canEditEtatPA = (a: Action) => a.statut === Statut.TODO
    && (a.etat === Etat.VALIDATED || a.etat === Etat.SKIPPED) && !(isAnimax && isObjectBorrowed && !a.from_axe)

  const canEditDecision = (a: Action) => a.statut !== Statut.CLOSED
  && a.statut !== Statut.FINISHED
  && !isPilot
  && !(isAnimax && isObjectBorrowed && !a.from_axe)

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <Box
        className="modalCopileMode"
      >
        {modalAction && (
          <div className="modalCopileMode-container">
            <ModalActionInfos action={modalAction} type={type} />
            <ActionContribution
              text={modalAction.etat_avancement_animateur}
              terms={terms.actionPlan.modalActionCopile.ifnoAvancementA}
              title={terms.actionPlan.modalActionCopile.avancementA}
              meteo={modalAction.meteo_animateur}
              asWeather
              isDecider={saRole?.includes(UserRole.decider)}
              canEdit={canEditEtatA(modalAction)}
              handleClickSaveEdit={(text, meteo) => handlePatchActionAnim && handlePatchActionAnim(modalAction.id, {
                ...(text && { etat_avancement_animateur: text }),
                ...(meteo && { meteo_animateur: meteo }),
              })}
            />
            {type !== ActionModalType.limited && (
            <ActionContribution
              text={modalAction.etat_avancement_pilote}
              terms={terms.actionPlan.modalActionCopile.ifnoAvancementPA}
              title={terms.actionPlan.modalActionCopile.avancementPA}
              meteo={modalAction.meteo_pilote}
              asWeather
              isDecider={saRole?.includes(UserRole.decider)}
              canEdit={canEditEtatPA(modalAction)}
              handleClickSaveEdit={(text, meteo) => handlePatchAction && handlePatchAction(modalAction.id, {
                ...(text && { etat_avancement_pilote: text }),
                ...(meteo && { meteo_pilote: meteo }),
              })}
              doneByAnim={modalAction.done_by_anim}
            />
            )}
            {modalAction.etat !== Etat.SKIPPED && (
              <ActionContribution
                text={modalAction.decision_validation}
                terms={terms.actionPlan.modalActionCopile.ifnoDecision}
                title={terms.actionPlan.modalActionCopile.decision}
                asWeather={false}
                isDecider={saRole?.includes(UserRole.decider)}
                canEdit={canEditDecision(modalAction)}
                handleClickSaveEdit={text => handlePatchActionAnim && handlePatchActionAnim(modalAction.id, {
                  ...(text && { decision_validation: text }),
                })}
              />
            )}
            {(type === ActionModalType.unlimitedDecider && saRole?.includes(UserRole.decider)) && (
            <ModalActionVisibility
              modalAction={modalAction}
              entitiesVisibility={entitiesVisibility}
              disabled={!isPilot}
              handlePatchActionPilot={handlePatchActionPilot}
            />
            )}
            {isRoute && saRole?.some(role => role === UserRole.admin || role === UserRole.animator) && (
              <ModalActionVisibility
                modalAction={modalAction}
                entitiesVisibility={entitiesVisibility}
                disabled={!isPilot}
                handlePatchActionPilot={handlePatchActionPilot}
              />
            )}
            <div className="modalCopileMode-action-button">
              {!saRole?.includes(UserRole.decider) && actionPlanTab === ActionPlanTab.COPIL && (
                <ButtonsModalCopilMode
                  action={modalAction}
                  handlePatchActionCopil={
                    (actionId, data) => {
                      if (handlePatchAction) {
                        handlePatchAction(actionId, data)
                      }
                      onClose()
                    }
                  }
                />
              )}
              {actionPlanTab === ActionPlanTab.ROADMAP && (
                <ButtonsModalRoadmap
                  action={modalAction}
                  isPilot={modalAction.pilote?.id === account.id}
                  handlePatchActionAnim={handlePatchActionAnim}
                  handlePatchActionPilot={handlePatchActionPilot}
                  isAnimax={isAnimax}
                  isAnimator={isAnimator}
                  isObjectBorrowed={isObjectBorrowed}
                />
              )}
            </div>
          </div>
        )}
      </Box>
    </Modal>
  )
}

ModalAction.defaultProps = {
  handlePatchActionAnim: undefined,
  handlePatchActionPilot: undefined,
}

export default ModalAction
