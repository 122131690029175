import { Box, Modal, Typography } from '@mui/material'
import terms from 'common/terms'
import ObjectJalonTabs from 'components/Jalons/ObjectJalonTabs'
import ModalCloseButton from 'components/Modal/ModalCloseButton/ModalCloseButton'
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Composant } from 'reducers/Admin/Composant/types'
import {
  checkPatchComplexe, checkPatchLigne, checkPatchTravaux, closeModalPatch, selectPatchFunction,
  uncheckPatchLigne, uncheckPatchTravaux, uncheckPatchComplexe, changePatchContributorsSa,
} from 'reducers/Parametrage/Contributeurs/contributeurs.reducer'
import {
  getSaContributeursFunctions, patchSaContributeurs,
} from 'reducers/Parametrage/Contributeurs/contributeurs.thunk'
import { User } from 'reducers/Users/types'
import { RootState } from 'Store'
import ContributorsAdd from '../contributorsAdd/ContributorsAdd'
import './ModalPatchContributors.scss'

const ModalPatchContributors = (): ReactElement => {
  const dispatch = useDispatch()
  const {
    modalPatch, patchContributorSa, selectedPatchFunction, entityFunctions, patchEntityId, contributeurs,
  } = useSelector((state: RootState) => state.paramContributeurs)
  const { idSA } = useSelector((state: RootState) => state.app)

  const foundUser = contributeurs.find(c => c.cerbereId === patchContributorSa?.utilisateur?.cerbere_id)
  const user: User | null = {
    id: foundUser?.cerbereId || '',
    firstName: foundUser?.firstName || '',
    lastName: foundUser?.lastName || '',
    displayName: foundUser?.displayName || '',
    email: '',
    cp: '',
  }

  const getDefaultComposants = (numJalon: number): Composant[] => {
    const defCompo = patchContributorSa.fonction?.jalon_composants || []
    return defCompo.find(dc => dc.numero_jalon === numJalon)?.active_composants || []
  }

  const handleChange = (newComposants: Composant[], numJalon: number, reason?: string) => {
    newComposants.sort((a, b) => a.id - b.id)
    if (patchContributorSa) {
      const newSaJalons = patchContributorSa.jalons?.filter(comp => comp.numero_jalon !== numJalon)
        .concat({
          numero_jalon: numJalon,
          composants: reason === 'clear' ? getDefaultComposants(numJalon) : newComposants,
        })
      dispatch(changePatchContributorsSa({
        ...patchContributorSa,
        jalons: newSaJalons,
      }))
    }
  }

  useEffect(() => {
    if (patchEntityId) {
      dispatch(getSaContributeursFunctions({ entite: patchEntityId, active: true }))
    }
  }, [patchEntityId])

  const handleClose = () => dispatch(closeModalPatch())

  return (
    <Modal
      open={modalPatch}
      onClose={handleClose}
    >
      <Box className="contributor-modal">
        <div className="contributor-modal-header">
          <Typography>
            {terms.Settings.contributor.composantModal.title}
          </Typography>
          <ModalCloseButton handleClose={handleClose} />
        </div>
        <div className="contributor-modal-body">
          <div className="contributor-modal-column">
            <ContributorsAdd
              contributor={patchContributorSa}
              user={user}
              userFunction={selectedPatchFunction}
              functionOptions={entityFunctions}
              readOnlyUser
              onFunctionChange={(_e, newValue) => dispatch(selectPatchFunction(newValue))}
              handleCheckComplexe={elem => (_e, checked) => {
                if (elem) {
                  if (checked) {
                    dispatch(checkPatchComplexe(elem))
                  } else {
                    dispatch(uncheckPatchComplexe(elem))
                  }
                }
              }}
              handleCheckLigne={elem => (_e, checked) => {
                if (elem) {
                  if (checked) {
                    dispatch(checkPatchLigne(elem))
                  } else {
                    dispatch(uncheckPatchLigne(elem))
                  }
                }
              }}
              handleCheckTravaux={elem => (_e, checked) => {
                if (elem) {
                  if (checked) {
                    dispatch(checkPatchTravaux(elem))
                  } else {
                    dispatch(uncheckPatchTravaux(elem))
                  }
                }
              }}
              handleValidate={() => dispatch(patchSaContributeurs({
                saId: idSA,
                saContributorId: patchContributorSa.id,
                contributor: patchContributorSa,
                user,
              }))}
              validateLabel={terms.Common.valid}
            />
          </div>
          <div className="contributor-modal-column overflow-hidden">
            <p>{terms.Settings.contributor.composantModal.jalonTabTitle}</p>
            <ObjectJalonTabs
              objectJalonComposants={patchContributorSa.jalons || []}
              getDefaultComposants={getDefaultComposants}
              handleChange={handleChange}
              clearText={terms.Settings.resetContribJalon}
            />
          </div>
        </div>
      </Box>
    </Modal>
  )
}

export default ModalPatchContributors
