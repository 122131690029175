import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  getAll,
  getUserInfos,
  getUserMe,
  getUserMeActionAxeToggle,
  getUserMeAxe,
  getUserMeAxeToggle,
  getUserMeEntitySupervisor,
  getUserMePlanAction,
  getUserMeSettings,
  getUserMeSuivi,
  getUsersListInfo,
  postUserMeActionAxeToggle,
  postUserMeAxeToggle,
} from './users.thunk'
import { UserState } from './types'
import { User } from './cerbereTypes'

const initialState: UserState = {
  users: [],
  userMe: {
    id: '',
    territories: [],
    axes: [],
  },
  userMeSettings: [],
  userMeSuivi: [],
  userMePlanAction: [],
  usersEntitySupervisor: null,
  robPermission: [],
  usersInfoList: [],
  isLoading: false,
  userInfosId: null,
  userInfos: null,
  displayUserInfos: false,
  isAxeToggle: false,
  isAxeActionToggle: false,
}

export const users = createSlice({
  name: 'users',
  initialState,
  reducers: {
    defineRole: (state, action: PayloadAction<string[]>) => {
      state.robPermission = action.payload
    },
    setDisplayUserInfos: (state, action: PayloadAction<boolean>) => {
      state.displayUserInfos = action.payload
    },
    setUserInfosId: (state, action: PayloadAction<string | null>) => {
      state.userInfosId = action.payload
    },
    setUserInfos: (state, action: PayloadAction<User | null>) => {
      state.userInfos = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getAll.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.users = payload.filter(user => user.enabled).map(user => ({
        email: user.email || '',
        firstName: user.firstName || '',
        cp: user.username || '',
        lastName: user.lastName || '',
        displayName: user.displayName || '',
        id: user.id || '',
      }))
    })
    builder.addCase(getAll.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(getAll.pending, state => {
      state.users = []
      state.isLoading = true
    })
    builder.addCase(getUserMe.fulfilled, (state, { payload }) => {
      state.userMe.territories = payload
    })
    builder.addCase(getUserMeAxe.fulfilled, (state, { payload }) => {
      state.userMe.axes = payload
    })
    builder.addCase(getUsersListInfo.fulfilled, (state, { payload }) => {
      state.usersInfoList = payload
    })
    builder.addCase(getUserMeSettings.fulfilled, (state, { payload }) => {
      state.userMeSettings = payload
    })
    builder.addCase(getUserMeSuivi.fulfilled, (state, { payload }) => {
      state.userMeSuivi = payload
    })
    builder.addCase(getUserMePlanAction.fulfilled, (state, { payload }) => {
      state.userMePlanAction = payload
    })
    builder.addCase(getUserInfos.fulfilled, (state, { payload }) => {
      if (payload) {
        state.userInfos = payload
      }
    })
    builder.addCase(getUserInfos.pending, state => {
      state.userInfos = null
    })
    builder.addCase(getUserMeEntitySupervisor.fulfilled, (state, { payload }) => {
      state.usersEntitySupervisor = payload || null
    })
    builder.addCase(getUserMeAxeToggle.fulfilled, (state, { payload }) => {
      state.isAxeToggle = payload.axe_toggle
    })
    builder.addCase(postUserMeAxeToggle.fulfilled, (state, { payload }) => {
      state.isAxeToggle = payload.axe_toggle
    })
    builder.addCase(getUserMeActionAxeToggle.fulfilled, (state, { payload }) => {
      state.isAxeActionToggle = payload.action_axe
    })
    builder.addCase(postUserMeActionAxeToggle.fulfilled, (state, { payload }) => {
      state.isAxeActionToggle = payload.action_axe
    })
  },
})

export const {
  defineRole, setDisplayUserInfos, setUserInfosId, setUserInfos,
} = users.actions

export default users.reducer
