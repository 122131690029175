import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PortfolioItem, PortfolioState } from './type'
import {
  getLignes, getPortfolio, getRobustestComplexes, postOrPatchPortfolio,
} from './portfolio.thunk'
import { Entity } from '../Entity/types'

const initialState: PortfolioState = {
  portfolioList: [],
  displaySnackbar: false,
  isLoadingPortfolio: false,
  lignes: [],
  robustestComplexes: [],
}

export const portfolio = createSlice({
  name: 'axes',
  initialState,
  reducers: {
    addPortfolio: state => {
      if (state.portfolioList.find(item => item.id === 'new')) {
        return
      }
      state.portfolioList = [...state.portfolioList, {
        libelle: '',
        id: 'new',
        batiments_voyageurs: [],
        travaux: [],
        lignes: [],
        entite: { id: '', libelle: '', active: false } as Entity,
        generic: true,
      } as PortfolioItem]
    },
    changePortfolio: (state, action: PayloadAction<Partial<PortfolioItem>>) => {
      const index = state.portfolioList.findIndex(item => item.id === action.payload.id)
      state.portfolioList[index] = { ...state.portfolioList[index], ...action.payload }
    },
    deleteNewPortfolio: state => {
      const index = state.portfolioList.findIndex(item => item.id === 'new')
      if (index !== -1) { state.portfolioList.splice(index, 1) }
    },
    hideSnackbar: state => {
      state.displaySnackbar = false
    },
  },
  extraReducers: builder => {
    builder.addCase(getLignes.fulfilled, (state, action) => {
      state.lignes = action.payload
    })
    builder.addCase(getPortfolio.pending, state => {
      state.isLoadingPortfolio = true
    })
    builder.addCase(getPortfolio.rejected, state => {
      state.isLoadingPortfolio = false
    })
    builder.addCase(getPortfolio.fulfilled, (state, action) => {
      state.portfolioList = action.payload
      state.isLoadingPortfolio = false
    })
    builder.addCase(postOrPatchPortfolio.fulfilled, state => {
      state.displaySnackbar = true
    })
    builder.addCase(getRobustestComplexes.fulfilled, (state, action) => {
      state.robustestComplexes = action.payload
    })
  },
})

export const {
  addPortfolio, deleteNewPortfolio, hideSnackbar, changePortfolio,
} = portfolio.actions

export default portfolio.reducer
